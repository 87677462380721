import Util from '../../../../services/util'
const util = Util.getInstance()

type get_evidence_template_list ={
  templateName?:string,
  createUser?:string|number,
  size: string|number,
  current: string|number
}

const getEvidenceTemplateList:(values:get_evidence_template_list) => Promise<any> =(values:get_evidence_template_list) => {
  return util.httpGet('/sinzetech-evidence/secure-evidence-templates',values).then(res => res)
}

// 新增模板
const addTemplateName = (params:any) => {
  return util.httpPost('/sinzetech-evidence/secure-evidence-templates', params)
  .then(res => res)
  .catch(err => err)
} 
// 客户管理-详情-存证模板详情
const getTemplateInfo = (id:any) => {
  return util.httpGet(`/sinzetech-evidence/customer/${id}`)
  .then(res => res)
  .catch(err => err)
}
// 存证模板详情
const getInfos = (id?:any) => {
  return util.httpGet(`/sinzetech-evidence/secure-evidence-templates/${id}`)
  .then(res => res)
}

// 客户存证场景启用或者停用
const changeStatus = (id:any, status?:any) => {
  return util.httpPutUrl(`/sinzetech-evidence/customer/${id}?status=${status}`,)
  .then(res => res)
  .catch(err => err)
}
export{
  getEvidenceTemplateList,
  addTemplateName,
  getTemplateInfo,
  changeStatus,
  getInfos
}