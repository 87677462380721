import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import {
    BankOutlined,
    UserOutlined,
    ProfileOutlined,
    PayCircleOutlined,
} from "@ant-design/icons";
import TopNavbar from "../../components/TopNavbar";
import LeftNavbar from "../../components/LeftNavbar";
import { useSelector } from "react-redux";

const list = [
    {
        name: "融资订单",
        path: "/supply/financingOrders",
        icon: <ProfileOutlined />,
    },
    {
        name: "核心企业准入",
        path: "/supply/enterpriseAccess",
        icon: <BankOutlined />,
    },
    {
        name: "供应商准入",
        path: "/supply/supplierAccess",
        icon: <UserOutlined />,
    },
    {
        name: "融资产品",
        path: "/supply/financingProducts",
        icon: <PayCircleOutlined />,
    },
];

const Supply = () => {
    const codeList: any =
        useSelector<any>((state: any) => state.codeList) || [];
    console.log(codeList, "codeList");
    const navigate = useNavigate();
    const location = useLocation();
    const [lists, setLists] = useState<any>([]);
    // const roleList = useSelector((state: any) => state.roleCodes);

    // useEffect(() => {
    //     let arr: any = [];
    //     for (let i = 0; i < list.length; i++) {
    //         if (!list[i].code || list[i].code === "") {
    //             arr.push(list[i]);
    //         } else {
    //             if (!!codeList && codeList.includes(list[i].code)) {
    //                 arr.push(list[i]);
    //             }
    //         }
    //     }
    //     if (arr.length) {
    //         let path = location?.pathname;
    //         if (path === "/system") {
    //             navigate(arr[0]?.path);
    //         }
    //     }
    //     console.log(arr, "arr");
    //     setLists(arr);
    // }, [codeList, location]);
    return (
        <div className="system-container">
            <TopNavbar config={{ value: "supply" }} />
            <div className="system-container-content">
                <LeftNavbar lists={list} />

                <Outlet></Outlet>
            </div>
        </div>
    );
};
export default Supply;
