import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import BreadCrumbBar from "@src/components/BreadCrumbBar";
import TableCom from "@src/components/TableCom";
import SearchForm from "@src/components/FormItem";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import styles from "../../index.module.scss";
import { statusOptions } from "./config";
import { getSupplyList } from "../promise";

const breads = [
    {
        label: "供应链金融",
        path: "/supply",
    },
    {
        label: "供应商准入",
        path: "",
    },
];
const FinancingOrders = () => {
    const navigate = useNavigate();
    const [pageOptions, setPageOptions] = useState({
        pageSize: 20,
        current: 1,
    });
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState<any>({});
    const [list, setList] = useState([]);
    const getList = async (object?: any) => {
        setLoading(true);
        try {
            const params = {
                size: pageOptions.pageSize,
                current: pageOptions.current,
                ...object,
            };
            if (object?.current && object?.size) {
                setPageOptions({
                    current: object?.current,
                    pageSize: object?.size,
                });
            }
            const res = await getSupplyList(params);
            setLoading(false);
            if (res?.code === 200) {
                setList(res?.data?.records);
                setTotal(res?.data?.total);
            }
        } catch {
            setLoading(false);
        }
    };
    useEffect(() => {
        getList();
    }, []);
    const onSearch = (values: any) => {
        const params: any = {
            approvalStatus: values.approvalStatus,
            keyWords: values.keyword,
        };
        if (values?.accessTime && values?.accessTime[0]) {
            params.accessTimeStart = dayjs(values.accessTime[0]).format(
                "YYYY-MM-DD" + " 00:00:00"
            );
            params.accessTimeEnd = dayjs(values.accessTime[0]).format(
                "YYYY-MM-DD" + " 23:59:59"
            );
        }
        console.log(params, "params");
        setSearch(params);
        getList({
            current: 1,
            size: 20,
            ...params,
        });
    };
    // search
    const handlePage = (params: any) => {
        getList({
            ...search,
            current: params.current,
            size: params.pageSize,
        });
    };
    // const onSearch = (values: any) => {
    //   const params: any = {
    //     approvalStatus: values.approvalStatus,
    //     keyword: values.keyword,
    //   };
    const formColumns = [
        {
            label: "状态",
            dataIndex: "approvalStatus",
            type: "Select",
            params: {
                placeholder: "请选择",
                options: statusOptions,
            },
        },
        {
            label: "准入时间",
            dataIndex: "accessTime",
            type: "DatePickers",
        },
        {
            label: "",
            dataIndex: "keyword",
            type: "Input",
            params: {
                placeholder: "供应商名称",
                suffix: <SearchOutlined />,
                style: {
                    width: "200px",
                },
            },
        },
    ];
    const columns = [
        {
            title: "供应商名称",
            dataIndex: "name",
        },
        {
            title: "状态",
            dataIndex: "approvalStatus",
            render: (record: any) => {
                const obj = statusOptions.find(
                    (item: any) => item.value === record
                );
                return <span>{obj?.label}</span>;
            },
        },
        {
            title: "准入时间",
            dataIndex: "accessTime",
        },
        {
            title: "操作",
            fixed: "right",
            render: (record: any) => {
                return (
                    <div>
                        <Button
                            type="link"
                            onClick={() => {
                                navigate(
                                    `/supply/supplierAccess/edit/${record?.id}`
                                );
                            }}
                        >
                            更新
                        </Button>
                        <Button
                            type="link"
                            onClick={() => {
                                navigate(
                                    `/supply/supplierAccess/info/${record?.id}`
                                );
                            }}
                        >
                            详情
                        </Button>
                    </div>
                );
            },
        },
    ];
    // const columns = [
    //   {
    //     title: "供应商名称",
    //     dataIndex: "name",
    //   },
    //   {
    //     title: "状态",
    //     dataIndex: "approvalStatusName",
    //   },
    //   {
    //     title: "准入时间",
    //     dataIndex: "accessTime",
    //   },
    //   {
    //     title: "操作",
    //     fixed: "right",
    //     render: (record: any) => {
    //       return (
    //         <div>
    //           <Button
    //             type="link"
    //             onClick={() => {
    //               navigate(`/supply/supplierAccess/edit/${record?.id}`);
    //             }}
    //           >
    //             更新
    //           </Button>
    //           <Button
    //             type="link"
    //             onClick={() => {
    //               navigate(`/supply/supplierAccess/detail/${record?.id}`);
    //             }}
    //           >
    //             详情
    //           </Button>
    //         </div>
    //       );
    //     },
    //   },
    // ];
    return (
        <div className={styles.supply_container}>
            <BreadCrumbBar breads={breads}></BreadCrumbBar>
            <div className={styles.supply_search}>
                <SearchForm
                    formParams={formColumns}
                    onSearch={onSearch}
                ></SearchForm>
            </div>
            <div className={styles.supply_tables}>
                {/* <div className={styles.supply_tables_button}>
                    <Button type="primary">添加供应商</Button>
                </div> */}
                <TableCom
                    rowKey={(record: any) => record?.id}
                    dataSource={list}
                    columns={columns}
                    pagination={{
                        ...pageOptions,
                        total,
                    }}
                    scroll={{ x: true }}
                    loading={loading}
                    onChange={handlePage}
                ></TableCom>
            </div>
        </div>
    );
};

export default FinancingOrders;
