import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import TopNavbar from "../../components/TopNavbar";
import LeftNavbar from "../../components/LeftNavbar";
import { TableOutlined, DashboardOutlined } from '@ant-design/icons'
import { useSelector } from "react-redux";
import { message } from 'antd'

const arr: any = [
  {
    name: "客户列表",
    path: "/customer/management",
    icon: <DashboardOutlined />,
    code: 'crm_customer_list_list'
  },
]
const Customer = () => {
  const location = useLocation();
  const roles = useSelector((state: any) => state.codeList)
  const [list, setList] = useState(arr)
  useEffect(() => {

    if (!roles) {
      setList(arr)
    } else {
      const newList = arr.filter((item: any) => {
        return roles.some((res: any) => res === item.code)
      })
      setList(newList)
    }

  }, [location])
  return (
    <div className="evidence-container">
      <TopNavbar config={{ value: "customer" }} />
      <div className="evidence-container-content">
        {
          list ? <LeftNavbar lists={list} /> : null
        }

        <Outlet></Outlet>
      </div>
    </div>
  )
}

export default Customer