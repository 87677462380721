import { Input, Modal, Select, message, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import "./edit.scss";
import Util from "../../../../../../../services/util";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
const util = Util.getInstance();
const { TextArea } = Input;
interface EditProps {
  cancelModal: (isRefresh?: boolean) => void;
  infos: any;
}
const statusMap = {
  RESERVE_CUSTOMERS: "储备客户",
  CONTINUOUS_FOLLOW_UP: "持续跟进",
  SIGNED: "已签约",
};
const EditBaseInfo = ({ cancelModal, infos }: EditProps) => {
  let params = useParams<{ id: string }>();
  let [userList, setUserList] = useState<any[]>([]);
  let [formInfo, setFormInfo] = useState<any>({});
  useEffect(() => {
    setFormInfo({
      ...infos,
      businessSalesmanList: infos.businessSalesmanList.map(
        (item: any) => item.id
      ),
      businessOwner: infos.businessOwner.id || "",
      firstContactTime: infos.firstContactTime && dayjs(infos.firstContactTime),
    });
    getUserList();
  }, []);
  const getUserList = () => {
    util
      .httpGet(`/sinzetech-admin-user/users`, {
        current: 1,
        size: 10000,
      })
      .then((res) => {
        setUserList(res.data?.records);
      });
  };
  const formInfoChange = (value: any, key: string) => {
    formInfo[key] = value;
    setFormInfo({ ...formInfo });
  };
  const submit = () => {
    delete formInfo.id;
    delete formInfo.createUser;
    util
      .httpPutUrl(`/sinzetech-crm/customer/${params.id}`, {
        ...formInfo,
        firstContactTime:
          formInfo.firstContactTime &&
          dayjs(formInfo.firstContactTime).format("YYYY-MM-DD"),
      })
      .then((res) => {
        message.success("操作成功");
        cancelModal(true);
      });
  };
  return (
    <>
      <Modal
        className="edit_base_info"
        title="编辑基本信息"
        open={true}
        width={1000}
        onOk={submit}
        onCancel={() => cancelModal()}
      >
        <div className="info_item">
          <div className="info_item_tip">客户名称：</div>
          <Input
            className="input"
            value={formInfo?.companyName}
            maxLength={20}
            onChange={(ev) => {
              formInfoChange(ev.target.value.trim(), "companyName");
            }}
          />
        </div>
        <div className="info_item">
          <div className="info_item_tip">创建人：</div>
          {infos?.createUser?.name}
        </div>
        <div className="info_item">
          <div className="info_item_tip">创建时间：</div>
          {infos?.createTime}
        </div>
        <div className="info_item">
          <div className="info_item_tip">状态：</div>
          {statusMap[infos?.customerStatus]}
        </div>
        <div className="info_item">
          <div className="info_item_tip">来源：</div>
          <Select
            placeholder="请选择"
            className="input"
            value={formInfo?.customerSource}
            onChange={(value) => {
              formInfoChange(value, "customerSource");
            }}
          >
            <Select.Option value="SELF_EXCAVATION">自行发掘</Select.Option>
            <Select.Option value="CUSTOMER_INTRODUCTION">
              客户介绍
            </Select.Option>
            <Select.Option value="NETWORK_PROMOTION">网络推广</Select.Option>
            <Select.Option value="SOCIAL_ACTIVITIES">社会活动</Select.Option>
            <Select.Option value="CUSTOMER_PROACTIVELY_CONTACTED">
              客户主动联系
            </Select.Option>
            <Select.Option value="OTHER">其他</Select.Option>
          </Select>
        </div>
        <div className="info_item">
          <div className="info_item_tip">客户类型：</div>
          <Select
            placeholder="请选择"
            className="input"
            value={formInfo?.companyType}
            onChange={(value) => {
              formInfoChange(value, "companyType");
            }}
          >
            <Select.Option value="CONSERVATION_PLANT">利废工厂</Select.Option>
            <Select.Option value="TRADING_COMPANY">贸易公司</Select.Option>
            <Select.Option value="FREIGHT_YARD_CATTLE">货场黄牛</Select.Option>
          </Select>
        </div>
        <div className="info_item">
          <div className="info_item_tip">重要程度：</div>
          <Select
            placeholder="请选择"
            className="input"
            value={"" + formInfo?.importance}
            onChange={(value) => {
              formInfoChange(value, "importance");
            }}
          >
            <Select.Option value="1">极高</Select.Option>
            <Select.Option value="2">高</Select.Option>
            <Select.Option value="3">中</Select.Option>
            <Select.Option value="4">低</Select.Option>
            <Select.Option value="5">极低</Select.Option>
          </Select>
        </div>
        <div className="info_item">
          <div className="info_item_tip">行业：</div>
          <Select
            placeholder="请选择"
            className="input"
            value={formInfo?.trade}
            onChange={(value) => {
              formInfoChange(value, "trade");
            }}
          >
            <Select.Option value="RENEWABLE_RESOURCES">再生资源</Select.Option>
            <Select.Option value="MINERAL">矿石</Select.Option>
          </Select>
        </div>
        <div className="info_item">
          <div className="info_item_tip">采购品类：</div>
          <Select
            placeholder="请选择"
            className="input"
            value={formInfo?.purchaseCategory}
            onChange={(value) => {
              formInfoChange(value, "purchaseCategory");
            }}
          >
            <Select.Option value="STEEL_SCRAP">废钢</Select.Option>
            <Select.Option value="BROKE">废纸</Select.Option>
            <Select.Option value="WASTE_PLASTICS">废塑料</Select.Option>
            <Select.Option value="WASTE_COLOR">废有色</Select.Option>
            <Select.Option value="ORE">矿石</Select.Option>
            <Select.Option value="OTHER">其他</Select.Option>
          </Select>
        </div>
        <div className="info_item">
          <div className="info_item_tip">年采购量：</div>
          <Input
            className="input"
            value={formInfo?.yearPurchase}
            onChange={(ev) => {
              formInfoChange(ev.target.value, "yearPurchase");
            }}
            maxLength={50}
          />
          {/* <Select
                        value={formInfo?.yearPurchaseUnit}
                        style={{ width: 80 }}
                        onChange={(value) => {
                            formInfoChange(value, "yearPurchaseUnit");
                        }}
                    >
                        <Select.Option value="T">吨</Select.Option>
                        <Select.Option value="KG">千克</Select.Option>
                        <Select.Option value="G">克</Select.Option>
                        <Select.Option value="PIECE">个</Select.Option>
                        <Select.Option value="TOWER">台</Select.Option>
                    </Select> */}
        </div>
        <div className="info_item">
          <div className="info_item_tip">经营地：</div>
          <Input
            className="input"
            value={formInfo?.manageAddress}
            maxLength={40}
            onChange={(ev) => {
              formInfoChange(ev.target.value.trim(), "manageAddress");
            }}
          />
        </div>
        <div className="info_item">
          <div className="info_item_tip">基本情况：</div>
          {/* <Input
                        className="input"
                        value={formInfo?.mainAppeal}
                        maxLength={1000}
                        onChange={(ev) => {
                            formInfoChange(
                                ev.target.value.trim(),
                                "mainAppeal"
                            );
                        }}
                    /> */}
          <TextArea
            value={formInfo?.mainAppeal}
            placeholder="请输入"
            maxLength={200}
            className="input"
            onChange={(ev) => {
              formInfoChange(ev.target.value.trim(), "mainAppeal");
            }}
          />
        </div>
        <div className="info_item">
          <div className="info_item_tip">业务负责人：</div>
          <Select
            placeholder="请选择"
            className="input"
            value={formInfo?.businessOwner}
            onChange={(value, opt: any) => {
              formInfoChange(value, "businessOwner");
            }}
          >
            {userList.map((item, index) => {
              return (
                <Select.Option value={item.id} name={item.name} key={index}>
                  {item.name}
                </Select.Option>
              );
            })}
          </Select>
        </div>
        <div className="info_item">
          <div className="info_item_tip">业务员：</div>
          <Select
            placeholder="请选择"
            className="input"
            mode="multiple"
            value={formInfo?.businessSalesmanList || []}
            onChange={(value) => {
              formInfoChange(value, "businessSalesmanList");
            }}
          >
            {userList.map((item, index) => {
              return (
                <Select.Option value={item.id} key={index}>
                  {item.name}
                </Select.Option>
              );
            })}
          </Select>
        </div>
        <div className="info_item">
          <div className="info_item_tip">初次接触日期：</div>
          <DatePicker
            value={formInfo.firstContactTime}
            onChange={(e) => {
              formInfoChange(e, "firstContactTime");
            }}
          ></DatePicker>
        </div>
      </Modal>
    </>
  );
};

export default EditBaseInfo;
