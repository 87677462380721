import React,{ReactNode, memo, useContext} from 'react'
import classnames from 'classnames'
import './index.scss'
import { DetailPageContext } from '../PageContainer'
import { Skeleton } from 'antd'
import BreadCrumbBar from '@src/components/BreadCrumbBar'

type props = {
  // 面包屑数据结构
  breadCrumb: {label:string,path?:string}[]
  title?: ReactNode
  rightContent?: ReactNode
}
export type DetailPageHeaderProps =  props & Omit<React.HTMLAttributes<HTMLDivElement>,'title'>
export const DetailPageHeader:React.FC<React.PropsWithChildren<DetailPageHeaderProps>> = memo((props)=>{
  const {className,title,breadCrumb,rightContent,...rest} = props
  const context = useContext(DetailPageContext)
  return <div {...rest} className={classnames('detail-page-header',className)}>
    <BreadCrumbBar breads={breadCrumb} style={{margin:0}}></BreadCrumbBar>
    {context?.loading?<Skeleton />:<div className={classnames('detail-page-header-pane')}>
      <div className={classnames('detail-page-header-left-content')}>
        <div className={classnames('detail-page-header-title')}>{title}</div>
        <div className={classnames('detail-page-header-content')}>{props.children}</div>
      </div>
      <div className={classnames('detail-page-header-right-content')}>
        {rightContent}
      </div>  
    </div>}
  </div>
})