import React from 'react';
import { useEffect, useState } from 'react';
import {Button, Table} from 'antd'
import FormItemCom from '../../../../components/FormItem'
import BreadCrumbBar from '../../../../components/BreadCrumbBar'
import { Link, useNavigate  } from 'react-router-dom';
import './index.scss'
import {getFieldList} from "./Promise";
import TableCom from '../../../../components/TableCom';

const breadsList =[
    {
        label:'存证平台',
        path: '/evidence'
    },
    {
        path: '/evidence/platform',
        label:'平台配置'
    },
    {
        path:'',
        label:'存证字段维护'
    }
]
const selectOptions =[
    {
        label:'图片',
        value:'photo',
      },{
        label:'文件',
        value:'file',
      },{
        label:'视频',
        value:'video',
    
      },{
        label:'音频',
        value:'audio',
        step: 3,
        type: 'file'
    
      },{
        label:'文本',
        value:'text',
        step: 3,
        type: 'text'
    
      },{
        label:'时间',
        value:'time',
        step: 3,
        type: 'date'
    
      },{
        label:'日期',
        value:'date',
        step: 3,
        type: 'date'
    
      },{
        label:'下拉选项',
        value:'select',
        step: 2,
        type: 'select'
      },
      {
        label:'金额',
        value:'money',
        step: 3,
        type: 'number'
      },{
        label:'重量',
        value:'weight',
        step: 3,
        type: 'number'
    
      },{
        label:'手机号',
        value:'phone',
        step: 3,
        type: 'text'
    
      },{
        label:'身份证号',
        value:'id_num',
        step: 3,
        type: 'text'
    
      },{
        label:'车牌',
        value:'vehicle',
        step: 3,
        type: 'text'
    
      },{
        label:'邮箱',
        value:'email',
        step: 3,
        type: 'text'
      },{
        label:'坐标',
        value:'coordinate',
        step: 2,
        type: 'number'
      }
]
const Fields = () => {
    const [list, setList] = useState([])
    const [search, setSearchParams] = useState<any>({})
    // const tableRef = useRef<null | HTMLDivElement>(null);
    // const [scrollY, setScrollY] = useState<number>(0);
    const navigate = useNavigate()
    const [pageOptions, setPageOptions] = useState({
        pageSize: 10,
        current: 1,
        total: 0
    })
    const formColumns = [
        {
            label:'字段名称',
            dataIndex: 'name',
            type:'Input',
            params:{ 
                placeholder: '请输入',
                allowClear: true,
                maxLength: 10
            }
        },
        {
            label:'配置人',
            dataIndex: 'username',
            type:'Input',
            params:{
                placeholder: '请输入',
                allowClear: true

            }
        },
        {
            label:'字段类型',
            dataIndex: 'componentTypes',
            type:'Select',
            params:{
                placeholder:"请选择",
                options:selectOptions
            }
        }
    ]
    const columns = [
        {
            title:'字段名称',
            dataIndex:'name',
        },{
            title:'配置人',
            dataIndex: 'createUsername'
        },
        {
            title:'字段类型',
            dataIndex:'componentType',
            render:(record:any) => {
                const str = record.toLowerCase()
                const res = selectOptions.find((item:any) => item.value === str)
                return <span>{res?.label}</span>
            }
        },
        {
            title:'操作',
            render:(record:any) => {
                // return <Link to={`/evidence/platform/field/add?id=${record?.id}`}>编辑</Link>
                return <Button type='link' onClick={() => {
                    navigate('/evidence/platform/field/add',{
                        state:{
                            id:record?.id
                        }
                    })
                }}>编辑</Button>
            }
        }
    ]


    const getFields= (object:any) => {
        const data={
            size: object?.pageSize || 10,
            current: object?.current || 1,
            ...object
        }
        getFieldList(data)
            .then(res => {
                if(res?.code === 200){
                    setList(res?.data?.records)
                    setPageOptions({
                        current: +res?.data?.current,
                        pageSize: +res?.data?.size,
                        total: +res?.data?.total
                    })
                }
            })
    }
    const handleSearch = (values:any) => {
        console.log(values, 'nnnnnnnn')
        let arr:any = []
        const comp = values?.componentTypes !== undefined? values.componentTypes.toUpperCase():''
        arr.push(comp)
        let params ={
            ...values,
            componentTypes:comp,
            size: 10,
            current: 1,
            name: values?.name?.trim(),
            username:values?.username?.trim()
        }
        setSearchParams({
            name: values?.name,
            createUser: values?.createUser,
            type: values?.type
        })
        if(JSON.stringify(values) === '{}'){
            params = {}
        }
        getFields(params)
    }
    const handlePage = (values:any) => {
        const params ={
            ...search,
            size: values.pageSize,
            current: values.current
        }
        getFields(params)
    }

    useEffect((): void => {
        // const y = tableRef?.current?.clientHeight ?? 0;
        // setScrollY(y);
        getFields({
            current:1,
            size:10
        })
    }, []);

    return (
        <div className='files_container'>
            <div className='content'>
                <BreadCrumbBar breads={breadsList}></BreadCrumbBar>
                <FormItemCom formParams={formColumns} onSearch={handleSearch}></FormItemCom>
                <div className='create_button'>
                    <Link to='/evidence/platform/field/add'>
                        <Button type='primary'>+新增字段</Button>
                    </Link>
                </div>
                {/* <div className="field-content-home-table"> */}
                    <TableCom
                    rowKey={(record:any) => record?.id}
                    columns={columns}
                    pagination={pageOptions}
                    dataSource={list}
                    onChange={handlePage}
                    scroll={{
                        y:600,
                        w:1200
                    }}
                    ></TableCom>
                {/* </div> */}
            </div>
        </div>
    )
}
export default Fields;