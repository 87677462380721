import React, { memo } from "react";
import Bank from "../../../../public/json/bank.json";
import Region from "../../../../public/json/region.json";
import { Cascader, Col, Form, Input, Row, Select, message } from "antd";
import axios from "axios";
import { useRequest } from "ahooks";
import { FormInstance } from "antd/lib/form";
import { Basics_URL } from "@src/services/api/invoice/invoice";
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};
const handleData = (start: any[]) => {
  const arr: any = start.map((item) => {
    if (item?.children?.length) {
      return {
        value: item,
        label: item.name,
        children: handleData(item.children),
      };
    } else {
      return {
        value: item,
        label: item.name,
        children: [],
      };
    }
  });
  return arr;
};
const colProps = {
  span: 12,
};
const defaultProps = {};
type props = {
  form: FormInstance
};
export type EditBankBranchFormFieldsProps = Required<typeof defaultProps> &
  props &
  React.HTMLAttributes<HTMLDivElement>;
export const EditBankBranchFormFields: React.FC<
  React.PropsWithChildren<EditBankBranchFormFieldsProps>
> = memo((props) => {
  const {form} = props

  const cityService = useRequest(()=>axios.get(Basics_URL + "/city/tree?maxDeep=2").then(res=>handleData(res.data.data)))
  const bankService = useRequest(()=>axios.get(Basics_URL + "/bank").then(res=>res.data.data.records))
  const bankBranchService = useRequest(
    ({
      ...params
    }: {
      cityCode: string;
      bankCode: string;
      keyword: string;
    }) =>
      axios
        .get<
          Common.ResponseData<
            Common.Paging<{ bankName: string; cnaps: string }>
          >
        >(Basics_URL+'/bankcnaps', {
          params: {
            ...params,
          },
        })
        .then((res) => {
          return res.data.data.records
        }),
    {
      debounceWait: 600,
      manual: true,
      onError() {
        message.error("搜索失败，请重试");
      },
    }
  );
  const searchHandle = (value: string) => {
    const { zsBankCode, cityCode } = form.getFieldsValue();
    if (cityCode && zsBankCode) {
      bankBranchService.run({
        cityCode: cityCode,
        bankCode: zsBankCode,
        keyword: value,
      });
    } else {
      message.error("请选择开户银行和省份");
      // form.validateFields(["cityCode", "zsBankCode"]);
    }
  };
  return (
    <>
      <Row>
        <Col {...colProps} span={colProps.span * 2}>
          <Form.Item hidden name='cityCode'></Form.Item>
          <Form.Item
            labelCol={{ span: layout.labelCol.span / 2 }}
            wrapperCol={{
              span: colProps.span * 2 - layout.labelCol.span / 2,
            }}
            label="开户行查询"
            style={{ flexWrap: "nowrap" }}
          >
            <Form.Item name="zsBankCode" style={{ display: "inline-block" }}>
              <Select
                style={{ width: 230 }}
                onSelect={(_, option) => {
                  // form.setFieldsValue({
                  //   bankName: option.label,
                  //   bankCode: option.id,
                  // });
                }}
                showSearch
                placeholder="请选择银行"
                optionFilterProp="label"
                options={bankService.data?.map((item: any) => ({
                  value: item.code,
                  label: item.name,
                  id: item.code,
                }))}
              />
            </Form.Item>
            <Form.Item
              name="city"
              rules={
                [
                  // { required: true, message: "请搜索选择省份" },
                  // { validator: validator }
                ]
              }
              style={{
                display: "inline-block",
                // width: "calc(35% - 8px)",
                marginLeft: "8px",
              }}
            >
              <Cascader
                style={{width:230}}
                onChange={(value: any) => {
                  if(value?.[1]?.code)
                    form.setFieldsValue({'cityCode':value?.[1]?.code,city: value.map((c: any) => c.name)})
                }}
                options={cityService.data}
                //  options={addressData} onChange={changeSearch} fieldNames={optionName}
                placeholder="请搜索选择省份"
                allowClear
                showSearch
              />
            </Form.Item>
            <Form.Item
              // name="searchBankBranchName"
              rules={[
                { required: true, message: "请输入开户行关键词查询" },
                // { validator: validator }
              ]}
              style={{
                display: "inline-block",
                //   width: "calc(40% - 8px)",
                marginLeft: "8px",
              }}
            >
              <Select
                onClick={()=>{
                  searchHandle('')
                }}
                placeholder="请输入开户行关键词查询"
                style={{ width: 300 }}
                onSearch={(value)=>{
                  searchHandle(value)
                }}
                onSelect={(value,option)=>{
                  form.setFieldsValue({
                    accountBankNo: option.value,
                    accountBankName: option.label,
                  })
                }}
                // onSelect={selectHandle}
                options={bankBranchService.data?.map(
                    (item) => {
                      return {
                        value: item.cnaps,
                        label: item.bankName,
                        data: item,
                      };
                    }
                  ) }
                showSearch
              ></Select>
              {/* <AutoComplete
                        // options={bankBranchService.data?.map(
                        //   (item: { branchNo: any; branchName: any }) => {
                        //     return {
                        //       id: item.branchNo,
                        //       value: item.branchName,
                        //       label: item.branchName,
                        //       data: item,
                        //     };
                        //   }
                        // )}
                        // onSelect={selectHandle}
                      >
                        <Input.Search
                          style={{ width: 300 }}
                          // className={styles.searchSty}
                          onSearch={searchHandle}
                          allowClear
                          placeholder="请输入开户行关键词查询"
                          // loading={ zsBanks ? false : true}
                          onPressEnter={(e) => e.preventDefault()}
                          enterButton
                        />
                      </AutoComplete> */}
            </Form.Item>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col {...colProps}>
          <Form.Item
            label="开户行名称"
            name="accountBankName"
            rules={[
              {
                required: true,
                message: "开户行名称不可以为空, 请重新查询开户行",
              },
            ]}
          >
            <Input placeholder="查询后自动输入" disabled />
          </Form.Item>
        </Col>
        <Col {...colProps}>
          <Form.Item
            label="开户行行号"
            name="accountBankNo"
            rules={[{ required: true, message: "开户行行号不可以为空" }]}
          >
            <Input placeholder="查询后自动输入" disabled />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
});
EditBankBranchFormFields.defaultProps = defaultProps;
