import { useTable } from '@src/hooks/useTable'
import React,{memo} from 'react'
import { getMembersByGroupId } from '../../service'
import { useParams } from 'react-router-dom'
import { Table, Tooltip } from 'antd'
import { ColumnType } from 'antd/es/table'
import dayjs from 'dayjs'
import { TableToolBar } from '@src/components/TableToolBar'
const defaultProps = {}
type props = {}
export type MembersProps = Required<typeof defaultProps> & props
export const Members:React.FC<MembersProps> = memo((props)=>{
  const routerParams = useParams()
  const columns:ColumnType<API.Member.Item>[] = [
    {title:'序号',dataIndex:'id', render: (_, _1, index) => index + 1,width:'5rem'},
    {title:'成员姓名',dataIndex:'name',width:'8rem'},
     {title:'手机号',dataIndex:'phone',width:'8rem'},
     {title:'角色',dataIndex:'roleList',
      ellipsis:true,
      // width:'20rem',
     render:(_,record)=>{
      const roleStr = record.roleList.map(item=>item.name).join("，")
      return <Tooltip title={roleStr.length>18?roleStr:undefined}>
        {roleStr}
      </Tooltip>
     }},
     {title:'绑定个体户',dataIndex:'selfEmployedname'},
     {title:'加入时间',dataIndex:'createTime',render:(value)=>{
      return value?dayjs(value).format('YYYY/MM/DD HH:mm'):''
     },width:'10rem'},
  ]
  const tableService = useTable(
    async (params) => {
      const { current, size,createDate, ...rest } = params;
      const res = await getMembersByGroupId({
        id:routerParams.id,
        ...rest,
        pageSize: size,
        pageNumber: current,
        withTotal: true,
        "sorts[0].direction":'DESCENDING',
        "sorts[0].field":'selfEmployedId',
        "sorts[1].direction":'DESCENDING',
        "sorts[1].field":'createTime',
      });
      return {
        data: res.list,
        total: Number(res.total),
      };
    },
    { defaultCurrent: 1, defaultSize: 20 }
  );
  return <>
      <TableToolBar
        {...tableService.getToolBarProps?.({
          search: {
            placeholder: "输入姓名，手机号搜索",
          },
          // title: "成员列表",
        })}
      />
   <Table
  rowKey={"id"}
  pagination={tableService.getPaginationProps?.({
    className: "page_num",
  })}
  {...tableService.getTableProps({ className: "public_table" ,scroll:{y:'34vh'}})}
  columns={columns}
/></>
})
Members.defaultProps = defaultProps