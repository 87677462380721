import React, { useState, useEffect, useMemo } from "react";
import { message } from "antd";
import { useParams } from "react-router-dom";
import BreadCrumbBar from "../../../../../../components/BreadCrumbBar";
import "./index.scss";
import StepsCom from "./Coms/StepsCom"; // 步骤条
import BasicDetails from "./Coms/BasicDetails"; // 基本信息数据
import TableCom from "./Coms/TableCom"; // 操作日志
import Todo from "./Coms/Todo"; // 重发短信  或者双录视频
import UploadBusinessLicense from "./Coms/UploadBusinessLicense"; // 营业执照上传
import {
    getAddministrstionInfo,
    getImageUrl,
    getTaxInfo,
    getSignFile,
} from "../../../promise";
import { useSelector } from "react-redux";
const breads = [
    {
        label: "代办业务",
        path: "/business",
    },
    {
        label: "工商税务开办",
        path: "/business/administration/list",
    },
    {
        label: "资料详情",
        path: "",
    },
];

const Info = () => {
    // 角色code
    const roleList = useSelector((state: any) => state.roleCodes);
    const searchParams = useParams();
    const [details, setDetails] = useState<any>({});
    const [licenseName, setlicenseName] = useState("");
    // 记录当前是否展示税祱通
    const [taxInfo, setTaxInfo] = useState({});
    // 签署协议地址
    const [fileDownUrl, setFileDownUrl] = useState("");
    const local = localStorage.getItem("operation_authorization") || "{}";
    const [isAssignor, setIsAssignor] = useState(true);
    const [isSuc, setIsSuc] = useState(false);
    // 录入员 上传营业执照 并且在提交工商和执照办理失败 状态下显示
    const isUpload = useMemo(() => {
        return (
            roleList.includes("uploader") &&
            ["SUBMIT_BUSINESS", "LICENSE_FAIL"].includes(details?.applyStatus)
        );
    }, [roleList, details]);
    // 是否显示双录视频以及重发短信 所有人都有操作权限 待审核状态
    const isEditVideo = useMemo(() => {
        return details?.applyStatus === "NO_APPROVAL";
    }, [details]);
    // 根据执照办理地 获取详情
    const getTaxInfos = (id?: any) => {
        if (!id || id === "") return;
        getTaxInfo(id).then((res: any) => {
            if (res.code === 200) {
                setTaxInfo(res.data);
                if (res.data?.registerUserId === JSON.parse(local)?.userId) {
                    setIsAssignor(true);
                } else {
                    setIsAssignor(false);
                }
                setIsSuc(true);
            }
        });
    };
    // 获取营业执照图片的地址
    const getImage = (str?: any) => {
        if (!str || str === "" || str === undefined) return;
        const obj = {
            zoom: false,
            fileIds: str,
        };
        getImageUrl(obj).then((res: any) => {
            if (res.success) {
                setlicenseName(res.data[0]);
            }
        });
    };
    // 查看签署协议
    const getFileUrl = (url: any) => {
        if (!url) return;
        getSignFile(url, {}).then((res?: any) => {
            if (res?.code === 200) {
                setFileDownUrl(res?.data?.fileDownUrl);
            }
        });
    };
    const getInfo = (id?: any) => {
        setIsSuc(false);
        if (!id || id === "") return;
        getAddministrstionInfo(id).then((res: any) => {
            if (res.code === 200) {
                setDetails(res.data);
                getImage(res.data?.licenseUrl);
                getTaxInfos(res.data.taxRegionId);
                getFileUrl(res?.data?.cancelSignFlowId);
            } else {
                message.error(res.message || "请求异常");
            }
        });
    };
    useEffect(() => {
        getInfo(searchParams?.id);
    }, [searchParams]);
    return (
        <div className="business_info_container">
            <BreadCrumbBar breads={breads}></BreadCrumbBar>
            <div className="step_conrainer">
                {isSuc ? (
                    <StepsCom
                        details={details}
                        licenseName={licenseName}
                        update={getInfo}
                        taxInfo={taxInfo}
                        fileDownUrl={fileDownUrl}
                        isAssignor={isAssignor}
                    ></StepsCom>
                ) : null}
            </div>
            {isEditVideo ? (
                <Todo details={details} update={getInfo}></Todo>
            ) : null}

            {/* 上传营业执照 */}
            {isUpload ? (
                <UploadBusinessLicense
                    details={details}
                    update={getInfo}
                    taxInfo={taxInfo}
                ></UploadBusinessLicense>
            ) : null}
            {/* 基本信息 */}
            <BasicDetails
                taxInfo={taxInfo}
                details={details}
                licenseName={licenseName}
                fileDownUrl={fileDownUrl}
            ></BasicDetails>
            {/* 操作日志 */}
            <TableCom details={details}></TableCom>
        </div>
    );
};

export default Info;
