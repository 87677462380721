import TableCom from "@src/components/TableCom";
import { customerRelatedEntityDetail } from "@src/pages/Customer/promise";
import { Button, Col, Descriptions, Image, Row, Space, Tag } from "antd";
import { useEffect, useState } from "react";
import "./index.scss";
import classnames from "classnames";
import { Link, useNavigate, useParams } from "react-router-dom";
import { RelatedEntityItemPartial } from "../relatedEntity";
import { FilePdfFilled } from "@ant-design/icons";
import { cross_env } from "@src/services/util";

const RelatedEntityDetail = (props: any) => {
    const routerParams = useParams();
    const navigate = useNavigate()
    const [details, setDetails] = useState<RelatedEntityItemPartial>()
    // 关联实体详情
    const getDetail = (object?: any) => {
        customerRelatedEntityDetail(routerParams?.id).then((res: any) => {
            if (res.code === 200) {
                setDetails(res.data)
            }
        });
    };
    useEffect(() => {
        getDetail();
    }, [routerParams])
    return (
        <div className="detail-entity-container">
            <div className="detail-entity-content">
                <Descriptions column={1} title="基本信息" extra={routerParams?.isShowEdit == 'true' && <Button type="primary" onClick={() => navigate(`/customer/${routerParams?.customerId}/entity/${routerParams?.id}/edit`)}>编辑</Button>}>
                    <Descriptions.Item className={classnames('required')} label="企业类型">{details?.enterpriseTypeName}</Descriptions.Item>
                    <Descriptions.Item className={classnames('required')} label="企业名称">{details?.enterpriseName}</Descriptions.Item>
                    <Descriptions.Item className={classnames('required')} label="上传营业执照">
                        {
                            details?.businessLicenseFile?.ossUrl !== '' ? <Image width={150} src={details?.businessLicenseFile?.ossUrl}></Image> : '-'
                        }
                    </Descriptions.Item>
                    <Descriptions.Item className={classnames('required')} label="统一信用代码">{details?.unifiedCreditCode}</Descriptions.Item>
                </Descriptions>
                <Descriptions column={1} title="法人信息">
                    <Descriptions.Item label="法人姓名">{details?.legalPersonName}</Descriptions.Item>
                    <Descriptions.Item label="身份证号">{details?.legalPersonIdCard}</Descriptions.Item>
                </Descriptions>
                <Descriptions column={1} title="开票信息">
                    <Descriptions.Item className={classnames('required')} label="开票行名称">{details?.invoicingBankName}</Descriptions.Item>
                    <Descriptions.Item className={classnames('required')} label="开票行账号">{details?.invoicingBankAccountNumber}</Descriptions.Item>
                    <Descriptions.Item className={classnames('required')} label="开票地址">{details?.invoicingBankAddress}</Descriptions.Item>
                    <Descriptions.Item className={classnames('required')} label="开票电话">{details?.invoicingBankPhone}</Descriptions.Item>
                </Descriptions>
                <Descriptions column={1} title="税区信息">
                    <Descriptions.Item className={classnames('required')} label="合作税区">
                        {
                            details?.cooperateTaxDistrictName?.split(',')?.map((item) => (
                                <Tag color={'processing'}>{item}</Tag>
                            ))
                        }
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions column={1} title="合同信息">
                    <Descriptions.Item label="上传合同">

                        {details?.contractFile && (
                            <Space wrap>{
                                details?.contractFile?.map(((contractFileItem: {
                                    id: any;
                                    fileName: string; ossUrl: any;
                                }, index: any) => {
                                    return (
                                        <div style={{ textAlign: "center" }} >
                                            <FilePdfFilled style={{ fontSize: '36px' }} />
                                            <div>{contractFileItem.fileName}</div>
                                            <Row gutter={8} style={{ justifyContent: 'space-between' }}>
                                                <Col>
                                                    <Button target={'_blank'} type="link" href={contractFileItem.ossUrl}>预览</Button>
                                                </Col>
                                                <Col>
                                                    <Button target={'_blank'} download={contractFileItem.fileName} type="link" href={`${cross_env}/sinzetech-crm/corporealEntity/download/${contractFileItem.id}`}>下载</Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                }))
                            }
                            </Space>
                        )}
                    </Descriptions.Item>
                </Descriptions>
                <Row justify="center">
                    <Space size={'large'}>
                        <Button type="primary" onClick={() => history.go(-1)}>关闭</Button>
                    </Space>
                </Row>
            </div>
        </div>
    );
};

export default RelatedEntityDetail;
