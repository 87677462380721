import { axiosRequrest } from '@src/services/axiosRequest'
import { Modal, Select, message } from 'antd'
import React, { useEffect, useState } from 'react'

interface CompleteProps {
    cancelModal: (a?: boolean) => void
    id: string
}
const Complete = ({ cancelModal, id }: CompleteProps) => {
    useEffect(() => {

    }, [])
    const [channel, setchannel] = useState<string>()
    const submit = () => {
        if (!channel) {
            return message.error('请选择开票渠道')
        }
        axiosRequrest.put(`/taxpayer-tasks/${id}?channel=${channel}`, null, {
            headers: {
                baseUrl: 'SP'
            }
        }).then(res => {
            message.success('操作成功')
            cancelModal(true)
        })
    }
    return (
        <>
            <Modal
                title="确认当前开票方已完成开通任务"
                open={true}
                onOk={() => { submit() }}
                onCancel={() => cancelModal()}
            >
                <div>
                    <span style={{ marginRight: 14 }}>
                        <span style={{ color: 'red' }}>*</span>
                        开票渠道
                    </span>

                    <Select
                        value={channel || '请选择'}
                        style={{ width: 200 }}
                        onChange={(value) => {
                            setchannel(value)
                        }}
                    >
                        <Select.Option value='BW'>百望云</Select.Option>
                        <Select.Option value='MANUAL'>电子税务局</Select.Option>
                    </Select>
                </div>
            </Modal>
        </>
    )
}

export default Complete