import React, { useEffect } from 'react'
import BreadCrumbBar from '@src/components/BreadCrumbBar'
import { Button, DatePicker, Form, Input, Pagination, Select, Table, } from 'antd';
import { useNavigate } from 'react-router-dom';
import { axiosRequrest } from '@src/services/axiosRequest';
import { useSetState } from 'ahooks';
import CommonSearch, { FormItem } from '@src/components/common/CommonSearch';
import { CommonStatus } from '@src/components/common';
import Complete from './complete';
interface OpenTaskState extends BaseState {
    taxRegion: any[]
    isModal: boolean
    id: string
}
const breads = [
    {
        label: "税票系统",
        path: "/business",
    },
    {
        label: "开通任务",
        path: "",
    },
];
const taskStatusColorMap = {
    PENDING: '#FAAB0C',
    COMPLETED: '#00D18B',
}
const taskStatusNameMap = {
    PENDING: '待处理',
    COMPLETED: '已完成',
}
const OpenTask = () => {
    const nav = useNavigate()
    let [state, setState] = useSetState<OpenTaskState>({
        columnsData: [],
        taxRegion: [],
        total: 0,
        current: 1,
        size: 10,
        isRefresh: false,
        searchContent: {},
        isModal: false,
        id: '',
    })
    let columns: any = [{
        title: '任务编号',
        dataIndex: 'taskId',
    },
    // {
    //     title: '来源',
    //     dataIndex: '',
    // },
    {
        title: '开票方',
        dataIndex: 'taxpayerName',
    }, {
        title: '纳税人识别号',
        dataIndex: 'taxpayerNo',
    }, {
        title: '办税人',
        dataIndex: 'taxClerk',
    }, {
        title: '状态',
        dataIndex: 'taskStatus',
        render: (t: string, item: any) => {
            return (
                <CommonStatus
                    color={taskStatusColorMap?.[t]}
                    text={taskStatusNameMap?.[t]}
                />
            );
        },
    }, {
        title: '创建时间',
        dataIndex: 'createTime',
    }, {
        title: '完成时间',
        dataIndex: 'completeTime',
    },
    // {
    //     title: '开票渠道',
    //     dataIndex: '',
    // },
    {
        title: '操作',
        dataIndex: 'operation',
        fixed: "right",
        render: (_: any, item: any) => {
            return (
                <div className='operation'>
                    {
                        item.taskStatus == 'PENDING' &&
                        <span
                            onClick={() => {
                                setState({ isModal: true, id: item.id })
                            }}
                        >办理完成</span>
                    }
                </div>
            )
        }
    },]
    const formList: FormItem[] = [{
        name: 'taskStatus',
        label: '状态',
        element: (
            <Select placeholder='请选择' allowClear>
                <Select.Option value='PENDING'>待处理</Select.Option>
                <Select.Option value='COMPLETED'>已完成</Select.Option>
            </Select>
        ),
    }, {
        name: 'createTime',
        label: '创建时间',
        timeKey: ['createTimeBegin', 'createTimeEnd'],
        element: <DatePicker.RangePicker style={{ width: '100%' }} allowClear />,
    }, {
        name: 'completeTime',
        label: '完成时间',
        timeKey: ['completeTimeBegin', 'completeTimeEnd'],
        element: <DatePicker.RangePicker style={{ width: '100%' }} allowClear />,
    }, {
        name: 'taxClerk',
        label: '办税人',
        element: <Input placeholder='请输入' allowClear />,
    }, {
        name: 'query',
        label: '关键字搜索',
        element: <Input placeholder='请输入任务编号、开票方、税号' allowClear />,
    }]
    useEffect(() => {
        getColumnsData()
    }, [state.current, state.size, state.isRefresh])
    const getColumnsData = () => {
        axiosRequrest.get<List>(`/taxpayer-tasks`, {
            params: {
                size: state.size,
                current: state.current,
                ...state.searchContent,
            },
            headers: {
                baseUrl: 'SP'
            }
        }).then(res => {
            setState({ columnsData: res.records, total: res.total, })
        })
    }
    return (
        <div className='public_list_page'>
            <BreadCrumbBar breads={breads}></BreadCrumbBar>
            <CommonSearch
                formList={formList}
                onSearch={(values) => {
                    console.log(values, 'values')
                    setState({ searchContent: values, isRefresh: !state.isRefresh })
                }}
            />
            <div className='table_box'>
                <Table
                    className='public_table'
                    columns={columns}
                    dataSource={state.columnsData}
                    rowKey={(item: any) => item.id}
                    pagination={false}
                    scroll={{ x: true }}
                />
                <Pagination
                    className='page_num'
                    total={state.total}
                    current={state.current}
                    pageSize={state.size}
                    showSizeChanger
                    showTotal={(total) => `共${total}条`}
                    onChange={(current, size) => {
                        setState({ current, size })
                    }}
                />
            </div>
            {
                state.isModal &&
                <Complete
                    cancelModal={(isRefresh?: boolean) => {
                        if (isRefresh) {
                            setState({ isRefresh: !state.isRefresh })
                        }
                        setState({ isModal: false, id: '', })
                    }}
                    id={state.id}
                />
            }
        </div>
    )
}

export default OpenTask