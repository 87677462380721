import React, { useState, useMemo } from "react";
import { Button, Steps, message, Input } from "antd";
import ModalCom from "../../../../../../components/ModalCom";
import { stepList } from "../../config";
import { agencyDeregister, agencySign, getImageUrl } from "../../promise";
import { downloadUrlFile } from "../../../../../../utils/index";
import { useNavigate } from "react-router-dom";

import "./index.scss";
const { TextArea } = Input;
interface Obj {
    id?: string;
    file?: string;
    nextDeregisterStatus?: string;
    rejectReason?: string;
}
interface Prop {
    details?: any;
    update?: (id: string | any) => void;
    taxRole?: boolean;
    logOff?: boolean;
}

const StepCom = (props: Prop) => {
    const { details, update, taxRole, logOff } = props;
    const nav = useNavigate();

    // 失败弹窗
    const [rejectOpen, setRejectOpen] = useState(false);
    const [rejectReason, setRejectReason] = useState("");
    // 下载申请书
    const downloadApplicationOperation = useMemo(() => {
        return [
            {
                role: true,
                operation: (
                    <Button
                        type="primary"
                        onClick={() =>
                            handleDownload(details?.deregisterFileId)
                        }
                    >
                        下载注销申请书
                    </Button>
                ),
            },
        ];
    }, [details]);
    // 下载注销协议
    const downloadCancellationOperation = useMemo(() => {
        return [
            {
                role: true,
                operation: (
                    <Button
                        type="primary"
                        onClick={() => handleDownload(details?.signFlowFileId)}
                    >
                        下载注销协议
                    </Button>
                ),
            },
        ];
    }, [details]);

    // 操作对象
    const operationObj = useMemo(() => {
        let objs = {
            WAIT_SIGN: {
                label: "申请书待签署",
                color: "warning",
                current: 0,
                list: [
                    ...downloadApplicationOperation, // 下载申请书
                    {
                        role: logOff, // logOff
                        operation: (
                            <Button type="primary" onClick={() => handleSign()}>
                                重发签署注销申请书短信
                            </Button>
                        ),
                    },
                ],
                value: "WAIT_CLEAR_TAX", // 存储用于提交数据的状态参数
            },
            WAIT_CLEAR_TAX: {
                label: "待清税",
                color: "warning",
                current: 1,
                list: [
                    {
                        role: taxRole, // taxRole
                        operation: (
                            <Button
                                type="primary"
                                danger
                                onClick={() => {
                                    setRejectOpen(true);
                                }}
                            >
                                无法清税，驳回申请
                            </Button>
                        ),
                    },
                    ...downloadCancellationOperation, // 下载注销协议
                ],
                value: "WAIT_DEREGISTER_LICENSE",
            },
            WAIT_DEREGISTER_LICENSE: {
                label: "执照待注销",
                color: "warning",
                current: 2,
                list: downloadCancellationOperation, // 下载注销协议
                value: "DEREGISTERED",
            },
            DEREGISTERED: {
                label: "已注销",
                color: "warning",
                current: 3,
                list: downloadCancellationOperation, // 下载注销协议
                value: "",
            },
        };
        let type = details?.deregisterStatus || "WAIT_SIGN";
        return objs[type];
    }, [details, taxRole, logOff]);
    // 更新
    const handleUpdate = () => {
        update && update(details?.id);
    };
    // 重发短信
    const handleSign = async () => {
        if (!details?.id) return;
        const res = await agencySign(details?.id);
        if (res?.code === 200) {
            message.success("已重发，请联系个体户法人签署");
            handleUpdate();
        }
    };
    // 流程推进操作
    const handleFlowPath = async (type: any, obj?: Obj) => {
        const params = {
            id: details?.id,
            nextDeregisterStatus: type,
            ...obj,
        };
        if (!type) {
            delete params.nextDeregisterStatus;
        }
        const res: any = await agencyDeregister(params);
        if (res?.code === 200) {
            setRejectOpen(false);
            if (!type) {
                nav("/business/logout/apply");
            } else {
                handleUpdate();
            }
        }
    };
    // 失败原因提交
    const handleOk = async () => {
        if (!rejectOpen || rejectReason.trim() === "") {
            message.error("请输入失败原因");
            return;
        }
        // if (operationObj?.value && operationObj?.value !== "") {
        //     const params = {
        //         rejectReason: rejectReason,
        //     };
        //     handleFlowPath(operationObj?.value, params);
        // }
        const params = {
            rejectReason: rejectReason,
        };
        handleFlowPath(null, params);
    };
    // 下载注销协议  或者申请书
    const handleDownload = async (value?: string) => {
        if (!value) {
            return;
        }
        const params = {
            zoom: false,
            fileIds: value,
        };
        const res: any = await getImageUrl(params);
        if (res?.code === 200) {
            if (res?.data[0]) {
                downloadUrlFile(res?.data[0]);
            }
        }
    };
    return (
        <div className="agency_step_container">
            <div className="agency_step_container_title">流程进度</div>
            <div className="agency_steps">
                <Steps items={stepList} current={operationObj?.current}></Steps>
            </div>
            <div className="agency_step_opera">
                <div className="agency_step_status">
                    状态
                    <div
                        className={`default_bg ${operationObj?.color + "_bg"}`}
                    >
                        <span
                            className={`default_color ${operationObj?.color}`}
                        ></span>
                        {operationObj?.label}
                    </div>
                </div>
                <div className="right_btn_operation">
                    {operationObj?.list.map((item: any) => {
                        return item.role ? (
                            <div className="mar_right">{item.operation}</div>
                        ) : null;
                    })}
                </div>
            </div>
            {/* 无法清税，驳回申请 */}
            <ModalCom
                open={rejectOpen}
                title="无法清税原因"
                onCancel={() => {
                    setRejectOpen(false);
                    setRejectReason("");
                }}
                className="reject_modal"
                width={500}
                onOk={handleOk}
            >
                <div className="reject_modal_container">
                    <span className="reject_form_label">请输入失败原因：</span>
                    <div className="reject_form_value">
                        <TextArea
                            placeholder="请输入失败原因"
                            maxLength={1000}
                            onChange={(e: any) => {
                                setRejectReason(e.target.value);
                            }}
                        ></TextArea>
                        {/* <span className="reject_form_value_desc">
                            最多输入1000个汉字
                        </span> */}
                    </div>
                </div>
            </ModalCom>
        </div>
    );
};

export default StepCom;
