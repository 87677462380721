import Util from '../../../util';
const util = Util.getInstance();
type add_author ={
    name: string,
    code: string,
    id?: string,
}
const authorityList: (current: number, pageSize: number) => Promise<any> = (current, pageSize) => {
    return util.httpGet(`/sinzetech-admin-user/authorities?current=${current}&size=${pageSize}`).then(data => {
        return data;
    }, err => err.response.data)
}

// 新增权限
const createAutho: (value: add_author) => Promise<any> = (value: add_author) => {
    return util.httpPost('/sinzetech-admin-user/authorities', value)
    .then(res => res,  err => err.response.data)
}
// 删除权限
const deleteAuthor: (id: string| number) => Promise<any> = (id) => {
    return util.httpDeletel(`/sinzetech-admin-user/authorities/${id}`).then(response => response , err => err.response.data)
}

export {
    authorityList,
    createAutho,
    deleteAuthor
}