export const limitFileSize = (targetSize: number,limitSize: number)=>{
  return targetSize / 1024 / 1024 > limitSize;
}
export const createImage = (src: string): Promise<HTMLImageElement> =>
  new Promise<HTMLImageElement>((resolve,reject) => {
    const image = new Image();
    image.crossOrigin = 'anonymous'
    image.src = src;
    image.onload = () => {
      resolve(image);
    };
    image.onerror = (e) => {
      reject(e);
    };
  });
