import Util from '@src/services/util'
import qs from 'qs'
import { message } from 'antd'
import { invoiceURL } from "@src/utils/invoiceURL";
const util = Util.getInstance();
const baseURL = invoiceURL();
// 开票列表
interface Invoces {
    size: number,
    curPage: number,
    query?: string,
    taxClerk?: string,
    createTimeBegin?: string,
    createTimeEnd?: string,
    completeTimeBegin?: string
    completeTimeEnd?: string,
    taskStatus?:string
}
export const getInvocesList = (params: Invoces) => {
    return util.httpGet('/invoicing-tasks', params, true, baseURL)
}

// 开票详情
export const getInvocesInfo = (id: string| number) => {
    return util.httpGet(`/invoicing-tasks/${id}`,{}, true, baseURL )
}

// 重开票
export const reInvoicing = (id: string | number) => {
    return util.httpGet(`/invoices/create/${id}`)
}

// ocr识别
export const getIdentifyInfoOCR = (id:string) => {
    return util.httpPost(`/invoices/ocr`, id, {
        headers:{
            'Content-Type': 'text/plain'
        }
    }, baseURL ).then(res => res).catch(e => e)
}

// 根据开票任务  获取发票数据列表
export const getInvoices = (id: string) => {
    return util.httpGet(`/invoices/${id}`, {}, true, baseURL)
}

// 发票预览
export const preview = (id: string) => {
    return util.httpGet(`/invoices/preview/${id}`,  {}, true, baseURL)
}
// 新建发票数据
interface createInvoces{
    fileId?: any,
    vatInvoiceResponse?:any
}
export const createInvoces = (id:string, params:createInvoces) => {
    return util.httpPost(`/invoices/create/${id}`, params, {}, baseURL)
}
