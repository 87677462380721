export const statusList = [
    {
        label: '待处理',
        value:'PENDING'
    },
    {
        label: '开票中',
        value:'PROCESSING'
    },
    {
        label: '开票完成',
        value:'COMPLETED'
    },
    {
        label: '待撤销',
        value:'PENDING_REVOKE'
    },
    {
        label: '已撤消',
        value:'REVOKED'
    }
]
export const statusObjs = {
    PENDING: {
        label: '待处理',
        color: 'warning'
    },
    PROCESSING: {
        label: '开票中',
        color: 'primary'
    },
    COMPLETED: {
        label: '开票完成',
        color: 'success'
    },
    PENDING_REVOKE: {
        label: '待撤销',
        color: 'warnig'
    },
    REVOKED: {
        label: '已撤消',
        color: 'error'
    }
}

export const taskSourceObjs = {
    'TRADE': '交易系统'
}

// 发票模式
export const invoiceTypeObjs = {
    'SPECIAL': '专用发票',
    'GENERAL': '普通发票'
}

// 开票渠道
export const issueChannelObjs = {
    MANUAL: '手工',
    BW: '百旺',
    HX: '航信'
}