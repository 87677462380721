import { useState } from "react";
import { Form, Radio, Input, Row, Col } from "antd";
import "./index.scss";
import { radioOption, connectCompanyDesc } from "../../content";

const FormItem = Form.Item;

type BaseInfoType = {
  applyType: "INDIVIDUAL" | "ENTERPRISE";
  setApplyType: (e: "INDIVIDUAL" | "ENTERPRISE") => void;
};
/**
 * 基本信息
 * @returns
 */
function BaseInfo(props: BaseInfoType) {
  const { applyType, setApplyType } = props;
  return (
    <div className="base_info">
      <div className="common_title">基本信息</div>
      <div className="base_info_content">
        <div className="open_type">
          <FormItem label="办理类型" name="applyType">
            <Radio.Group
              defaultValue={applyType}
              onChange={(e: any) => {
                setApplyType(e.target.value);
              }}
            >
              {radioOption.map((item: any) => {
                return (
                  <Radio key={item.value} value={item.value}>
                    {item.label}
                  </Radio>
                );
              })}
            </Radio.Group>
          </FormItem>
        </div>
        <div className="connect_company">
          <FormItem
            label="关联集团"
            name="linkOrganization"
            rules={[{ max: 20 }]}
          >
            <Input></Input>
          </FormItem>

          <div className="desc">{connectCompanyDesc}</div>
        </div>
      </div>
    </div>
  );
}

export default BaseInfo;
