import BreadCrumbBar from "@src/components/BreadCrumbBar";
import { Badge, Card, Col, Descriptions, Row, TabPaneProps, Tabs, Tag } from "antd";
import classNames from "classnames";
import React, { createContext, memo, useEffect } from "react";
import { Members } from "./Members";
import { Goods } from "./Goods";
import { Customers } from "./Customers";
import { PriceManagement } from "./PriceManagement";
import { Merchants } from "./Merchants";
import { Configuration } from "./Configuration";
import { useParams } from "react-router-dom";
import { useLocalStorageState, useRequest } from "ahooks";
import { getRecoverDetail } from "../service";
import dayjs from "dayjs";
import { recoverGroup } from "@src/constant/recoverGroup";
import { formatSplit } from "@src/utils/number/utils";

export const RecoverContext = createContext<{ id: string } | null>(null);

const breads = [
  {
    label: "回收小组",
    path: "/consignor/recover/list",
  },
  {
    label: "小组管理",
    path: "/consignor/recover/list",
  },
  {
    label: "详情",
    path: "",
  },
];

const tabs: (TabPaneProps & { key?: React.Key })[] = [
  { tab: "成员表", children: <Members></Members>, key: 1 },
  { tab: "货品表", children: <Goods></Goods>, key: 2 },
  { tab: "客户表", children: <Customers> </Customers>, key: 3 },
  { tab: "价格表", children: <PriceManagement></PriceManagement>, key: 4 },
  { tab: "服务商表", children: <Merchants></Merchants>, key: 5 },
  { tab: "配置", children: <Configuration></Configuration>, key: 6 },
];
const defaultProps = {};
type props = {};
export type RecoverManagementProps = Required<typeof defaultProps> & props;
export const RecoverManagement: React.FC<RecoverManagementProps> = memo(
  (props) => {
    const searchParams = useParams<{ id: string }>();
    const [recoverGroupDetail, setRecoverGroupDetail] = useLocalStorageState<
      Record<string, undefined | API.Recover.Item>
    >("recoverGroupDetail", {});
    useEffect(() => {
      return () => {
        setRecoverGroupDetail((prev) =>
          searchParams.id ? { ...prev, [searchParams.id]: undefined } : {}
        );
      };
    }, []);
    if (!searchParams.id) {
      return <></>;
    }
    return (
      <div className={classNames("public_detail_page")}>
        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          <BreadCrumbBar breads={breads}></BreadCrumbBar>
          <span style={{fontSize:'14px',cursor:'pointer'}} onClick={()=>{
            window.history.back()
          }}>返回上一级</span>
        </div>
        <Card
          title={<>小组名称：{recoverGroupDetail?.[searchParams.id]?.name}</>}
          extra={<>状态 : { recoverGroupDetail?.[searchParams.id]?.status?({
            "NORMAL":<Tag color="#e5faf3" style={{borderColor:'#d0f5e9',paddingTop:'4px',paddingBottom:'4px'}}><Badge color="#00d18b" text="正常"></Badge></Tag>,
            "DISABLED":<Tag color="#fee9e7" style={{borderColor:'#ffdfdb',paddingTop:'4px',paddingBottom:'4px'}}><Badge color="#f53f3f" text="禁用"></Badge></Tag>,
        }[recoverGroupDetail?.[searchParams.id]?.status!]):''}</>}
        >
          <Descriptions column={3}>
            <Descriptions.Item label="创建账号">
              {recoverGroupDetail?.[searchParams.id]?.createAccount}
            </Descriptions.Item>
            <Descriptions.Item label="所属人">
              {recoverGroupDetail?.[searchParams.id]?.ownerName}
            </Descriptions.Item>
            <Descriptions.Item label="累计交易额">
              {formatSplit(recoverGroupDetail?.[searchParams.id]?.transAmountTotal.toFixed(2))}元
            </Descriptions.Item>
            <Descriptions.Item label="创建时间">
              {recoverGroupDetail?.[searchParams.id]?.createTime
                ? dayjs(
                    recoverGroupDetail?.[searchParams.id]?.createTime
                  ).format("YYYY-MM-DD HH:mm")
                : "-"}
            </Descriptions.Item>
            <Descriptions.Item label="所属权转移时间">
              {recoverGroupDetail?.[searchParams.id]?.ownershipTransferTime
                ? dayjs(
                    recoverGroupDetail?.[searchParams.id]?.ownershipTransferTime
                  ).format("YYYY-MM-DD HH:mm")
                : "-"}
            </Descriptions.Item>
          </Descriptions>
        </Card>
        <br />
        <Card>
          <RecoverContext.Provider value={{ id: searchParams.id! }}>
            <Tabs defaultActiveKey="1">
              {tabs.map(({ children, ...props }) => (
                <Tabs.TabPane key={props.key} {...props}>
                  <div className="public_detail_tab_pane">{children}</div>
                </Tabs.TabPane>
              ))}
            </Tabs>
          </RecoverContext.Provider>
        </Card>
      </div>
    );
  }
);
RecoverManagement.defaultProps = defaultProps;
