import React, { useContext } from 'react'
import classnames from 'classnames'
import './index.scss'
import { DetailPageContext } from '../PageContainer'

type props = {}
export type DetailPageContentProps = props & React.HTMLAttributes<HTMLDivElement>
export const DetailPageContent: React.FC<React.PropsWithChildren<DetailPageContentProps>> = (props) => {
  const context = useContext(DetailPageContext)

  return <div className={classnames('detail-page-content', props.className)}>
    {props.children}
  </div>
}