import React, { useEffect, useState, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Tabs, Form, Input } from "antd";
import BreadCrumbBar from "../../../../../components/BreadCrumbBar";
import BasicForm from "./pages/basicForm";
import "./index.scss";
import Contacts from "./pages/Contacts";
import Logs from "./pages/Logs";
import FollowUp from "./pages/FollowUp";
// import Notification from "./pages/Notification";
import PermissionSetting from "./pages/PermissioSetting";
import { getCustomerInfo, customerRoleInfo } from "../../../promise";
import { useSelector } from "react-redux";
import RelatedEntity from "./pages/RelatedEntity";

const breads = [
    {
        label: "客户管理",
        path: "",
    },
    {
        label: "详情",
        path: "",
    },
];

const Info = () => {
    const searchParams = useParams();
    const codeList = useSelector((state: any) => state.codeList) || [];
    const isShowEdit = codeList.includes("crm_customer_list_edit");
    const defaultActiveKey = searchParams.current || "1";
    // const [defaultActiveKey, setDefaultActiveKey] = useState("1");
    const [details, setDetails] = useState<any>({});
    // 是否为高管组权限
    // const [isTeam, setIsTeam] = useState(true);
    // 是否为业务员或者业务负责人权限
    const [isBusiness, setIsBusiness] = useState(false);
    const local = JSON.parse(
        localStorage.getItem("operation_authorization") || "{}"
    );
    // 编辑
    const isUpdateRole = useMemo(() => {
        return isShowEdit && isBusiness;
    }, [isBusiness, isShowEdit]);
    const getInfo = (id?: any) => {
        if (!id) return;
        getCustomerInfo(id).then((res: any) => {
            if (res.code === 200) {
                setDetails(res.data);
                let bool =
                    res?.data?.isEdit ||
                    res?.data?.businessOwner?.id === local?.userId;
                const busiRes = res?.data?.businessSalesmanList.some(
                    (item: any) => item.id === local?.userId
                );
                const role = busiRes || bool;
                setIsBusiness(role);
            }
        });
    };

    // const getCustomerRoles = () => {
    //     if (!local?.userId) return;
    //     customerRoleInfo({ userId: local?.userId }).then((res: any) => {
    //         if (res?.code === 200) {
    //             const newArr = res?.data.filter(
    //                 (item: any) => item.name === "高管组"
    //             );
    //             if (newArr.length !== 0) {
    //                 setIsTeam(true);
    //             } else {
    //                 setIsTeam(false);
    //             }
    //         }
    //     });
    // };
    useEffect(() => {
        getInfo(searchParams.id);
        // getCustomerRoles();
    }, [searchParams]);
    const tabItems = [
        {
            key: "1",
            label: "跟进记录",
            children: (
                <div className="customer_tabs_content">
                    {defaultActiveKey === "1" ? (
                        <FollowUp
                            isShowEdit={isUpdateRole}
                            details={details}
                        ></FollowUp>
                    ) : null}
                </div>
            ),
        },
        {
            key: "2",
            label: "联系人",
            children: (
                <div className="customer_tabs_content">
                    {defaultActiveKey === "2" ? (
                        <Contacts
                            isShowEdit={isUpdateRole}
                            details={details}
                        ></Contacts>
                    ) : null}
                </div>
            ),
        },
        {
            key: "3",
            label: "权限设置",
            children: (
                <div className="customer_tabs_content">
                    <PermissionSetting
                        isShowEdit={isUpdateRole}
                        details={details}
                    ></PermissionSetting>
                </div>
            ),
        },
        // {
        //     key: "4",
        //     label: "展示范围",
        //     children: (
        //         <div className="customer_tabs_content">
        //             <Permission
        //                 isShowEdit={isUpdateRole}
        //                 details={details}
        //             ></Permission>
        //         </div>
        //     ),
        // },
        {
            key: "4",
            label: "关联实体",
            children: (
                <div className="customer_tabs_content">
                    {defaultActiveKey === "4" ? (
                        <RelatedEntity
                            isShowEdit={isUpdateRole}
                            details={details}
                        ></RelatedEntity>
                    ) : null}
                </div>
            ),
        },
        {
            key: "5",
            label: "操作日志",
            children: (
                <div className="customer_tabs_content">
                    {defaultActiveKey === "5" ? (
                        <Logs
                            isShowEdit={isUpdateRole}
                            details={details}
                        ></Logs>
                    ) : null}
                </div>
            ),
        }
    ];
    const navigate = useNavigate();
    const onChange = (key: string) => {
        navigate(`/customer/management/info/${searchParams.id}/${key}`, {
            replace: true,
        });
    };
    return (
        <div className="cus_container">
            <BasicForm isShowEdit={isUpdateRole}></BasicForm>
            <div className="customer_info_tabs">
                <Tabs
                    defaultActiveKey={defaultActiveKey}
                    items={tabItems}
                    onChange={onChange}
                ></Tabs>
            </div>
        </div>
    );
};

export default Info;
