// 跟进记录
import React, { useEffect, useState } from "react";
import TableCom from "../../../../../../../components/TableCom";
import ModalCom from "../../../../../../../components/ModalCom";
import {
    Button,
    Form,
    Input,
    Select,
    DatePicker,
    Checkbox,
    message,
} from "antd";
import {
    addCustomerFollow,
    editCustomerFollow,
    customerFollowList,
    getCustomerFollowInfo,
    getCustomerNodityInfo,
    getUsersList,
} from "../../../../../promise";
import { followWayList } from "../../config";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 },
};
const Option = Select.Option;

const FollowUp = (props?: any) => {
    const { details, isShowEdit } = props;
    const getSession = localStorage.getItem("operation_authorization") || "{}";
    const updateList = useSelector((state: any) => state.updateFollow);
    const [form] = Form.useForm();
    const [pageOptions, setPageOptions] = useState({
        current: 1,
        pageSize: 10,
    });
    const [total, setTotal] = useState(0);
    const [list, setList] = useState([]);
    const [open, setOpen] = useState(false);
    const [openTitle, setOpenTitle] = useState("新增跟进记录");
    // 用户组数据查询
    const [notifyObj, setNotifyObj] = useState<any>({
        groups: [],
        users: [],
    });
    const [uList, setUList] = useState<any>([]);
    // 当前操作跟进记录
    const [currentObj, setCurrentObject] = useState<any>({});
    // 获取跟进人数据

    // const getUses = () => {
    //   const params ={
    //     size: 99999,
    //     current:1
    //   }
    //   getUsersList(params)
    //   .then((res:any) => {
    //     if(res.code === 200){
    //       setUList(res.data.records)
    //     }

    //   })
    // }
    const columns = [
        {
            title: "跟进方式",
            dataIndex: "followWay",
            render: (record: any) => {
                const res = followWayList.find(
                    (item: any) => item.value === record
                );
                return <span>{res?.label}</span>;
            },
        },
        {
            title: "跟进摘要",
            dataIndex: "followSummary",
        },

        {
            title: "跟进详情",
            dataIndex: "followDetail",
        },
        {
            title: "跟进人",
            dataIndex: "followUser",
        },
        {
            title: "发生时间",
            dataIndex: "followTime",
        },
        {
            title: "操作",
            render: (record: any) => {
                return isShowEdit ? (
                    <Button
                        type="link"
                        onClick={() => {
                            getInfo(record.id);
                        }}
                    >
                        编辑
                    </Button>
                ) : null;
            },
        },
    ];
    // 获取操作的跟进记录详情数据
    const getInfo = (id?: any) => {
        if (!id || id === "") return;
        getCustomerFollowInfo(id).then((res: any) => {
            if (res.code === 200) {
                const obj = {
                    ...res.data,
                    notifyGroups: res.data.groups.map((item: any) => item.id),
                    notifyUsers: res.data.users.map((item: any) => item.id),
                };
                setCurrentObject(res.data);
                form.setFieldsValue(obj);
                setOpen(true);
                setOpenTitle("编辑跟进记录");
            }
        });
    };
    // 获取通知用户组和个人信息
    const getNodityInfo = (id?: any) => {
        if (!id || id === "") return;
        getCustomerNodityInfo(id).then((res: any) => {
            if (res.code === 200) {
                setNotifyObj(res.data);
                // 通知组
                const grops =
                    res.data.groups.map((item: any) => item.groupId) || [];
                // 通知个人
                const users =
                    res.data.users.map((item: any) => item.userId) || [];

                form.setFieldValue("notifyUsers", users);
                form.setFieldValue("notifyGroups", grops);
            }
        });
    };
    // 跟进记录列表
    const getList = (object?: any) => {
        if (!details.id || details.id === "") return;
        const params = {
            customerId: details.id,
            current: pageOptions.current,
            size: pageOptions.pageSize,
            ...object,
        };
        if (object?.current && object?.size) {
            setPageOptions({
                current: object?.current,
                pageSize: object?.size,
            });
        }
        customerFollowList(params).then((res: any) => {
            if (res.code === 200) {
                setList(res.data.records);
                setTotal(res.data.total);
            }
        });
    };
    const onchange = (params: any) => {
        // console.log(params, 'params')
        setPageOptions({
            current: params.current,
            pageSize: params.pageSize,
        });
        getList({
            current: params.current,
            size: params.pageSize,
        });
    };
    useEffect(() => {
        getList({
            current: pageOptions.current,
            size: pageOptions.pageSize,
        });
    }, [updateList]);
    const handleOk = () => {
        form.validateFields().then((res: any) => {
            const params = {
                ...res,
                customerId: details.id,
                notifyGroups: res.notifyGroups.toString(),
                notifyUsers: res.notifyUsers.toString(),
                followTime: dayjs(res.followTime).format("YYYY-MM-DD HH:mm:ss"),
            };
            if (currentObj.id) {
                editCustomerFollow(currentObj.id, params)
                    .then((response: any) => {
                        if (response.code === 200) {
                            message.success("编辑跟进记录成功");
                            getList({
                                current: pageOptions.current,
                                size: pageOptions.pageSize,
                            });
                            setOpen(false);
                            setCurrentObject({});
                            form.resetFields();
                        } else {
                            message.error(
                                response.message || "编辑跟进记录失败"
                            );
                        }
                    })
                    .catch((err) => {
                        console.log(err, "err");
                    });
            } else {
                addCustomerFollow(params)
                    .then((response: any) => {
                        if (response.code === 200) {
                            message.success("新建跟进记录成功");
                            getList({
                                current: pageOptions.current,
                                size: pageOptions.pageSize,
                            });
                            setOpen(false);
                            form.resetFields();
                        } else {
                            message.error(
                                response.message || "新建跟进记录失败"
                            );
                        }
                    })
                    .catch((err) => {
                        console.log(err, "err");
                    });
            }
        });
    };
    useEffect(() => {
        getNodityInfo(details?.id);
        getList();
        if (
            details &&
            (details?.businessSalesmanList || details?.businessOwner)
        ) {
            // const res= details?.businessOwner || [].concat(details?.businessSalesmanList || [])

            const arr = JSON.parse(
                JSON.stringify(details?.businessSalesmanList)
            );
            if (
                !arr.some((item: any) => item.id === details?.businessOwner?.id)
            ) {
                arr.push(details?.businessOwner);
            }
            setUList(arr);
        }
    }, [details]);
    return (
        <div>
            {isShowEdit ? (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        type="primary"
                        onClick={() => {
                            const grops =
                                notifyObj.groups.map(
                                    (item: any) => item.groupId
                                ) || [];
                            // 通知个人
                            const users =
                                notifyObj.users.map(
                                    (item: any) => item.userId
                                ) || [];

                            form.setFieldValue("notifyUsers", users);
                            form.setFieldValue("notifyGroups", grops);
                            setOpen(true);
                            setOpenTitle("新增跟进记录");
                            form.setFieldValue("followWay", "PHONE");
                            form.setFieldValue(
                                "followUser",
                                JSON.parse(getSession)?.userName
                            );
                        }}
                    >
                        新增跟进记录
                    </Button>
                </div>
            ) : null}

            <TableCom
                rowKey={(record: any) => record.id}
                columns={columns}
                dataSource={list}
                pagination={{
                    ...pageOptions,
                    total: total,
                }}
                onChange={onchange}
                // style={{heght:'30vh', overflow:'auto'}}
                // scroll={{
                //     y: 300,
                // }}
            ></TableCom>
            {open && (
                <ModalCom
                    open={open}
                    title={openTitle}
                    onCancel={() => {
                        setOpen(false);
                        form.resetFields();
                        setCurrentObject({});
                    }}
                    onOk={() => handleOk()}
                    okText="保存"
                >
                    <Form {...layout} form={form}>
                        <Form.Item
                            label="跟进方式"
                            name="followWay"
                            rules={[
                                {
                                    required: true,
                                    message: "请选择跟进方式",
                                },
                            ]}
                        >
                            <Select>
                                {followWayList.map((item: any) => {
                                    return (
                                        <Option
                                            key={item.value}
                                            value={item.value}
                                        >
                                            {item.label}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="跟进人"
                            name="followUser"
                            rules={[
                                {
                                    required: true,
                                    message: "请选择跟进人",
                                },
                            ]}
                        >
                            <Select>
                                {uList.map((item: any) => {
                                    return (
                                        <Select.Option
                                            key={item.id}
                                            value={item.name}
                                        >
                                            {item.name}
                                        </Select.Option>
                                    );
                                })}
                                {/* 客户详情里面的业务员和业务负责人数据选择 */}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="发生时间"
                            name="followTime"
                            rules={[
                                {
                                    required: true,
                                    message: "请选择跟进时间",
                                },
                            ]}
                        >
                            {currentObj?.followTime ? (
                                <span>
                                    <DatePicker
                                        defaultValue={dayjs(
                                            currentObj?.followTime,
                                            "YYYY-MM-DD HH:mm:ss"
                                        )}
                                        showTime
                                        onChange={(e) => {
                                            form.setFieldValue("followTime", e);
                                        }}
                                    />
                                </span>
                            ) : (
                                <DatePicker showTime />
                            )}
                        </Form.Item>
                        <Form.Item
                            label="跟进摘要"
                            name="followSummary"
                            rules={[
                                {
                                    required: true,
                                    message: "请输入跟进摘要",
                                },
                            ]}
                        >
                            <Input placeholder="请输入" maxLength={100}></Input>
                        </Form.Item>
                        <Form.Item
                            label="跟进详情"
                            name="followDetail"
                            rules={[
                                {
                                    required: true,
                                    message: "请输入跟进详情",
                                },
                            ]}
                        >
                            <Input.TextArea
                                rows={3}
                                placeholder="请输入"
                                maxLength={500}
                            ></Input.TextArea>
                        </Form.Item>
                        <Form.Item label="通知用户组" name="notifyGroups">
                            <Checkbox.Group>
                                {notifyObj.groups.map((item: any) => {
                                    return (
                                        <Checkbox value={item.groupId}>
                                            {item.name}
                                        </Checkbox>
                                    );
                                })}
                            </Checkbox.Group>
                        </Form.Item>
                        <Form.Item label="通知个人" name="notifyUsers">
                            <Checkbox.Group>
                                {notifyObj.users.map((item: any) => {
                                    return (
                                        <Checkbox value={item.userId}>
                                            {item.name}
                                        </Checkbox>
                                    );
                                })}
                            </Checkbox.Group>
                        </Form.Item>
                    </Form>
                </ModalCom>
            )}
        </div>
    );
};

export default FollowUp;
