import {
  Row,
  Col,
  Form,
  Space,
  message,
  Modal,
  Tooltip,
  Input,
  Select,
  Cascader,
  AutoComplete,
} from "antd";
import "./index.scss";
import { useRequest } from "ahooks";
import axios from "axios";
import { useEffect, useState } from "react";
let current_env = process.env.REACT_APP_ENV || "production";
const cross_env = {
  development: "https://dev.api.saikul.com/pub-data",
  test: "https://test.api.saikul.com/pub-data",
  production: "https://api.saikul.com/pub-data",
}[current_env];
const baseURL = cross_env || "https://api.saikul.com/pub-data";
//银行行联号
const financeBankInformationAPI = "/bankcnaps";
const FormItem = Form.Item;

const colProps = {
  span: 8,
};
type BankPropsType = {
  form: any;
};
/**
 * 个体户银行信息
 * @returns
 */
function Bank(props: BankPropsType) {
  const { form } = props;
  const [city, setCity] = useState([]);
  const [selectCityCode, setSelectCityCode] = useState("");
  const handleBank = (arr: any[]) => {
    return arr.map((i) => {
      return {
        branchNo: i.cnaps,
        branchName: i.bankName,
      };
    });
  };
  const bankBranchService = useRequest(
    ({
      // zsBankCode,
      ...params
    }: {
      cityCode: string;
      bankCode: string;
      keyword: string;
    }) =>
      axios
        .get<
          Common.ResponseData<
            Common.Paging<{ branchName: string; branchNo: string }>
          >
        >(baseURL + financeBankInformationAPI, {
          params: {
            ...params,
            // bankType: zsBankCode,
          },
        })
        .then((res) => {
          return handleBank(res.data.data.records);
        }),
    {
      manual: true,
      onError() {
        message.error("搜索失败，请重试");
      },
    }
  );

  const [bankData, setBankData] = useState([]);
  const searchHandle = (value: string) => {
    const { zsBankCode, cityCode } = form.getFieldsValue();
    if (cityCode && selectCityCode && zsBankCode) {
      bankBranchService.run({
        bankCode: zsBankCode,
        cityCode: selectCityCode,
        keyword: value,
      });
    } else {
      message.error("请选择开户银行和省份");
      // form.validateFields(["cityCode", "zsBankCode"]);
    }
  };
  const selectHandle = (val: any, option: any) => {
    form.setFieldsValue({
      bankBranchName: option.label,
      bankBranchCode: option.id,
    });
  };
  const getBanks = async () => {
    const res = await axios.get(baseURL + "/bank");
    if (res.data.data) {
      setBankData(res.data.data.records);
    }
  };
  const handleData = (start: any[]) => {
    const arr: any = start.map((item) => {
      if (item?.children?.length) {
        return {
          value: item,
          label: item.name,
          children: handleData(item.children),
        };
      } else {
        return {
          value: item,
          label: item.name,
          children: [],
        };
      }
    });
    return arr;
  };
  const getCity = async () => {
    const res = await axios.get(baseURL + "/city/tree?maxDeep=2");
    if (res.data.data) {
      const arr: any = handleData(res.data.data);
      console.log(arr);
      setCity(arr);
    }
  };
  useEffect(() => {
    getBanks();
    getCity();
  }, []);
  return (
    <div className="bank_info">
      <div className="common_title">银行信息</div>
      <div className="bank_info_content">
        <Row>
          <Col {...colProps}>
            <Form.Item
              className="bank_num"
              label="银行账号"
              name="bankAccountNo"
              rules={[
                { required: true, message: "请输入银行账号" },
                { pattern: /^[0-9]*$/, message: "请输入正确的银行账号" },
              ]}
            >
              <Input placeholder={"请输入银行账号"} />
            </Form.Item>
          </Col>
        </Row>
        <Row className="bank_desc">
          建议使用一类卡作为收款账号，因各银行限制，二类卡一天只能收一万元，超额打款会导致收款失败！
        </Row>
        <Row>
          <Col {...colProps}>
            <Form.Item
              label="预留手机号"
              name="bankPhoneNo"
              rules={[
                { max: 11, message: "请输入正确的手机号" },
                { required: true, message: "请输入银行预留手机号" },
                {
                  pattern: /^1[0-9]{10}/,
                  message: "请输入正确的手机号",
                },
              ]}
            >
              <Input placeholder={"请输入银行预留手机号"} />
            </Form.Item>
          </Col>
          <Col {...colProps}></Col>
        </Row>
        <Row>
          <Col>
            <Space>
              <Form.Item
                label="开户银行"
                name="zsBankCode"
                style={{ display: "inline-block" }}
                rules={[{ required: true, message: "请选择银行" }]}
              >
                <Select
                  onSelect={(_, option) => {
                    // form.setFieldValue({
                    //   bankName: option.label,
                    //   bankCode: option.id,
                    // });
                    form.setFieldValue("bankName", option.label);
                    form.setFieldValue("bankCode", option.id);
                  }}
                  showSearch
                  placeholder="请选择银行"
                  optionFilterProp="label"
                  options={bankData.map((item: any) => ({
                    value: item.code,
                    label: item.name,
                    id: item.code,
                  }))}
                />
              </Form.Item>
              <Form.Item
                label=""
                name="cityCode"
                style={{ display: "inline-block" }}
              >
                <Cascader
                  options={city}
                  onChange={(e: any) => {
                    form.setFieldValue(
                      "cityCode",
                      e.map((c: any) => c.name)
                    );
                    setSelectCityCode(e[1].code);
                  }}
                  //  options={addressData} onChange={changeSearch} fieldNames={optionName}
                  placeholder="请搜索选择省份"
                  allowClear
                  showSearch
                />
              </Form.Item>
              <Form.Item
                name="bankBranchName"
                rules={[
                  { required: true, message: "请输入开户行关键词查询" },
                  // { validator: validator }
                ]}
                style={{
                  display: "inline-block",
                  //   width: "calc(40% - 8px)",
                  marginLeft: "8px",
                }}
              >
                <AutoComplete
                  options={bankBranchService.data?.map(
                    (item: { branchNo: any; branchName: any }) => {
                      return {
                        id: item.branchNo,
                        value: item.branchName,
                        label: item.branchName,
                        data: item,
                      };
                    }
                  )}
                  onSelect={selectHandle}
                >
                  <Input.Search
                    // className={styles.searchSty}
                    onSearch={searchHandle}
                    allowClear
                    placeholder="请输入开户行关键词查询"
                    // loading={ zsBanks ? false : true}
                    onPressEnter={(e) => e.preventDefault()}
                    enterButton
                  />
                </AutoComplete>
              </Form.Item>
            </Space>
          </Col>
        </Row>
        <Row>
          <Col {...colProps}>
            <Form.Item
              label="开户行名称"
              name="bankBranchName"
              rules={[
                {
                  required: true,
                  message: "开户行名称不可以为空, 请重新查询开户行",
                },
              ]}
            >
              <Input placeholder="查询后自动输入" disabled />
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item
              label="开户行行号"
              name="bankBranchCode"
              rules={[{ required: true, message: "开户行行号不可以为空" }]}
            >
              <Input placeholder="查询后自动输入" disabled />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default Bank;
