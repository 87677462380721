import React, { useState, useEffect } from "react";
import { Space, Button, message, Popconfirm } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import AddModal from "./UserModal/AddModal";
import EditModal from "./UserModal/EditModal";
import BreadCrumbBar from "../../../../components/BreadCrumbBar";
import {
  usersList,
  changeStatus,
} from "../../../../services/api/system/page/user";
import "./index.scss";
import { useSelector } from "react-redux";
import TableCom from "../../../../components/TableCom";
// import Loading from '../../../../components/Loading';
type type_datatype = {
  key: string;
  name: string;
  job: string;
  department: string;
  email: string;
  account: string;
  role: string;
  status: string;
  operator: string;
  id: string;
  wxAccount: string;
  roleIdList: any;
  idNo: any;
};
// type type_edit_account = {
//     id: string;
//     account: string;
//     role: string[],
//     roles: { name: string, id: string }[],
//     department: string;
//     email: string;
//     name: string;
//     position: string;
//     wxAccount: string;
// }
const User = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const codeList: any = useSelector<any>((state: any) => state.codeList) || [];
  const updateState = codeList.includes("admin_user_user_update_status");
  // 新增和编辑权限
  const isAddEditCode = codeList.includes("admin_user_user_add_or_update");
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  // const [loading, setLoading] = useState<boolean>(true);
  const [records, setRecords] = useState<type_datatype[]>();
  const [paginationProps, setPaginationProps] = useState<{
    showQuickJumper: boolean;
    pageSize: number;
    current: number;
    total: number;
    // onChange: () => void
  }>({
    showQuickJumper: true,
    pageSize: 10,
    current: 1,
    total: 0,
    // onChange: () => { }
  });
  const [modalData, setModalData] = useState({});
  const getUserList = (object?: any) => {
    let params = {
      current: paginationProps.current,
      size: paginationProps.pageSize,
      ...object,
    };

    usersList(params.current, params.size).then((data) => {
      if (data.code === 200) {
        // setLoading(true);
        const records: any = [];
        data.data.records.forEach((i: any, index: number) => {
          const record = {
            key: index + "",
            name: i.name,
            job: i.position,
            department: i.department,
            email: i.email,
            account: i.account,
            role: i.roleNames,
            status: i.status + "",
            operator: "",
            id: i.id,
            wxAccount: i.wxAccount,
            roleIdList: i.roleIdList,
            idNo: i.idNo,
          };
          records.push(record);
        });
        setRecords(records);
        console.log(data, "--data");
        setPaginationProps({
          ...paginationProps,
          total: data.data.total,
          current: +data.data.current,
          pageSize: +data.data.size,
        });
      } else {
        messageApi.open({
          type: "error",
          content: data.message || "请求异常",
        });
      }
    });
  };
  const onChange = (params: any) => {
    setPaginationProps({
      ...paginationProps,
      ...params,
    });
    getUserList({
      size: params.pageSize,
      current: params.current,
    });
  };
  useEffect(() => {
    getUserList();
  }, []); // eslint-disable-line

  const columns: ColumnsType<type_datatype> = [
    {
      title: "名称",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "职位",
      dataIndex: "job",
      key: "job",
      align: "center",
    },
    {
      title: "部门",
      dataIndex: "department",
      key: "department",
      align: "center",
    },
    {
      title: "邮箱",
      dataIndex: "email",
      key: "email",
      align: "center",
    },
    {
      title: "账号",
      dataIndex: "account",
      key: "account",
      align: "center",
    },
    {
      title: "角色",
      dataIndex: "role",
      key: "role",
      align: "center",
    },
    {
      title: "状态",
      key: "status",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          {updateState ? (
            record!.status === "1" ? (
              <Popconfirm
                title="是否禁用？禁用后无法登录！"
                onConfirm={() => {
                  changeStatus(record.id, "0").then((data) => {
                    if (data.successful) {
                      messageApi.open({
                        type: "success",
                        content: "禁用成功",
                      });
                      usersList(
                        paginationProps.current,
                        paginationProps.pageSize
                      ).then((data) => {
                        // setLoading(true);
                        const records: type_datatype[] = [];
                        data.data.records.forEach((i: any, index: number) => {
                          const record: type_datatype = {
                            key: index + "",
                            name: i.name,
                            job: i.position,
                            department: i.department,
                            email: i.email,
                            account: i.account,
                            role: i.roleNames,
                            status: i.status + "",
                            operator: "",
                            id: i.id,
                            wxAccount: i.wxAccount,
                            roleIdList: i.roleIdList,
                            idNo: i.idNo,
                          };
                          records.push(record);
                          setRecords(records);
                        });
                        // setLoading(false);
                      });
                    }
                  });
                }}
              >
                <Button type="link">禁用</Button>
              </Popconfirm>
            ) : (
              <Button
                type="link"
                onClick={() => {
                  changeStatus(record.id, "1").then((data) => {
                    if (data.successful) {
                      messageApi.open({
                        type: "success",
                        content: "启用成功",
                      });
                      usersList(
                        paginationProps.current,
                        paginationProps.pageSize
                      ).then((data) => {
                        // setLoading(true);
                        const records: type_datatype[] = [];
                        data.data.records.forEach((i: any, index: number) => {
                          const record: type_datatype = {
                            key: index + "",
                            name: i.name,
                            job: i.position,
                            department: i.department,
                            email: i.email,
                            account: i.account,
                            role: i.roleNames,
                            status: i.status + "",
                            operator: "",
                            id: i.id,
                            wxAccount: i.wxAccount,
                            roleIdList: i.roleIdList,
                            idNo: i.idNo,
                          };
                          records.push(record);
                          setRecords(records);
                        });
                        // setLoading(false);
                      });
                    }
                  });
                }}
              >
                {" "}
                启用
              </Button>
            )
          ) : null}
          {/* {
                        
                    } */}
        </Space>
      ),
    },
    {
      title: "操作",
      key: "operator",
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          {isAddEditCode && (
            <Button
              type="link"
              onClick={(key) => {
                // setModalData({
                //     id: record.id,
                //     account: record.account,
                //     role: record?.role,
                //     department: record.department,
                //     email: record.email,
                //     name: record.name,
                //     position: record.job,
                //     roles: [{
                //         name: '超级管理员',
                //         id: '1'
                //     }],
                //     wxAccount: record.wxAccount
                // });
                setModalData(record);
                setIsEdit(true);
              }}
            >
              编辑
            </Button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div className="system-user">
      {contextHolder}
      <div className="content">
        <BreadCrumbBar
          breads={[
            { label: "组织管理", path: "" },
            { label: "用户管理", path: "" },
          ]}
        />
        {isAddEditCode ? (
          <div className="system-user-add">
            <Button
              type="primary"
              onClick={() => {
                setIsAdd(true);
              }}
            >
              +新增
            </Button>
          </div>
        ) : null}
        <TableCom
          rowKey={(record: any) => record.id}
          columns={columns}
          dataSource={records}
          scroll={{ y: "calc(100vh - 300px)" }}
          pagination={{
            ...paginationProps,
            total: paginationProps.total,
          }}
          onChange={onChange}
        ></TableCom>
        <AddModal
          isAdd={isAdd}
          addModal={(value: boolean) => {
            setIsAdd(value);
          }}
          cb={getUserList}
        />
        {isEdit && (
          <EditModal
            isEdit={isEdit}
            editModal={(value: boolean) => {
              setIsEdit(value);
            }}
            cb={getUserList}
            modalData={modalData}
          />
        )}

        {/* <Loading status={loading} /> */}
      </div>
    </div>
  );
};
export default User;
