import Util from "../../../services/util";
import qs from "qs";
import { message } from "antd";
import { invoiceURL } from "@src/utils/invoiceURL";
import axios from "axios";
const util = Util.getInstance();
const baseURL = invoiceURL();
// 营业执照列表
export const getAdministrationList = (values?: any) => {
  return util
    .httpGet("/sinzetech-agency-processing/license", values)
    .then((res) => res);
};

// 新增营业执照

export const addAdministration = (values: any) => {
  return util
    .httpPost("/sinzetech-agency-processing/license", values)
    .then((res) => res);
};

// 营业执照详情

export const getAddministrstionInfo = (id?: string) => {
  return util
    .httpGet(`/sinzetech-agency-processing/license/${id}`)
    .then((res) => res);
};

// 资料打包下载
// const downloadFun = (id?:any) => {
//   return util.httpGetDownload(`/sinzetech-agency-processing/license/download/${id}`)
//   .then(res => res)
// }
export const download = (id?: string, file?: any) => {
  // return downloadFun(`https://dev.api.saikul.com/admin/sinzetech-agency-processing/license/download/${id}`)
  // .then(res => res)
  return util
    .httpGetDownload(
      `/sinzetech-agency-processing/license/download/${id}`,
      {},
      file
    )
    .then((res: any) => {
      // if(res?.code  === 200){
      //   // downloadFun(id)
      // }
      return res;
    })
    .catch((err) => {
      console.log(err, "err");
    });
};

// 营业执照 办理失败

// export const administrationError = (id?:string, values?:any) => {
//   return util.httpPutBodyJSON(`/sinzetech-agency-processing/license/fail/${id}`, values)
//   .then(res => res)
// }

// 上传营业执照
// export const uploadAdministration = (values?:any) => {
//   return util.httpPost('/sinzetech-agency-processing/license/upload', values)
//   .then(res => res)
//   .catch(err => {
//     message.error(err.response.data.message || '上传失败')
//   })
// }

// 营业执照审核资料
// export const administrationExamine = (values?:any) => {
//   return util.httpPutBody('/sinzetech-agency-processing/license/approval', values)
//   .then(res => res)
// }

// 获取所有税区

export const getTaxList = (values?: any) => {
  return util.httpGet("/sinzetech-tax/tax-region", values).then((res) => res);
};

// 获取所用户数据，使用到分配办税人

export const getAllUsers = (values: any) => {
  return util.httpGet("/sinzetech-admin-user/users", values).then(
    (res) => res,
    (err) => {
      message.error(err.response.data.message);
      return err.response.data;
    }
  );
};
export const getUserList = (values: any) => {
  return util
    .httpGet("/sinzetech-admin-user/users/fill", values)
    .then((res) => res);
};
// 业务代办设置
export const businessSet = (values: any) => {
  return util
    .httpPost("/sinzetech-agency-processing/setting", values)
    .then((res) => res);
};
// 获取代办列表
export const getBusinessList = (values?: any) => {
  return util
    .httpGet("/sinzetech-agency-processing/setting", values)
    .then((res) => res);
};

// // 重发短信协议
// export const send = (id?:any) => {
//   return util.httpUpload(`/sinzetech-agency-processing/license/message/${id}`,{}, {headers:{
//     "Content-Type": "	application/x-www-form-urlencoded"
// }})
// .then(res => res)
// }

// // 开始登记
// export const startRegister = (id:any) => {
//   return util.httpPutUrl(`/sinzetech-agency-processing/taxation/begin/${id}`)
//   .then(res => res)
// }
// 分配办税人
export const assign = (id: any, value?: any) => {
  return util
    .httpPutBodyJSON(
      `/sinzetech-agency-processing/taxation/assign/${id}`,
      value
    )
    .then((res) => res)
    .catch((err) => {
      message.error(err.response.data.message || "上传失败");
    });
};
// // 登记成功
// export const taxSuc = (id:any, value?:any) => {
//   return util.httpPutBodyJSON(`/sinzetech-agency-processing/taxation/success/${id}`,value)
//   .then(res => res)
// }
// // 登记失败
// export const taxError = (id:any, value?:any) => {
//   return util.httpPutBodyJSON(`/sinzetech-agency-processing/taxation/fail/${id}`, value)
//   .then(res => res)
// }
// // 开票员变更
// export const invoicer = (id:any, value:any) => {
//   return util.httpPutBody(`/sinzetech-agency-processing/taxation/invoicer/${id}`, value)
//   .then(res => res)
// }

// 操作日志 http://yapi.saikul.com/project/748/interface/api/25213
export const getLogs = (values?: any) => {
  return util
    .httpGetev(
      `/api/sinzetech-log/log?${qs.stringify({
        "sorts[0].field": "createTime",
        "sorts[0].direction": "DESCENDING",
      })}`,
      values
    )
    .then((res) => res);
};

// ocr 识别  根据上传营业执照识别关键字段信息
export const getImageMsg = (values?: any) => {
  return util
    .httpGet("/sinzetech-ocr/ocr/business-license", values)
    .then((res) => res);
};

// 根据图片id 获取图片的地址
export const getImageUrl = (params: any) => {
  return util
    .httpGetDomain(`/admin/sinzetech-resource/files/ossUrlZoom`, params)
    .then((res) => res)
    .catch((err) => err);
};

// 税区详情
export const getTaxInfo = (id: any) => {
  return util.httpGet(`/sinzetech-tax/tax-region/${id}`).then((res) => res);
};

// // 个体户注销发起
// export const deregistration = (id?:any) => {
//   return util.httpGet(`/sinzetech-agency-processing/license/${id}/cancel`)
//   .then(res => res)
// }
// // 撤回注销申请
// export const canelRevoke = (id?:string) => {
//   return util.httpPut(`/sinzetech-agency-processing/license/${id}/cancel/revoke`,{}, {headers:{
//     "Content-Type": "	application/x-www-form-urlencoded"
// }})
// .then(res => res)
// }
// // 上传清税证明，执照注销
// export const UploadTaxCertificate = (id?:string|number, value?:any) => {
//   return util.httpPost(`/sinzetech-agency-processing/license/${id}/cancel/file`, value)
//   .then(res => res)
// }
// // 清税证明，营业执照
// export const viewProof = (id?:number|string, params?:any) => {
//   return util.httpGet(`/sinzetech-agency-processing/license/${id}/file`, params)
//   .then(res => res)
// }
let current_env = process.env.REACT_APP_ENV || "production";
const url: any = {
  development: "https://sign-test.saikul.com/signFlows",
  test: "https://sign-test.saikul.com/signFlows",
  production: "https://realsign.saikul.com/signFlows",
}[current_env];
// 查看签署协议
export const getSignFile = (id?: any, params?: any) => {
  return util
    .httpGet(`/${id}/documents`, params, true, url, false)
    .then((res) => res);
};

// 改版后的请求接口参数

// 删除重发委托协议
export const delegationAgreement = (id: string) => {
  return util
    .httpDeletel(
      `/sinzetech-agency-processing/license/${id}/delegation-agreement`
    )
    .then((res) => res);
};
// 删除重发双录视频
export const dualreCordingVideo = (id: string) => {
  return util
    .httpDeletel(
      `/sinzetech-agency-processing/license/${id}/dualre-cording-video`
    )
    .then((res) => res);
};
// 删除申请
export const deleteLicense = (id: string) => {
  return util
    .httpDeletel(`/sinzetech-agency-processing/license/${id}`)
    .then((res) => res);
};
interface Process {
  applyStatus: string;
  applyUserId?: string;
  applyUserName?: string;
  reason?: string;
  linkOrganization?: string;
  licenseUrl?: string;
  enterpriseName?: string;

  licenseNo?: string;
  type?: string;
  legal?: string;
  invoiceClerkIds?: string;
}
// 流程推进接口
export const licenseProcess = (id: string, params?: Process) => {
  return util.httpPost(
    `/sinzetech-agency-processing/license/process/${id}`,
    params
  );
};
interface Sign {
  type: string | number;
}
// 重发短信或者双录视频
export const licenseSign = (id: string, type: string | number) => {
  return util.httpPutBody(
    `/sinzetech-agency-processing/license/message/${id}?type=${type}`,
    {}
  );
};

// 开票方列表
export const getInvoicingList = (params: any) => {
  return util
    .httpGet(`/taxpayers`, params, true, baseURL, false)
    .then((res) => res);
};
// 手动更新
export const upDataCredit = (params: any) => {
  return util.httpPutBody(
    "/taxpayers/manual-update-credit",
    params,
    true,
    baseURL,
    false
  );
};
// 自动更新
export const apiUpdateCredit = (params: any, url: string) => {
  return util.httpPutBody(url, params, true, baseURL, false);
};
