import React, { useState, useEffect } from "react";
import { Upload, message, Modal } from "antd";
import Util from "../../services/util";

const util = Util.getInstance();
// 组件上传  区别  视频， 图片  音频

/**
 * @param{type}  image, video audio
 *
 * */

const uuid = () => {
    let date = new Date().valueOf(); //获取时间戳
    let txt = "1234567890"; //生成的随机机器码
    let len = 13; //机器码有多少位
    let pwd = ""; //定义空变量用来接收机器码
    for (let i = 0; i < len; i++) {
        pwd += txt.charAt(Math.floor(Math.random() * txt.length)); //循环机器码位数随机填充
    }
    return date + pwd;
};

const UploadCom = (props: any) => {
    const {
        type = "image",
        accept = "image/jpeg,image/png,image/gif",
        maxSize,
        listType = "picture-card",
        maxCount = 1,
        name = "图片",
        cb,
        directory,
        value,
        multiple = false,
        ButtonText = `+上传默认${name}`,
        nullText = null,
    } = props;
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [fileList, setFileList] = useState<any>([]);
    const [messageApi, contextHolder] = message.useMessage();
    const beforeUpload = (file: any, fileList: any) => {
        const typeList = accept.split(",");
        const fileType = file.name.lastIndexOf(".");
        let str = file.name.substring(fileType);
        if (type !== "file") {
            if (typeList.indexOf(file.type) < 0) {
                messageApi.open({
                    type: "error",
                    content: `请选择正确格式的${name}`,
                });
                return false;
            }
        } else {
            if (typeList.indexOf(str) < 0) {
                messageApi.open({
                    type: "error",
                    content: `请选择正确格式的${name}`,
                });
                return false;
            }
        }
        if (maxSize && maxSize !== 0) {
            const currentSize = file.size / 1024 / 1024;
            if (currentSize > maxSize) {
                messageApi.open({
                    type: "error",
                    content: `大小不能超过${maxSize}M`,
                });
                return false;
            }
        }
        return file;
    };
    const customRequest = (params: any) => {
        const { file, onSuccess, onError } = params;
        const formData = new FormData();
        formData.append("file", file);
        util.httpUpload(
            `/sinzetech-resource/oss/endpoint?contentType=${
                file.type
            }&rename=${uuid()}`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        ).then((res: any) => {
            if (res.code === 200) {
                onSuccess();
                if (maxCount !== 1) {
                    let arr = [...fileList];
                    const fileType = file.name.lastIndexOf(".");
                    let str = file.name.substring(fileType);
                    arr.push({
                        url: res.data.downloadLink,
                        downloadLink: res.data.downloadLink,
                        filePath: res.data.filePath,
                        mimeType: str,
                        fileName: file.name,
                        size: file.size / 1024 / 1024,
                        fileId: res.data.filePath,
                    });
                    setFileList(arr);
                    cb && cb(arr, file);
                } else {
                    let arr = [];
                    const fileType = file.name.lastIndexOf(".");
                    let str = file.name.substring(fileType);
                    arr.push({
                        url: res.data.downloadLink,
                        downloadLink: res.data.downloadLink,
                        filePath: res.data.filePath,
                        mimeType: str,
                        fileName: file.name,
                        size: file.size / 1024 / 1024,
                        fileId: res.data.filePath,
                    });
                    setFileList(arr);
                    cb && cb(arr, file);
                }
            } else {
                onError();
                messageApi.open({
                    type: "error",
                    content: res.message || "上传服务异常",
                });
            }
        });
    };
    const handleRemove = (file: any) => {
        if (!multiple) {
            setFileList([]);
            cb && cb([], file);
        } else {
            const arr = fileList.filter((item: any) => {
                return item.url !== file.url;
            });
            setFileList(arr);
            cb && cb(arr, file);
        }
    };
    useEffect(() => {
        if (!multiple) {
            if (value && value !== "") {
                const str = {
                    url: value,
                };
                setFileList([str]);
            }
        } else {
            if (value && value.length !== 0) {
                let arr = JSON.parse(JSON.stringify(value)) || [];
                if (arr.length !== 0) {
                    arr.map((item: any) => {
                        (item.url = item.fileUrl), (item.name = item.fileName);
                        return item;
                    });
                }

                setFileList(arr);
            }
        }
    }, [value]);
    const handlePreview = (file: any) => {
        if (type === "image") {
            if (file.url && file.url !== "") {
                setPreviewImage(file.url);
                setPreviewOpen(true);
            }
        } else {
            messageApi.open({
                type: "error",
                content: "仅支持图片预览",
            });
            window.location.href = file.fileUrl;
        }
    };
    return (
        <div>
            {contextHolder}
            <Upload
                listType={listType}
                accept={accept}
                fileList={fileList}
                maxCount={maxCount}
                multiple={multiple}
                onRemove={handleRemove}
                beforeUpload={beforeUpload}
                customRequest={customRequest}
                directory={directory}
                onPreview={handlePreview}
            >
                {fileList.length !== 0 && !multiple ? (
                    nullText
                ) : (
                    <div style={{ marginTop: 8 }}>{ButtonText}</div>
                )}
            </Upload>

            <Modal
                open={previewOpen}
                title={null}
                footer={null}
                onCancel={() => {
                    setPreviewOpen(false);
                }}
            >
                <img
                    alt="example"
                    style={{
                        width: "100%",
                    }}
                    src={previewImage}
                />
            </Modal>
        </div>
    );
};

export default UploadCom;
