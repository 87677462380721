import React, { useState, useEffect, useRef } from "react";
import { Space, Table, Button, Popconfirm, message } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import RoleModal from "./RoleModal";
import BreadCrumbBar from "../../../../components/BreadCrumbBar";
import "./index.scss";
import { getRoles, deleteRole } from "./promise";
import { useSelector } from "react-redux";
import TableCom from "../../../../components/TableCom";

type type_datatype = {
    id: string;
    key: string;
    authority: string;
    name: string;
};
const Role = () => {
    const [messageApi, contextHolder] = message.useMessage();
    // const [scrollY, setScrollY] = useState<number>(0);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const tableRef = useRef<null | HTMLDivElement>(null);
    const [currentObj, setCurrentObj] = useState({});
    const [pageOptions, setPageOptions] = useState<any>({
        current: 1,
        pageSize: 10,
        // total:0
    });
    const [total, setTotal] = useState(0);
    const codeList: any =
        useSelector<any>((state: any) => state.codeList) || [];
    // 删除
    const isRemoveCode = codeList.includes("admin_user_role_delete");
    // 新增
    const isAddCode = codeList.includes("admin_user_role_add");
    // 编辑
    const isEditCode = codeList.includes("admin_user_role_update");
    const [list, setList] = useState<any[]>([]);
    // useEffect((): void => {
    //     const y = tableRef?.current?.clientHeight ?? 0;
    //     setScrollY(y);
    // }, []);
    const handleEdit = (record: any) => {
        setCurrentObj(record);
        setOpenModal(true);
    };
    const handleDelete = (id: string | number) => {
        deleteRole(id).then((res) => {
            if (res?.code === 200) {
                messageApi.open({
                    type: "success",
                    content: "删除成功",
                });
                getRoleList({
                    current: pageOptions.current,
                    size: pageOptions.page,
                });
            }
        });
    };

    const columns: ColumnsType<type_datatype> = [
        {
            title: "角色名称",
            dataIndex: "name",
            key: "name",
            align: "center",
        },
        {
            title: "权限",
            dataIndex: "authorityNames",
            key: "authorityNames",
            align: "center",
            width: 350,
        },
        {
            title: "操作",
            key: "operator",
            align: "center",
            render: (_, record: any) => (
                <>
                    {
                        record.code != 'admin' &&
                        <Space size="middle">
                            {isEditCode && (
                                <Button type="link" onClick={() => handleEdit(record)}>
                                    编辑
                                </Button>
                            )}

                            {isRemoveCode && (
                                <Popconfirm
                                    title="确定要删除吗？"
                                    onConfirm={() => handleDelete(record?.id)}
                                >
                                    <Button type="link">删除</Button>
                                </Popconfirm>
                            )}
                        </Space>
                    }
                </>
            ),
        },
    ];
    const getRoleList = (obj?: any) => {
        let params = {
            current: pageOptions.current,
            size: pageOptions.pageSize,
        };
        if (obj && obj !== undefined && obj?.current && obj?.size) {
            params = {
                current: obj.current || 1,
                size: obj.size || 10,
            };
        }
        if (obj?.size && obj?.current) {
            setPageOptions({
                current: obj.current,
                pageSize: obj.size,
            });
        }
        getRoles(params).then((res) => {
            if (res?.code === 200) {
                setList(res.data.records);
                // setPageOptions({
                //     // current: +res.data.pages,
                //     // pageSize: +res.data.size,
                //     ...pageOptions,
                //     total: res.data.total
                // })
                setTotal(res.data.total);
            } else {
                messageApi.open({
                    type: "error",
                    content: res.message || "请求异常",
                });
            }
        });
    };
    const changePage = (obj: any) => {
        getRoleList({
            current: obj.current,
            size: obj.pageSize,
        });
    };
    useEffect(() => {
        getRoleList();
    }, []); // eslint-disable-line
    return (
        <div className="system-role" ref={tableRef}>
            {contextHolder}
            <div className="content">
                <BreadCrumbBar
                    breads={[
                        { label: "组织管理", path: "" },
                        { label: "角色管理", path: "" },
                    ]}
                />
                {isAddCode && (
                    <div className="system-role-add">
                        <Button
                            type="primary"
                            onClick={() => {
                                setOpenModal(true);
                                setCurrentObj({});
                            }}
                        >
                            +新增
                        </Button>
                    </div>
                )}

                {/* <Table rowKey={record => record.id} columns={columns} dataSource={list} scroll={{ y: scrollY - 200 }} pagination={pageOptions} onChange={changePage}/> */}
                <TableCom
                    rowKey={(record: any) => record.id}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        ...pageOptions,
                        total: total,
                    }}
                    onChange={changePage}
                    scroll={{
                        y: "calc(100vh - 300px)",
                    }}
                ></TableCom>
                <RoleModal
                    isOpen={openModal}
                    openModal={(value: boolean) => {
                        setOpenModal(value);
                        setCurrentObj({});
                    }}
                    cb={() =>
                        getRoleList({
                            current: pageOptions.current,
                            size: pageOptions.pageSize,
                        })
                    }
                    currentObj={currentObj}
                />
            </div>
        </div>
    );
};
export default Role;
