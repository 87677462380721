import React, { useRef, useEffect } from 'react'
import { Form, Input, Select, Button, DatePicker, Radio, Checkbox, Upload } from 'antd'
import './index.scss'
const { RangePicker } = DatePicker;
const { TextArea } = Input
const layout = {
  labelCol: {
    span: 8
  },
  wrapperCol: {
    span: 12
  }
}
const FormItem = Form.Item
// isRealTime 是否实时更新
const FormItemComponent = (props) => {
  const { formParams, layoutType = 'inline', optionsList = [{
    label: '重置',
    key: 'reset',
    params: {
      onClick: () => onFill(),
      style: {
        marginRight: '30px'
      }
    }
  },
  {
    label: '查询',
    key: 'search',
    params: {
      type: 'primary',
      // htmlType:'submit'
      onClick: () => onFinish()
    }
  }], onSearch, isRealTime = true } = props
  const timer = useRef(null)
  const [form] = Form.useForm();
  const onFill = () => {
    form.resetFields();
    onSearch && onSearch({})
  }
  const onFinish = () => {
    const object = form.getFieldsValue()
    onSearch && onSearch(object)
  }
  const renderChild = (object) => {
    const { type, params, children, descriptions } = object
    switch (type) {
      case 'Input':
        return <Input style={{ minWidth: 140 }} {...params} allowClear ></Input>
      case 'Select':
        return <Select {...params} style={{ minWidth: 140 }} allowClear ></Select>
      case 'DatePickers':
        return <RangePicker allowClear style={{ minWidth: 140 }} />
      case "Radio.Group":
        return <Radio.Group>
          {children.map(item => {
            return <Radio value={item.value}>{item.lable}</Radio>
          })}
        </Radio.Group>
      case 'Checkbox':
        return <Checkbox.Group>
          {
            children.map(item => {
              return <Checkbox value={item.value}>{item.label}</Checkbox>
            })
          }
        </Checkbox.Group>
      case 'TextArea':
        return <TextArea {...params} allowClear></TextArea>
      case 'Upload':
        return <>
          <Upload {...params}></Upload>
          <div>{descriptions}</div>
        </>
      case 'DatePicker':
        return <DatePicker />
      default:
        return null
    }
  }
  const formItem = (object) => {
    const { dataIndex, label, rules } = object
    return (
      <div className='form_item_container'>
        <FormItem name={dataIndex} label={label} rules={rules ? rules : []} >
          {renderChild(object)}
        </FormItem>
      </div>
    )

  }
  // 根据所选数据 更新数据
  const formataSearch = (values) => {
    console.log(values, 'click')
    const params = {}
    values && values.forEach((item) => {
      if (item.touched) {
        params[item.name] = item.value
        if (item.value === undefined) {
          const res = formParams.find((cItem) => cItem.dataIndex === item.name[0])
          form.setFieldValue(item.name[0], res?.params?.defaultValue)
        }
      }
    })
    if (isRealTime) {
      // 立即更新同步到业务组件
      onSearch(params)
    } else {
      if (timer.current) {
        clearTimeout(timer.current)
        timer.current = null
      }
      timer.current = setTimeout(() => {
        onSearch(params)
      }, 500)
    }
  }
  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    }
  }, [])
  return <>
    <Form layout={layoutType} {...layout} onFinish={onFinish} form={form} onFieldsChange={(values, allFields) => {
      if (!optionsList) {
        formataSearch(allFields)
      }
    }}
    >
      {
        formParams.map(item => {
          return formItem(item)
        })
      }
      {/* <FormItem>
          <div className='options_button' style={{width: '200px', marginLeft:'60px', display:'flex', justifyContent:'flex-end'}}>
            {
              optionsList.map(item => {
                return (
                  <Button {...item.params}>{item.label}</Button>
                )
              })
            }
          </div>
        </FormItem> */}
    </Form>
    {
      optionsList && <div className='options_button'>
        {
          optionsList.map(item => {
            return (
              <Button {...item.params}>{item.label}</Button>
            )
          })
        }
      </div>
    }

  </>
}

export default FormItemComponent;