import React, { useEffect, useState, useMemo } from "react";
import "./index.scss";
import { Steps } from "antd";
import { applyStatusObject } from "../../config";
import { useSelector } from "react-redux";
import Description from "./components/RenderStepDesc";
import RenderOperationButton from "./components/RenderOperationButton";
// steps 流程进度
const StepsCom = (props: any) => {
    // 权限code
    const codeList = useSelector((state: any) => state.codeList) || [];
    // 角色code
    const roleList = useSelector((state: any) => state.roleCodes);
    const { details, update, isAssignor, taxInfo } = props;
    let timer: any = null;
    // 获取倒计时
    const [text, setText] = useState("");
    // 倒计时是否显示
    const showCountdown = useMemo(() => {
        var nowtime = new Date(), //获取当前时间
            endtime = new Date(details?.approvalTime);
        var lefttime = endtime.getTime() - nowtime.getTime();
        return details?.applyStatus === "APPROVAL" && lefttime > 0;
    }, [details]);
    const getTime = () => {
        var nowtime = new Date(), //获取当前时间
            endtime = new Date(details?.approvalTime); //定义结束时间
        var lefttime = endtime.getTime() - nowtime.getTime(); //距离结束时间的毫秒数
        if (lefttime <= 0) {
            update(details.id);
            clearInterval(timer);
            return "";
        }
        let leftd = Math.floor(lefttime / (1000 * 60 * 60 * 24)); //计算天数
        let lefth = Math.floor((lefttime / (1000 * 60 * 60)) % 24); //计算小时数
        let leftm = Math.floor((lefttime / (1000 * 60)) % 60); //计算分钟数
        let lefts = Math.floor((lefttime / 1000) % 60); //计算秒数
        return leftd + "天 " + lefth + "小时 " + leftm + "分 " + lefts + "秒";
    };
    const renderTime = () => {
        if (showCountdown) {
            timer = setInterval(() => {
                setText(getTime());
            }, 1000);
        }
    };
    useEffect(() => {
        renderTime();
        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [details]);
    const renderStatus = useMemo(() => {
        let objs: any = {};
        if (details?.applyStatus) {
            objs = applyStatusObject[details?.applyStatus];
        }
        return objs;
    }, [details]);
    const renderStatusDom = useMemo(() => {
        let obj = renderStatus;
        return (
            <div className="step_status_container_label">
                状态{" "}
                <div className={`default_span_bg ${obj?.color + "_bg"}`}>
                    <span className={`default_span ${obj?.color}`}></span>
                    {details["applyStatusName"]}
                </div>
            </div>
        );
    }, [renderStatus, details]);
    const stepList = [
        {
            title: "申请营业执照资料审核", // label
            description: (
                <Description
                    step="1"
                    person={details?.approvalUserName} // 名称
                ></Description>
            ),
        },
        {
            title: "营业执照办理", // label
            description: (
                <Description
                    step="2"
                    entryClerkName={details?.entryClerkName}
                    person={details?.licenseAgentName}
                ></Description>
            ),
        },
        {
            title: "税务登记", // label
            description: (
                <Description
                    step="3"
                    person={details?.taxAgentName}
                ></Description>
            ),
        },
    ];

    return (
        <div className="steps">
            <div className="step_container_title">流程进度</div>
            <div className="step_container_content">
                <Steps
                    items={stepList}
                    current={renderStatus?.step}
                    className="steps_component"
                ></Steps>
            </div>
            <div className="step_status_container">
                {/* 渲染status */}
                {renderStatusDom}
                <div className="step_operation_button_container">
                    {/* 审核倒计时  审核中显示 */}
                    {showCountdown ? (
                        <div className="step_time">
                            审核时长还剩余
                            <span className="downCount_warning">{text}</span>
                        </div>
                    ) : null}
                    {/* button操作 */}
                    <RenderOperationButton
                        details={details}
                        codeList={codeList}
                        roleList={roleList}
                        update={update}
                        isAssignor={isAssignor}
                        taxInfo={taxInfo}
                    />
                </div>
            </div>
        </div>
    );
};

export default StepsCom;
