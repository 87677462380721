import { PlusOutlined } from '@ant-design/icons'
import TableCom from '@src/components/TableCom'
import { getInvoiceCategory, getInvoiceConfiguration } from '@src/services/api/invoice/invoice'
import { Button, Col, Form, Modal, Row, Select, Popconfirm, message, Space } from 'antd'
import axios from 'axios'
import React, { memo, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { deleteInvoiceConfiguration, getConfigurationInfoService, getTaxList, postInvoiceConfiguration, postTaxArea } from '../../service'
const defaultProps = {}
type props = {}
export type ConfigurationProps = Required<typeof defaultProps> & props
export const Configuration: React.FC<ConfigurationProps> = memo((props) => {

  const [taxOptions, setTaxOptions] = useState([]);
  const [invoiceProjectOptions, setInvoiceProjectOptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [list, setList] = useState([]);
  const [invoiceProjectNameList, setInvoiceProjectNameList] = useState([]);

  const [formTax] = Form.useForm();
  const [formInvoice] = Form.useForm();
  const routerParams = useParams()

  const InvoiceCategoryService = async () => {
    const res = await axios.get(getInvoiceCategory);
    setInvoiceProjectOptions(res.data.data)
  }
  const getInvoiceProjectName = async (params?: any) => {
    const data = {
      size: 9999,
      current: 1,
      ...params,
    };
    await axios.get(getInvoiceConfiguration, {
      params: data,
    }).then((res: any) => {
      if (res.data.code === 200) {
        setInvoiceProjectNameList(res?.data?.data?.records)
      }
    });
  };
  const getTaxLists = () => {
    const data = {
      size: 99999,
      current: 1,
    };
    getTaxList(data).then((res: any) => {
      if (res.code === 200) {
        let arr: any = [];
        res.data.records.map((item: any) => {
          let obj = {
            label: item.name,
            value: item.id,
          };
          arr.push(obj);
        });
        setTaxOptions(arr);
      }
    });
  };
  const handleCancel = () => {
    formInvoice.resetFields();
    setIsModalOpen(false);
    setInvoiceProjectNameList([])
  };
  const onFinishTax = async (values: any) => {
    await postTaxArea({ id: routerParams.id, registeredTaxAreaId: values.registeredTaxAreaId })
    message.success('保存成功')
    getConfigurationInfo()
  }
  const onFinishInvoice = async (values: any) => {
    const handleList = JSON.parse(JSON.stringify(invoiceProjectNameList.filter((item: any) => values?.invoiceCategoryCode.some((code: any) => {
      return item.invoiceCategoryCode == code
    }))).replace(/id/g, 'invoiceConfigurationId'))
    setIsModalOpen(false)
    await postInvoiceConfiguration({ id: routerParams.id, invoiceConfigurations: handleList })
    await getConfigurationInfo()
    message.success('保存成功')
    formInvoice.resetFields();
    setInvoiceProjectNameList([])
  }
  const columns = [
    {
      title: "id",
      dataIndex: "id",
    },
    {
      title: "发票项目名称",
      dataIndex: "invoiceProjectName",
    },
    {
      title: "税收分类编码",
      dataIndex: "invoiceCategoryCode",
    },
    {
      title: "操作",
      render: (_: any, record: any) => (
        <Popconfirm
          title="确定要删除吗？"
          onConfirm={() => deleteInvoiceConfiguration({ groupId: routerParams.id, id: record.id, }).then(() => {
            getConfigurationInfo()
            message.success('删除成功')
          })}
        >
          <Button type="link">删除</Button>
        </Popconfirm>
      ),
    },
  ];
  const getConfigurationInfo = (params?: any) => {
    getConfigurationInfoService({ id: routerParams.id }).then((res: any) => {
      setList(res?.invoiceConfigurationVos);
      formTax.setFieldValue('registeredTaxAreaId', res.registeredTaxAreaId)

    });
  };
  useEffect(() => {
    getTaxLists()
    getConfigurationInfo()
    InvoiceCategoryService()
  }, [])
  return <>
    <div>
      <div style={{ fontSize: '18px' }}>邀请注册个体户</div>
      <Form
        name="tax"
        title='邀请注册个体户'
        form={formTax}
        // name="formTaxArea"
        labelCol={{ span: 2 }}
        wrapperCol={{ span: 6 }}
        onFinish={onFinishTax}
        colon={false}
      >
        <Form.Item
          label="注册税区"
        >
          <Space.Compact block>
            <Form.Item
              name="registeredTaxAreaId"
            >
              <Select
                style={{ width: 200 }}
                defaultValue={taxOptions[0] ? taxOptions[0] : ""}
                // onChange={handleChange}
                options={taxOptions}
              />
            </Form.Item><Button type="primary" htmlType="submit" >
              保存
            </Button>
          </Space.Compact>
        </Form.Item>
      </Form>
    </div>
    <div>
      <Row justify={"space-between"} align={'middle'} wrap={false} style={{ marginBottom: '10px' }}>
        <Col>
          <div style={{ fontSize: '18px' }}>开票配置</div>
        </Col>
        <Col>
          {/* <Space align="end"> */}
          <Button type="primary" onClick={() => setIsModalOpen(true)} >
            <PlusOutlined />添加开票名称
          </Button>
          {/* </Space> */}
        </Col>
      </Row>

      <TableCom
        border
        rowKey={(record: any) => record.id}
        columns={columns}
        dataSource={list}
        total={0}
      ></TableCom>

    </div>
    {
      isModalOpen && <Modal
        open={isModalOpen}
        title="新增开票名称"
        onCancel={handleCancel}
        centered
        width={600}
        footer={null}
      >
        <Form
          form={formInvoice}
          name="formInvoice"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          style={{ maxWidth: 600 }}
          onFinish={onFinishInvoice}
        >
          <Form.Item
            label="税收分类简称"
            name="invoiceCategoryShort"
          >
            <Select
              allowClear
              fieldNames={{ label: 'invoiceCategoryShort', value: 'invoiceCategoryShort' }}
              options={invoiceProjectOptions}
              onChange={(value => {
                formInvoice.setFieldValue('invoiceProjectName', undefined)
                getInvoiceProjectName({
                  invoiceCategoryShort: value,
                })
              })}
            />
          </Form.Item>
          <Form.Item
            label="发票项目名称"
            name="invoiceCategoryCode"
            rules={[{ required: true }]}
          >
            <Select
              mode="multiple"
              allowClear
              fieldNames={{ label: 'invoiceProjectName', value: 'invoiceCategoryCode' }}
              options={invoiceProjectNameList}
            />
          </Form.Item>
          <Row justify={"end"} wrap={false} gutter={8}>
            <Col>
              <Button
                onClick={(e) => {
                  handleCancel();
                }}
              >
                取消
              </Button>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit">
                确定
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    }
  </>

})
Configuration.defaultProps = defaultProps