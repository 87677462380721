import React,{memo} from 'react'
import { getMerchantsByGroupId } from '../../service'
import { useTable } from '@src/hooks/useTable'
import { Col, Descriptions, Modal, Row, Table } from 'antd'
import { ColumnType } from 'antd/es/table'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { TableToolBar } from '@src/components/TableToolBar'
const defaultProps = {}
type props = {}
export type MerchantsProps = Required<typeof defaultProps> & props
export const Merchants:React.FC<MerchantsProps> = memo((props)=>{
  
  const routerParams = useParams()
  const columns:ColumnType<API.Merchant.Item>[] = [
    {title:'序号',dataIndex:'id', render: (_, _1, index) => index + 1 },
    {title:'服务商',dataIndex:'name'},
    {title:'联系人',dataIndex:'contactName'},
    {title:'联系电话',dataIndex:'contactPhone'},
    {title:'结算账户',dataIndex:'action',render:(_,record)=>{
      return <>
        <Link to={''} onClick={()=>{
              Modal.confirm({
                closable:true,
                icon: null,
                title: '结算账户信息',
                content: <div style={{display:'flex',justifyContent:'center'}}>
                  <Descriptions labelStyle={{minWidth:120}}  column={1}>
                    <Descriptions.Item label="持卡人姓名">{record.settlementBankName}</Descriptions.Item>
                    <Descriptions.Item label="银行账号">{record.settlementBankNo}</Descriptions.Item>
                    <Descriptions.Item label="开户行">{record.settlementBank}</Descriptions.Item>
                    <Descriptions.Item label="开户行所在地">{record.settlementBankBranchPlace}</Descriptions.Item>
                    <Descriptions.Item label="支行名称">{record.settlementBankBranchName}</Descriptions.Item>
                  </Descriptions>
                </div>,
                maskClosable:true,
                width: 380,
                cancelButtonProps: {
                  hidden: true,
                },
                okButtonProps: {
                  hidden: true,
                },
              });
        }}>查看</Link>
      </>
    }}
  ]
  const tableService = useTable(
    async (params) => {
      const { current, size,createDate, ...rest } = params;
      const res = await getMerchantsByGroupId({
        id:routerParams.id,
        ...rest,
        pageSize: size,
        pageNumber: current,
        withTotal: true,
        "sorts[0].direction":'ASCENDING',
        "sorts[0].field":'createTime',
      });
      return {
        data: res.list,
        total: Number(res.total),
      };
    },
    { defaultCurrent: 1, defaultSize: 20 }
  );
  return <>
        <TableToolBar
        {...tableService.getToolBarProps?.({
          search: {
            placeholder: "输入服务商名称，联系人搜索",
          },
          // title: "服务商列表",
        })}
      />
   <Table
  rowKey={"id"}
  pagination={tableService.getPaginationProps?.({
    className: "page_num",
  })}
  {...tableService.getTableProps({ className: "public_table" ,scroll:{y:'34vh'}})}
  columns={columns}
/></>
})
Merchants.defaultProps = defaultProps