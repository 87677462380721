import React, { useEffect, useState } from "react";
import { Button } from "antd";
import {
    getTaxList,
    getAdministrationList,
    getUserList,
    getAllUsers,
} from "../promise";
import {
    auditOptions,
    licensingOptions,
    taxStatusOptions,
    approvalStatusColor,
    licenseProcessStatusColor,
    taxProcessStatusColor,
    applyStatusList,
    signStatusList,
    dualStatusList,
} from "./config";
import BreadCrumbBar from "../../../../components/BreadCrumbBar";
import SearchForm from "../../../../components/FormItem";
import { useNavigate } from "react-router-dom";
import TabelCom from "../../../../components/TableCom";
import dayjs from "dayjs";
import "./index.scss";
import { CommonStatus } from "@src/components/common";
const applyStatusColrMap = {
    DRAFT: "#F0F2F5",
    NO_APPROVAL: "#FAAB0C",
    APPROVAL: "#FAAB0C",
    APPROVAL_REJECTION: "#F53F3F",
    NO_LICENSE: "#FAAB0C",
    SUBMIT_BUSINESS: "#FAAB0C",
    LICENSE_FAIL: "#F53F3F",
    NO_ASSIGN_TAX: "#FAAB0C",
    TAX_REGISTERING: "#FAAB0C",
    TAX_PASS: "#00D18B",
    TAX_REJECTION: "#F53F3F",
    TERMINATION: "#F53F3F",
};
const signStatusColorMap = {
    true: '#00D18B',
    false: '#FAAB0C',
}
const dualStatusColrMap = {
    true: '#00D18B',
    false: '#FAAB0C',
}
const breads = [
    {
        label: "代办业务",
        path: "/business",
    },
    {
        label: "工商税务开办",
        path: "/business/administration",
    },
    {
        label: "工商税务开办",
        path: "",
    },
];
const TaxAdministration = () => {
    const navigate = useNavigate();
    const [taxOptions, setTaxOptions] = useState([]);
    const [pageOptions, setPageOptions] = useState({
        pageSize: 10,
        current: 1,
    });
    const [total, setTotal] = useState(0);
    const [list, setList] = useState([]);
    const [user, setUser] = useState([]);
    const [LicenseOfficerList, setLicenseOfficerList] = useState([]);
    const [dataAuditor, setDataAuditor] = useState<any>([]);
    const [search, setSearch] = useState<any>({});
    const local = JSON.parse(
        localStorage.getItem("operation_authorization") || "{}"
    );
    const formColumns = [
        {
            label: "个体户/法人姓名",
            dataIndex: "legal",
            type: "Input",
            params: {
                placeholder: "请输入",
            },
        },
        {
            label: "联系电话",
            dataIndex: "legalPhone",
            type: "Input",
            params: {
                placeholder: "请输入",
            },
        },
        {
            label: "提交申请时间",
            dataIndex: "time",
            type: "DatePickers",
            params: {},
        },
        {
            label: "身份证号",
            type: "Input",
            dataIndex: "idNo",
            params: {
                placeholder: "请输入",
            },
        },
        {
            label: "申请编号",
            type: "Input",
            dataIndex: "applyCode",
            params: {
                placeholder: "请输入",
            },
        },
        {
            label: "所属税区",
            dataIndex: "taxRegionIds",
            type: "Select",
            params: {
                placeholder: "请选择",
                options: taxOptions,
                showSearch: true,
                filterOption: (input: any, option: any) => {
                    return option.label.indexOf(input) >= 0;
                },
            },
        },
        {
            label: "申请开办状态",
            dataIndex: "applyStatus",
            type: "Select",
            params: {
                placeholder: "请选择",
                options: applyStatusList,
            },
        },
        {
            label: "委托协议签署状态",
            dataIndex: "signStatus",
            type: "Select",
            params: {
                placeholder: "请选择",
                options: signStatusList,
            },
        },
        {
            label: "双录视频面签状态",
            dataIndex: "dualStatus",
            type: "Select",
            params: {
                placeholder: "请选择",
                options: dualStatusList,
            },
        },
        {
            label: "资料审核员",
            dataIndex: "approvalUserId",
            type: "Select",
            params: {
                placeholder: "请选择",
                options: dataAuditor,
                showSearch: true,
                filterOption: (input: any, option: any) => {
                    return option.name.indexOf(input) >= 0;
                    // return true
                },
            },
        },
        {
            label: "执照办理员",
            dataIndex: "licenseAgentId",
            type: "Select",
            params: {
                placeholder: "请选择",
                options: LicenseOfficerList,
                showSearch: true,
                filterOption: (input: any, option: any) => {
                    return option.name.indexOf(input) >= 0;
                },
            },
        },
        {
            label: "办税人",
            dataIndex: "taxAgentId",
            type: "Select",
            params: {
                placeholder: "请选择",
                options: user,
                showSearch: true,
                filterOption: (input: any, option: any) => {
                    return option.name.indexOf(input) >= 0;
                },
            },
        },
    ];
    const columns = [
        {
            title: "申请编号",
            dataIndex: "applyCode",
            width: 150,
        },
        {
            title: "个体/法人姓名",
            dataIndex: "legal",
            width: 180,
        },
        {
            title: "联系电话",
            dataIndex: "legalPhone",
            width: 150,
        },
        {
            title: "提交申请时间",
            dataIndex: "createTime",
            width: 250,
        },
        {
            title: "身份证号",
            dataIndex: "idNo",
            width: 170,
        },
        {
            title: "所属税区",
            dataIndex: "taxRegionName",
            width: 150,
        },
        {
            title: "申请开办状态",
            dataIndex: "applyStatusName",
            width: 150,
            render: (t: string, item: any) => {
                return (
                    <CommonStatus
                        color={applyStatusColrMap[item.applyStatus]}
                        text={t}
                    />
                );
            },
        },
        {
            title: "委托协议签署状态",
            dataIndex: "signStatusName",
            width: 150,
            render: (t: string, item: any) => {
                return (
                    <CommonStatus
                        color={signStatusColorMap[item.signStatus]}
                        text={t}
                    />
                );
            },
        },
        {
            title: "双录视频面签状态",
            dataIndex: "dualStatusName",
            width: 150,
            render: (t: string, item: any) => {
                return (
                    <CommonStatus
                        color={dualStatusColrMap[item.dualStatus]}
                        text={t}
                    />
                );
            },
        },
        {
            title: "资料审核员",
            dataIndex: "approvalUserName",
            width: 150,
        },
        {
            title: "执照办理员",
            dataIndex: "licenseAgentName",
            width: 150,
        },
        {
            title: "录入员",
            dataIndex: "entryClerkName",
            width: 150,
        },
        {
            title: "办税人",
            dataIndex: "taxAgentName",
            width: 150,
        },
        {
            title: "创建人",
            dataIndex: "createUserName",
            width: 150,
        },
        {
            title: "业务员",
            dataIndex: "clerkUserName",
            width: 150,
        },
        {
            title: "申请来源",
            dataIndex: "sourceIdName",
            width: 150,
            render: (record:any) => record ? record : '-'
        },
        {
            title: "操作",
            fixed: "right",
            width: 100,
            render: (record: any) => {
                return (
                    <Button
                        type="link"
                        onClick={() => {
                            navigate(
                                `/business/administration/info/${record.id}`
                            );
                        }}
                    >
                        详情
                    </Button>
                );
            },
        },
    ];
    // 办税人
    const getUser = () => {
        const params = {
            roleCodes: "TaxOfficer",
        };
        getUserList(params).then((res: any) => {
            if (res.code === 200) {
                const val = res.data;
                const data = val.map((item: any) => {
                    item.label = item.name;
                    item.value = item.id;
                    return item;
                });
                data.unshift({
                    label: "指派给我",
                    value: local?.userId,
                });
                setUser(data);
            }
        });
    };
    // 办税人
    const getUsers = () => {
        const params = {
            roleCodes: "LicenseOfficer",
        };
        getUserList(params).then((res: any) => {
            if (res.code === 200) {
                const val = res.data;
                let data = val.map((item: any) => {
                    item.label = item.name;
                    item.value = item.id;
                    return item;
                });
                data.unshift({
                    label: "指派给我",
                    value: local?.userId,
                });
                setLicenseOfficerList(data);
            }
        });
    };
    // 获取所有的税区数据
    const getAllTaxs = () => {
        const data = {
            current: 1,
            size: 999999,
        };
        getTaxList(data).then((res: any) => {
            if (res.code === 200) {
                let arr: any = [];
                res.data.records.map((item: any) => {
                    let obj = {
                        label: item.name,
                        value: item.id,
                    };
                    arr.push(obj);
                });
                setTaxOptions(arr);
            }
        });
    };
    // 获取所有的营业执照列表
    const getLists = (object?: any) => {
        const data = {
            size: pageOptions.pageSize,
            current: pageOptions.current,
            ...object,
        };
        getAdministrationList(data).then((res: any) => {
            if (res.code === 200) {
                setTotal(res.data.total);
                setList(res.data.records);
            }
        });
    };
    const handleChange = (params?: any) => {
        setPageOptions({
            current: params.current,
            pageSize: params.pageSize,
        });
        getLists({
            ...search,
            current: params.current,
            size: params.pageSize,
        });
    };
    // 资料审核员
    const getAlls = () => {
        const params = {
            roleCodes: "LicenseReviewer",
        };
        getUserList(params).then((res: any) => {
            if (res.code === 200) {
                const val = res.data;
                let data = val.map((item: any) => {
                    item.label = item.name;
                    item.value = item.id;
                    return item;
                });
                data.unshift({
                    label: "指派给我",
                    value: local?.userId,
                });
                setDataAuditor(data);
            }
        });
    };
    useEffect(() => {
        getAllTaxs();
        getLists();
        getUser();
        getUsers();
        getAlls();
        // const y = tableRef?.current?.clientHeight ?? 0;
        // setScrollY(y);
    }, []);
    const onSearch = (values: any) => {
        const startTime = values.time
            ? dayjs(values.time[0]).format("YYYY-MM-DD") + " 00:00:00"
            : "";
        const endTime = values.time
            ? dayjs(values.time[1]).format("YYYY-MM-DD") + " 23:59:59"
            : "";
        const params = {
            ...values,
            size: pageOptions.pageSize,
            startTime,
            endTime,
            current: pageOptions.current,
        };
        setSearch(params);
        delete params.time;
        getLists(params);
    };
    return (
        <div className="public_list_page tax_admin_list_container">
            <BreadCrumbBar breads={breads}></BreadCrumbBar>
            <div
                className="tax_content"
                style={{
                    marginBottom: 20,
                    backgroundColor: "#fff",
                    padding: 20,
                }}
            >
                <div>
                    <SearchForm
                        formParams={formColumns}
                        onSearch={onSearch}
                    ></SearchForm>
                </div>
            </div>
            <div className="table_box">
                <div className="func">
                    <Button
                        className="func_item"
                        type="primary"
                        onClick={() => {
                            navigate("/business/administration/newAdd");
                        }}
                    >
                        +新增营业执照申请
                    </Button>
                </div>
                <div>
                    <TabelCom
                        rowKey={(record: any) => record.id}
                        columns={columns}
                        dataSource={list}
                        pagination={{
                            ...pageOptions,
                            total: total,
                        }}
                        onChange={handleChange}
                        scroll={{
                            // y: "40vh",
                            x: 2000,
                        }}
                    ></TabelCom>
                </div>
            </div>
        </div>
    );
};

export default TaxAdministration;
