import React, { memo } from "react";
import { getCustomersByGroupId } from "../../service";
import { useTable } from "@src/hooks/useTable";
import { Button, Col, Row, Table, message } from "antd";
import { ColumnType } from "antd/es/table";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { TableToolBar } from "@src/components/TableToolBar";
const defaultProps = {};
type props = {};
export type CustomersProps = Required<typeof defaultProps> & props;
export const Customers: React.FC<CustomersProps> = memo((props) => {
  const routerParams = useParams();
  const columns: ColumnType<API.Customer.Item>[] = [
    { title: "序号", dataIndex: "id", render: (_, _1, index) => index + 1 },
    { title: "采购企业", dataIndex: "name" },
    { title: "纳税人识别号", dataIndex: "taxpayerNo" },
    { title: "采购货品", dataIndex: "purchaseGoodsCount" },
    { title: "送货地", dataIndex: "addressCount" },
    { title: "供货协议", dataIndex: "supplyAgreementCount" },
    {
      title: "操作",
      dataIndex: "action",
      render() {
        return (
          <Link
            to={""}
            onClick={() => {
              message.warning("暂未开放");
            }}
          >
            详情
          </Link>
        );
      },
    },
  ];
  const tableService = useTable(
    async (params) => {
      const { current, size, createDate, ...rest } = params;
      const res = await getCustomersByGroupId({
        id: routerParams.id,
        ...rest,
        pageSize: size,
        pageNumber: current,
        withTotal: true,
        "sorts[0].direction": "ASCENDING",
        "sorts[0].field": "createTime",
      });
      return {
        data: res.list,
        total: Number(res.total),
      };
    },
    { defaultCurrent: 1, defaultSize: 20 }
  );
  return (
    <>
      <TableToolBar
        {...tableService.getToolBarProps?.({
          search: {
            placeholder: "输入客户名称搜索",
          },
          // title: "客户列表",
        })}
      />
      <Table
        rowKey={"id"}
        pagination={tableService.getPaginationProps?.({
          className: "page_num",
        })}
        {...tableService.getTableProps({
          className: "public_table",
          scroll: { y: "34vh" },
        })}
        columns={columns}
      />
    </>
  );
});
Customers.defaultProps = defaultProps;
