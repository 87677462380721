import { EyeOutlined, PaperClipOutlined } from '@ant-design/icons'
import ProDescriptions, { ProDescriptionsItemProps, ProDescriptionsProps } from '@ant-design/pro-descriptions'
import { DetailPageContent } from '@src/components/DetailPageComponents/Content'
import { DetailPageHeader } from '@src/components/DetailPageComponents/Header'
import { DetailPageContainer } from '@src/components/DetailPageComponents/PageContainer'
import { OperableImage } from '@src/components/OperableImage'
import { Space, Card,message, Badge, Button } from 'antd'
import React,{memo, useEffect} from 'react'
import { InvoiceItemTable } from '../../components/InvoiceItemTable'
import { PaymentInfo as PaymentInfoCard } from '../../components/PaymentInfo'
import { EntityDetailContext, uploadFileAndGettingRes, useEntityDetailLogic } from '../../hooks/useEntityDetailLogic'
import { IndividualBusinessStatusEnum, EntityTypeEnum, commonHeaderDetail, invoiceInfoColumns, EntityStatusEnum, invoiceSystemStatusEnum, TaxPaymentCycleEnum } from '../../constant'
import { cross_env } from '@src/services/util'
import { whileLoading } from '@src/utils/utils'
import { UploadFile } from 'antd/lib/upload'
import { EntityDetailPageContainer } from '../../components/EntityDetailPageContainer'
import { Link, useNavigate } from 'react-router-dom'
import { RexChinesePhone, RexSocialCreditCode } from '@src/utils/RegExp'
import { RexIdNo } from '@src/constant/RegExp'
const defaultProps = {}
type props = {}
export type IndividualityDetailProps = Required<typeof defaultProps> & props

export const IndividualityDetail:React.FC<IndividualityDetailProps> = memo((props)=>{
  const entityDetailService = useEntityDetailLogic()
  const {isAgency,isCRM,isNotAgency} = entityDetailService
  useEffect(()=>{
    if(entityDetailService.entityDataService.data){
      entityDetailService.fileUrlService.run([entityDetailService.entityDataService.data.license,entityDetailService.entityDataService.data.businessFilingCertificate,entityDetailService.entityDataService.data.contractFileId])
    }
  },[entityDetailService.entityDataService.data])
  const navigate = useNavigate()
  const [licenseUrl,businessFilingCertificateUrl,contractUrl] = entityDetailService.fileUrlService.data||[]
  // 来源类型：CRM/EP/回收小组,创建人：章三,实体类型：企业,来源名称：台州森林,创建账号：18813012345,实体状态：正常,创建时间：2023 05-13 09:23:32
  const headerDescColumns:ProDescriptionsItemProps<API.Entity.Info>[] = [
    ...commonHeaderDetail,
    {
      dataIndex: "status",
      title: "实体状态",
      renderText: (v) => v,
      render:(v,record)=><Space size={'small'} style={{gap:4}}>{<Badge status={IndividualBusinessStatusEnum.get(record.status)?.status}></Badge>}{IndividualBusinessStatusEnum.get(record.status)?.text} {[-1,-2].includes(record.status)&&<EyeOutlined onClick={()=>{window.location.href = (`/business/agency/info/${entityDetailService.entityDataService.data?.deregisterId}`)}} style={{color: '#1677ff',cursor:'pointer'}} />}</Space>,
      valueEnum: EntityStatusEnum,
      editable:isNotAgency,
    },
    {dataIndex:'createTime',title:'创建时间',editable:false}
  ]

  const basicInfoColumns:ProDescriptionsItemProps<API.Entity.Info>[] = [
    {dataIndex:'name',title:'个体户名称',copyable:true,editable:isNotAgency,formItemProps:{rules:[{message:'请输入',required:true}]}},
    {dataIndex:'taxNo',title:'统一信用代码',copyable:true,editable:isNotAgency,formItemProps:{rules:[{message:'请填写正确格式',pattern:RexSocialCreditCode},{message:'请输入',required:true}]}},
    {dataIndex:'legalPerson',title:'经营者',editable:isNotAgency,render:(dom,record)=>record.sourceType==='AGENCY'? <Link to={`/entity/transaction/natural/${record.naturalPersonId}`}>{dom}</Link>:dom},
    {dataIndex:'idNumber',title:'身份证号',editable:isNotAgency,formItemProps:{rules:[{message:'请填写正确格式',pattern:RexIdNo},{message:'请输入',required:true}]}},
    {dataIndex:'taxRegionIds',title:'合作税区',editable:isNotAgency, renderText: (value)=>value?.split(','), valueType:'select',fieldProps:{
      mode:'multiple',options: entityDetailService.taxOptions
    }},
    {dataIndex:'contractFileId',title:'合同',hideInDescriptions: !isCRM(),editable:false, renderText:(value)=>value,render: (value,record)=>record.contractFileId?<Space size='small'><PaperClipOutlined /> <a href={contractUrl} target='_blank'>合同.pdf</a></Space>:<>-</>},
    { dataIndex: "agentNo", title: "代办编号",hideInDescriptions: !isAgency(),editable:false,render:(dom,record)=><Link onClick={()=>window.location.href = `/business/administration/info/${entityDetailService.entityDataService.data?.sourceId}`} to={``}>{dom}</Link> },
    {dataIndex:'license',title:'营业执照',editable:false, render:(dom,record)=><><OperableImage filename="营业执照" width={150} height={150} uploadFileProps={{onChange:entityDetailService.onUploadFileChange.bind(null,'license')}} src={licenseUrl} /></>},
    {dataIndex:'businessFilingCertificate',title:'商务部备案证明',editable:false, render:(dom,record)=><><OperableImage filename="商务部备案证明" width={150} height={150} uploadFileProps={{onChange:entityDetailService.onUploadFileChange.bind(null,'businessFilingCertificate')}} src={businessFilingCertificateUrl} /></>},
  ]

  const invoiceConfigColumns:ProDescriptionsItemProps<API.Entity.Info>[] = [
    {dataIndex:'taxpayerId',title:'办税人',renderText:(value)=>value,valueType:'select',fieldProps:{allowClear:true,options:entityDetailService.taxOfficerOptions}},
    {dataIndex:'invoiceUserIds',title:'开票员',valueType:'select',fieldProps:{allowClear:true,mode:'multiple',options: entityDetailService.invoiceClerkOptions},renderText:(value)=>value?.split(',')},
    {dataIndex:'invoiceStatus',title:'税票系统',editable:false,valueEnum:invoiceSystemStatusEnum},
    {dataIndex:'taxPaymentCycle',title:'缴税周期',valueEnum:TaxPaymentCycleEnum},
    {dataIndex:'taxDisk',title:'[税盘] 设备号(主分机号)',formItemProps:{rules:[{message:'请输入',required:true}]}},
  ]

  return  <EntityDetailPageContainer value={entityDetailService}>
    <DetailPageHeader breadCrumb={[{label:'交易实体'},{label:'个体户'},{label:entityDetailService.entityDataService.data?.name||''}]} title={entityDetailService.entityDataService.data?.name}>
      <ProDescriptions editable={{onSave:entityDetailService.onSave}} columns={headerDescColumns} dataSource={entityDetailService.entityDataService.data}></ProDescriptions>
    </DetailPageHeader>
    <DetailPageContent>
      <Card title="基本信息">
        <ProDescriptions editable={{onSave:entityDetailService.onSave}} columns={basicInfoColumns} dataSource={entityDetailService.entityDataService.data}></ProDescriptions>
      </Card>
      <Card title="开票信息">
        <ProDescriptions editable={{onSave:entityDetailService.onSave}} columns={invoiceConfigColumns} dataSource={entityDetailService.entityDataService.data}></ProDescriptions>
        <ProDescriptions editable={{onSave:entityDetailService.onSave}} columns={invoiceInfoColumns} dataSource={entityDetailService.entityDataService.data}></ProDescriptions>
        <InvoiceItemTable />
      </Card>
      <PaymentInfoCard></PaymentInfoCard>
    </DetailPageContent>
    </EntityDetailPageContainer>
})
IndividualityDetail.defaultProps = defaultProps