import { DownloadFileButton } from '@/components/DownloadFileButton';
import { DownloadOutlined, LoadingOutlined, PlusOutlined, ReloadOutlined, SyncOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { EditableImage, EditableImageProps } from '../EditableImage';
import { EasyUpload, EasyUploadFileProps } from '../EasyUpload';
import styles from './index.module.scss';
import { cross_env } from '@src/services/util';
import { uuid } from '@src/utils';

const defaultProps = {

};
type props = {
  src?: string;
  editableImageProps?: EditableImageProps;
  action?: string;
  uploadFileProps?: EasyUploadFileProps;
  updateFileProps?: EasyUploadFileProps;
  createFileProps?: EasyUploadFileProps;
  width?: number;
  height?: number;
  onSuccess?: EasyUploadFileProps['onSuccess'];
  onError?: EasyUploadFileProps['onError'];
  title?: string;
  filename?: string
};
const actionStyle = { color: '#fff', fontSize: 16 }
export type OperableImageProps = Partial<typeof defaultProps> & props;

export const OperableImage: React.FC<PropsWithChildren<OperableImageProps>> = props => {
  const {
    src,
    children,
    editableImageProps,
    uploadFileProps,
    width,
    height,
    onSuccess,
    onError, title, filename,
    action = `${cross_env}/sinzetech-resource/oss/endpoint?rename=${uuid()}`
  } = props;
  const { updateFileProps = uploadFileProps, createFileProps = uploadFileProps } = props
  return (
    <>
      <div className={classNames(styles.container)} style={{ width, height }} title={title}>
        {src ? (
          <EditableImage
            title={title}
            width={width}
            height={height}
            src={src}
            {...editableImageProps}
            actions={[
              <EasyUpload
                headers={{
                  Authorization: `Bearer ${JSON.parse(window.localStorage?.getItem("operation_authorization") || "{}")?.["access_token"]}`,
                }}
                onSuccess={onSuccess}
                onError={onError}
                action={action}
                method='PUT'
                {...updateFileProps}

              >
                <div style={actionStyle} title="更新图片">
                  <SyncOutlined />
                </div>
              </EasyUpload>,
              <DownloadFileButton href={src} filename={filename ? filename : title}>
                {(props) =>
                (<div style={actionStyle} onClick={props.onDownload} title="下载图片">
                  {props.loading ? <LoadingOutlined /> : <DownloadOutlined />}
                </div>)}
              </DownloadFileButton>
            ]}
          />
        ) : (
          <EasyUpload
            headers={{
              Authorization: `Bearer ${JSON.parse(window.localStorage?.getItem("operation_authorization") || "{}")?.["access_token"]}`,
            }}
            onSuccess={onSuccess}
            onError={onError}
            listType="picture-card"
            action={action}
            method='PUT'
            {...createFileProps}
          >
            {(props) => <EasyUpload.ImageTypeUI {...props} children={<><PlusOutlined />
              <span style={{ marginTop: 8 }}>{children}</span></>} />}
          </EasyUpload>
        )}
      </div>
    </>
  );
};

OperableImage.defaultProps = defaultProps;
