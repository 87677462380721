// 跟进方式枚举
export const followWayList = [
  {
    label:'电话沟通',
    value:'PHONE'
  },
  {
    label:'邮件沟通',
    value:'EMAIL'
  },
  {
    label:'当面拜访',
    value:'FACE'
  },
  {
    label:'其他',
    value:'OTHER'
  },
]
