import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import Util from '../../../../../../../../services/util';
import { useSelector } from 'react-redux';
import './index.scss'
const util = Util.getInstance()

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'code-block',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'background',
  'font',
  'align',
  'clean',
];

const Editor = (props: any) => {
  const {
    onChange,
    refInstance,
    readOnly = false,
    closeClipboardImg = false,
    ...otherProps
  } = props;
  let reactQuillRef: any = useRef(null);
  const selector:any= useSelector((state:any) => state.text)
  // 上传图片
  const imageHandler = async () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.setAttribute('multiple', 'multiple');
    input.click();
    input.onchange = async () => {

      input?.files && Array.from(input?.files).forEach((item) => {
        const formData = new FormData();
        formData.append('file', item);
        // 上传图片
        util.httpUpload(`/sinzetech-resource/oss/endpoint?contentType=${item.type}&rename=${item.name}`, formData, {headers:{
            "Content-Type": "multipart/form-data"
        }})
        .then((res:any) => {
          if(res?.code === 200){
            let quill = reactQuillRef?.current?.getEditor(); //获取到编辑器本身
            const cursorPosition = quill.getSelection().index; //获取当前光标位置
            const link = res?.data?.downloadLink;
            quill.insertEmbed(cursorPosition, 'image', link); //插入图片
            quill.setSelection(cursorPosition + 1); //光标位置加1
          }
        })
      });
    };
  };

  // 对外暴露
  useImperativeHandle(refInstance, () => ({
    focus: (param = {}) => {
      reactQuillRef?.current?.focus?.();
    },
    getEditorContents: (param = {}) => {
      return reactQuillRef?.current?.getEditorContents?.();
    },
    setEditorContents: (data: any) => {
      reactQuillRef?.current?.setEditorContents?.(
        reactQuillRef?.current?.getEditor(),
        data,
      );
    },
    moveMouseEnd: () => {
      // 将光标移动到最后
      let editor = reactQuillRef?.current?.getEditor();
      editor.setSelection(9999, 0);
    },
  }));

  // 粘贴图片
  // const customImgForPaste = async (node: any, delta: any) => {
  //   delta.forEach((op: any) => {
  //     let file = op?.insert?.image
  //     console.log(node, delta, '============')
  //     const formData = new FormData();
  //     formData.append('file', file);
  //     util.httpUpload(`/sinzetech-resource/oss/endpoint?contentType=${file.type}&rename=${file.name}`, formData, {headers:{
  //       "Content-Type": "multipart/form-data"
  //   }})
  //   .then((res:any) => {
  //     if(res?.code === 200){
  //       console.log(res,'item-res')
  //       let quill = reactQuillRef?.current?.getEditor(); //获取到编辑器本身
  //       const cursorPosition = quill.getSelection().index; //获取当前光标位置
  //       const link = res?.data?.downloadLink;
  //       quill.insertEmbed(cursorPosition, 'image', link); //插入图片
  //       quill.setSelection(cursorPosition + 1); //光标位置加1
  //     }
  //   })
  //   });
  // };

  const toolbar = React.useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
          ],
          ['link', 'image'],
          [{ color: [] }, { background: [] }, { align: [] }],
          ['clean'],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      clipboard: {
        matchers: [
          // 临时处理方式：需要setEditorContents的地方先将readOnly=true再使用，或者先去掉粘贴功能比如表单编辑的时候
          // closeClipboardImg ? [] : ['img', customImgForPaste],
        ],
      },
      // imageDrop: true,
    }),
    [],
  );

  const defaultProps = {
    theme: 'snow',
    modules: toolbar,
    formats,
    placeholder: '请输入',
    // className: styles.default_style,
  };

  const readOnlyProps = readOnly
    ? {
        readOnly: true,
        modules: {},
        formats: {},
        theme: '',
      }
    : {};

  return (
    <>
      <ReactQuill
        value={selector}
        ref={reactQuillRef}
        {...defaultProps}
        {...otherProps}
        {...readOnlyProps}
        onChange={onChange}
        placeholder='请输入'
      />
    </>
  );
};


export default forwardRef((props, ref) => 
{
  console.log(props, 'props-quill')
  return <Editor {...props} refInstance={ref} />
}
);

