import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'antd';
import './index.scss';
import BreadCrumbBar from '../../../../../../components/BreadCrumbBar';
import {useNavigate, useLocation  } from 'react-router-dom'
import {getImageUrl, getEvidenceInfo} from '../Promise'
import InfoDetails from './Coms/Infos'
import Evidence from './Coms/Evidence';
const breadsList = [
    {
        label:'首页',
        path:'/'
    },{
        label:'客户管理',
        path:'/evidence'
    },
    {
        label:'存证平台-客户管理',
        path:'/evidence/account/management'
    },
    {
        label:'客户详情',
        path:''
    }
]
const tab_list = [
    {
        label:'存证配置',
        value:'1',
        // Com: Evidence
    },
    {
        label:'设备管理',
        value:'2',
        // Com: null
    }
]
const Add= () => {
    const[logo, setLogo] = useState('')
    const [businessLicense, setBusinessLicense] = useState('')
    const navigate = useNavigate()
    // const search = useSearchParams()
    const loaction = useLocation()
    const [details, setDetails] = useState<any>({})
    const [currentTab] = useState<any>({
        label:'存证配置',
        value:'1',
    })
    const getUrl = (str:any,callback:any) => {
        if(!str) return 
        const obj ={
            zoom:false,
            fileIds: str
          }
        getImageUrl(obj).then((res:any) => {
            console.log(res, 'get_url')
            if(res.success){
                callback && callback(res.data[0])
            }
        })
    }
    useEffect(() => {
        getInfo(loaction?.state?.id)
    }, [loaction]) // eslint-disable-line
    const getInfo = (id:any) => {
        if(!id || id === '') return 
        getEvidenceInfo(id) 
        .then(res => {
            if(res?.code === 200){
                let data={
                    ...res.data
                }
                getUrl(res?.data?.logo,(value:any) => {
                    data.logo = value
                    setLogo(value)
                })
                getUrl(res?.data?.businessLicense, (value:any) => {
                    data.businessLicense = value
                    setBusinessLicense(value)
                })
                setDetails(data)
                // form.setFieldsValue(data)
            }
        })
    }

    return  (
        <div className="evidence-management-content-add"  >
            <div className="evidence-management-countent-add-form">
            <BreadCrumbBar breads={breadsList} />
                <Row className="evidence-management-content-add-form-title">
                    <Col span={24}>
                        <p>客户基本信息</p>
                    </Col>
                </Row>
                <div style={{display:'flex', justifyContent:'space-around'}}>
                    <p>客户基本信息</p>
                    <Button type='primary' onClick={() => {
                        navigate('/evidence/account/management/add', {
                            state:{
                                id: details?.id
                            }
                        })
                    }}>编辑</Button>
                </div>
                <InfoDetails details={details} logo={logo} businessLicense={businessLicense}></InfoDetails>
                <div className='tab_container'>
                    {
                        tab_list.map((item:any) => {
                            return <div key={item.value} className={`tab_item ${currentTab.value=== item.value ? 'current_tab' : ''}`}>{item.label}</div>
                        })
                    }
                    
                </div>
                <div style={{display:'flex', justifyContent:'flex-end', margin:'10px 0'}}>
                    <Button type='primary' onClick={() => {
                      navigate(`/evidence/account/management/info/add_evidence`, {
                        state:{
                            customerId: details?.id,
                            name:details?.name
                        }
                      })  
                    }}>+新增配置</Button>
                </div>
                <Evidence customerId={details?.id}></Evidence> 
            </div>
            </div>
        )

}
export default Add;