import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import TableCom from '../../../../../components/TableCom'
import BreadCrumbBar from "../../../../../components/BreadCrumbBar";
import './index.scss'
import { Button, Col, Form, Input, Row, Select, message, DatePicker } from "antd";
import { addCustomer, getCustomerInfo } from "../../../promise";
import Util from '../../../../../services/util';
import dayjs from "dayjs";
const util = Util.getInstance();
const { TextArea } = Input;

const AddCustomer = () => {
    const [form] = Form.useForm()
    const params = useParams<{ id: string }>()
    const nav = useNavigate()
    let [userList, setUserList] = useState<any[]>([])
    let [userGroupList, setUserGroupList] = useState<any[]>([])
    useEffect(() => {
        getUserList()
        getUserGropupList()
        form.setFieldsValue({
            customerSource: 'SELF_EXCAVATION',//自行发掘
            companyType: 'CONSERVATION_PLANT',//默认利废工厂
            trade: 'RENEWABLE_RESOURCES',//默认再生资源
            importance: '3',
            purchaseCategory: 'STEEL_SCRAP',//默认废钢
        })
    }, [])
    const getUserGropupList = () => {
        util.httpGet(`/sinzetech-admin-user/user-groups`, {
            current: 1,
            size: 10000,
        }).then((res) => {
            setUserGroupList(res.data?.records)
            let resulet = res.data?.records
            resulet.forEach((item: any) => {
                if (item.name == '高管组') {
                    form.setFieldValue('showGroups', [item.id])
                    form.setFieldValue('notifyGroups', [item.id])
                }
            });
        })
    }
    const getUserList = () => {
        util.httpGet(`/sinzetech-admin-user/users`, {
            current: 1,
            size: 10000,
            status: 1,
        }).then((res) => {
            setUserList(res.data?.records)
        })
    }
    const onFinish = () => { }
    const onFinishFailed = () => { }
    const submit = async () => {
        await form.validateFields()
        let values = form.getFieldsValue()
        addCustomer({
            ...values,
            contactDto: {
                name: values.name,
                phone: values.phone,
                email: values.email,
                dept: values.dept,
                position: values.position,
            },
            firstContactTime:
                values.firstContactTime &&
                dayjs(values.firstContactTime).format('YYYY-MM-DD'),
        }).then((res) => {
            message.success("操作成功");
            nav(-1);
        });
    }
    return (
        <div className="container add_customer">
            <div className="content">
                <Form
                    form={form}
                    name="basic"
                    // labelCol={{ span: 8 }}
                    // wrapperCol={{ span: 16 }}
                    // style={{ maxWidth: 560 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <div className="block_item">
                        <h3>基础信息</h3>
                        <Row>
                            <Col span={8}>
                                <Form.Item
                                    label="客户名称"
                                    name="companyName"

                                    rules={[{ required: true, message: '请输入公司名称' }]}
                                >
                                    <Input placeholder="请输入" maxLength={20} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="来源"
                                    name="customerSource"

                                    rules={[{ required: true, message: '请选择来源' }]}
                                >
                                    <Select placeholder='请选择'>
                                        <Select.Option value='SELF_EXCAVATION'>自行发掘</Select.Option>
                                        <Select.Option value='CUSTOMER_INTRODUCTION'>客户介绍</Select.Option>
                                        <Select.Option value='NETWORK_PROMOTION'>网络推广</Select.Option>
                                        <Select.Option value='SOCIAL_ACTIVITIES'>社会活动</Select.Option>
                                        <Select.Option value='CUSTOMER_PROACTIVELY_CONTACTED'>客户主动联系</Select.Option>
                                        <Select.Option value='OTHER'>其他</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="客户类型"
                                    name="companyType"

                                    rules={[{ required: true, message: '请选择公司类型' }]}
                                >
                                    <Select placeholder='请选择'>
                                        <Select.Option value='CONSERVATION_PLANT'>利废工厂</Select.Option>
                                        <Select.Option value='TRADING_COMPANY'>贸易公司</Select.Option>
                                        <Select.Option value='FREIGHT_YARD_CATTLE'>货场黄牛</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="行业"
                                    name="trade"

                                    rules={[{ required: true, message: '请选择行业' }]}
                                >
                                    <Select placeholder='请选择'>
                                        <Select.Option value='RENEWABLE_RESOURCES'>再生资源</Select.Option>
                                        <Select.Option value='MINERAL'>矿石</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="采购品类"
                                    name="purchaseCategory"

                                    rules={[{ required: true, message: '请选择采购品类' }]}
                                >
                                    <Select placeholder='请选择'>
                                        <Select.Option value='STEEL_SCRAP'>废钢</Select.Option>
                                        <Select.Option value='BROKE'>废纸</Select.Option>
                                        <Select.Option value='WASTE_PLASTICS'>废塑料</Select.Option>
                                        <Select.Option value='WASTE_COLOR'>废有色</Select.Option>
                                        <Select.Option value='ORE'>矿石</Select.Option>
                                        <Select.Option value='OTHER'>其他</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8} style={{ display: 'flex' }}>
                                <Form.Item
                                    label="年采购量"
                                    name="yearPurchase"
                                    style={{ flex: 1 }}
                                // getValueFromEvent={(ev) => {
                                //     return ev.target.value.replace(/^\D*(\d*(?:\.\d{0,6})?).*$/g, '$1')
                                // }}
                                >
                                    <Input placeholder="请输入" maxLength={50} />
                                </Form.Item>
                                {/* <Form.Item
                                    name="yearPurchaseUnit"
                                >
                                    <Select placeholder='请选择' style={{ width: 80 }}>
                                        <Select.Option value='T'>吨</Select.Option>
                                        <Select.Option value='KG'>千克</Select.Option>
                                        <Select.Option value='G'>克</Select.Option>
                                        <Select.Option value='PIECE'>个</Select.Option>
                                        <Select.Option value='TOWER'>台</Select.Option>
                                    </Select>
                                </Form.Item> */}
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="初次接触日期"
                                    name="firstContactTime"
                                >
                                    <DatePicker placeholder='请选择日期' style={{ width: '100%' }}></DatePicker>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="基本情况"
                                    name="mainAppeal"

                                >
                                    <TextArea placeholder="请输入" maxLength={200} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="经营地址"
                                    name="manageAddress"

                                >
                                    <Input placeholder="请输入" maxLength={40} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <div className="block_item">
                        <h3>联系人信息</h3>
                        <Row>
                            <Col span={8}>
                                <Form.Item
                                    label="姓名"
                                    name="name"

                                    // rules={[{ required: true, message: '请输入姓名' }]}
                                >
                                    <Input placeholder="请输入" maxLength={10} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="手机号"
                                    name="phone"
                                    // rules={[{ required: true, message: '请输入手机号' },]}
                                    getValueFromEvent={(ev) => {
                                        return ev.target.value.replace(/[^0-9]/ig, "")
                                    }}
                                >
                                    <Input placeholder="请输入" maxLength={11} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="邮箱"
                                    name="email"

                                >
                                    <Input placeholder="请输入" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="部门"
                                    name="dept"

                                >
                                    <Input placeholder="请输入" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="职位"
                                    name="position"

                                >
                                    <Input placeholder="请输入" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <div className="block_item">
                        <h3>客户设置：<span>注：选中的业务负责人或业务员可查看和编辑此客户信息</span></h3>
                        <Row>
                            <Col span={8}>
                                <Form.Item
                                    label="重要程度"
                                    name="importance"

                                    rules={[{ required: true, message: '重要程度' }]}
                                >
                                    <Select placeholder='请选择'>
                                        <Select.Option value='1'>极高</Select.Option>
                                        <Select.Option value='2'>高</Select.Option>
                                        <Select.Option value='3'>中</Select.Option>
                                        <Select.Option value='4'>低</Select.Option>
                                        <Select.Option value='5'>极低</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <Form.Item
                                    label="业务负责人"
                                    name="businessOwner"

                                    rules={[{ required: true, message: '请选择业务负责人' }]}
                                >
                                    <Select placeholder='请选择'>
                                        {
                                            userList.map((item, index) => {
                                                return <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <Form.Item
                                    label="业务员"
                                    name="businessSalesmanList"
                                >
                                    <Select placeholder='请选择' mode='multiple'>
                                        {
                                            userList.map((item, index) => {
                                                return <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <div className="block_item">
                        <h3 style={{borderBottom:'1px solid #ccc'}}>权限设置</h3>
                        <h3>展示范围：<span>注：选中的组或个人可查看此客户信息</span></h3>
                        <Row>
                            <Col span={16}>
                                <Form.Item
                                    label="展示用户组"
                                    name="showGroups"
                                >
                                    <Select placeholder='请选择' mode='multiple'>
                                        {
                                            userGroupList.map((item, index) => {
                                                return (
                                                    <Select.Option
                                                        value={item.id}
                                                        key={index}
                                                        disabled={item.name == '高管组'}
                                                    >{item.name}</Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <Form.Item
                                    label="展示个人"
                                    name="showUsers"
                                >
                                    <Select placeholder='请选择' mode='multiple'>
                                        {
                                            userList.map((item, index) => {
                                                return <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <div className="block_item">
                        <h3>通知范围：<span>注：选中的组或个人可接收当前客户信息</span></h3>
                        <Row>
                            <Col span={16}>
                                <Form.Item
                                    label="通知用户组"
                                    name="notifyGroups"
                                >
                                    <Select placeholder='请选择' mode='multiple'>
                                        {
                                            userGroupList.map((item, index) => {
                                                return (
                                                    <Select.Option
                                                        value={item.id}
                                                        key={index}
                                                        disabled={item.name == '高管组'}
                                                    >{item.name}</Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <Form.Item
                                    label="通知个人"
                                    name="notifyUsers"
                                >
                                    <Select placeholder='请选择' mode='multiple'>
                                        {
                                            userList.map((item, index) => {
                                                return <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </Form>
                <div className="btn_box" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        onClick={() => {
                            nav(-1)
                        }}
                    >返回列表</Button>
                    <Button
                        type='primary'
                        style={{ marginLeft: 10 }}
                        onClick={() => {
                            submit()
                        }}
                    >保存</Button>
                </div>
            </div>
        </div>
    )

}

export default AddCustomer
