export const RexChineseMobile = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
export const RexChinesePhone = /^1[3|4|5|6|7|8|9][0-9]{9}$|^0\d{2,3}-?\d{7,8}$/;
export const PlateNumberReg = /^(([\u4e00-\u9fa5]([a-zA-Z]|\d{2})|[\u4e00-\u9fa5]{2}\d{2}|[\u4e00-\u9fa5]{2}[a-zA-Z])[-]?|([wW][Jj][\u4e00-\u9fa5]{1}[-]?)|([a-zA-Z]{2}))([A-Za-z0-9]{5}|[DdFf][A-HJ-NP-Za-hj-np-z0-9][0-9]{4}|[0-9]{5}[DdFf])$/;
//银行卡号校验
export const RexBankCard = /^[1-9]\d{9,29}$/;
// 身份证校验
export const RexIdNo = /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/;
// 中文姓名校验
export const RexChineseName = /^(?:[\u4e00-\u9fa5·]{2,25})$/;
// 英文文姓名校验
export const RexEnglisheName = /(^[a-zA-Z][a-zA-Z\s]{0,20}[a-zA-Z]$)/;
// 金额的校验
export const RexMoney = /^\d+(,\d{3})*(\.\d{1,2})?$/;
// 数字的校验 小数点后最多四位, 可以正负
export const RexMinusNumber = /^[+-]?\d+(?:\.\d{1,4})?$/;
// 手机号校验
export const RexPhone = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
// 邮政编码格式
export const RexPostCode = /^(0[1-7]|1[0-356]|2[0-7]|3[0-6]|4[0-7]|5[1-7]|6[1-7]|7[0-5]|8[013-6])\d{4}$/;
// 保留两位小数
export const RexTwoDigit = /^([1-9]+[\d]*(.[0-9]{1,2})?)$/
// 数字和字母组成
export const RexALPHANUM = /^[A-Za-z0-9]+$/;
// 电子邮箱
export const RexALEmail = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
// 汉字
export const RexChinese = /^[\u4e00-\u9fa5]+$/;
// 统一社会信用代码
export const RexSocialCreditCode = /^(([0-9A-Za-z]{15})|([0-9A-Za-z]{18})|([0-9A-Za-z]{20}))$/;
// 限制数字精度
export const getPrecisionLimitRex = (precision: number) => {
  return new RegExp('^\\d+(.\\d{0,' + precision + '})?$');
};
// 限制整数位数
export const getDigitCapacityLimitRex = (capacity: number) => {
  return new RegExp('^\\d{1,' + capacity + '}(\\.\\d*)?$');
};
// 同时限制位数与精度
export const getDigitLimitRex = (capacity: number, precision: number) => {
  return new RegExp('^\\d{1,' + capacity + '}(\\.\\d{0,' + precision + '})?$');
};
// 大于等于0的数字且限制小数位数
export const getDigitZeroCapacityLimitRex = (precision: number) => {
  return new RegExp('^\\d+(?:\\.\\d{1,' + precision + '})?$');
};
// 新能源+非新能源
export const RexCarNumber = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-HJ-NP-Z][A-HJ-NP-Z0-9]{4,5}[A-HJ-NP-Z0-9挂学警港澳]$/
//  是否为 YYYY-MM-DD hh:mm 格式
export const RexPatternYMDHM = /^(\d{4})-(\d{2})-(\d{2})\s(\d{2}):(\d{2})$/;