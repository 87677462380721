export const stepList = [
    {
        title: "注销协议签署",
    },
    {
        title: "税务清税",
    },
    {
        title: "执照注销",
    },
    {
        title: "注销完成",
    },
];

// 存储当前按钮状态以及对应的文本样式
export const deregisterStatusOject = Object.freeze({
    WAIT_SIGN: {
        label: '申请书待签署',
        color: 'warning',
        current: 0,
    },
    WAIT_CLEAR_TAX: {
        label: '待清税',
        color: 'warning',
        current: 1,
    },
    WAIT_DEREGISTER_LICENSE: {
        label: '执照待注销',
        color: 'warning',
        current: 2,
    },
    DEREGISTERED: {
        label: '已注销',
        color: 'warning',
        current: 3,
    }
})