import { axiosRequrest, proxyAxios } from "@src/services/axiosRequest"
import { APIPrefix } from "./constant"
import Util from "@src/services/util"

const util = Util.getInstance()

export const getConfiguration = (id: string) => {
  return axiosRequrest.get<API.Recover.Configuration>(APIPrefix + `${id}/configuration`)
}

export const getRecoverList = async (params: any) => {
  return proxyAxios.get<{ list: API.Recover.Item[], total: number }>(`${APIPrefix}`, { params })
}
export const getRecoverDetail = async (params: any) => {
  return proxyAxios.get<API.Recover.Item>(`${APIPrefix}/${params.id}`)
}
export const getMembersByGroupId = async (params: any) => {
  return proxyAxios.get<Common.Paging02<API.Member.Item>>(`${APIPrefix}/${params.id}/member`, { params })
}
export const getGoodsByGroupId = async (params: any) => {
  return proxyAxios.get<Common.Paging02<API.Goods.Item>>(`${APIPrefix}/${params.id}/goods`, { params })
}
export const getCustomersByGroupId = async (params: any) => {
  return proxyAxios.get<Common.Paging02<API.Customer.Item>>(`${APIPrefix}/${params.id}/customer`, { params })
}
export const getPricesByGroupId = async (params: any) => {
  return proxyAxios.get<Common.Paging02<API.Price.Item>>(`${APIPrefix}/${params.id}/price`, { params })
}
export const getMerchantsByGroupId = async (params: any) => {
  return proxyAxios.get<Common.Paging02<API.Merchant.Item>>(`${APIPrefix}/${params.id}/serviceProvider`, { params })
}

export const updateRecoverGroup = async (params: any) => {
  return proxyAxios.put(`${APIPrefix}`, params)
}



// 小组详情-配置详情
export const getConfigurationInfoService = (params: { id: any }) => {
  return proxyAxios.get(`${APIPrefix}/${params.id}/configuration`)
}

// 小组详情-配置-税区保存
export const postTaxArea = (params: { id: any; registeredTaxAreaId: any }) => {
  const { id, ...rest } = params
  return proxyAxios.post(`${APIPrefix}/${params.id}/tax-area`, { ...rest })
}

// 小组详情-配置-开票配置保存
export const postInvoiceConfiguration = (params: any) => {
  const { id, ...rest } = params
  return proxyAxios.post(`${APIPrefix}/${id}/invoice-configuration`, { ...rest })
}

// 小组详情-配置-开票配置移除
export const deleteInvoiceConfiguration = (params: { groupId: any; id: any }) => {
  return proxyAxios.delete(`${APIPrefix}/${params.groupId}/invoice-configuration/${params.id}`)
}
// 获取所有税区
export const getTaxList = (values?: any) => {
  return util.httpGet('/sinzetech-tax/tax-region', values)
    .then(res => res)
}