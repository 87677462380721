import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Input, message, Button, Select, Table } from "antd";
import Util from "../../../../../../services/util";
const util = Util.getInstance();
import "./index.scss";
import BreadCrumbBar from "../../../../../../components/BreadCrumbBar";

const breads = [
    {
        label: "组织管理",
        path: "/system",
    },
    {
        label: "客户管理",
        path: "/system/user_group",
    },
    {
        label: "新增",
    },
];
const AddUserGroup = () => {
    let params = useParams<{ id: string }>();
    let nav = useNavigate();
    let [userGroupName, setUserGroupName] = useState<string>("");
    let [selectIds, setSelectIds] = useState<string[]>([]);
    let [selectRows, setSelectRows] = useState<any[]>([]);
    let [userList, setUserList] = useState<any[]>([]);
    let [isEdit, setIsEdit] = useState<boolean>(false);
    let columns: any[] = [
        {
            title: "成员姓名",
            dataIndex: "name",
        },
        {
            title: "电话",
            dataIndex: "account",
        },
    ];
    useEffect(() => {
        getUserList();
        if (params.id != "add") {
            getUserGroupInfo();
        }
    }, []);
    const getUserGroupInfo = () => {
        util.httpGet(`/sinzetech-admin-user/user-groups/${params.id}`).then(
            (res) => {
                if (res.code == 200) {
                    setUserGroupName(res.data.name);
                    setSelectRows(res.data.members);
                    setSelectIds(res.data.members.map((item: any) => item.id));
                } else {
                    message.error(res.message);
                }
            }
        );
    };
    const getUserList = () => {
        util.httpGet(`/sinzetech-admin-user/users`, {
            current: 1,
            size: 10000,
            status: 1,
        }).then((res) => {
            setUserList(res.data?.records);
        });
    };
    const submit = () => {
        if (!userGroupName) {
            return message.error("请输入用户组名称");
        }
        if (!selectIds.length) {
            return message.error("请选择成员");
        }
        util.httpPost(`/sinzetech-admin-user/user-groups`, {
            id: params.id == "add" ? null : params.id,
            name: userGroupName,
            members: selectIds,
        }).then((res: any) => {
            if (res.code == 200 || res.code == 0) {
                message.success("操作成功");
                nav(-1);
            } else {
                message.error(res.message);
            }
        });
        // if (params.id == 'add') {

        // } else {
        //     util.httpPutUrl(`/sinzetech-admin-user/user-groups`, {
        //         id: params.id,
        //         name: userGroupName,
        //         members: selectIds,
        //     }).then(() => {
        //         message.success('操作成功')
        //         nav(-1)
        //     })
        // }
    };
    return (
        <div className="container addUserGroup">
            <div className="content">
                <BreadCrumbBar breads={breads}></BreadCrumbBar>
                <div className="title">
                    <div className="name">
                        {params.id == "add" ? "新增用户组" : "用户组管理"}
                    </div>
                    {params.id !== "add" && (
                        <Button
                            type="primary"
                            style={{ marginTop: 0 }}
                            onClick={() => {
                                if (isEdit) {
                                    getUserGroupInfo();
                                }
                                setIsEdit(!isEdit);
                            }}
                        >
                            {isEdit ? "取消" : "编辑"}
                        </Button>
                    )}
                </div>
                <div className="input_item">
                    <span className="tip">*用户组名称：</span>
                    {params.id == "add" || (params.id !== "add" && isEdit) ? (
                        <Input
                            style={{ width: 300 }}
                            value={userGroupName}
                            maxLength={20}
                            onChange={(ev) => {
                                setUserGroupName(ev.target.value.trim());
                            }}
                        />
                    ) : (
                        userGroupName
                    )}
                </div>
                {params.id == "add" || (params.id !== "add" && isEdit) ? (
                    <div className="input_item">
                        <span className="tip">*成员：</span>
                        <Select
                            style={{ width: 300 }}
                            mode="multiple"
                            value={selectIds}
                            onChange={(value: string[], option) => {
                                setSelectIds(value);
                                setSelectRows(
                                    option.map((item: any) => item.item) || []
                                );
                                console.log(value, option, "454654313213");
                            }}
                        >
                            {userList.map((item, index) => {
                                return (
                                    <Select.Option
                                        value={item.id}
                                        key={index}
                                        item={item}
                                    >
                                        {item.name}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </div>
                ) : null}
                <Table
                    columns={columns}
                    dataSource={selectRows}
                    pagination={false}
                />
                {(params.id == "add" || (params.id !== "add" && isEdit)) && (
                    <div className="foot_btn_box">
                        <Button
                            className="btn"
                            onClick={() => {
                                nav(-1);
                            }}
                        >
                            取消
                        </Button>
                        <Button
                            className="btn"
                            type="primary"
                            onClick={() => {
                                submit();
                            }}
                        >
                            保存
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AddUserGroup;
