import { Button, Space, message } from "antd";
import axios from "axios";
import "./index.scss";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { throttle } from "lodash";
import ModalCom from "../../../../../../../../components/ModalCom";
import { useState } from "react";
/**
 * 提交
 * @param props
 * @returns
 */
function Control(props: any) {
  const { startStr, endStr, enableNuclearName } = props;
  const url = "/sinzetech-agency-processing/license";
  const navigate = useNavigate();
  const searchParams = useParams();
  const [open, setOpen] = useState(false);
  const { form } = props;
  const handleSubmit = async () => {
    const applyType = form.getFieldValue("applyType") || "INDIVIDUAL";
    const cal = form.validateFields().then((res: any) => {
      console.log(form.getFieldValue("pictureProof"));
      res.pictureProof = res.pictureProof.map((i: any) => {
        return {
          url: i.url,
          name: i.name,
          description: i.description,
          proofPhotoId: i.fileId,
        };
      });
      const enterpriseNameList = [startStr + res.registrationName + endStr];
      if (res.firstAlternativeName) {
        enterpriseNameList.push(startStr + res.firstAlternativeName + endStr);
      }
      if (res.secondAlternativeName) {
        enterpriseNameList.push(startStr + res.secondAlternativeName + endStr);
      }
      const cityCode = res.cityCode;
      let param = {
        ...res,
        provinceCode: cityCode[0],
        cityCode: cityCode[1],
        applyType,
        createUserName: JSON.parse(
          localStorage.getItem("operation_authorization") || ""
        ).userName,
        createUserId: JSON.parse(
          localStorage.getItem("operation_authorization") || ""
        ).userId,
        applyUserName: JSON.parse(
          localStorage.getItem("operation_authorization") || ""
        ).userName,
        applyUserId: JSON.parse(
          localStorage.getItem("operation_authorization") || ""
        ).userId,
        source: "YY",
        applyUserAccount: JSON.parse(
          localStorage.getItem("operation_authorization") || ""
        )?.phone,
        clerkUserId: JSON.parse(
          localStorage.getItem("operation_authorization") || ""
        )?.userId,
        clerkUserName: JSON.parse(
          localStorage.getItem("operation_authorization") || ""
        )?.userName,
      };
      if (searchParams.id) {
        param.id = searchParams.id;
      }
      if (enableNuclearName === 1 || res.nuclearName === 1) {
        param.enterpriseNameList = enterpriseNameList;
      }
      axios.post(url, param).then((end) => {
        if (end.data.code === 200) {
          message.info(end.data.message);
          navigate(-1);
        }
      });
    });
  };
  return (
    <div className="form_control">
      <ModalCom
        open={open}
        title="确认保存"
        onCancel={() => {
          setOpen(false);
        }}
        onOk={throttle(handleSubmit, 500)}
        okText="确认保存"
        cancelText="取消"
        width={550}
      >
        <p>
          <label>个体户姓名:</label>
          {form.getFieldValue("legal")}
        </p>
        <p>手机号(账号):{form.getFieldValue("legalPhone")}</p>
        <Button type="link" style={{ paddingLeft: 0 }}>
          系统自动发送短信通知个体户，个体户在短信中签署协议后，启动营业执照办理
        </Button>
      </ModalCom>
      <div>
        <Space>
          <Button
            onClick={() => {
              navigate(-1);
            }}
          >
            关闭
          </Button>
        </Space>
        <Button
          style={{ marginLeft: "20px" }}
          type="primary"
          onClick={() => setOpen(true)}
        >
          保存
        </Button>
      </div>
    </div>
  );
}

export default Control;
