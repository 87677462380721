import { TagCustomStyleProps } from '@/components/TagCustomStyle';


export const OrderPaymentStatusEnum:Record<API.Order.PaymentStatus,TagCustomStyleProps> = {
  'COMPLETED': {
    status: 'success',
    text:'已付款'
  },
  'PARTIAL_PAID':  {
    status: 'warning',
    text:'部分付款'
  },
  'PENDING': {
    status: 'error',
    text:'未完成'
  },
  'CLOSE': {
    status: 'default',
    text:'已关闭'
  }
}

export const ContractStatusEnum:Record<Exclude<API.SignStatus,'REVOKED'>,TagCustomStyleProps> = {
  'ALL_COMPLETED': {
    status: 'success',
    text:'已签署'
  },
  'BUYER_COMPLETED':  {
    status: 'warning',
    text:'卖方未签署'
  },
  'SELLER_COMPLETED': {
    status: 'warning',
    text:'买方未签署'
  },
  'PENDING': {
    status: 'error',
    text:'未签署'
  }
}

export const SupplyContractEffectiveEnum:Record<0|1,TagCustomStyleProps> = {
  0: {
    status: 'error',
    text:'已失效'
  },
  1: {
    status: 'success',
    text:'生效中'
  }
}

export const SupplyContractStatusEnum:Record<Exclude<API.SupplyContractStatus,'SELLER_NO_SIGN'|'BUYER_NO_SIGN'>,TagCustomStyleProps> = {
  'SIGNED':{
    status: 'success',
    text:'已签署'
  },
  'NO_SIGN':{
    status: 'error',
    text:'未签署'
  }
}