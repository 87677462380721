import BreadCrumbBar from "../../../../../../components/BreadCrumbBar";
import { breads } from "./content";
import BaseInfo from "./components/BaseInfo";
import { useEffect, useState, useRef } from "react";
import Control from "./components/Control";
import Identity from "./components/Identity";
import Bank from "./components/Bank";
import Address from "./components/Address";
import { ApplyType } from "../newAdd/types/index";
import {
  getTaxList,
  addAdministration,
  getAddministrstionInfo,
  getTaxInfo,
} from "../../../promise";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import "./index.scss";
import {
  Form,
  Input,
  Button,
  message,
  Select,
  Radio,
  Row,
  Col,
  Modal,
} from "antd";
import axios from "axios";
import { useRequest } from "ahooks";

/**
 * 新增个体户
 * @returns
 */
function NewAdd() {
  const [form] = Form.useForm();
  const searchParams = useParams();
  const [enableNuclearName, setEnableNuclearName] = useState(-1); // 是否需要核名配置

  const [startStr, setStartStr] = useState("");
  const [endStr, setEndStr] = useState("");
  const [applyType, setApplyType] = useState<ApplyType>("INDIVIDUAL"); // 代办
  const [nuclearName, setNuclearName] = useState(1); // 代办
  const [pics, setPics] = useState([]);
  const cRef: any = useRef();
  const taxRegionsAPI = "/sinzetech-tax/tax-region";
  /**
   * 获取是否显示核名和不核名的id
   */
  const getId = async (id: string | number, enterpriseNameList: string[]) => {
    try {
      const res = await axios.get("/sinzetech-tax/tax-region/" + id);
      if (res.data.code === 200) {
        form.setFieldValue(
          "enableNuclearName",
          res.data.data.enableNuclearName
        );
        setEnableNuclearName(res.data.data.enableNuclearName);
        const val =
          res?.data?.data?.businessNamePrefix || res.data?.data.taxName;
        const startLen = val.length;
        const end = res.data.data.businessNameSuffix;
        if (enterpriseNameList?.length) {
          let newArr = JSON.parse(JSON.stringify(enterpriseNameList));
          const data = newArr.map((item: any) => {
            let lastIndex = item.lastIndexOf(end);
            item = item.substring(startLen, lastIndex);
            return item;
          });
          // if (enableNuclearName === 1) {
          if (enterpriseNameList[0]) {
            form.setFieldValue("registrationName", data[0]);
          }
          if (enterpriseNameList[1]) {
            form.setFieldValue("firstAlternativeName", data[1]);
          }
          if (enterpriseNameList[2]) {
            form.setFieldValue("secondAlternativeName", data[2]);
          }
          // }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  const taxRegionService = useRequest(
    (id: string) =>
      axios
        .get<Common.ResponseData<API.TaxRegion.Detail>>(
          taxRegionsAPI + `/${id}`
        )
        .then((res: any) => {
          if (res.data.data?.uploadPhotoProof) {
            form.setFieldValue("pictureProof", res.data.data.uploadPhotoProof);
            setPics(res.data.data.uploadPhotoProof);
          } else {
            form.setFieldValue("pictureProof", []);
            setPics([]);
          }
          setEnableNuclearName(res.data.data.enableNuclearName);

          form.setFieldsValue({
            nuclearName: 1,
          });
          setNuclearName(1);
          if (res.data.data?.businessNamePrefix) {
            setStartStr(res.data.data?.businessNamePrefix);
          }
          if (res.data.data?.businessNameSuffix) {
            setEndStr(res.data.data?.businessNameSuffix);
          }
          return res.data.data;
        }),
    {
      manual: true,
      // onSuccess(response){
      //   form.setFieldsValue({businessScope:response.businessScope})
      // }
    }
  );
  /**
   * 获取税区详情
   * @param id
   */
  const getDetail = async (id: number | string) => {
    const res = await axios.get("/sinzetech-agency-processing/license/" + id);
    if (res.data.code === 200) {
      form.setFieldsValue(res.data.data);
      if (res.data.data?.bankCode) {
        form.setFieldValue("zsBankCode", res.data.data.bankCode);
      }

      if (res.data.data?.nuclearName) {
        setNuclearName(res.data.data?.nuclearName);
        form.setFieldValue("nuclearName", res.data.data?.nuclearName);
      }
      if (res.data.data?.taxRegionId) {
        taxRegionService.run(res.data.data.taxRegionId);
        getId(res.data.data.taxRegionId, res.data.data.enterpriseNameList);
      }
      if (res.data.data?.cityCode && res.data.data?.provinceCode) {
        const { provinceCode, cityCode } = res.data.data;
        form.setFieldValue("cityCode", [provinceCode, cityCode]);
      }
      if (res.data.data?.headUrl) {
        form.setFieldValue("headFileId", res.data.data.headUrl);
      }
      if (res.data.data?.taxRegionRequirement?.length) {
        const arr = res.data.data?.taxRegionRequirement.map(
          (item: any, index: number) => {
            form.setFieldValue("pictureProof" + index, item.photoUrl);
            return {
              url: item.photoId,
              name: item.name,
              description: item.description,
              proofPhotoId: item.proofPhotoId,
              fileId: item.proofPhotoId,
              downloadLink: item.photoUrl,
            };
          }
        );
        setTimeout(() => {
          form.setFieldValue("pictureProof", arr);
          setPics(arr);
        }, 1500);
      }
    }
  };

  useEffect(() => {
    if (searchParams.id) {
      getDetail(searchParams.id);
    }
  }, [searchParams.id]);
  return (
    <div className="container new_add">
      <BreadCrumbBar breads={breads}></BreadCrumbBar>
      <Form.Item hidden name="idCardUrlName"></Form.Item>
      <Form.Item hidden name="idCardBackUrlName"></Form.Item>
      <div className="content no_border">
        <Form form={form}>
          <BaseInfo applyType={applyType} setApplyType={setApplyType} />
          <Identity
            form={form}
            idCardFrontUrl={form.getFieldValue("idCardUrlName")}
            idCardBackUrl={form.getFieldValue("idCardBackUrlName")}
          ></Identity>
          <Bank form={form} />
          <Address
            taxRegionService={taxRegionService}
            enableNuclearName={enableNuclearName}
            form={form}
            nuclearName={nuclearName}
            setNuclearName={setNuclearName}
            setStartStr={setStartStr}
            setEnableNuclearName={setEnableNuclearName}
            setEndStr={setEndStr}
            pics={pics}
            setPics={setPics}
          />
          {/* <Photos form={form} /> */}
          <Control
            enableNuclearName={enableNuclearName}
            form={form}
            startStr={startStr}
            endStr={endStr}
          />
        </Form>
      </div>
    </div>
  );
}

export default NewAdd;
