const current_env = process.env.REACT_APP_ENV || "production";
const cross_env = {
  development: "https://dev.api.saikul.com/pub-data",
  test: "https://test.api.saikul.com/pub-data",
  production: "https://api.saikul.com/pub-data",
}[current_env];
export const Basics_URL = cross_env || "https://api.saikul.com/pub-data";
// 税收分类简称列表
export const getInvoiceCategory = (Basics_URL + "/invoice-configuration/invoice-category/short");
// 开票配置列表
export const getInvoiceConfiguration = (Basics_URL + "/invoice-configuration");
// 开票配置删除
export const deleteInvoiceCategory = (Basics_URL + `/invoice-configuration/`);
type addInvoiceConfigurationType = {
  invoiceProjectName: string;  // 发票项目名称	
  invoiceCategoryCode: string;  // 税收分类编码
}
// 开票配置新增
export const addInvoiceConfiguration = (Basics_URL + `/invoice-configuration`);

