import { useRef, ChangeEventHandler, useState, useCallback } from 'react';
import { InputProps } from 'antd';

export const useCompositionInput = (
  onChange: ChangeEventHandler<HTMLInputElement>,
  initialValue: string | number = '',
): Pick<InputProps, 'onChange' | 'onCompositionEnd' | 'onCompositionStart' | 'value'> => {
  const lockRef = useRef(false);
  const [value, setValue] = useState(initialValue);
  const _onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      if (e.type === 'compositionstart') {
        lockRef.current = true;
        return;
      }
      setValue(e.target.value);
      if (e.type === 'compositionend') {
        lockRef.current = false;
        return onChange(e);
      }

      if (lockRef.current) return;
      return onChange(e);
    },
    [setValue, onChange],
  );
  return {
    onCompositionStart: _onChange as any,
    onCompositionEnd: _onChange as any,
    onChange: _onChange,
    value,
  };
};
