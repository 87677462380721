import React from "react"

interface CommonStatusProps {
    color?: string
    text?: any
}

export default ({
    color,
    text,
}: CommonStatusProps) => {
    return (
        <div
            className="common_status"
        >
            <div
                className="mock"
                style={{
                    // backgroundColor: color,
                }}
            >
            </div>
            <div
                className="cont"
            //  style={{ color }}
            >
                <span
                    className="cont_Badge"
                    style={{
                        backgroundColor: color,
                    }}
                ></span>
                <span>{text}</span>
            </div>
        </div>
    )
}