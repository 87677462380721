import TableCom from '@src/components/TableCom'
import { Button, Card, Col, Form, Input, InputNumber, List, message, Modal, Popconfirm, Row, Space } from 'antd'
import "./index.scss";
import React, { memo, useEffect, useState } from 'react'
import { addInvoiceConfiguration, deleteInvoiceCategory, getInvoiceCategory, getInvoiceConfiguration } from '@src/services/api/invoice/invoice';
import axios from 'axios';
import { proxyAxios } from '@src/services/axiosRequest';
import * as XLSX from 'xlsx'
import BreadCrumbBar from '@src/components/BreadCrumbBar';
import { RexNumber } from '@src/constant/RegExp';
const defaultProps = {}
type props = {}
export type InvoiceSettingProps = Required<typeof defaultProps> & props
export const InvoiceSetting: React.FC<InvoiceSettingProps> = memo((props) => {
  const [pageOptions, setPageOptions] = useState({
    pageSize: 20,
    current: 1,
  });
  const [tableList, setTableList] = useState([]);
  const [total, setTotal] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [invoiceCategoryShort, setInvoiceCategoryShort] = useState('全部分类');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [invoiceCategoryList, setInvoiceCategoryList] = useState([])
  const { Search } = Input;
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const breads = [
    {
      label: "首页",
      path: "/",
    },
    {
      label: "开票配置",
      path: "/system/invoiceSetting",
    },
  ];

  const InvoiceCategoryService = async () => {
    const res = await axios.get(getInvoiceCategory);
    const newArr = res.data.data.unshift({ invoiceCategoryShort: '全部分类', count: res.data.data.reduce((sum: any, certificate: { count: any; }) => (sum += certificate.count), 0) })
    getList({
      invoiceCategoryShort: invoiceCategoryShort == '全部分类' ? '' : invoiceCategoryShort,
      keyword: keyword
    })
    setInvoiceCategoryList(res.data.data)
  }
  const getList = async (params?: any) => {
    const data = {
      size: pageOptions.pageSize,
      current: pageOptions.current,
      ...params,
    };
    await axios.get(getInvoiceConfiguration, {
      params: data,
    }).then((res: any) => {
      if (res.data.code === 200) {
        setTotal(res.data.data?.total);
        setTableList(res?.data?.data?.records);
      }
    });
  };
  useEffect(() => {
    InvoiceCategoryService()
  }, [])
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "发票项目名称",
      dataIndex: "invoiceProjectName",
    },
    {
      title: "税收分类编码",
      dataIndex: "invoiceCategoryCode",
    },
    {
      title: "操作",
      render: (_: any, record: any) => (
        <Popconfirm
          title="确定要删除吗？"
          onConfirm={async () => await axios({
            method: 'delete',
            url: deleteInvoiceCategory + record.id,
          }).then((response) => {
            InvoiceCategoryService()
            // getList({
            //   invoiceCategoryShort: invoiceCategoryShort
            // })
            message.success('删除成功')
          })}
        >
          <Button type="link">删除</Button>
        </Popconfirm>
      ),
    },
  ];
  const handlePage = (params?: any) => {
    setPageOptions({
      current: params.current,
      pageSize: params.pageSize,
    });
    setInvoiceCategoryShort('全部分类')
    setKeyword('')
    getList({
      current: params.current,
      size: params.pageSize,
    });
  };
  const onSearch = (value: React.SetStateAction<string>) => {
    setKeyword(value)
    getList({
      size: pageOptions.pageSize,
      current: pageOptions.current,
      keyword: value,
      invoiceCategoryShort: invoiceCategoryShort == '全部分类' ? '' : invoiceCategoryShort
    });
  }

  const onFinish = async (values: any) => {
    await axios.post(addInvoiceConfiguration, {
      ...values,
    }).then((response) => {
      if (response.data.code === 200) {
        handleCancel()
        message.success('新增成功')
        InvoiceCategoryService()
      } else {
        message.error(response.data.message)
      }
    }).catch(async (e) => {
      handleCancel()
      await message.error('新增失败')
    })
  };
  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };
  const checkListItem = (value: any) => {
    setInvoiceCategoryShort(value)
    getList({
      invoiceCategoryShort: value == '全部分类' ? '' : value,
      keyword,
    })
  }
  const columnsExport = [
    {
      label: "id",
      value: "id",
    },
    {
      label: "发票项目名称",
      value: "invoiceProjectName",
    },
    {
      label: "税收分类编码",
      value: "invoiceCategoryCode",
    },
  ];
  const submitExport = () => {
    proxyAxios.get(`/pub-data/invoice-configuration`, {
      params: {
        size: total,
        current: 1,
        invoiceCategoryShort: invoiceCategoryShort == '全部分类' ? '' : invoiceCategoryShort,
        keyword: keyword
      },
    }).then((res: any) => {
      let header: Array<string> = columnsExport?.map(item => item.value)
      let fields = {}, newKey = [];
      columnsExport.forEach((item,) => {
        fields[item.value] = item.label;
        newKey.push(item.value)
      })
      const workbook = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet([fields, ...res.records?.map((item: { [x: string]: any; }) => {
        let data = {}
        header.forEach((it, index) => {
          data[it] = item[it]
        })
        return data
      })], {
        header,
        skipHeader: true,
      });
      ws['!cols'] = [{ wpx: 50, }, { wpx: 200, }, { wpx: 100, },]
      XLSX.utils.book_append_sheet(workbook, ws, "Sheet1");
      XLSX.writeFile(workbook, "导出数据.xlsx", { bookType: "xlsx" });
    })
  }
  return <div className="public_list_page">
    <BreadCrumbBar breads={breads}></BreadCrumbBar>
    <div className="invoiceCategoryBox ">
      <div className='default_item' style={{float:'left', marginTop:'4px'}}>
        <div>
          税收分类简称：
        </div>
      </div>
      <div className='word_all'>

        {invoiceCategoryList?.map((item: any, index: React.Key | null | undefined) => <div onClick={() => checkListItem(item.invoiceCategoryShort)} className={`${'default_item'} ${invoiceCategoryShort == item.invoiceCategoryShort ? 'activeListItem' : 'listItem'}`} key={index}>{item.invoiceCategoryShort} ({item.count})</div>)}
      </div>
      <div className='line'></div>
      <div style={{ lineHeight: '1.8' }}>
        搜索：
        <Search value={keyword} placeholder="输入发票项目名称，税收分类编码搜索" onChange={(value) => setKeyword(value.target.value)} allowClear onSearch={onSearch} style={{ width: 360 }} />
      </div>
    </div>
    <div className='invoiceCategoryBox'>
      <Row justify={"space-between"} align={'middle'} className='tableTop'>
        <Col>
          <div style={{ fontSize: '18px' }}>开票配置列表</div>
        </Col>
        <Col>
          <Space align="end">
            <Button onClick={() => submitExport()}>导出</Button>
            <Button type="primary" onClick={() => setIsModalOpen(true)}>新增</Button>
          </Space>
        </Col>
      </Row>
      <div className="table_container">
        <TableCom
          columns={columns}
          onChange={handlePage}
          dataSource={tableList}
          pagination={{
            ...pageOptions,
            total: total,
          }}
        ></TableCom>
      </div>
    </div>
    {
      isModalOpen && <Modal
        open={isModalOpen}
        title="新增开票名称"
        onCancel={handleCancel}
        centered
        width={600}
        footer={null}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          style={{ maxWidth: 800 }}
          onFinish={onFinish}
        >

          <Form.Item
            label="发票项目名称"
            name="invoiceProjectName"
            rules={[{ required: true }, { message: "最多50个字", max: 50 }]}
          >
            <Input placeholder='格式为：*税收分类简称*商品名称' />
          </Form.Item>
          <Form.Item
            label="税收分类编码"
            name="invoiceCategoryCode"
            rules={[{ required: true }, { pattern: RexNumber, message: '请输入数字' }]}
          >
            <Input placeholder='请输入' min={0} style={{ width: '100%' }} />
          </Form.Item>


          <Space direction="horizontal" style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              onClick={(e) => {
                handleCancel();
              }}
            >
              取消
            </Button>
            <Button type="primary" htmlType="submit">
              确定
            </Button>
          </Space>
        </Form>
      </Modal>
    }
  </div>
})
InvoiceSetting.defaultProps = defaultProps