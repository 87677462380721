import React, { useEffect } from 'react';
import Util from '../../../services/util';
const QrInput = ({ tabsKey }: { tabsKey: string }) => {
    useEffect(() => {
        const util = Util.getInstance();
        util.httpGet('/sinzetech-admin-auth/wxLogin/properties', null, false).then((data: any) => {
            console.log(data, 'datadatadatadatadata');
            (window as any).WwLogin({
                id: 'wx_code',
                "appid": data.data.corpId,
                "agentid": data.data.agentId,
                "redirect_uri": data.data.backUrl,
                "state": "",
                "href": "",
            })
        });
    }, [tabsKey]);
    return (
        <div className='qr-input'>
            <div id="wx_code"></div>
        </div>
    )
}
export default QrInput;