import { limitMethods, uploadFileBeforeLimit } from '@/utils/uploadFileBeforeLimit'
import { withDefaults } from '@/utils/withDefaults'
import { message, Upload, UploadProps } from 'antd'
import { RcFile, UploadChangeParam, UploadFile } from 'antd/lib/upload'
import React, { useState } from 'react'
import { UploadFileStatus } from 'antd/lib/upload/interface'
import { ProgressProps } from 'antd/es/progress'
import { tableDrivenFactory } from '@/utils/tableDrivenFactory'
import { UploadImageTypeUI } from './RenderUploadUI'
import { withGroup } from '@/utils/withGroup'

const defaultProps = {
  name:'file',
  showUploadList:false,
  beforeUploadLimitHandlers: [],
}
type UploadFileStatuses = UploadFileStatus|'block'
export type RenderUploadUIProps = {percent?: number,status?: UploadFileStatuses,loading: boolean,errorMessage?:string,currentFile?: RcFile} 

type props<T> = {
  beforeUploadLimitHandlers?:  ((file: RcFile, FileList: RcFile[]) => Promise<boolean>)[],
  onError?: (e?:{message:string},info?:UploadChangeParam<UploadFile<T>>)=>boolean|void,
  onSuccess?: (info:UploadChangeParam<UploadFile<T>>)=>boolean|void,
  maxSize?: number,
  children?: ((doms: RenderUploadUIProps)=>React.ReactNode)|React.ReactNode
} & Omit<UploadProps<T>,'children'>
export type EasyUploadFileProps<T={}> = props<T>

// const getProgressStatus = tableDrivenFactory<UploadFileStatus,ProgressProps['status']>({
//   'done': 'success',
//   'error': 'exception',
//   'uploading': 'active',
//   'block': 'active',
//   'success':'success',
//   'OTHER':'normal'
// })


const Component:React.FC<EasyUploadFileProps> = <T = {}> (props:EasyUploadFileProps<T>)=>{
  const {beforeUploadLimitHandlers,children,onSuccess,onError,maxSize,accept,...rest} = {...defaultProps,...props}
  const [loading,setLoading] = useState(false)
  const [uploadPercent,setUploadPercent] = useState(0)
  const [uploadStatus,setUploadStatus] = useState<UploadFileStatuses>()
  const [errorMessage,setErrorMessage] = useState<string>()
  const [currentFile,setCurrentFile] = useState<RcFile>()
  const reset = ()=>{
    setUploadPercent(0)
    setUploadStatus(undefined)
  }

  const onChange:UploadProps<T>['onChange'] = (info,...rest)=>{
    setUploadStatus(info.file.status)
    if (info.file.status === 'uploading') {
      if(info.event){
        setUploadPercent(info.event?.percent)
      }
      setLoading(true)
    }
    if (info.file.status === 'done') {
      reset()
      setLoading(false)
      onSuccess?.(info)
    } else if (info.file.status === 'error') {
      setLoading(false)
      reset()
      // @ts-ignore
      const message = info.file.response?.error||info.file.response?.message
      setErrorMessage(message)
      onError?.({message},info)
    } 
    return props.onChange?.(info,...rest)
  }
  const beforeUpload:UploadProps<T>['beforeUpload'] = async (file,fileList)=>{
    setCurrentFile(file)
    setUploadStatus('block')
    await uploadFileBeforeLimit([limitMethods.limitType.bind(null,accept?.split(',')),limitMethods.limitSize.bind(null,maxSize),...beforeUploadLimitHandlers],file,fileList).catch((e)=>{
      message.error(e)
      throw e
    })
    const result = await props.beforeUpload?.(file,fileList)
    return result
  }
  return  <Upload
      accept={accept}
      {...rest}
      onChange={onChange}
      beforeUpload={beforeUpload}
      disabled={loading}
     >
      {typeof children ==='function'? children?.({percent:uploadPercent,status:uploadStatus,loading,errorMessage,currentFile}):children}
     </Upload>
}

const EasyUpload = withGroup(withDefaults(Component,defaultProps),{
  ImageTypeUI: UploadImageTypeUI
})
export {
  EasyUpload,
}