import { message } from "antd"
import axios, { AxiosRequestConfig } from "axios"
import { cross_env } from "./util"

export const axiosRequrest = {
    get<T>(url: string, config?: AxiosRequestConfig<any> | undefined): Promise<T> {
        return new Promise((resolve, reject) => {
            if(config?.params){
                url += '?'+encodeURI(Object.keys(config?.params).filter((key)=>config.params[key]).map((key)=>`${key}=${config.params[key]}`,'').join('&')||"")
                config.params = undefined
            }
            axios.get(url, config).then(res => {
                if (res.data.code === 200) {
                    resolve(res.data.data)
                } else {
                    reject(res.data.data)
                    message.error(res.data.message)
                }
            })
        })
    },
    post<T>(url: string, data?: any, config?: AxiosRequestConfig<any> | undefined): Promise<T> {
        return new Promise((resolve, reject) => {
            axios.post(url, data, config).then(res => {
                if (res.data.code === 200) {
                    resolve(res.data.data)
                } else {
                    message.error(res.data.message)
                    reject(res.data.data)
                }
            })
        })
    },
    put<T>(url: string, data?: any, config?: AxiosRequestConfig<any> | undefined): Promise<T> {
        return new Promise((resolve, reject) => {
            axios.put(url, data, config).then(res => {
                if (res.data.code === 200) {
                    resolve(res.data.data)
                } else {
                    message.error(res.data.message)
                    reject(res.data.data)
                }
            })
        })
    },
    delete<T>(url: string, config?: AxiosRequestConfig<any> | undefined): Promise<T> {
        return new Promise((resolve, reject) => {
            axios.delete(url, config).then(res => {
                if (res.data.code === 200) {
                    resolve(res.data.data)
                } else {
                    message.error(res.data.message)
                    reject(res.data.data)
                }
            })
        })
    },
}

export const proxyAxios = new Proxy(axiosRequrest, {
    get(target,key){
      const url = new URL(cross_env||"https://api.saikul.com")
      const origin = url.origin
      return (path:string,...args:any)=>{
        return target[key](origin+path,...args)
      }
    }
})