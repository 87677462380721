import { useSetState } from "ahooks"
import { message, Select, Spin } from "antd"
import Axios from "axios"
import React, { CSSProperties, useEffect } from "react"
const { Option } = Select

interface VagueSelectProps {
    style?: CSSProperties
    url: string //要查询的url
    value: string | number | any[] //select绑定的value值
    onSelect: (...age: any[]) => void //选中change事件
    keywords: string //模糊查询关键词
}
interface state extends BaseState {
    isSlectLoading: boolean
}
const VagueSelect = ({ url, onSelect, keywords, style = {}, value, ...props }: VagueSelectProps) => {
    const [state, setState] = useSetState<state>({
        isSlectLoading: false,
        columnsData: [],
        current: 1,
        // size: 50,
    })
    // 获取列表
    const getColumnsData = (value?: string) => {
        setState({ isSlectLoading: true });
        Axios.get(`${url}`, {
            params: {
                current: state.current,
                size: 9999,
                [keywords]: value,
            }
        }).then(res => {
            setState({ isSlectLoading: false, })
            if (res.data.code === 200) {
                setState({ columnsData: res.data.data.records || res.data.data, })
            } else {
                message.error(res.data.msg)
            }
        })
    }
    let timer: any = null
    return (
        <Select
            className="input"
            showSearch
            // allowClear
            value={value}
            placeholder='请选择或搜索'
            style={{ width: '100%', ...style }}
            defaultActiveFirstOption={false}
            filterOption={false}
            optionFilterProp='children'
            onSearch={(value) => {
                clearTimeout(timer)
                timer = setTimeout(() => {
                    setState({
                        columnsData: [],
                        current: 1,
                    })
                    getColumnsData(value)
                }, 1000)
            }}
            onChange={(...age) => {
                onSelect(...age)
            }}
            notFoundContent={state.isSlectLoading ? <Spin size='small' /> : null}
            {...props}
        >
            {
                state.columnsData?.map((item, index) => {
                    return (
                        <Option
                            key={index}
                            value={item.id}
                            item={item}
                            disabled={item.deregisterStatus == 'WAIT_DEREGISTER'}
                        >{item.deregisterStatus == 'WAIT_DEREGISTER' ? `(注销中)${item.enterpriseName}` || `(注销中)${item.legal}` : item.enterpriseName || item.legal}</Option>
                    )
                })
            }
        </Select>
    )
}

export default VagueSelect