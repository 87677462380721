import React from 'react';
// import { Breadcrumb } from 'antd';
import { useNavigate } from 'react-router-dom';
import './index.scss';

type type_breadcrumb_bar = {
    breads: any[];

}

const BreadCrumbBar: React.FC<type_breadcrumb_bar & React.HTMLAttributes<HTMLDivElement>> = (props) => {
    const {breads} = props
    const navigate = useNavigate()
    return (
        <div className="bread-crumb-bar"style={{display:'flex',...props.style}}>
            {
                breads.map((item:any, index:number) => {
                    return <div key={item.label}
                    style={{cursor:'pointer', color:'#666',fontSize:14}}
                    //  onClick={() => {
                    //     navigate(item.path)
                    // }}
                    >
                        {index !== breads.length-1?item.label:<span style={{color:'#000'}}>{item.label}</span>}
                        
                        {index !== breads.length-1 && <span style={{marginLeft:8,marginRight:8}}>/</span>}
                    </div>
                })
            }
            {/* <Breadcrumb items={[]}> */}
                {/* {
                    breads.map((item) => (
                        <Breadcrumb.Item key={item.path}>
                            <Link to={item.path || ''}>
                                {item.label}
                            </Link>
                        </Breadcrumb.Item>
                    ))
                } */}
            {/* </Breadcrumb> */}
        </div>
    )
}

export default BreadCrumbBar;