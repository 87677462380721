import TableCom from "@src/components/TableCom";
import { customerRelatedEntityList } from "@src/pages/Customer/promise";
import { Button } from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { radioOption, RelatedEntityItemPartial } from "./relatedEntity";
import { getEntryList, getTaxs } from "../../../../../promise";

const RelatedEntity = (props: any) => {
  const { details, isShowEdit } = props;
  const [pageOptions, setPageOptions] = useState({
    current: 1,
    pageSize: 10,
  });
  const [total, setTotal] = useState(0);
  const [list, setList] = useState([]);
  const columns = [
    {
      title: "企业名称",
      dataIndex: "name",
    },
    {
      title: "类型",
      dataIndex: "levelName",
    },
    {
      title: "统一信用代码",
      dataIndex: "taxNo",
    },
    {
      title: "法人",
      dataIndex: "legalPerson",
    },
    {
      title: "合作税区",
      dataIndex: "taxRegionIds",
      render: (record: any) => {
        let str: any = [];
        taxList.length !== 0 &&
          taxList.map((item: any) => {
            if (record.includes(item.id)) {
              str.push(item.name);
            }
          });
        return <span>{str.toString()}</span>;
      },
    },

    {
      title: "添加人",
      dataIndex: "createUserName",
    },
    {
      title: "添加时间",
      dataIndex: "createDate",
    },
    {
      title: "操作",
      render: (record: RelatedEntityItemPartial) => {
        return record?.type === "ENTERPRISE" ? (
          <Link
            // to={`/customer/management/${details?.id}/relatedEntityDetail/${record?.id}/${isShowEdit}`}
            to={`/entity/transaction/enterprise/${record.id}`}
          >
            详情
          </Link>
        ) : (
          <Link to={`/entity/transaction/individuality/${record.id}`}>
            详情
          </Link>
        );
      },
    },
  ];
  const [taxList, setTaxList] = useState([]);
  const getTaxList = async () => {
    const params = {
      current: 1,
      size: 99999,
    };
    const res = await getTaxs(params);
    if (res?.code === 200) {
      setTaxList(res?.data?.records);
    }
  };
  // 关联实体列表
  const getList = (object?: any) => {
    if (!details?.id || details?.id === "") return;
    const params = {
      sourceId: details?.id,
      size: pageOptions.pageSize,
      current: pageOptions.current,
      ...object,
    };
    if (object?.current && object?.size) {
      setPageOptions({
        current: object?.current,
        pageSize: object?.size,
      });
    }
    getEntryList(params).then((res: any) => {
      if (res.code === 200) {
        setList(res.data.records);
        setTotal(res.data.total);
      }
    });
  };
  useEffect(() => {
    getList();
    getTaxList();
  }, [details]);
  const handlePage = (params: any) => {
    getList({
      size: params?.pageSize,
      current: params?.current,
    });
  };
  const navigate = useNavigate();
  return (
    <div>
      {isShowEdit && (
        <Button
          style={{ float: "right", zIndex: 1000 }}
          type="primary"
          onClick={() => {
            navigate(`/customer/${details?.id}/entity/add`);
          }}
        >
          添加实体
        </Button>
      )}
      <TableCom
        rowKey={(record: any) => record.id}
        columns={columns}
        dataSource={list}
        pagination={{
          ...pageOptions,
          total: total,
        }}
        scroll={{ y: "30vh" }}
        onChange={handlePage}
      ></TableCom>
    </div>
  );
};

export default RelatedEntity;
