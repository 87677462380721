// 客户来源
export const customerSourceList = [
  {
    label:'自行发掘',
    value:'SELF_EXCAVATION'
  },
  {
    label:'客户介绍',
    value:'CUSTOMER_INTRODUCTION'
  },
  {
    label:'网络推广',
    value:'NETWORK_PROMOTION'
  },
  {
    label:'社会活动',
    value:'SOCIAL_ACTIVITIES'
  },
  {
    label:'客户主动联系',
    value:'CUSTOMER_PROACTIVELY_CONTACTED'
  },
  {
    label:'其他',
    value:'OTHER'
  },
]
// 采购品类
export const purchaseCategoryList =[
  {
    label:'废钢',
    value:'STEEL_SCRAP'
  },
  {
    label:'废纸',
    value:'BROKE'
  },
  {
    label:'废塑料',
    value:'WASTE_PLASTICS'
  },
  {
    label:'废有色',
    value:'WASTE_COLOR'
  },
  {
    label:'矿石',
    value:'ORE'
  },
  {
    label:'其他',
    value:'OTHER'
  },
]
// 公司类型
export const companyTypeList = [
  {
    label:'利废工厂',
    value:'CONSERVATION_PLANT'
  },
  {
    label:'贸易公司',
    value:'TRADING_COMPANY'
  },
  {
    label:'货场黄牛',
    value:'FREIGHT_YARD_CATTLE'
  }
]
// 客户状态
export const customerStatusList =[
  {
    label:'储备客户',
    value:'RESERVE_CUSTOMERS',
    color:'#0958d9'
  },
  {
    label:'持续跟进',
    value:'CONTINUOUS_FOLLOW_UP',
    color:'#faad14'
  },
  {
    label:'已签约',
    value:'SIGNED',
    color:'#43cd43'
  },
]
// 重要程度
export const importanceList = [
  {
    label:'极高',
    value:1
  },
  {
    label:'高',
    value:2
  },
  {
    label:'中',
    value:3
  },
  {
    label:'低',
    value:4
  },
  {
    label:'极低',
    value:5
  },
]