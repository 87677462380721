
import React, { useState, useEffect } from 'react';

import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import './index.scss';

type type_menu_item = Required<MenuProps>['items'][number];
type type_key = {
    name: string,
    path: string,
    icon?: JSX.Element,
    children?: type_key[];
    code?:string,
    key?:string
}
type type_props = {
    lists: type_key[]
}

function getItem(
    label: React.ReactNode,
    key?: React.Key,
    icon?: React.ReactNode,
    children?: type_menu_item[],
    type?: 'group',
    code?:string
): type_menu_item {
    return {
        key,
        icon,
        children,
        label,
        type,
        code
    } as type_menu_item;
}

const handleItem = (lists: type_key[]) => {
    return lists.reduce((a: type_menu_item[], b: type_key) => {
        if (b?.children) {
            a = a.concat([getItem(b.name, b.path, b.icon, handleItem(b.children))]);
        }
        else {
            a = a.concat([getItem(b.name, b.path, b.icon)]);
        }
        return a;
    }, [] as type_menu_item[])
}
const LeftNavbar: React.FC<type_props> = ({ lists }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [pathName, setPathName] = useState('');
    const [defaultOpen, setDefaultOpen] = useState<any>([])
    const [items, setItems] = useState<type_menu_item[]>([]);
    // const [upRoute, setUpRoute] = useState<string[]>([]);

    useEffect((): void => {
        const text = location.pathname.match(/(\/.*)/);
        const urlName = text![1];
          // eslint-disable-next-line react-hooks/exhaustive-deps
        setItems(handleItem(lists))
        const urlNameSplit = urlName.split('/');
        if (urlNameSplit.length >= 3) {
            // setUpRoute([urlNameSplit.slice(0, 3).join('/')]);
        }
    }, []);  // eslint-disable-line
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(()=> {
        const text = location.pathname.match(/(\/.*)/);
        const urlName = text![1];
        setPathName(urlName);
        lists.forEach((item:any) => {
        if(item.key && item.key === urlName.split('/')[1]){
            let arr1:any =[]
            arr1.push(item.path)
            setDefaultOpen(arr1)
        }else{
            if(item.children){
                item.children.map((cItem:any) => {
                    if(cItem.path === urlName){
                        let arr:any =[]
                        arr.push(item.path)
                        setDefaultOpen(arr)
                    }
                })
            }
        } 
        }) 
        
    }, [location]);
    return (
        <div className="left-nav-bar" onClick={(e) => { }}>
            {
               pathName?  <Menu
               selectedKeys={[pathName]}
               onClick={(e) => {
                   navigate(e.key)
               }}
               mode="inline"
               theme="dark"
               multiple={false}
               inlineCollapsed={false}
               items={items}
               defaultOpenKeys={defaultOpen}
           />: null
            }
            
        </div>
    );
};
export default LeftNavbar;