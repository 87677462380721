import { EditOutlined } from '@ant-design/icons'
import ProDescriptions from '@ant-design/pro-descriptions'
import ModalForm from '@src/components/ModalForm'
import { Card, Space } from 'antd'
import React,{memo, useContext} from 'react'
import { EditBankBranchFormFields } from '../EditBankBranch'
import { useForm } from 'antd/es/form/Form'
import { EntityDetailContext } from '../../hooks/useEntityDetailLogic'
import { RexChinesePhone } from '@src/constant/RegExp'

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};
const defaultProps = {}
type props = {}
export type PaymentInfoProps = Required<typeof defaultProps> & props & React.HTMLAttributes<HTMLDivElement>
export const PaymentInfo:React.FC<React.PropsWithChildren<PaymentInfoProps>> = memo((props)=>{
  const [form] = useForm()
  const context = useContext(EntityDetailContext)
  const editBankBranch = async(value:Partial<API.Entity.Info>)=>{
    await context?.onSaveByNewData({
      accountBankNo: value.accountBankNo,
      accountBankName: value.accountBankName,
    })
  }
  return <Card title="支付信息">
    <ProDescriptions editable={{onSave:context?.onSave}} columns={[
    {title:'银行账号',dataIndex:'bankNo',formItemProps:{rules:[{message:'请输入',required:true}]}},
    {title:'开户行名称',dataIndex:'accountBankName',editable:false,render:(dom)=>{
      return <Space size='small' style={{gap:4}}>{dom} <ModalForm onFinish={editBankBranch} modalProps={{title:"编辑开户行",width:1000}} fieldProps={{form:form,...layout}} trigger={<EditOutlined style={{cursor:'pointer'}} />}><EditBankBranchFormFields form={form} /></ModalForm></Space>
    }},
    {title:'银行预留手机号',dataIndex:'bankPhone',formItemProps:{rules:[{message:'请填写正确格式',pattern:RexChinesePhone},{message:'请输入',required:true}]}},
    {title:'开户行行号',dataIndex:'accountBankNo',editable:false}
  ]} dataSource={context?.entityDataService.data}></ProDescriptions>
  </Card>
})
PaymentInfo.defaultProps = defaultProps