import { Badge, Tag } from 'antd'
import React,{ReactNode, memo} from 'react'
import classnames from 'classnames'
import './index.scss'
const defaultProps = {}
export type TagCustomStyleProps = {
  status: 'default'|'processing'|'success'|'error'|'warning'
  text: ReactNode
}
const classPrefix = 'tag-custom'
export type TagProps = TagCustomStyleProps & React.HTMLAttributes<HTMLDivElement>
export const TagCustomStyle: React.FC<React.PropsWithChildren<TagProps>> = memo((props)=>{
  const {status,text,children} = props
  return <Tag className={classnames(classPrefix,[classPrefix,status].join('-'))}><Badge color="" text={text||children}></Badge></Tag>
})
TagCustomStyle.defaultProps = defaultProps