import React, { useEffect, useState } from 'react';
import { Button, Modal, Col, Divider, Row, Input, Select, Form, message } from 'antd';
import './index.scss';
import {addRole, editRole} from '../promise'
import {authorityList} from '../../../../../services/api/system/page/authority'
const Option = Select.Option
const formLayout ={
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 14,
    },
  }
type type_modal_operator = {
    (a: boolean): void;
}
type type_authority_modal = {
    isOpen: boolean;
    openModal: type_modal_operator;
    data?: {},
    cb:() => void,
    currentObj?: any
}
const RoleModal: React.FC<type_authority_modal> = ({ isOpen, openModal, data, cb, currentObj }) => {
    // const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [authorList, setAuthorList] = useState<any[]>([])
    const [messageApi, contextHolder] = message.useMessage();
    // 获取角色列表
    const getAuthorList = () => {
        const data={
            current:1,
            pageSize: 999999
        }
        authorityList(data.current, data.pageSize)
        .then(res => {
            if(res.code === 200){
                setAuthorList(res.data.records)
            }
        })
    }
    useEffect(() => {
        getAuthorList()
    }, [])
    useEffect(() => {
        form.resetFields();
        const obj ={
            name: currentObj.name,
            code: currentObj.code,
            authorityList: currentObj.authorityIds
        }
        form.setFieldsValue(obj)
    }, [currentObj]) // eslint-disable-line
    const handleClick= (e:any) => {
        e.stopPropagation()
        form.validateFields()
        .then((values: any) => {
            let roleData={
                code: values.code,
                name: values.name,
                authorityList:values.authorityList,
                id: currentObj?.id
            }
            if(currentObj && currentObj?.id){
                editRole(roleData)
                .then(res => {
                    if(res?.code === 200){
                        messageApi.open({
                            type:'success',
                            content: '编辑成功',
                            onClose:() => {
                                openModal(false);
                                cb();
                                onFinishFailed()
                            }
                        })
                        
                    }else{
                        messageApi.open({
                            type:'error',
                            content:res?.message
                        })
                    }
                })
                return 
            }
            addRole(roleData)
            .then(res => {
                if(res.code === 200){
                    messageApi.open({
                        type:'success',
                        content: '新增成功',
                        onClose:() => {
                            openModal(false);
                            cb();
                            onFinishFailed()
                        }
                    })
                    
                }else{
                    messageApi.open({
                        type:'error',
                        content:res?.message
                    })
                }
            })
        })
    }
    const onFinish = (values:any) => {
        console.log( values)
    };
    const onFinishFailed = () => {
        
        openModal(false);

    }

    return (
        <>
            <Modal
                open={isOpen}
                title="角色新增"
                onCancel={onFinishFailed}
                centered
                width={700}
                footer={null}
            >
                {contextHolder}
                <Divider orientation="left"></Divider>
                <Form {...formLayout} form={form} 
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}>
                    <Row>
                        <Col span={12}>
                            <Form.Item label='角色名称：'name='name' rules={[{required: true, message:'请输入角色名称'}]}>
                                <Input placeholder="请输入" />
                            </Form.Item> 
                        </Col>
                        <Col>
                            <Form.Item label='角色编码：'name='code' rules={[{required: true, message:'请输入角色编码'}]}>
                                <Input placeholder="请输入" />
                            </Form.Item> 
                        </Col>
                        <Col span={12}>
                        <Form.Item label='权限：'name='authorityList'  rules={[{required: true, message:'请选择权限'}]}>
                            <Select
                            defaultValue={currentObj.authorityIds}
                                showSearch
                                placeholder="请选择"
                                style={{ width: 200 }}
                                mode="multiple"
                                allowClear
                                filterOption={(input, option:any) =>{                    
                                  return option.props.children.indexOf(input) >= 0
                                 }}
                                onChange={(e) => { console.log(e) }}
                                >
                                    {authorList.map((item:any) =><Option value={item.id} key={item.id}>{item.name}</Option> )}
                                </Select>
                            </Form.Item> 
                        </Col>
                    </Row>
                    <Row>
                        <Col offset={8} span={4}>
                            <Form.Item wrapperCol={{ span: 16 }}>
                                <Button onClick={(e) => { onFinishFailed() }}>
                                    取消
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item wrapperCol={{ span: 16 }}>
                                <Button type="primary" onClick={(e) => handleClick(e)}>
                                    保存
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};
export default RoleModal;