import ModalForm from '@src/components/ModalForm'
import { TableToolBar } from '@src/components/TableToolBar'
import { useTable } from '@src/hooks/useTable'
import { Button, Table } from 'antd'
import React,{memo, useContext} from 'react'
import { AddInvoiceItemFormFields } from '../AddInvoiceItem'
import { useForm } from 'antd/es/form/Form'
import { EntityDetailContext } from '../../hooks/useEntityDetailLogic'
import { confirmToContinue } from '@src/utils/utils'
import _ from 'lodash'

const defaultProps = {}
type props = {}
export type InvoiceItemTableProps = Required<typeof defaultProps> & props & React.HTMLAttributes<HTMLDivElement>

export const InvoiceItemTable:React.FC<React.PropsWithChildren<InvoiceItemTableProps>> = memo((props)=>{
  const context = useContext(EntityDetailContext)
  
  const invoiceTable = useTable(async ()=>{
    // const res = await context?.invoiceItemsConfig.run()
    // console.log(res,'res')
    return Promise.resolve( {
      data:[],
      total:0
    })
  })
  const onRemove = async (code: string)=>{
    await confirmToContinue({title:'确认',content:'是否移除该开票名称？'})
    const newInvoicingNames = context?.entityDataService.data?.invoicingNames.filter((item)=>item.code!==code).map((item)=>({code:item.code,name:item.name}))
    await context?.onSave(['invoicingNames'],{invoicingNames:newInvoicingNames},{invoicingNames: context.entityDataService.data?.invoicingNames})
  }
  const onAdd = async (items: API.Entity.Info['invoicingNames'])=>{
    const newInvoicingNames = context?.entityDataService.data?.invoicingNames
    ? context?.entityDataService.data?.invoicingNames.map((item)=>({code:item.code,name:item.name})).concat(items)
    : items
    await context?.onSave(['invoicingNames'],{invoicingNames:newInvoicingNames},{invoicingNames: context.entityDataService.data?.invoicingNames})
  }
  const [form] = useForm()
  return <>
          <TableToolBar
        {...invoiceTable.getToolBarProps?.({
          title: '开票名称',
          action:<>
          <ModalForm<API.Entity.Info['invoicingNames'][number]>
          onFinish={async (values: any)=>{
            await onAdd(values.invoiceItems)
          }}
          fieldProps={{form}}
          modalProps={{
            title:'添加发票项目名称',
          }}
          trigger={
          <Button
              className="func_item"
              type="primary"
            >
              添加开票名称
            </Button>}>
              <AddInvoiceItemFormFields form={form} />
          </ModalForm>
          </>
        })}
      />
        <Table
          pagination={false}
          rowKey={"id"}
          // {...invoiceTable?.getTableProps?.({
          //   className: "public_table",
          // })}
          dataSource={context?.entityDataService.data?.invoicingNames}
          columns={[
            {title:"发票项目名称",dataIndex:'name'},
          {title:"税收编码",dataIndex:'code'},
          {title:"操作",dataIndex:'action',render:(_,record)=>{
            return <a onClick={()=>onRemove(record.code)}>移除</a>
          }}
        ]}
        />
  </>
})
InvoiceItemTable.defaultProps = defaultProps