import React, { useEffect } from 'react'
import BreadCrumbBar from '@src/components/BreadCrumbBar'
import { Button, Form, Input, Pagination, Select, Table, } from 'antd';
import { useNavigate } from 'react-router-dom';
import { axiosRequrest } from '@src/services/axiosRequest';
import { useSetState } from 'ahooks';
import CommonSearch, { FormItem } from '@src/components/common/CommonSearch';
import { CommonStatus } from '@src/components/common';
interface LogoutApplyState extends BaseState {
    taxRegion: any[]
}
const breads = [
    {
        label: "代办业务",
        path: "/business",
    },
    {
        label: "工商税务开办",
        path: "/business/administration",
    },
    {
        label: "个体户注销申请",
        path: "",
    },
];
const deregisterStatusMap = {
    WAIT_SIGN: '#FAAB0C',
    WAIT_CLEAR_TAX: '#FAAB0C',
    WAIT_DEREGISTER_LICENSE: '#FAAB0C',
    DEREGISTERED: '#52C41A',
}
const LogoutApply = () => {
    let userId = JSON.parse(localStorage.getItem('operation_authorization') as string).userId
    const nav = useNavigate()
    let [state, setState] = useSetState<LogoutApplyState>({
        columnsData: [],
        taxRegion: [],
        total: 0,
        current: 1,
        size: 10,
        isRefresh: false,
        searchContent: {},
    })
    let columns = [{
        title: '注销申请编号',
        dataIndex: 'deregisterCode',
    }, {
        title: '个体户/法人姓名',
        dataIndex: 'legal',
    }, {
        title: '个体户名称',
        dataIndex: 'enterpriseName',
    }, {
        title: '所属税区',
        dataIndex: 'taxRegionName',
    }, {
        title: '注销状态',
        dataIndex: 'deregisterStatusName',
        render: (t: string, item: any) => {
            return (
                <CommonStatus
                    color={deregisterStatusMap[item.deregisterStatus]}
                    text={t}
                />
            );
        },
    }, {
        title: '执照办理员',
        dataIndex: 'licenseAgentName',
    }, {
        title: '办税人',
        dataIndex: 'taxAgentName',
    }, {
        title: '注销创建人',
        dataIndex: 'createUserName',
    },{
        title: "业务员",
        dataIndex: "clerkUserName",
        width: 150,
    },
    {
        title: "申请来源",
        dataIndex: "sourceIdName",
        width: 150,
        render: (record:any) => '-'
    },
    {
        title: '操作',
        dataIndex: 'operation',
        render: (_: any, item: any) => {
            return (
                <div className='operation'>
                    <span
                        onClick={() => {
                            nav(`/business/agency/info/${item?.id}`);
                        }}
                    >详情</span>
                </div>
            )
        }
    },]
    const formList: FormItem[] = [{
        name: 'legalName',
        label: '个体户/法人姓名',
        element: <Input placeholder='请输入' allowClear />,
    }, {
        name: 'enterpriseName',
        label: '个体户名称',
        element: <Input placeholder='请输入' allowClear />,
    }, {
        name: 'taxRegionId',
        label: '所属税区',
        element: (
            <Select placeholder='请选择' allowClear>
                {
                    state.taxRegion.map((item, index) => {
                        return (
                            <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                        )
                    })
                }
            </Select>
        ),
    }, {
        name: 'deregisterCode',
        label: '注销申请编号',
        element: <Input placeholder='请输入' allowClear />,
    }, {
        name: 'deregisterStatus',
        label: '注销状态',
        element: (
            <Select placeholder='请选择' allowClear>
                <Select.Option value='WAIT_SIGN'>申请书待签署</Select.Option>
                <Select.Option value='WAIT_CLEAR_TAX'>待清税</Select.Option>
                <Select.Option value='WAIT_DEREGISTER_LICENSE'>执照待注销</Select.Option>
                <Select.Option value='DEREGISTERED'>已注销</Select.Option>
            </Select>
        ),
    }, {
        name: 'userId',
        label: '查看范围',
        element: (
            <Select placeholder='请选择' allowClear>
                <Select.Option value=''>全部</Select.Option>
                <Select.Option value={userId}>与我相关</Select.Option>
            </Select>
        ),
    },]
    useEffect(() => {
        getTaxRegion()
    }, [])
    useEffect(() => {
        getColumnsData()
    }, [state.current, state.size, state.isRefresh])
    const getColumnsData = () => {
        axiosRequrest.get<List>(`/sinzetech-agency-processing/individual-business-deregister`, {
            params: {
                size: state.size,
                current: state.current,
                ...state.searchContent,
            }
        }).then(res => {
            setState({ columnsData: res.records, total: res.total, })
        })
    }
    const getTaxRegion = () => {
        axiosRequrest.get<List>(`/sinzetech-tax/tax-region?current=1&size=99999`).then(res => {
            setState({ taxRegion: res.records })
        })
    }
    return (
        <div className='public_list_page'>
            <BreadCrumbBar breads={breads}></BreadCrumbBar>
            <CommonSearch
                formList={formList}
                onSearch={(values) => {
                    console.log(values, 'values')
                    setState({ searchContent: values, isRefresh: !state.isRefresh })
                }}
            />
            <div className='table_box'>
                <div className='func'>
                    <Button
                        className='func_item'
                        type='primary'
                        onClick={() => {
                            nav('/business/logout/apply/add')
                        }}
                    >新建注销申请</Button>
                </div>
                <Table
                    className='public_table'
                    columns={columns}
                    dataSource={state.columnsData}
                />
                <Pagination
                    className='page_num'
                    total={state.total}
                    current={state.current}
                    pageSize={state.size}
                    showSizeChanger
                    showTotal={(total) => `共${total}条`}
                    onChange={(current, size) => {
                        setState({ current, size })
                    }}
                />
            </div>
        </div>
    )
}

export default LogoutApply