import { Row, Col, Form, Input, Select, Radio, Modal, Button } from "antd";
import { authKeys } from "../../content";
import "./index.scss";
import { useRequest } from "ahooks";
import axios from "axios";
export const taxRegionsAPI = "/sinzetech-tax/tax-region";
import UploadCom from "@src/components/UploadCom";
import { useEffect, useState, ReactNode, useImperativeHandle } from "react";

const colProps = {
  span: 12,
};
const FormItem = Form.Item;
const viewModel = (content: ReactNode) => {
  Modal.confirm({
    icon: null,
    title: null,
    closable: true,
    content: content,
    width: 590,
    cancelButtonProps: {
      hidden: true,
    },
    okButtonProps: {
      hidden: true,
    },
  });
};
function checkExample(info: { src: string; description: string }): void {
  viewModel(
    <div className="desc_img">
      <img style={{ width: "100%" }} src={info.src} alt="" />
      {/* <p>{info.description}</p> */}
    </div>
  );
}
/**
 * 办理地要求
 * @returns
 */
function Address(props: any) {
  const {
    form,
    enableNuclearName,
    setEnableNuclearName,
    initialValues,
    setEndStr,
    setStartStr,
    nuclearName,
    setNuclearName,
    setPics,
    pics,
    taxRegionService,
  } = props;

  const taxRegionsService = useRequest(
    () =>
      axios
        .get<Common.ResponseData<Common.Paging<API.TaxRegion.Item>>>(
          taxRegionsAPI,
          {
            params: {
              size: 1000,
              current: 1,
            },
          }
        )
        .then((res) => res.data.data),
    {
      onSuccess(response: any) {
        if (response.records.length === 1 && !initialValues?.taxRegionId) {
          form.setFieldsValue({ taxRegionId: response.records[0].id });
          taxRegionService.run(response.records[0].id);
        }
      },
    }
  );
  const getPrefix = () => taxRegionService.data?.businessNamePrefix || "";
  const getSuffix = () => taxRegionService.data?.businessNameSuffix || "";
  const registrationCommonProps = {
    addonBefore: getPrefix(),
    addonAfter: getSuffix(),
  };

  // useEffect(() => {
  //   setPics(form?.getFieldValue("pictureProof"));
  //   // console.log(form?.getFieldValue("pictureProof"));
  // }, [form?.getFieldValue("pictureProof")]);
  return (
    <div>
      <Form.Item name="pictureProof" hidden></Form.Item>
      <Form.Item hidden name="taxRegionName"></Form.Item>

      <div className="common_title">办理地要求</div>
      <div className="newAdd_address">
        <Row>
          <Col {...colProps}>
            <Form.Item
              name={"taxRegionId"}
              label={"执照办理地"}
              rules={[{ required: true, message: "请选择执照办理地" }]}
            >
              <Select
                onChange={(value, option) => {
                  if (Array.isArray(option)) return;
                  taxRegionService.run(value);
                  form.setFieldsValue({
                    businessScope: option.businessScope,
                    taxRegionName: option.name,
                  });
                }}
                options={taxRegionsService.data?.records.map((item: any) => ({
                  ...item,
                  label: item.name,
                  value: item.id,
                }))}
                placeholder={"请选择执照办理地"}
              ></Select>
            </Form.Item>
          </Col>
          {enableNuclearName === 0 ? (
            <Col {...colProps}>
              {/* <Form.Item
              label="联系电话"
              name="name"
              rules={[{ required: true, message: "请输入个体户联系电话" }]}
            >
              <Input placeholder={"请输入个体户联系电话"} />
            </Form.Item> */}
              <FormItem
                className="open_type_select"
                label="办理类型"
                initialValue={1}
                name="nuclearName"
              >
                <Radio.Group
                  onChange={(e: any) => {
                    form.setFieldValue({
                      nuclearName: e.target.value,
                    });
                    setNuclearName(e.target.value);
                  }}
                >
                  {authKeys.map((item: any) => {
                    return (
                      <Radio key={item.value} value={item.value}>
                        {item.label}
                      </Radio>
                    );
                  })}
                </Radio.Group>
              </FormItem>
              <div className="open_type_desc">
                若不需要核名，个体户营业执照中名称为空
              </div>
            </Col>
          ) : null}
        </Row>

        {enableNuclearName === 1 ||
        (enableNuclearName === 0 && nuclearName === 1) ? (
          <>
            <Row className="sign_name_row">
              <Col {...colProps}>
                {/* <Form.Item
              label="企业/个体户名称"
              name="registrationName"
              rules={[{ required: true, message: "请输入企业名称" }]}
            >
              <div className="sign_name">
                <div className="sign_name_start">青岛胶州</div>
                <Input placeholder={"请输入企业名称"} />
                <div className="sign_name_end">再生资源回收站</div>
              </div>
            </Form.Item>
             */}
                <Form.Item
                  label="企业/个体户名称"
                  name="registrationName"
                  rules={[
                    {
                      required: true,
                      message: "请选择登记名称",
                    },
                  ]}
                >
                  <Input
                    placeholder="请输入"
                    {...registrationCommonProps}
                  ></Input>
                </Form.Item>
              </Col>
              <Col {...colProps}>
                <Form.Item
                  label="备选1"
                  name="firstAlternativeName"
                  rules={[
                    {
                      required: true,
                      message: "请输入备选1",
                    },
                  ]}
                >
                  <Input
                    placeholder="请输入"
                    {...registrationCommonProps}
                  ></Input>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col {...colProps}>
                {/* <Form.Item
                label="备用2"
                name="name"
                rules={[{ required: true, message: "请输入企业名称" }]}
              >
                <div className="sign_name">
                  <div className="sign_name_start">青岛胶州</div>
                  <Input placeholder={"请输入企业名称"} />
                  <div className="sign_name_end">再生资源回收站</div>
                </div>
              </Form.Item> */}
                <Form.Item label="备选2" name="secondAlternativeName">
                  <Input
                    placeholder="请输入"
                    {...registrationCommonProps}
                  ></Input>
                </Form.Item>
              </Col>
            </Row>
          </>
        ) : null}
      </div>
      <div>
        <div className="photo_content">
          <Form.Item name="pictureProof" hidden></Form.Item>
          {pics ? (
            <div>
              {pics.map((item: any, index: number) => {
                return (
                  <Row>
                    <Col span={5}>
                      <Form.Item
                        label={item.name}
                        name={"pictureProof" + index}
                        rules={[
                          { required: true, message: "请输入税税通截图" },
                        ]}
                      >
                        <UploadCom
                          value={item?.downloadLink}
                          ButtonText="点击上传"
                          cb={(value: any) => {
                            const res = value[0] && value[0]?.filePath;
                            const arr = JSON.parse(JSON.stringify(pics));
                            arr[index].url = res;
                            arr[index].downloadLink =
                              value[0] && value[0]?.downloadLink;
                            setPics(arr);
                            form.setFieldValue("pictureProof", arr);
                            form.setFieldValue(
                              "pictureProof" + index,
                              arr[index].downloadLink
                            );
                          }}
                        ></UploadCom>
                      </Form.Item>
                    </Col>
                    <Col span={7}>
                      <p className="photo_desc">
                        {item?.description}
                        <Button
                          type="link"
                          onClick={checkExample.bind(null, {
                            src: item?.uploadPhotoProofUrl || "",
                            description: item?.description,
                          })}
                        >
                          {/* 查看示例 */}
                          <span>查看示例</span>
                        </Button>
                      </p>
                    </Col>
                  </Row>
                );
              })}
            </div>
          ) : null}

          {/* {
            <div>
              {form.getFieldValue("desc") ? (
                <>
                  {form
                    .getFieldValue("desc")
                    .map((item: any, index: number) => {
                      return (
                        <Row>
                          <Col span={5}>
                            <Form.Item
                              label={"税税通截图" + (index + 1)}
                              name={"pictureProof" + (index + 1)}
                              rules={[
                                { required: true, message: "请输入税税通截图" },
                              ]}
                            >
                              <UploadCom
                                ButtonText="点击上传"
                                value={item.url}
                                cb={(value: any) => {
                                  const res = value[0] && value[0]?.filePath;
                                  item.url = res;
                                  form.setFieldValue(
                                    "pictureProof" + (index + 1),
                                    item
                                  );
                                }}
                              ></UploadCom>
                            </Form.Item>
                          </Col>
                          <Col span={7}>
                            <p className="photo_desc">
                              {item.description}
                              <span>查看示例</span>
                            </p>
                          </Col>
                        </Row>
                      );
                    })}
                </>
              ) : null}
            </div>
          } */}
        </div>
      </div>
    </div>
  );
}

export default Address;
