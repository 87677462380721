// 第一步操作状态
export const stepstatusObj1 ={
  'NO_APPROVAL':'待提交审核',
  'TO_BE_ASSIGNED':'待分配审核员',
  'ASSIGNED':'已分配审核员',
  'APPROVAL_REJECTION':'审核驳回',
  'APPROVAL_PASS':'审核通过'
}
// 第二部操作状态
export const stepstatusObj2={
  'NO_HANDLED':'待办理',
  'REJECTION':'办理失败',
  'SUBMIT_BUSINESS':'提交工商',
  'PASS':'办理通过',
  'PENDING_CANCELLATION':'待注销',
  'CANCELED':'已注销',
  'FAIL':'办理失败'
}
export const stepstatusObj3={
  'NO_HANDLED':'待登记',
  'REGISTERING':'登记中',
  'PASS':'登记成功',
  'REJECTION':'登记失败',
  'PENDING_TAX_CLEAR':'待清税',
  'TAX_CLEARED':'已清税'
}

export const radioOption = [
  {
    label:'个体户办理',
    value:'INDIVIDUAL'
  },
  {
    label:'企业办理',
    value:'ENTERPRISE'
  }
]
// 
export const RadioList = [
    {
        label: "个体工商户",
        value: "INDIVIDUAL",
    },
    {
        label: "公司",
        value: "ENTERPRISE",
    },
];
// step1  对象集合
export const step1Object = {
    NO_APPROVAL: {
        label: '待提交审核',
        color: 'pedding'
    },
    TO_BE_ASSIGNED: {
        label: '待分配审核员',
        color: 'pedding'
    },
    ASSIGNED: {
        label: '已分配审核员',
        color: 'success' 
    },
    APPROVAL_PASS: {
        label: '审核通过',
        color: 'success' 
    },
    APPROVAL_REJECTION: {
        label: '审核驳回',
        color: ''
    }
}
// step2 对象集合
export const step2Object = {
    NO_HANDLED: {
        label: '待办理',
        color: 'pedding'
    },
    REJECTION: {
        label: '办理失败',
        color: 'error'
    },
    SUBMIT_BUSINESS: {
        label: '提交工商',
        color: 'success'
    },
    PASS: {
        label: '办理通过',
        color: 'success'
    },
    PENDING_CANCELLATION: {
        label: '待注销',
        color: 'warning'
    },
    CANCELED: {
        label: '已注销',
        color: 'error'
    },
    FAIL: {
        label: '办理失败',
        color: 'error'
    },
}

// step3对象集合
export const step3Object = {
    NO_HANDLED: {
        label: '待登记',
        color: 'pedding'
    },
    REGISTERING: {
        label: '登记中',
        color: 'pedding'
    },
    PASS: {
        label: '登记成功',
        color: 'success'
    },
    REJECTION: {
        label: '登记失败',
        color: 'error'
    },
    PENDING_TAX_CLEAR: {
        label: '待清税',
        color: 'warning'
    },
    TAX_CLEARED: {
        label: '已清税',
        color: 'error'
    },
}

// 对象集合
export const stepPerson = {
    '1': "审核员",
    '2': "办理员",
    '3': "开票员",
};

// 改版后的全新状态参数
// 当前状态参数内的所有的参数   审批流程
export const applyStatusObject = Object.freeze({
    NO_APPROVAL: {
        label: '待审核',
        color: 'warning',
        step: 0,
        statusLabel: '审核员'
    },
    APPROVAL: {
        label: '审核中',
        color: 'warning',
        step: 0,
        statusLabel: '审核员'
    },
    APPROVAL_REJECTION: {
        label: '审核驳回',
        color: 'error',
        step: 0,
        statusLabel: '审核员'
    },
    NO_LICENSE: {
        label: '待办理',
        color: 'warning',
        step: 1,
        statusLabel: '办理员'
    },
    SUBMIT_BUSINESS: {
        label: '提交工商',
        color: 'success',
        step: 1,
        statusLabel: '办理员'
    },
    LICENSE_FAIL: {
        label: '办理失败',
        color: 'error',
        step: 1,
        statusLabel: '办理员'
    },
    NO_ASSIGN_TAX: {
        label: '待分配办税员',
        color: 'warning',
        step: 2,
        statusLabel: '开票员'
    },
    TAX_REGISTERING: {
        label: '税务登记中',
        color: 'warning',
        step: 2,
        statusLabel: '开票员'
    },
    TAX_PASS: {
        label: '税务登记成功',
        color: 'success',
        step: 2,
        statusLabel: '开票员'
    },

    TAX_REJECTION: {
        label: '税务登记失败',
        color: 'error',
        step: 2,
        statusLabel: '开票员'
    },
    TERMINATION: {
        label: '已终止',
        color: 'error',
        step: 2,
        statusLabel: '开票员'
    },
})

// 注销流程 
export const deregisterStatusObject = Object.freeze({
    WAIT_SIGN: {
        label: '申请书待签署',
        color: 'warning',
        step: 1,
        
    },
    WAIT_CLEAR_TAX: {
        label: '待清税',
        color: 'warning',
        step: 2,
        
    },
    WAIT_DEREGISTER_LICENSE: {
        label: '执照待注销',
        color: 'warning',
        step: 3,
        
    },
    DEREGISTERED: {
        label: '已注销',
        color: 'warning',
        step: 4,
        
    },
}) 

// 当前选择的applyType
export const applyTypeObjet = Object.freeze({
    INDIVIDUAL: '个体户办理',
    ENTERPRISE: '企业办理'
})