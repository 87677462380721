import React, { useEffect, useState } from 'react';
import { Button, Modal, Col, Divider, Row, Input, message } from 'antd';
import './index.scss';
import {createAutho} from '../../services/api/system/page/authority'

type type_modal_operator = {
    (a: boolean): void;
}
type type_authority_modal = {
    isOpen: boolean;
    openModal: type_modal_operator;
    currentObj?: any;
    data?: {},
    cb: () => void
}
const AuthorityModal: React.FC<type_authority_modal> = ({ isOpen, openModal,  data, cb, currentObj }) => {
    const [messageApi, contextHolder] = message.useMessage();
    console.log(data, 'data')
    // const [loading, setLoading] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [role, setRole] = useState<string>('');
    const handleOk = () => {
        if(!name || name === ''){
            messageApi.open({
                type:'error',
                content:'请输入权限名称'
            })
            return 
        }
        if(!role || role === ''){
            messageApi.open({
                type:'error',
                content:'请输入权限编码'
            })
            return  
        }
        // setLoading(true);
        var addData ={
            name:name,
            code: role,
            id: currentObj.id?? currentObj.id
        }
        createAutho(addData).then(res => {
            if(res.successful){
                messageApi.open({
                    type:'success',
                    content:`${currentObj.id !== undefined ? '编辑':'新增'}成功`,
                    onClose:()=> {
                       cb();
                        // setLoading(false);
                        openModal(false);
                        setName('')
                        setRole('') 
                    }
                })
                
            }
        })
    };

    const handleCancel = () => {
        openModal(false);
    };
    useEffect(() => {
        setName('')
        setRole('')
        if(currentObj && currentObj.id) {
            setName(currentObj?.name)
            setRole(currentObj?.code)
        }else{
            setName('')
        setRole('')
        }
    }, [currentObj])
    return (
        <>
            <Modal
                open={isOpen}
                title='权限新增'
                onOk={handleOk}
                onCancel={handleCancel}
                centered
                width={700}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        取消
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleOk}>
                        保存
                    </Button>,
                ]}
            >
                {contextHolder}
                <Divider orientation="left"></Divider>
                <Row>
                    <Col className="authority-modal" span={12}>
                        <p>权限名称：</p>
                        <Input placeholder="请输入" value={name} onChange={e => {
                            const value = e.target.value.replace(/\s+/g,"")
                            setName(value)
                        }
                            }/>
                    </Col>
                    <Col className="authority-modal" span={12}>
                        <p>权限编码：</p>
                        <Input placeholder="请输入" value={role} onChange={e => {
                            const value = e.target.value.replace(/\s+/g,"")
                            setRole(value)
                        }}/>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};
export default AuthorityModal;