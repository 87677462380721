import Util from '../../../../services/util'
const util = Util.getInstance()

type role_list ={
  current: number| string, 
  size: number|string,
  status?:number | boolean,
  keywords?: string| string
}
type add_role={
  code: string,
  name: string,
  authorityList?: [string],
  id?:string
}
const getRoles :(value: role_list) => Promise<any> = (value:role_list) => {
    return util.httpGet(`/sinzetech-admin-user/roles`,value).then(data => {
      return data;
  },err => err.response.data)

}

const addRole:(value:add_role) => Promise<any> =(value:add_role) => {
  return util.httpPost('/sinzetech-admin-user/roles', value).then(res => res).catch(msg => msg.response.data) 
}

const editRole:(value:add_role) => Promise<any> =(value:add_role) => {
  return util.httpPutBody('/sinzetech-admin-user/roles', value).then(res => res)
}

const deleteRole:(id:string|number) => Promise<any> = (id) => {
  return util.httpDeletel(`/sinzetech-admin-user/roles/${id}`).then(res => res)
}

export {
  getRoles,
  addRole,
  editRole,
  deleteRole
}