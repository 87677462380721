import React, { useEffect, useState, useMemo } from "react";
import { Table, Button, Input, message, Image } from "antd";
import { uuid } from "../../../../../../../../utils/index";
import UploadCom from "../../../../../../../../components/UploadCom";
const { TextArea } = Input;
interface Params {
    cb?: (value: any) => void;
    value?: any;
    disabled?: boolean;
}
const RenderNuclearTable = (props: Params) => {
    const { cb, value = [], disabled } = props;
    console.log(props, "props-table");
    const [list, setList] = useState<any>(value);
    const btnStatus = useMemo(() => {
        let bool = false;
        if (disabled) {
            bool = disabled;
        } else {
            if (list && list.length !== 0) {
                bool = list.length === 20;
            } else {
                bool = false;
            }
        }
        return bool;
    }, [disabled, list]);
    // 存储当前选的照片的参数
    const handleDelete = (record: any) => {
        const res = list.filter((item: any) => item.id !== record?.id);
        setList(res);
    };
    // 新增
    const handleCreate = () => {
        let arr: any = [];
        if (list && list.length !== 0) {
            arr = [...list];
        }
        if (arr.length < 20) {
            arr.push({
                name: "",
                description: "",
                fileId: "",
                id: uuid(),
                downloadLink: "", // 上传图片的参数
            });
            setList(arr);
        }
    };
    // 根据操作项   更新list
    const handleUpdate = (key: string, value: any, row: any) => {
        const res = list.map((item: any) => {
            if (item.id === row.id) {
                item[key] = value;
            }
            return item;
        });
        setList(res);
    };
    useEffect(() => {
        cb && cb(list);
    }, [list]);
    const columns = [
        {
            title: "序号",
            width: 70,
            render: (text: any, _: any, index: any) => {
                return <span>{index + 1 || "1"}</span>;
            },
        },
        {
            title: "图片名称",
            // dataIndex: "name",
            width: 200,
            render: (record: any) => {
                return (
                    <Input
                        defaultValue={record?.name}
                        maxLength={10}
                        onBlur={(e) =>
                            handleUpdate("name", e.target.value, record)
                        }
                        disabled={disabled}
                    ></Input>
                );
            },
        },
        {
            title: "图片说明",
            // dataIndex: "description",
            render: (record: any) => {
                return (
                    <TextArea
                        defaultValue={record?.description}
                        maxLength={100}
                        onBlur={(e) =>
                            handleUpdate("description", e.target.value, record)
                        }
                        disabled={disabled}
                    ></TextArea>
                );
            },
        },
        {
            title: "图片示例",
            // dataIndex: "fileId",
            render: (record: any) => {
                return disabled ? (
                    <Image src={record?.uploadPhotoProofUrl} width={80}></Image>
                ) : (
                    <UploadCom
                        type="image"
                        accept="image/jpeg,image/png,image/gif"
                        ButtonText={
                            <Button type="link" disabled={disabled}>
                                + 点击上传
                            </Button>
                        }
                        maxSize={50}
                        value={record?.uploadPhotoProofUrl || ""}
                        cb={(value: any) => {
                            if (value[0]) {
                                const res = list.map((item: any) => {
                                    if (item.id === record.id) {
                                        item["fileId"] = value[0]?.fileId;
                                        item["downloadLink"] =
                                            value[0]?.downloadLink;
                                    }
                                    return item;
                                });
                                setList(res);
                                // handleUpdate(
                                //     "fileId",
                                //     value[0]?.fileId,
                                //     record
                                // );
                            }
                        }}
                        // listType=""
                    ></UploadCom>
                );
            },
        },
        {
            title: "操作",
            render: (record: any) => {
                return (
                    !disabled && (
                        <Button
                            type="link"
                            onClick={() => handleDelete(record)}
                        >
                            删除
                        </Button>
                    )
                );
            },
        },
    ];
    return (
        <div style={{ width: "730px" }}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "20px",
                }}
            >
                <Button
                    disabled={btnStatus}
                    type="primary"
                    onClick={handleCreate}
                >
                    新增图片上传
                </Button>
            </div>
            <Table
                rowKey={(record) => record?.id || record?.fileId}
                columns={columns}
                dataSource={list}
                scroll={{ x: 700 }}
                pagination={false}
                bordered
            ></Table>
        </div>
    );
};

export default RenderNuclearTable;
