import React, { useEffect } from 'react'
import BreadCrumbBar from '../../../../../components/BreadCrumbBar'
import { Button, Checkbox, Select, Table, Upload, UploadProps, message } from 'antd';
import './add.scss'
import { UploadOutlined } from '@ant-design/icons';
import { useSetState } from 'ahooks';
import { useNavigate } from 'react-router-dom';
import { axiosRequrest } from '@src/services/axiosRequest';
import VagueSelect from './select';

interface AddState {
    columnsData: any[]
    isCheckXieYi: boolean
    individualBusinessId: string
    signFlowFileId: string
    fileName: string
    isApplyLoading: boolean
}

const breads = [
    {
        label: "代办业务",
        path: "/business",
    },
    {
        label: "工商税务开办",
        path: "/business/administration",
    },
    {
        label: "个体户注销申请",
        path: "",
    },
];
const LogoutApply = () => {
    const nav = useNavigate()
    const columns = [{
        title: '个体户名称',
        dataIndex: 'enterpriseName',
    }, {
        title: '法人姓名',
        dataIndex: 'legal',
    }, {
        title: '联系电话',
        dataIndex: 'legalPhone',
    }, {
        title: '所属税区',
        dataIndex: 'taxRegionName',
    },]
    let [state, setState] = useSetState<AddState>({
        columnsData: [],
        isCheckXieYi: false,
        individualBusinessId: '',
        fileName: '',
        signFlowFileId: '',
        isApplyLoading: false,
    })
    const fileChange = (ev: any) => {
        let file = ev.target.files[0];
        console.log(file, '5456545')
        const params = new FormData();
        params.append('file', file);
        axiosRequrest.put<{ filePath: string }>(`/sinzetech-resource/oss/endpoint`, params, {
            params: {
                contentType: file.type,
                rename: file.name,
            }
        }).then(res => {
            setState({ signFlowFileId: res.filePath, fileName: file.name })
        })
    }
    const submit = () => {
        if (!state.isCheckXieYi) {
            return message.error('请同意个体户注销条件')
        }
        if (!state.columnsData.length) {
            return message.error('请选择注销的个体户')
        }
        setState({ isApplyLoading: true })
        axiosRequrest.post(`/sinzetech-agency-processing/individual-business-deregister`, {
            individualBusinessId: state.individualBusinessId,
            signFlowFileId: state.signFlowFileId,
            // clerkUserId: JSON.parse(
            //     localStorage.getItem("operation_authorization") || ""
            //     )?.userId,
            // clerkUserName: JSON.parse(
            //     localStorage.getItem("operation_authorization") || ""
            //     )?.userName,
        }).then(res => {
            setState({ isApplyLoading: false })
            message.success('操作成功')
            nav(-1)
        }).catch(() => setState({ isApplyLoading: false }))
    }
    return (
        <div className='public_list_page logout_apply'>
            <BreadCrumbBar breads={breads}></BreadCrumbBar>
            <div className="apply_content">
                <h3 className='title'>选择注销的个体户</h3>
                <div className='search'>
                    <span>搜索个体户：</span>
                    <VagueSelect
                        style={{ width: 200 }}
                        url='/sinzetech-agency-processing/individual-business'
                        value={state.individualBusinessId || '请搜索'}
                        keywords='keyWord'
                        onSelect={(value, { item }: any) => {
                            setState({ individualBusinessId: value, columnsData: [item] })
                        }}
                    />
                </div>
                <Table
                    className='table'
                    columns={columns}
                    dataSource={state.columnsData}
                    pagination={false}
                    rowKey={(item: any) => item.id}
                />
                <h3 className='title'>注销信息补充 <span className='tip' style={{}}>非必填,若已完成线下签署,可在此上传注销申请书</span></h3>
                <div className='upload_file'>
                    <span>上传注销申请书：</span>
                    <div className='file_box'>
                        <input
                            accept='.doc,.docx,.pdf,.jpg,.png,.jpeg'
                            className='upload'
                            type='file'
                            style={{ opacity: 0 }}
                            onChange={fileChange}
                        />
                        <Button icon={<UploadOutlined />}>选择文件</Button>
                    </div>
                    <div style={{ marginLeft: 10 }}>{state.fileName}</div>
                </div>
                <div className='upload_tip'>
                    <div className='tip2'>支持扩展名: .doc, .docx,.pdf，.jpg，.jpeg,.png格式</div>
                </div>
                <div className='xieyi'>
                    <Checkbox
                        className='checkbox'
                        checked={state.isCheckXieYi}
                        onChange={(ev) => {
                            setState({ isCheckXieYi: ev.target.checked })
                        }}
                    />发起申请前，请仔细阅读《<span style={{ cursor: 'pointer' }} onClick={() => {
                        window.open('https://doc.weixin.qq.com/doc/w3_AaEAPgZ3ADkoYkVAGeXSI6R1j7p9n?scode=ABYAQwciABE2EDV4rNAf8A8ga_ADM')
                    }}>个体户注销条件</span>》
                </div>
                <div className='btn_box'>
                    <Button
                        style={{ marginRight: 20 }}
                        onClick={() => {
                            nav(-1)
                        }}
                    >取消</Button>
                    <Button
                        type='primary'
                        loading={state.isApplyLoading}
                        onClick={() => {
                            submit()
                        }}
                    >发起注销申请</Button>
                </div>
            </div>
        </div>
    )
}

export default LogoutApply