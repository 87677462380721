import React, { PropsWithChildren } from 'react'
import { Col, Progress, Row, Spin } from 'antd'
import {RenderUploadUIProps} from './index'

export const UploadImageTypeUI:React.FC<PropsWithChildren<RenderUploadUIProps>> = (props)=>{
  props.currentFile&&props.currentFile.size>1024
  return (props.currentFile&&props.currentFile.size/1024/1024>5
  ? <Row style={{width:'80%'}}>
    <Col span={24}>{props.children}</Col>
    <Col span={24}>
    {props.percent?<Progress status={'active'} percent={props.percent} showInfo={false}/>:''}
    </Col>
  </Row>:<Spin spinning={props.loading}>{props.children}</Spin>)
  
}