import React,{memo} from 'react'
import { getPricesByGroupId } from '../../service'
import { useTable } from '@src/hooks/useTable'
import { Table } from 'antd'
import { ColumnType } from 'antd/es/table'
import { useParams } from 'react-router-dom'
import { GOODS } from '@src/constant/goods'
import dayjs from 'dayjs'
import { TableToolBar } from '@src/components/TableToolBar'
const defaultProps = {}
type props = {}
export type PriceManagementProps = Required<typeof defaultProps> & props
export const PriceManagement:React.FC<PriceManagementProps> = memo((props)=>{
  
  const routerParams = useParams()
  const columns:ColumnType<API.Price.Item>[] = [
    {title:'序号',dataIndex:'id', render: (_, _1, index) => index + 1 },
    {title:'货品名称',dataIndex:'goodsName'},
    {title:'客户',dataIndex:'entName'},
    {title:'收货地',dataIndex:'addressName'},
    {title:'价格（元）',dataIndex:'price'},
    {title:'单位',dataIndex:'goodsUnit',render:(text)=>{
      return <>{GOODS.unitOfMeasure[text]||text}</>
    }},
    {title:'最后更新时间',dataIndex:'lastModityTime',render:(date)=>{
      return date?dayjs(date).format('YYYY/MM/DD HH:mm'):''
    }},
  ]
  const tableService = useTable(
    async (params) => {
      const { current, size,createDate, ...rest } = params;
      const res = await getPricesByGroupId({
        id:routerParams.id,
        ...rest,
        pageSize: size,
        pageNumber: current,
        withTotal: true,
        "sorts[0].direction":'DESCENDING',
        "sorts[0].field":'modifyTime',
      });
      return {
        data: res.list,
        total: Number(res.total),
      };
    },
    { defaultCurrent: 1, defaultSize: 20 }
  );
  return <>
        <TableToolBar
        {...tableService.getToolBarProps?.({
          search: {
            placeholder: "输入货品名称，客户名称，送货地搜索",
          },
          // title: "价格列表",
        })}
      />
  <Table
  rowKey={"id"}
  pagination={tableService.getPaginationProps?.({
    className: "page_num",
  })}
  {...tableService.getTableProps({ className: "public_table" ,scroll:{y:'34vh'}})}
  columns={columns}
/></>
})
PriceManagement.defaultProps = defaultProps