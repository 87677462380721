/*
 * @Description: 区分（预览pdf格式）
 * @Author: 韩玉光
 * @Date: 2023-8-16 
 */
import { useEffect, useState } from "react";
import { Image } from "antd";
import { CloseOutlined } from '@ant-design/icons';

import styles from "../../index.module.scss";
import { Page, Document, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface PdfProps {
  previewPdf: boolean;
  visible: boolean;
  openImgURL: string;
  setVisible: any,
  setPreviewPdfFlag: any,
  previewPdfFlag: boolean;
}

const CompatiblePdf = (props: PdfProps) => {
  const {
    previewPdf,//记录是否是pdf
    visible,
    openImgURL,//预览url
    previewPdfFlag,//pdf控制展示 隐藏
    setVisible,//父组件setstate方法 （Imgage受控）
    setPreviewPdfFlag//父组件setstate方法 （pdf受控）
  } = props
  const [numPages, setNumPages] = useState<number>(1);
  return (
    <div >
      {!previewPdf ?
        <Image
          width={200}
          style={{ display: "none" }}
          preview={{
            forceRender: true,
            visible,
            src: openImgURL,
            onVisibleChange: (value) => {
              setVisible(value);
            },
          }}
        />
        :
        <div
          className={previewPdfFlag ? styles.Document_preview_transform : ""}
        >
          <div className={styles.Document_preview_mask}
            onClick={() => {
              setPreviewPdfFlag(true)
            }}
          >
          </div>
          <CloseOutlined className={styles.Document_close_outlined} onClick={() => {
            setPreviewPdfFlag(true)
          }} />
          <Document
            file={openImgURL}
            className={`${styles.Document_pdf} ${previewPdfFlag ? styles.Document_pdf_out : ''}`}
            renderMode="canvas"
            onLoadSuccess={(e: any) => {
              setNumPages(e.numPages);
            }}
            options={{
              // 解决电子发票部分样式 无法识别
              cMapUrl: `https://cdn.jsdelivr.net/npm/pdfjs-dist@2.16.105/cmaps/`,
              cMapPacked: true,
              disableWorker: true,
            }}
          >
            {new Array(numPages).fill("").map((item, index) => {
              return (
                <Page
                  key={index}
                  pageNumber={index + 1}
                  width={700}
                  renderMode="canvas"
                  renderInteractiveForms={true}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                />
              );
            })}
          </Document>
        </div>
      }
    </div>
  );
};

export default CompatiblePdf;
