import React, { useEffect, useState, useMemo } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Logo from "../../assets/logo.png";
import { FUNCTION_CODE, ROLE_CODE } from "../../store/constants";
import { useDispatch, useSelector } from "react-redux";
import "./index.scss";
type type_props = {
  config: {
    value: string;
  };
};
const nav_list = [
  {
    label: "销售管理",
    path: "/customer/management",
    value: "customer",
    code: [
      "crm_customer_list_list",
      "crm_customer_list_add",
      "crm_customer_list_get",
      "crm_customer_list_edit",
    ],
  },
  {
    label: '交易中心',
    path: '/entity/transaction/enterprise',
    value: 'entity',
  },
  {
    label: "存证平台",
    path: "/evidence/account/management",
    value: "evidence",
  },
  {
    label: "业务代办",
    path: "/business",
    value: "business",
  },
  {
    label: "货主服务",
    path: "/consignor/recover/list",
    value: "consignor",
  },
  {
    label: "组织管理",
    path: "/system",
    value: "system",
  },
  // {
  //   label: "税票系统",
  //   path: "/ticket/openTask",
  //   value: "ticket",
  // },
  {
    label: "供应链金融",
    path: "/supply/financingOrders",
    value: "supply",
  },
];
const TopNavbar: React.FC<type_props> = ({ config }) => {
  const nav = useNavigate();
  const location = useLocation();
  const roles: any = useSelector((state: any) => state.codeList);
  const dispatch = useDispatch<any>();
  const locationObject =
    localStorage.getItem("operation_authorization") || "{}";
  const navigate = useNavigate();
  useEffect(() => {
    if (
      !JSON.parse(locationObject).access_token ||
      JSON.parse(locationObject).access_token === undefined
    ) {
      // navigate('/login')
    } else {
      dispatch({
        type: ROLE_CODE,
        payload: {
          codeList: JSON.parse(locationObject)?.functionCode.split(","),
          roleCodes: JSON.parse(locationObject)?.roleCodes.split(","),
        },
      });
    }
  }, [locationObject]);
  // const [list, setList] = useState<any>([]);
  const getList = useMemo(() => {
    const res = nav_list.filter((item: any) => {
      if (item?.code && item?.code.length !== 0) {
        const val = item.code.filter((cItem: any) => {
          return roles.some((sItem: any) => sItem === cItem);
        });
        return val.length !== 0;
      } else {
        return true;
      }
    });
    return res;
  }, [roles]);
  // useEffect(() => {
  //     const res = nav_list.filter((item: any) => {
  //         if (item?.code && item?.code.length !== 0) {
  //             const val = item.code.filter((cItem: any) => {
  //                 return roles.some((sItem: any) => sItem === cItem);
  //             });
  //             return val.length !== 0;
  //         } else {
  //             return true;
  //         }
  //     });
  //     setList(res);
  // }, []);
  useEffect(() => {
    const endIndex = location.pathname.lastIndexOf("/");
    const str = location.pathname.substring(0, endIndex + 1);
    if ("/business/ticket/InvoicingTask/info/" !== str) {
      localStorage.removeItem("cacheInformation");
      localStorage.removeItem("invoces");
    }
  }, [location]);
  return (
    <header
      className="top-navbar"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <div style={{ display: "flex" }}>
        <div className="logo">
          <img src={Logo} alt="logo" />
          <p>运营中心</p>
        </div>
        <div className="nav_list_content">
        {getList &&
          getList.map((item: any) => {
            return (
              <div
                className={`navbar-link ${
                  config?.value === item.value ? "active" : null
                }`}
              >
                <Link to={item.path}>{item.label}</Link>
              </div>
            );
          })}

        </div>
        
      </div>
      {/* <div className={`navbar-link ${config.value === 'evidence' ? 'active' : null}`}>
                <Link to="/evidence/account/management">存证平台</Link>
            </div>
            <div className={`navbar-link ${config.value === 'system' ? 'active' : null}`}>
                <Link to="/system">系统设置</Link>
            </div> */}
      {/* 用户信息 */}
      <div className="user_info">
        <img src={Logo} alt="logo" className="user_logo" />
        <span className="user_name">
          {JSON.parse(locationObject)?.userName}
        </span>
        <div className="user_children">
          <div style={{ height: 10 }}></div>
          <div
            className="user_children_item"
            onClick={() => {
              localStorage.clear();
              nav("/login");
            }}
          >
            退出登录
          </div>
        </div>
      </div>
    </header>
  );
};

export default TopNavbar;
