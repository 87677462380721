import React, {useEffect, useState} from "react";
import TableCom from '../../../../../../../components/TableCom'
import ModalCom from '../../../../../../../components/ModalCom'
import { Button, Form, Input, message } from "antd";
import {createFromIconfontCN} from '@ant-design/icons'
import {customerContactList, customerContactInfo, deleteCustomerContact, editCustomerContact, addCustomerContact} from '../../../../../promise'
// 联系人
const layout={
  labelCol: { span: 5 },
  wrapperCol: { span: 16 },
}
const MyIcon = createFromIconfontCN({
  scriptUrl:'https://at.alicdn.com/t/c/font_3981257_011re57qeh14p.js?spm=a313x.7781069.1998910419.53&file=font_3981257_011re57qeh14p.js'
})
const Contacts = (props?:any) => {
  const {details, isShowEdit} = props
  const [form]= Form.useForm()
  const [pageOptions, setPageOptions] = useState({
    current:1,
    pageSize:10
  })
  const [total, setTotal] = useState(0)
  const [list,setList] = useState([])
  const [open, setOpen] = useState(false)
  const [openTitle, setOpenTitle] = useState('新增联系人')
  // 保存当前编辑联系人对象参数
  const [currentObject, setCurrentObject] = useState<any>({})
  const columns =[
    {
      title:'部门',
      dataIndex:'dept',
    },
    {
      title:'职位',
      dataIndex:'position',
    },

    {
      title:'姓名',
      dataIndex:'name',
    },
    {
      title:'电话',
      dataIndex:'phone',
    },
    {
      title:'邮箱',
      dataIndex:'email',
    },
    {
      title:'添加时间',
      dataIndex:'createTime',
    },
    {
      title:'操作',
      render:(record:any) => {
        return  isShowEdit ?
        <Button type="link" onClick={() => {
          setOpen(true)
          form.setFieldsValue(record)
          setOpenTitle('编辑联系人')
          setCurrentObject(record)
        }}>编辑</Button> : null
      }
    },
  ]
  const getList = (object?:any) => {
    const params ={
      customerId: details?.id,
      current: pageOptions.current,
      size: pageOptions.pageSize,
      ...object
    }
    console.log(params, 'params-list')
    if(object?.current || object?.size){
      setPageOptions({
        current:object?.current,
        pageSize:object?.size
      })
    }
    customerContactList(params)
    .then((res:any) => {
      if(res.code === 200){
        setList(res.data.records)
        setTotal(res.data.total)
      }
    })
  }
  const onchange = (params:any) => {
    getList({
      current:params.current,
      size:params.pageSize
    })
  }
  const handleOk = () => {
    
    form.validateFields()
    .then((res:any) => {
      if(currentObject.id){
        // 编辑
        const params ={
          ...res
        }
        editCustomerContact(currentObject.id, params)
        .then((res:any) =>{
          if(res.code === 200){
            message.success('编辑成功')
            setCurrentObject({})
            setOpen(false)
            getList({
              customerId: details?.id,
              current:pageOptions.current,
              size: pageOptions.pageSize
            })
          }else{
            message.error(res.message || "编辑异常")
          }
        })
      }else{
        // 新增
        const params ={
          customerId: details.id,
          ...res
        }
        addCustomerContact(params)
        .then((res:any) => {
          if(res.code === 200){
            message.success('新建成功')
            setOpen(false)
            getList({
              customerId: details?.id,
              current:pageOptions.current,
              size: pageOptions.pageSize
            })
          }else{
            message.error(res.message || '新建异常')
          }
        })
      }
      console.log(res,'any-submit')
    })
  }
  const handleDelete = () => {
    // 删除该数据
    if(!currentObject.id || currentObject.id === ''){
      message.error('当前参数为空，不能执行删除操作')
      return
    }
    deleteCustomerContact(currentObject.id)
    .then((res:any) => {
      console.log(res, 'rrrrrrrrrrrrr')
      if(res.code === 200){
        message.success('删除成功')
        setOpen(false)
        setCurrentObject({})
        getList({
          customerId: details?.id,
          current:pageOptions.current,
          size: pageOptions.pageSize
        })
      }else{
        message.error(res.message || '删除失败')
      }
    })
  }
  useEffect(() => {
    if(details?.id){
      getList(
        {
          customerId: details?.id,
        }
      )
    }
    
  }, [details])
  return (
    <div>
      {
        isShowEdit ?<div style={{display:'flex', justifyContent:'flex-end'}}>
        <Button type="primary" onClick={() => {
          setOpen(true)
          setOpenTitle('新增联系人')
          setCurrentObject({})
          form.resetFields()
        }}>新增联系人</Button>
      </div> : null
      }
      
      <div>
        <TableCom 
          rowKey={(record:any) => record.id}
          columns={columns} dataSource={list} pagination={
            {
              ...pageOptions,
              total:total
            }
          }
          onChange={onchange}
          scroll={{
            // y:300
          }}
          // style={{height:'30vh', overflow:'auto'}}
        ></TableCom>
      </div>
      {
        open && <ModalCom open={open} title={openTitle} onCancel={() => {
          setOpen(false)
          setCurrentObject({})
          form.resetFields()

        }}
        onOk={() =>handleOk()}
        okText='保存'
        >
          <div>
            {
              currentObject.id ? <div>
              <MyIcon type="icon-shanchu" style={{display:'flex', justifyContent:'flex-end', fontSize:'22px'}} onClick={() => handleDelete()}></MyIcon>
            </div> : null
            }
            <Form {...layout} form={form}>
              <Form.Item label='姓名' name='name' rules={
                [
                  {
                    required:true, message:'请输入姓名'
                  }
                ]
              }>
                <Input placeholder="请输入" maxLength={10}></Input>
              </Form.Item>
              <Form.Item label='手机号' name='phone' 
            //   rules={
            //     [
            //       {
            //         required:true, message:'请输入手机号'
            //       },
            //       {pattern:/^1[0-9]{10}/, message:'请输入正确的手机号'}
            //     ]
            //   }
              >
                <Input placeholder="请输入" maxLength={11}></Input>
              </Form.Item>
              <Form.Item label='邮箱' name='email'>
                <Input placeholder="请输入"></Input>
              </Form.Item>
              <Form.Item label='部门' name='dept'>
                <Input placeholder="请输入"></Input>
              </Form.Item>
              <Form.Item label='职位' name='position'>
                <Input placeholder="请输入"></Input>
              </Form.Item>
            </Form>
          </div>
        </ModalCom>
      }
      
    </div>
  )
}

export default Contacts