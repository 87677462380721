export const breads = [
  {
    label: "代办业务",
    path: "/business",
  },
  {
    label: "工商税务开办",
    path: "/business/administration",
  },
  {
    label: "工商税务开办",
    path: "/business/administration/list",
  },
  {
    label: "新增申请",
    path: "",
  },
];

/**
 * 创建代办个体户类型
 */

export const radioOption = [
  {
    label: "个体户办理",
    value: "INDIVIDUAL",
  },
  {
    label: "企业办理",
    value: "ENTERPRISE",
  },
];
/**
 * 关联公司描述
 */
export const connectCompanyDesc =
  "可输入集团简称，后续由业务人员进行实际绑定。邀请码为企业生成，如有需要请向该企业业务员索取";
export const politicsStatusOptions = [
  "群众",
  "中共党员",
  "中共预备党员",
  "共青团员",
  "民革党员",
  "民盟盟员",
  "民建会员",
  "民进会员",
  "农工党党员",
  "致公党党员",
  "九三学社社员",
  "台盟盟员",
  "无党派人士",
];

export const authKeys = [
  {
    label: "需要核名",
    value: 1,
  },
  {
    label: "不需要核名",
    value: 0,
  },
];
