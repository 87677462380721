import React,{memo, useEffect, useState} from 'react'
import { EntityForm, EntityFormData, EntityResponseData } from '../components/EntityForm'
import { customerRelatedEntityDetail, updateEntity } from '../promises'
import { useParams } from 'react-router-dom'
import { Spin, message } from 'antd'
const defaultProps = {}
type props = {}
export type EditEntityProps = Required<typeof defaultProps> & props
export const EditEntity:React.FC<EditEntityProps> = memo((props)=>{
  const params = useParams();
  const [entityInfo,setEntityInfo] = useState<EntityResponseData>()
  const [businessLicenseFileUrl,setBusinessLicenseFileUrl] = useState<string>()
  const [loading,setLoading] = useState(false)

  useEffect(()=>{
    setLoading(true)
    customerRelatedEntityDetail(params.id).then((res)=>{
      const data = res.data as EntityResponseData
      const {businessLicenseFile,contractFile,cooperateTaxDistrict,...rest} = data
      setBusinessLicenseFileUrl(data.businessLicenseFile.ossUrl)
      // @ts-ignore
      setEntityInfo({...rest,businessLicenseFileId:businessLicenseFile.id,contractFileId:contractFile?.map((file)=>file.id),contractFile ,cooperateTaxDistrict:cooperateTaxDistrict.split(',')})
    }).finally(()=>{
      setLoading(false)
    })
  },[])
  return loading?<></>:<EntityForm businessLicenseFileUrl={businessLicenseFileUrl} initialValues={entityInfo} onFinish={async (values)=>{
   await updateEntity({...values,id:params.id,customerId: params.customerId}).catch((e)=>{
    throw e
   })
   message.success("编辑成功")
   history.back()
  }}></EntityForm>
})
EditEntity.defaultProps = defaultProps