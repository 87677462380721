import React ,{useEffect, useState} from 'react';
import {Button, Steps, Input, Col, Row,Space, Modal, message} from 'antd'
import BreadCrumbBar from '../../../../../../components/BreadCrumbBar'
import {PlusOutlined, DeleteOutlined} from '@ant-design/icons';
import { useNavigate, useLocation} from 'react-router-dom';
import {addTemplateName, getTemplateInfo, changeStatus, getInfos} from '../../Promise'
import './index.scss'

// import { any } from 'prop-types';

const {TextArea} = Input;
// const {Step} = Steps;

const breadsList = [
    {
        label: '存证平台',
        path: '/evidence'
    },
    {
        path: '/evidence/platform',
        label: '平台配置'
    },
    {
        path: '/evidence/platform/template',
        label: '存证场景模板'
    },
    {
        path: '',
        label: '模板详情'
    }
]

const RenderConfirm = (props:any) => {
    const {title, description, onConfirm, onCancel, okText, open} = props
    return <Modal  title={title} open={open} cancelText='取消' okText={okText} onCancel={onCancel} onOk={onConfirm}>
        {description}
    </Modal>    
}
const AddTemplate = () => {
    const location = useLocation()
    const isManageInfo = location?.state?.isConnection
    // 是否为模板新增
    const isTemplateAdd = location?.state?.type === 'add'? true : false
    const [messageApi, contextHolder] = message.useMessage();
    const [detail, setDetail] = useState<any>({})
    const navigate = useNavigate()
    const [isEdit, setIsEdit] = useState(location?.state?.info)
    const [open, setOpen] = useState<boolean>(false)
    // const [form, setForm] = useState<any>({
    //     templateName:'',
    //     linkList:[]
    // })
    const [secureEvidenceTemplateDescription, setSecureEvidenceTemplateDescription] = useState('')
    const [isHandleEdit, setIsHandleEdit] = useState(location?.state?.type)
    // const [templateID, setTemplateID] = useState<string|number|undefined>('')
    const [current, setCurrent] = useState(location?.state?.current || 0)
    const getInfo = (id:any) => {
        // 获取客户-详情-村正模板详情
        if(!id || id === '') return 
        getTemplateInfo(id)
        .then((res:any) => {
            if(res?.successful){
                let arr:any =[]
                res?.data?.linkEntities.forEach((item:any, index:number) => {
                    let obj ={
                        title:`阶段${index+1}`,
                        label:item.name,
                        index:index+1, 
                        id: item.id
                    }
                    arr.push(obj)
                })
                setSteps(arr)
                setDetail(res.data)
                setSecureEvidenceTemplateDescription(res.data.secureEvidenceSceneTemplateDescription)
            }else{
                messageApi.open({
                    type:'error',
                    content: res?.message || '获取模板数据异常'
                })
            }
        })
    }
    const getTemInfo = (id?:any) => {
        // 获取存证模板详情
        if(!id || id === '') return 
        
        getInfos(id)
        .then((res:any) => {
            if(res.successful){
                console.log(res, '/===')
                let arr:any =[]
                res?.data?.linkList.forEach((item:any, index:number) => {
                    let obj ={
                        title:`阶段${index+1}`,
                        label:item.name,
                        index:index+1, 
                        id: item.id
                    }
                    arr.push(obj)
                })
                setSteps(arr)
                setDetail(res.data)
                // form.setFieldValue('templateName',)
                setSecureEvidenceTemplateDescription(res.data.secureEvidenceTemplateDescription)
            }else{
                messageApi.open({
                    type:'error',
                    content: res?.message || '获取模板数据异常'
                })
            }
        })
    }
    useEffect(() => {
        console.log(location, 'location')
        if(location?.state?.isConnection){
          getInfo(location?.state?.id)  
        }else{
            getTemInfo(location?.state?.id)
        }
        
    }, [location])

    // const [currentStep] = useState(0);
    const [steps, setSteps] = useState([
        {title: '阶段 1', label: '',index: 1, id: ''},
    ]);
    const handleAddStep = () => {
        setSteps([...steps, {title: `阶段 ${steps.length + 1}`, label: ``, index: steps.length + 1, id:''}]);
    };

    const handleDeleteStep = () => {
        setSteps(steps.slice(0, -1));
    };

    const handleInputChange = (index: any, value: any) => {
        const newSteps = [...steps];
        newSteps[index].label = value;
        setSteps(newSteps);
    };
    const handleNext = () => {
        if(current === 0){
            if(!secureEvidenceTemplateDescription || secureEvidenceTemplateDescription?.trim() === ''){
                messageApi.open({
                    type:'error',
                    content:'请输入模板名称'
                })
                return 
            }
            var arr:any = []
            const index= steps.findIndex((item:any) => item.label === '' || item.label.trim() === '')
            if(index >= 0){
                messageApi.open({
                    type:'error',
                    content:`请输入环节${index+1}内容,并且不能为空`
                })
                return 
            }
            steps.forEach((item:any,index:number) => {
                let obj ={
                    name: item.label,
                    sort: item.index,
                    id: item.id
                }
                arr.push(obj)
            })
            
            const params ={ 
                templateName: secureEvidenceTemplateDescription,
                linkList: arr,
                templateId: detail?.secureEvidenceTemplateId || ''
            }
            console.log(steps,params, 'sss')
            // console.log(params, 'pppppppppppp')
            addTemplateName(params)
            .then((res:any) => {
                if(res?.successful){
                    const value = current+1
                    setCurrent(value)
                    getTemInfo(res?.data)
                }else{
                    messageApi.open({
                        type:'error',
                        content:res.message||'操作模板失败'
                    })
                }
            })
        }else{
           const value = current+1
           if(value === 3){
            navigate('/evidence/platform/template')
           }
           setCurrent(value)  
        }
        
    }
    const handlePre = () => {
        if(current !== 0){
            const value = current-1
            setCurrent(value)
        }
    }

    // 存证确认框
     const onConfirm = () => {
        changeStatus(location?.state?.id, location?.state?.connectionStatus ? 0 : 1 )
        .then((res:any) => {
            if(res?.successful){
                navigate(-1)
            }else{
                messageApi.open({
                    type:'error',
                    content:res.message||'操作失败'
                })
            }
        })

     }

    return (
        <div className='container'>
            {contextHolder}
            <div className='content'>
                <BreadCrumbBar breads={breadsList}></BreadCrumbBar>
                <div className='create_button'>

                    {
                        location?.state?.isConnection ? 
                            <Button type='primary' style={{marginRight:'10px'}} onClick={() => {
                                setOpen(true)
                            }}>
                                {
                                    location?.state.connectionStatus ? '停用该方案': '启用该方案'
                                }
                            </Button>: null
                    }

                    {/* <Link to=''> */}
                    {
                        location?.state?.type === 'manage'? <Button type='primary' onClick={() => {
                            navigate(`/evidence/account/management/info/add_evidence`, {
                                state:{
                                    customerId: location?.state?.customerId,
                                    id: location?.state?.id
                                    // name:details?.name
                                }
                              })  
                        }}>编辑</Button>:(location?.state?.isHandleEdit ==='edit' || !isTemplateAdd) && location?.state?.id ?<Button type='primary' onClick={() => {
                            // 如果不是客户详情 编辑则直接进行模板字段的操作
                            if(!isManageInfo){
                               setIsEdit(false) 
                               setCurrent(0)
                               setIsHandleEdit('add')
                            }else{
                                // 进入客户管理-模板新增界面
                                navigate(`/evidence/account/management/info/add_evidence`, {
                                    state:{
                                        customerId: location?.state?.customerId,
                                        id: location?.state?.id
                                        // name:details?.name
                                    }
                                  })  
                            }
                            
                        }}>编辑</Button> : null
                    }
                </div>
                {
                    !isEdit ? <div className='config_step'>
                    <Steps
                        size="small"
                        current={current}
                        items={[
                            {
                                title: '配置存证阶段',
                                description: '按客户需求，配置存证阶段',
                            },
                            {
                                title: '配置存证字段',
                                description: '按客户需求，配置存证字段'
                            },
                            {
                                title: '配置关联关系',
                                description: '按客户需求，配置阶段关联关系'
                            },
                        ]}
                    />
                </div> : null
                }
                
                <div className="template_description_input">
                    <Row wrap={false}>
                        <Col flex="none">
                            <div style={{padding: '5px 16px'}}>存证场景:</div>
                        </Col>
                        <Col flex="auto">
                            <TextArea
                                disabled={current !== 0}
                                placeholder="请填写清楚存证场景，便于后续匹配相关存证场景。尽量包含:使用的企业名称，适用行业，适用场景等信息"
                                autoSize={{minRows: 3, maxRows: 5}}
                                onChange={e => {
                                    // setForm({
                                    //     ...form,
                                    //     templateName:e.target.value
                                    // })
                                    setSecureEvidenceTemplateDescription(e.target.value)
                                }} 
                                value={secureEvidenceTemplateDescription}
                            />
                        </Col>
                    </Row>
                </div>
                

                <div className="template_steps_content">
                    {/* <Steps current={currentStep} className="steps"  progressDot={customDot} items={steps}> */}
                        {/* {steps.map((step:any, index) => (
                            <Step key={step.title} title={step.title} className="step_description" description={
                                <div key={step.title}>
                                    <div>
                                        <span>阶段名称:</span>
                                        <Input
                                            placeholder={`请输入阶段名称`}
                                            value={step.label}
                                            onChange={(e) => handleInputChange(index, e.target.value)}
                                            style={{marginBottom: 8, width:'180px'}}
                                            disabled={current !== 0}
                                        />
                                    </div>
                                    {
                                        current === 1 && <Button type='link' onClick={() => {
                                            navigate('/evidence/platform/field/list',{
                                                state:{
                                                    id: detail?.secureEvidenceTemplateId,
                                                    templateName:step?.label, 
                                                    secureEvidenceTemplateLinkId: step?.id
                                                }
                                            })
                                        }}>
                                        选择阶段字段
                                    </Button>
                                    }
                                    
                                </div>
                            }/>
                        ))} */}
                    {/* </Steps> */}

                    <div style={{display:'flex',flexWrap:'wrap'}}>
                    {steps.map((step:any, index) => (
                        <div key={step.title} className="step_descriptions">
                            <div className='step_index'>
                                <span className='step_label'>{`${index+1}`}</span>
                                <span className={`${index !== steps.length-1 ? 'step_line':''}`}></span>
                            </div>
                            <div>
                                <span>阶段名称:</span>
                                <Input
                                    placeholder={`请输入阶段名称`}
                                    value={step.label}
                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                    style={{marginBottom: 8, width:'180px'}}
                                    disabled={current !== 0}
                                />
                            </div>
                            {
                                current === 1 && <Button type='link' onClick={() => {
                                    console.log(step, steps,'ssssss')
                                    navigate('/evidence/platform/field/list',{
                                        state:{
                                            id: detail?.secureEvidenceTemplateId,
                                            templateName:step?.label, 
                                            secureEvidenceTemplateLinkId: step?.id, 
                                            isConnection: location?.state?.isConnection?  location?.state?.isConnection : location?.state?.id ,
                                            type:isHandleEdit,
                                            info:location?.state?.info,
                                            isHandleEdit : location?.state?.type
                                        }
                                    })
                                }}>
                                {
                                    location?.state?.type === 'manage' ? '查看字段': isHandleEdit === 'add' ? '选择字段':'查看字段'
                                }
                                
                            </Button>
                            }
                            
                        </div>
                        ))}
                    </div>
                </div>
                {
                        current === 0 ?<div style={{marginTop: 16}}>
                        <Button type="dashed" icon={<PlusOutlined/>} onClick={handleAddStep}>
                            添加步骤
                        </Button>
                        {steps.length > 1 && (
                            <Button style={{marginLeft: 8}} icon={<DeleteOutlined/>} onClick={handleDeleteStep}>
                                删除步骤
                            </Button>
                        )}
                    </div>: null
                    }
                {
                    !isEdit? <Row justify="end" align="middle" style={{ position: 'fixed', bottom: "5%", right: "5%" }}>
                    <Col>
                        <Space>
                            <Button onClick={() => handlePre()}>上一步</Button>
                            <Button type="primary" onClick={() => handleNext()}>
                                {
                                    current !== 2 ?'下一步': '完成'
                                }
                                
                            </Button>
                        </Space>
                    </Col>
                </Row>:null
                }
                <RenderConfirm 
                    open={open}
                    okText={location?.state?.connectionStatus? '停用': '启用'}
                    title={`${location?.state?.connectionStatus? '停用': '启用'}存证链方案`}
                    description={
                        <div>
                            {
                                `${location?.state?.connectionStatus? '停用': '启用'}存证链方案`
                            } <br />
                            {
                                `${location?.state?.connectionStatus? '停用后用户无法发起该存证方案进行存证': '启用后用户可根据该存证方案进行存证'}` 
                            }
                        </div> 
                        
                    }
                    onCancel={() => {
                        setOpen(false)
                    }}
                    onConfirm={onConfirm}
                    >
                </RenderConfirm>
                {
                    location?.state?.isConnection?<div style={{display:'flex', justifyContent:'flex-end'}}>
                    <Button onClick={() => {
                        navigate(-1)
                    }}>返回上一级</Button>
                </div>: null
                }
                
            </div>
        </div>
    )
}
export default AddTemplate;