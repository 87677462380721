
import React, { useEffect, useState, useRef } from 'react';
import {Button, message} from 'antd'
// import FormItemCom from '../../../../../../components/FormItem'
import BreadCrumbBar from '../../../../../../components/BreadCrumbBar'
import {  useNavigate, useLocation } from 'react-router-dom';
import FeildTemplate from '../../../../../../components/FieldTemplate'
import { useDispatch, useSelector} from 'react-redux'
import {addFiled, getFiledInfo, getImageUrl} from '../../Promise'
import {RESET_FORM, INIT_FORM, UPDATE_BASIC} from '../../../../../../store/constants'
import './index.scss'
// import { object } from 'prop-types';

const breadsList =[
    {
        label:'存证平台',
        path: '/evidence'
    },
    {
        path: '/evidence/platform',
        label:'平台配置'
    },
    {
        path:'/evidence/platform/field',
        label:'存证字段维护'
    },
    {
      path:'',
      label:'新增字段'
    }
]

// const stepList =[
//   {
//     label: '选择字段类型',
//     line: 200
//   },{
//     label: '配置字段基本信息',
//     line: 200
//   },{
//     label: '配置字段限制条件',
//     line: 0
//   }
//   ]
const foundationList = [
  {
    label:'图片',
    ComponentType:'photo',
    step: 3,
    type: 'file',
    value:"PHOTO",
    typeValue:'FILE'
  },{
    label:'文件',
    ComponentType:'file',
    step: 3,
    type: 'file',
    value:"FILE",
    typeValue:'FILE'
  },{
    label:'视频',
    ComponentType:'video',
    step: 3,
    type: 'file'

  },{
    label:'音频',
    ComponentType:'audio',
    step: 3,
    type: 'file'

  },{
    label:'文本',
    ComponentType:'text',
    step: 3,
    type: 'text'

  },{
    label:'时间',
    ComponentType:'time',
    step: 3,
    type: 'date'

  },{
    label:'日期',
    ComponentType:'date',
    step: 3,
    type: 'date'

  },{
    label:'下拉选项',
    ComponentType:'select',
    step: 2,
    type: 'select'
  }
]
const templateList =[
  {
    label:'金额',
    ComponentType:'money',
    step: 3,
    type: 'number'
  },{
    label:'重量',
    ComponentType:'weight',
    step: 3,
    type: 'number'

  },{
    label:'手机号',
    ComponentType:'phone',
    step: 3,
    type: 'text'

  },{
    label:'身份证号',
    ComponentType:'id_num',
    step: 3,
    type: 'text'

  },{
    label:'车牌',
    ComponentType:'vehicle',
    step: 3,
    type: 'text'

  },{
    label:'邮箱',
    ComponentType:'email',
    step: 3,
    type: 'text'
  },{
    label:'坐标',
    ComponentType:'coordinate',
    step: 2,
    type: 'number'
  }
]

const CurrentStep = (props: any) => {
  const {index , title, children, height, desc, step=3} = props
  return <div className="step_container">
    <div className='title'>
      <span className='circle'>{index}</span>  <span className='title'>{title}</span> 
      {
        desc ? <span className='desc'>{desc}</span> : null
      }
      
    </div>
    <div className={`${index < step ? 'border_left_line':'border_default'}`} style={{minHeight: height}}>
      {children}
    </div>
  </div>
}

const FieldCom = (props: any) => {
  const {cb, infoData, isInfo} = props
  const [current, setCurrent] = useState<any>({})
  const changeModule = (item:any) => {
    setCurrent(item)
    cb && cb(item)
  }
  useEffect(() => {
    if(infoData.componentType &&infoData.componentType !== ''){
      const infoType = infoData.componentType.toLowerCase()
      const res = foundationList.find(item => item.ComponentType === infoType) || templateList.find(item => item.ComponentType === infoType)
      setCurrent(res)
    } 
  },[infoData])
  return <div className='field_com'>
    <div className='title'>基础组件</div>
    <div className='list'>
      {
        foundationList.map((item:any) => {
          return <div 
          key={item.ComponentType} 
          onClick={() => changeModule(item)}
          className={`default_module ${current.ComponentType === item.ComponentType ? 'select_module': ''} ${isInfo && 'not_allowed'}`}
          >{item.label}</div>
        })
      }
    </div>
    <div className='title'>
      模板
    </div>
    <div className='list_template'>
      {
        templateList.map((item:any) => {
          return <div key={item.ComponentType} onClick={() => changeModule(item)}
          className={`default_module ${current.ComponentType === item.ComponentType ? 'select_module': ''}  ${isInfo && 'not_allowed'}`}
          >{item.label}</div>
        })
      }
    </div>
    
  </div>
}
const AddFeild = () => {
  const location = useLocation()
  const isInfo = location?.state?.isInfo
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [selectComm,setSelectCom] = useState<{
    label?:string,
    ComponentType?:string,
    step?: number,
    type?:string
  }>({
    ComponentType:'',
    label:'',
    step: 3,
    type:''
  })

  const basicFormData = useSelector((state:any) => state.basicForm)
  const ruleFormData = useSelector((state:any) => state.ruleForm)
  const parentRef = useRef<any>()
  const basicRef = useRef<any>()
  const dispatch = useDispatch<any>()
  const [infoData, setInfoData] = useState<any>({})
  const [infoImage, setInfoImage] =useState<string|undefined>('')
  const getImage = (str:string|undefined) => {
    if(!str || str === '' || str === undefined) return
    const obj ={
      zoom:false,
      fileIds: str
    }
    getImageUrl(obj)
    .then((res:any) => {
      if(res.success){
        setInfoImage(res.data[0])
      }
    })
  }
  const getFileInfo = (id:string|undefined) => {
    if(id === undefined || id === '') return
    getFiledInfo(id)
    .then((res:any) => {
      if(res.successful){
        setInfoData(res.data)
        getImage(res.data.defaultValue?.fileInfos?.fileId)
        if(res.data.componentType &&res.data.componentType !== ''){
          const infoType = res.data.componentType.toLowerCase()
          const Coms = foundationList.find(item => item.ComponentType === infoType) || templateList.find(item => item.ComponentType === infoType) || {}
          setSelectCom(Coms)
          const object = res?.data
          const params ={
            basicForm:{
              name:object?.name ,
              accessType:object?.accessType,
              comment: object?.comment,
              textPrefixOption: object?.defaultValue?.textPrefixOption,
              textSuffixOption: object.defaultValue?.textSuffixOption,
              dataValue: object?.defaultValue?.dataValue,
              haveRequire: object?.rules?.haveRequire,
              requireTitle:object?.rules?.requireTitle,
              requireContent:object?.rules?.requireContent,
              fileInfos: object?.defaultValue?.fileInfos,
              regularExpression: object?.rules.regularExpression,
              isMultiple: object.rules?.isMultiple,
              optionData: object.rules?.optionData,
              placeholder: object?.placeholder,
            },
            ruleForm:{
              ...object?.rules
            }
          }
          // INIT_FORM
          dispatch({
            type: INIT_FORM, payload:params
          })
          dispatch({
            type:UPDATE_BASIC,payload:{
              name:object?.name ,
            }
          })
        } 
      }
    })
  }
  useEffect(() => {
    console.log(location, 'info')
    const id = location?.state?.id || ''
    getFileInfo(id)
  }, [location]) // eslint-disable-line
  const handleCallback = (object:any) => {
    setSelectCom(object)
    setInfoData({
      id: infoData.id,
      createTime: infoData.createTime,
      createUser: infoData.createUser,
      createUsername: infoData.createUsername,
      updateTime: infoData.updateTime,
      updateUser: infoData.updateUser,
      updateUsername: infoData.updateUsername
    })
    reset()
    basicRef.current.reset()
  }
  const reset = () => {
    basicRef.current.reset()
    dispatch({
      type:RESET_FORM,
      payload:{}
    })
  }

  const convertData = (comType:string|undefined) => {
    const com = selectComm.ComponentType?.toUpperCase()
    const type = selectComm.type?.toUpperCase()
    let par ={

    }
    if(infoData.id){
      par={
        isDeleted: infoData.isDeleted,
        status: infoData.status,
        createTime: infoData.createTime,
        createUser: infoData.createUser,
        createUsername: infoData.createUsername,
        updateTime: infoData.updateTime,
        updateUser: infoData.updateUser,
        updateUsername: infoData.updateUsername
      }
    }
    let data={
      componentType:com,
      type:type,
      name: basicFormData.name,
      id: infoData.id || undefined,
      ...par
    }
    console.log(ruleFormData, basicFormData, 'bianji')
    let obj = JSON.parse(JSON.stringify(data))
    switch(comType){
      case 'photo':
      case 'video':
      case 'audio':
      case 'file':
        data= {
          ...obj,
          accessType:basicFormData.accessType,
          comment: basicFormData.comment,
          defaultValue:{
            // dataValue: '',
            // fileInfos:[],

            fileInfos: basicFormData.fileInfos || []
          },
          rules:{
            ...ruleFormData,
            haveRequire: basicFormData.haveRequire,
            requireTitle:basicFormData.requireTitle,
            requireContent:basicFormData.requireContent,
          }
        }
        break;
      case 'text':
        data= {
          ...obj,
          accessType:basicFormData.accessType,
          comment: basicFormData.comment,
          placeholder: basicFormData.placeholder,
          defaultValue:{
            dataValue: basicFormData.dataValue || '',

            fileInfos: basicFormData.fileInfos
          },
          rules:{
            ...ruleFormData,
            textSuffixOption:{
              option: basicFormData.textSuffixOption?.option.filter((item:any) => item !== '')
            },
            textPrefixOption:{
              option: basicFormData.textPrefixOption.option.filter((item:any) => item !== ''),
            },
            limitInputWordQuantity:{
              maxValue:ruleFormData?.limitInputWordQuantity?.maxValue || infoData?.rules?.limitInputWordQuantity?.maxValue,
              minValue: ruleFormData?.limitInputWordQuantity?.minValue || infoData?.rules?.limitInputWordQuantity?.minValue,
            },
            haveRequire: basicFormData.haveRequire,
            requireTitle:basicFormData.requireTitle,
            requireContent:basicFormData.requireContent,
          }
        }
        break;
      case 'time':
      case 'date':
        console.log(basicFormData, ruleFormData,infoData, '////////////')
        data= {
          ...obj,
          accessType:basicFormData.accessType,
          comment: basicFormData.comment,
          placeholder: basicFormData.placeholder,
          defaultValue:{
            // ...ruleFormData,
            dataValue: basicFormData.dataValue || '',

            fileInfos: basicFormData.fileInfos
          },
          rules:{
            // ...
            ...ruleFormData,
            haveRequire: basicFormData.haveRequire,
            requireTitle:basicFormData.requireTitle,
            requireContent:basicFormData.requireContent,
          }
        }
        break
      case 'select':
        data= {
          ...obj,
          accessType:basicFormData.accessType,
          comment: basicFormData.comment,
          placeholder: basicFormData.placeholder,
          defaultValue:{
            dataValue: basicFormData.dataValue || [],

            fileInfos: basicFormData.fileInfos
          },
          rules:{
            ...ruleFormData,
            textSuffixOption:{
              option: basicFormData.textSuffixOption?.option.filter((item:any) => item !== '')
            },
            textPrefixOption:{
              option: basicFormData.textPrefixOption.option.filter((item:any) => item !== ''),
            },
            regularExpression: basicFormData.regularExpression,
            isMultiple: basicFormData.isMultiple,
            optionData: basicFormData.optionData,
            haveRequire: basicFormData.haveRequire,
            requireTitle:basicFormData.requireTitle,
            requireContent:basicFormData.requireContent,
            // limitInputWordQuantity:{
            //   maxValue:ruleFormData?.limitInputWordQuantity.maxValue || infoData?.rules?.limitInputWordQuantity?.maxValue,
            //   minValue: ruleFormData?.limitInputWordQuantity.minValue || infoData?.rules?.limitInputWordQuantity?.minValue,
            // }
          }
        }
        break;
      case 'money':
      case 'id_num':
      case 'weight':
      case 'phone':
      case 'vehicle':
      case 'email':
      // case 'coordinate':  
        data= {
          ...obj,
          accessType:basicFormData.accessType,
          comment: basicFormData.comment,
          placeholder: basicFormData.placeholder,
          defaultValue:{
            dataValue: basicFormData.dataValue || '',

            // fileInfos: basicFormData.fileInfos
          },
          rules:{
            ...ruleFormData,
            textSuffixOption:{
              option: basicFormData.textSuffixOption?.option.filter((item:any) => item !== '')
            },
            textPrefixOption:{
              option: basicFormData.textPrefixOption.option.filter((item:any) => item !== ''),
            },
            regularExpression: basicFormData.regularExpression,
            limitInputWordQuantity:{
              maxValue:ruleFormData?.limitInputWordQuantity?.maxValue || infoData?.rules?.limitInputWordQuantity?.maxValue,
              minValue: ruleFormData?.limitInputWordQuantity?.minValue || infoData?.rules?.limitInputWordQuantity?.minValue,
            },
            haveRequire: basicFormData.haveRequire,
            requireTitle:basicFormData.requireTitle,
            requireContent:basicFormData.requireContent,
          }
        }

        break;
        case 'coordinate':
          data= {
            ...obj,
            accessType:basicFormData.accessType,
            comment: basicFormData.comment,
            placeholder: basicFormData.placeholder,
            defaultValue:{
              dataValue:  '',

            },
          }
      // default
    }

    return data
  }
  const handleSumit = () => {
    if(!selectComm.ComponentType || selectComm.ComponentType === ''){
      messageApi.open({
        type:'error',
        content:'请选择组件或者模板'
      })
      return 
    }
    basicRef.current.formValidator()
    // console.log(selectComm, '////////')
    let str = selectComm.ComponentType
    let obj:any ={
      select:true,
      coordinate: true
    }
    if(str && !obj[str]){
      parentRef.current.formValidator()
    }
    if(!basicFormData.name || basicFormData.name === ''){
      return 
    }

    let data = convertData(selectComm.ComponentType)
    addFiled(data)
    .then((res:any) => {
      if(res.successful){
        messageApi.open({
          type:'success',
          content:'创建成功'
        })
        reset()
        navigate(-1)
      }else{
        messageApi.open({
          type:'error',
          content:res.message
        })
      }
    })
  }
  return <div className="container">
    {contextHolder}
    <div className='content'>
      <BreadCrumbBar breads={breadsList}></BreadCrumbBar>
      <div>
        <CurrentStep index={1} title='先择字段类型' height={200}>
          <FieldCom cb={handleCallback} infoData={infoData} isInfo={isInfo}></FieldCom>
        </CurrentStep>
        <CurrentStep index={2} title='配置字段基本信息' height={200} step={selectComm.step}>
          <FeildTemplate params={{infoType:'basicInfo', infoData:{...infoData, 
          infoImage:infoImage}, isInfo:isInfo}} type={selectComm.ComponentType} ref={basicRef}></FeildTemplate>
        </CurrentStep>
        {
          selectComm.step === 3 ? 
            <CurrentStep index={3} title='配置字段限制条件' desc='限制条件之间，为"且"的关系，各种限制条件必须同时满足即为合法输入'>
            <FeildTemplate params={{infoType:'condition', infoData:{...infoData, infoImage:infoImage}, isInfo:isInfo}} type={selectComm.ComponentType}  ref={parentRef} ></FeildTemplate>
          </CurrentStep>
          : ''
        }
        {
          !isInfo && <div style={{width: '100%', display:'flex',justifyContent:'flex-end'}}>
          <Button type="primary" onClick={handleSumit}>保存</Button>
        </div>
        }
        
      </div>
    </div>
  </div>
}

export default AddFeild