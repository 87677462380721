import loadsh from 'lodash'
export const objToArr = (object = {}, arrs?: any) => {
console.log(arrs, 'aaaaaaaaaaa')
let arr:any =[]
const num = arrs.length
const keys = Object.keys(object)
    for(let w= 0 ;w< num;w++){
        let newObj={}
        for(let i=0; i<keys.length; i++){
            let spl = keys[i].split('_')
            if(spl[0]+`_${arrs[w].id}` === keys[i]){
            newObj[spl[0]] = object[spl[0]+`_${arrs[w].id}`]
            arr[w] = newObj
            }
        }
    }
    return arr
}
// 数组转换为form

export const arrToObject = (arr?:any, key?:any) => {
    const data=arr
    console.log(data, 'data')
    let obj:any={}
    if(!data || data === undefined) return obj
    // if(!Array.isArray(data)) return obj
    for(let i =0;i<data.length;i++){
        for(let item in data[i]){
        if(key){
            if(key !== item){

            }
            obj[item+'_'+ data[i][key]] = data[i][item]
        }else{
            obj[item+'_'+i] = data[i][item]
        }
        }
    }
    return obj
}


// 是否需要核名数据
export const renderNuclears = [
    {
        label: '允许不核名',
        value: '0'
    },
    {
        label: '不允许不核名',
        value: '1'
    }
]

// 校验table内的某个字段的是否重复
export const valiatorValue = (key: string, list= []) => {
    let bool = false;
    if (Array.isArray(list) && list.length !== 0) {
        let res = loadsh.uniqBy(list, key)
        bool = res.length !== list.length
    }
    bool = list && list.some((item:any) => !item[key] || item[key] === '')
    return bool
}