import React, { useState, useEffect } from "react";
import "./index.scss";
import TableCom from "../../../components/TableCom";
import BreadCrumbBar from "../../../components/BreadCrumbBar";
import SearchForm from "../../../components/FormItem";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "antd";
import { getList, getUsersList } from "../promise";
import dayjs from "dayjs";
import {
    customerStatusList,
    companyTypeList,
    purchaseCategoryList,
    customerSourceList,
    importanceList,
} from "./config";
import { useSelector } from "react-redux";
import qs from "qs";
const breads = [
    {
        label: "销售管理",
        path: "/customer/management",
    },
    {
        label: "客户列表",
        path: "/customer/management",
    },
];

const importantMap = {
    1: "极高",
    2: "高",
    3: "中",
    4: "低",
    5: "极低",
};
// 客户管理
const CustomerManagement = () => {
    const navigate = useNavigate();
    const codeList = useSelector((state: any) => state.codeList) || [];
    const isShowAdd = codeList.includes("crm_customer_list_add");
    const isShowDetail = codeList.includes("crm_customer_list_get");
    const [pageOptions, setPageOptions] = useState<any>({
        current: 1,
        pageSize: 10,
    });
    const [total, setTotal] = useState(0);
    const [list, setList] = useState<any>([]);
    // 用户列表
    const [users, setUsers] = useState([]);
    const getUList = () => {
        const params = {
            size: 999999,
            current: 1,
        };
        getUsersList(params).then((res: any) => {
            if (res.code === 200) {
                const val = res.data.records.map((item: any) => {
                    item.label = item.name;
                    item.value = item.id;
                    return item;
                });
                setUsers(val);
            }
        });
    };
    const formColumns = [
        {
            label: "客户名称",
            dataIndex: "companyName",
            type: "Input",
            params: {
                placeholder: "请输入公司名称",
                maxLength: 20,
                allowClear: true,
            },
        },
        {
            label: "来源",
            dataIndex: "customerSource",
            type: "Select",
            params: {
                placeholder: "请选择",
                options: customerSourceList,
                allowClear: true,
            },
        },
        {
            label: "客户类型",
            dataIndex: "companyType",
            type: "Select",
            params: {
                placeholder: "请选择",
                options: companyTypeList,
                allowClear: true,
            },
        },
        {
            label: "采购类品",
            dataIndex: "purchaseCategory",
            type: "Select",
            params: {
                placeholder: "请选择",
                options: purchaseCategoryList,
                allowClear: true,
            },
        },
        {
            label: "重要程度",
            dataIndex: "importance",
            type: "Select",
            params: {
                placeholder: "请选择",
                options: importanceList,
                allowClear: true,
            },
        },
        {
            label: "状态",
            dataIndex: "customerStatus",
            type: "Select",
            params: {
                placeholder: "请选择",
                options: customerStatusList,
                allowClear: true,
            },
        },
        {
            label: "业务负责人",
            dataIndex: "businessOwner",
            type: "Select",
            params: {
                placeholder: "请选择",
                options: users,
                showSearch: true,
                filterOption: (input: any, option: any) => {
                    return option.label.indexOf(input) >= 0;
                },
                allowClear: true,
            },
        },
        {
            label: "创建日期",
            dataIndex: "time",
            type: "DatePickers",
            params: {
                placeholder: "请选择",
                allowClear: true,
            },
        },
    ];
    const columns = [
        {
            title: "客户名称",
            dataIndex: "companyName",
        },
        {
            title: "来源",
            dataIndex: "customerSource",
            render: (record: any) => {
                const res = customerSourceList.find(
                    (item: any) => item.value === record
                );
                return <span>{res?.label}</span>;
            },
        },

        {
            title: "客户类型",
            dataIndex: "companyType",
            render: (record: any) => {
                const res = companyTypeList.find(
                    (item: any) => item.value === record
                );
                return <span>{res?.label}</span>;
            },
        },
        {
            title: "采购品类",
            dataIndex: "purchaseCategory",
            render: (record: any) => {
                const res = purchaseCategoryList.find(
                    (item: any) => item.value === record
                );
                return <span>{res?.label}</span>;
            },
        },
        {
            title: "重要程度",
            dataIndex: "importance",
            render: (t: any) => importantMap[t],
        },
        {
            title: "状态",
            dataIndex: "customerStatus",
            render: (record: any) => {
                const res = customerStatusList.find(
                    (item: any) => item.value === record
                );
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <span
                            style={{
                                display: "inline-block",
                                width: "6px",
                                height: "6px",
                                borderRadius: "50%",
                                backgroundColor: res?.color,
                                marginRight: "3px",
                            }}
                        ></span>
                        <span>{res?.label}</span>
                    </div>
                );
            },
        },
        {
            title: "业务负责人",
            // dataIndex:'name'
            render: (record: any) => {
                return <span>{record?.businessOwner?.name}</span>;
            },
        },
        // {
        //     title: "创建时间",
        //     width: 150,
        //     dataIndex: "createTime",
        // },
        {
            title: "初次接触日期",
            width: 150,
            dataIndex: "firstContactTime",
            render: (record: any) => {
                return (
                    <span>
                        {record ? dayjs(record).format("YYYY-MM-DD") : "-"}
                    </span>
                );
            },
            sorter: true,
        },
        {
            title: "最新跟进时间",
            dataIndex: "lastFollowTime",
            width: 150,
            render: (record: any) => {
                return <span>{record || "-"}</span>;
            },
            sorter: true,
        },
        {
            title: "操作",
            fixed: "right",
            width: 100,
            render: (record: any, item: any) => {
                return (
                    <span>
                        {isShowDetail ? (
                            <Link to={`/customer/management/info/${item.id}`}>
                                详情
                            </Link>
                        ) : null}
                    </span>
                );
            },
        },
    ];
    // 搜索
    const onSearch = (values?: any) => {
        let params = {
            ...values,
            // startCreateTime,
            // endCreateTime,
            current: 1,
            size: pageOptions.pageSize,
        };
        if (values.time) {
            const startCreateTime = values.time
                ? dayjs(values.time[0]).format("YYYY-MM-DD") + " 00:00:00"
                : "";
            const endCreateTime = values.time
                ? dayjs(values.time[1]).format("YYYY-MM-DD") + " 23:59:59"
                : "";
            console.log(values, startCreateTime, endCreateTime, "values");
            params = {
                ...params,
                startCreateTime,
                endCreateTime,
            };
        }
        delete params.time;
        getCustomerList(params);
    };
    // list
    const getCustomerList = (object?: any) => {
        setList([]);
        setTotal(0);
        const params = {
            current: pageOptions.current,
            size: pageOptions.pageSize,
            ...object,
        };
        getList(params).then((res: any) => {
            if (res.code === 200) {
                setTotal(res.data?.total);
                setList(res.data?.records);
            }
        });
    };
    const handlePage = (params?: any) => {
        setPageOptions({
            pageSize: params.pageSize,
            current: params.current,
        });
        getCustomerList({
            size: params.pageSize,
            current: params.current,
        });
    };
    // 单行的排序
    const handleColumns = (
        pagination: any,
        filters: any,
        sorter: any,
        extra: any
    ) => {
        if (sorter?.field === "firstContactTime") {
            // 首次跟进时间排序
            let obj: any = {};
            if (sorter?.order === "ascend") {
                obj.sortFirstContactTime = "asc";
            }
           if (sorter?.order === "descend") {
               obj.sortFirstContactTime = "desc";
           }
            getCustomerList(obj);
        }
        if (sorter?.field === "lastFollowTime") {
            // 首次跟进时间排序
            let obj: any = {};
            if (sorter?.order === "ascend") {
                obj.sortLastFollowTime = "asc";
            }
            if (sorter?.order === "descend") {
                obj.sortLastFollowTime = "desc";
            }
            getCustomerList(obj);
        }
    };
    useEffect(() => {
        getCustomerList();
        getUList();
    }, []);
    return (
        <div className="container">
            <div className="content">
                <BreadCrumbBar breads={breads}></BreadCrumbBar>
                <div>
                    <SearchForm
                        formParams={formColumns}
                        onSearch={onSearch}
                    ></SearchForm>
                    {isShowAdd && (
                        <div className="flex_right">
                            <Button
                                type="primary"
                                onClick={() => {
                                    navigate("/customer/management/add");
                                }}
                            >
                                新增客户
                            </Button>
                        </div>
                    )}
                    <TableCom
                        rowKey={(record: any) => record.id}
                        dataSource={list}
                        columns={columns}
                        pagination={{
                            ...pageOptions,
                            total: total,
                        }}
                        onChange={handlePage}
                        // scroll={{ y: "calc(100vh - 400px)", x: 1400 }}
                        scroll={{
                            x: 1200,
                        }}
                        changeColumn={handleColumns}
                    ></TableCom>
                </div>
            </div>
        </div>
    );
};

export default CustomerManagement;
