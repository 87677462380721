import React, { useEffect } from 'react';
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import TopNavbar from '../../components/TopNavbar';
import LeftNavbar from '../../components/LeftNavbar';
import {
    EditOutlined,
    HomeOutlined,
    GroupOutlined,
    CompressOutlined,
    UserOutlined,
    PieChartOutlined
} from '@ant-design/icons';
import './index.scss';

const Evidence = () => {
    const navigate = useNavigate();
    const location = useLocation();
    // const [pathName, setPathName] = useState('');
    console.log('render')
    useEffect((): void => {
        const pathName = location.pathname.match(/\/evidence\/(.*)/);
        const systemUrlPaths = ['role', 'authority', 'user'];
        let urlName = '';
        if (pathName) {
            urlName = pathName![1];
            if (systemUrlPaths.includes(urlName)) {
                navigate(`/evidence/${urlName}`);
            }
        }
        else {
              // eslint-disable-next-line react-hooks/exhaustive-deps
            navigate(`/evidence/account/management`);
        }
    }, []); // eslint-disable-line

    // useEffect((): void => {
    //     const pathName = location.pathname.match(/\/evidence\/(.*)/);
    //     const systemUrlPaths = ['role', 'authority', 'user'];
    //     let urlName = '';
    //     if (pathName) {
    //         urlName = pathName![1];
    //         if (systemUrlPaths.includes(urlName)) {
    //             setPathName(urlName);
    //         }
    //     }
    // }, []);
    return (
        <div className="evidence-container">
            <TopNavbar config={{ value: "evidence" }} />
            <div className="evidence-container-content">
                <LeftNavbar lists={[
                    {
                        name: "客户管理",
                        path: "/evidence/account",
                        icon: <HomeOutlined />,
                        children: [
                            {
                                name: "存证平台-客户管理",
                                path: "/evidence/account/management",
                                icon: <UserOutlined />,
                            },
                        ]
                    },
                    {
                        name: "平台配置",
                        path: "/evidence/platform",
                        icon: <EditOutlined />,
                        children: [
                            {
                                name: "存证场景模板",
                                path: "/evidence/platform/template",
                                icon: <GroupOutlined />,
                            },
                            {
                                name: "存证字段维护",
                                path: "/evidence/platform/field",
                                icon: <CompressOutlined />,
                            },
                            {
                                name: "外部数据库配置",
                                path: "/evidence/platform/database",
                                icon: <PieChartOutlined />,
                            }
                        ]
                    }
                ]} />
                <Outlet></Outlet>
            </div>
        </div>

    )
}
export default Evidence;



