export function formatSplit(num: number|string='') {

  return !(num + '').includes('.')
    ? // 就是说1-3位后面一定要匹配3位
      (num + '').replace(/\d{1,3}(?=(\d{3})+$)/g, (match) => {
        return match + ',';
      })
    : (num + '').replace(/\d{1,3}(?=(\d{3})+(\.))/g, (match) => {
        return match + ',';
      });
}