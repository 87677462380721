import React from "react";
import "../../index.scss";
import { stepPerson } from "../../../../config";

interface StepDesc {
    person?: string | undefined;
    entryClerkName?: string | undefined;
    step: string;
}
const StepsDesc = (props: StepDesc) => {
    const { person, entryClerkName, step } = props;
    return (
        <div className="description">
            <div>
                {stepPerson[step]}:{person}
            </div>
            {entryClerkName !== undefined && entryClerkName ? (
                <div>录入员:{entryClerkName}</div>
            ) : null}
        </div>
    );
};

export default StepsDesc;
