import React, {useEffect, useState} from "react";
import {Tabs, Form, Input, Button, message} from 'antd'
import {businessSet, getBusinessList} from '../promise'
import './index.scss'
const FormItem = Form.Item

const layout ={
  labelCol:{
    span:8
  },
  wrapperCol:{
    span:14
  }
}
const Agency = () => {
  const [form] = Form.useForm()
  const [details, setDetails] = useState<any>({})
  const getBusiness = () => {
    getBusinessList()
    .then((res:any) => {
      if(res.code === 200){
        if(res.data.length !== 0){
          console.log(res, 'res')
          setDetails(res.data[0])
          form.setFieldValue('value', res.data[0].value)
        }
      }
    })
  }
  const handleSubmit  = () => {
    const params = {
      type:'APPROVAL_TIME',
      value: form.getFieldValue('value'),
      id: details?.id
    }
    businessSet(params)
    .then((res:any) => {
      if(res.code === 200){
        message.success('保存成功')
        getBusiness()
      }else{
        message.error(res.message || '保存异常')
      }
    })
  }
  useEffect(() => {
    getBusiness()
  }, [])
  const RenderSet = () => {
    return (
      <div className="tabs_container">
        <Form {...layout} form={form}>
          <FormItem label='营业执照资料审核-审核员审核时长配置' name='value'>
            <Input className="ipt" defaultValue={details?.value} onChange={(e:any) => {
              form.setFieldValue('value', e.target.value)
            }}></Input>&nbsp; 
              分钟
          </FormItem>
          {/* <FormItem label='税务登记-绑定户数最大差值'>
          <Input className="ipt"></Input>&nbsp; 个
          </FormItem>
          <FormItem label='税务登记-绑定用户数最大值'>
            <Input className="ipt"></Input>&nbsp; 个
          </FormItem> */}
        </Form>
        <div className="tabs_button">
        <Button type="primary" onClick={() => handleSubmit()}>保存</Button>
        </div>
      </div>
    )
  }
  const items =[
    {
      label:'工商税务办理设置',
      key:'1',
      children: <RenderSet></RenderSet>
    }
  ]
  const onChange = (e:any) => {
    console.log(e)
  }
  return <div className="agency_container">
    <Tabs defaultActiveKey="1" items={items} onChange={onChange}></Tabs>
  </div>
}

export default Agency