import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Checkbox, Radio, Form, Button, Upload, DatePicker, message, Modal } from 'antd';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import './index.scss';
import BreadCrumbBar from '../../../../../../components/BreadCrumbBar';
import Util from '../../../../../../services/util';
import {useNavigate, useLocation  } from 'react-router-dom'
import dayjs from 'dayjs'
import {addEvidence, getEvidenceInfo, getImageUrl} from '../Promise'
const util = Util.getInstance()

const { TextArea } = Input;
const breads = [
    {
        label:'首页',
        path:'/'
    },{
        label:'客户管理',
        path:'/evidence'
    },
    {
        label:'存证平台-客户管理',
        path:'/evidence/account/management'
    },
    // {
    //     label:'新增用户',
    //     path:''
    // }
]
const options = [
    { label: '存证功能', value: '存证功能' },
    { label: '取证功能', value: '取证功能' },
];
const dateFormat ='YYYY-MM-DD'

const RederModal = (props:any) => {
    const {title ='开通正式账户', okText='确认提交', open, children, submit, onCancel } = props              
    return <Modal
    title={title}
    open={open}
    cancelText='取消'
    okText={okText}
    onOk={submit}
    onCancel={onCancel}
    >
        {children}
    </Modal>
}
const ManagementAdd= () => {
    const [open, setOpen] = useState<boolean>(false)
    const [breadsList, setBreadsList] = useState(breads)
    
    const location = useLocation()
    const navigate = useNavigate()
    const [form] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const [characteristic, setCharacteristic] = useState<any[]>([])
    const [fileList, setFileList] = useState<any[]>([])
    const [info, setInfo] = useState<any>({})
    const [socialCreditCode, setSocialCreditCode] = useState('')
    const [previewOpen, setPreviewOpen] = useState(false)
    const [previewImage, setPreviewImage] = useState('')
    const beforeUpload = (file:any) => {
        const typeList =['image/jpeg','image/png','image/gif','image/webp']
        const maxSize = file.size/1024/1024 
        if(typeList.indexOf(file.type) < 0){
            messageApi.open({
                type:'error',
                content: '图片格式异常'
            })
            return false
        }
        if(maxSize > 2) {
            messageApi.open({
                type:'error',
                content: '图片不能超过2M'
            })
            return false
        }

        return file
    }
    // const handleReset = () => {
    //     navigate(-1)
    //     // form.resetFields()
        
    // }

    const handleSubmit = () => {

        form.validateFields()
        .then((values:any) => {
            const date = values.establishmentDate !== undefined && values.establishmentDate? dayjs(new Date(values.establishmentDate)).format('YYYY-MM-DD') : ''
            const params ={
                ...info,
                ...values,
                establishmentDate: !location?.state?.id? date: info?.establishmentDate,
                serverDateLimit: +values.serverDateLimit,
                logo:!location?.state?.id? characteristic && characteristic[0]&&characteristic[0].filePath : info?.logo,
                businessLicense: !location?.state?.id? fileList && fileList[0]&&fileList[0].filePath: info?.businessLicense,
                permission: values.permission.toString(),
                id:location?.state?.id,
                socialCreditCode: socialCreditCode
            }
            addEvidence(params) 
            .then(res => {
                setOpen(false)
                if(res.code === 200){
                    if(!location?.state?.id){
                        messageApi.open({
                            type:'success',
                            content:'创建成功',
                            onClose:() => {
                                navigate('/evidence/account/management/manage_info', {
                                    state:{
                                        id:res?.data
                                    }
                                })
                                form.resetFields() 
                            }
                        })
                    }else{
                        messageApi.open({
                            type:'success',
                            content:'编辑成功',
                            onClose:() => {
                                navigate(-1)
                            }
                        })
                    }
                    

                }else{
                    messageApi.open({
                        type:'error',
                        content: res.message||'创建失败'
                    })
                }
            })
        })

    }
    // 自定义上传
    const customRequest = (params:any, type:string) => {
        const {file} = params
        const formData = new FormData()
        formData.append('file', file)
        util.httpUpload(`/sinzetech-resource/oss/endpoint?contentType=${file.type}&rename=${file.name}`, formData, {headers:{
            "Content-Type": "multipart/form-data"
        }})
        .then((res:any)=> {
            if(res.code === 200){
                let arr = []
                arr.push({
                    url: res.data.downloadLink,
                    downloadLink: res.data.downloadLink,
                    filePath: res.data.filePath
                })
                if(type === 'characteristic'){
                    setCharacteristic(arr)
                }
                if(type === 'license'){
                    setFileList(arr)
                }
                
            }else{
                messageApi.open({
                    type:'error',
                    content:res.message || '上传服务异常'
                })
            }
        })
    }
    const getUrl = (str:any,callback:any) => {
        if(!str) return 
        const obj ={
            zoom:false,
            fileIds: str
          }
        getImageUrl(obj).then((res:any) => {
            if(res.success){
                callback && callback(res.data[0])
            }
        })
    }
    const getInfo = (id:any) => {
        if(!id || id ==='') return 
        getEvidenceInfo(id) 
        .then(res => {
            if(res?.code === 200){
                let data = {
                    ...res?.data,
                    // establishmentDate: dayjs(new Date(res?.data?.establishmentDate)).format('YYYY-MM-DD')
                    // establishmentDate: res?.data?.establishmentDate !== undefined ? res?.data?.establishmentDate.toString(): '',
                    establishmentDate:null,
                    strDate: dayjs(info?.establishmentDate).format(dateFormat),
                    attribute: +res.data.attribute
                }
                if(res?.data?.establishmentDate!==undefined && res?.data?.establishmentDate){
                    data.establishmentDate=  ''+ res?.data?.establishmentDate
                }
                getUrl(res?.data?.logo,(value:any) => {
                    // data.logo = value

                    setCharacteristic([{url: value}])
                })
                getUrl(res?.data?.businessLicense, (value:any) => {
                    // data.businessLicense = value
                    setFileList([{url:value}])
                })
                setInfo(data)
                
                // form.initialValues(data)
                form.setFieldsValue(data)
                setSocialCreditCode(data?.socialCreditCode)
                

                console.log(data, '/dddddddd')
            }
        })
    }
    const handlePreview = (file:any) => {
        if(file.url && file.url !== ''){
            setPreviewImage(file.url)
            setPreviewOpen(true)
          }
        
      }
    useEffect(() => {
        getInfo(location?.state?.id)
        const arr = JSON.parse(JSON.stringify(breadsList))
        if(location?.state?.id){
            arr.push({
                label:'编辑客户',
                path:''
            })
        }else{
            arr.push({
                label:'新增客户',
                path:''
            })
        }
        setBreadsList(arr)
    }, [location]) // eslint-disable-line
    return  (
        <div className="evidence-management-content-add"  >
            {contextHolder}
            <div className="evidence-management-countent-add-form">
            <BreadCrumbBar breads={breadsList} />
                <Row className="evidence-management-content-add-form-title">
                    <Col span={24}>
                        <p>录入客户基本信息</p>
                    </Col>
                </Row>
                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{ maxWidth: 600 }}
                    // onFinishFailed={handleReset}
                    autoComplete="off"
                    form={form}
                >
                    <Form.Item
                        label="公司名称："
                        name="name"
                        rules={[{ required: true, message: '请输入公司名称，100字以内!', max: 100 }]}
                    >
                        <Input defaultValue={form.getFieldValue('name')} placeholder="请输入公司名称，100字以内!" />
                    </Form.Item>

                    <Form.Item
                        label="公司地址："
                        name="address"
                        rules={[{ required: false, message: '请输入公司地址，100字以内!' }]}
                    >
                        <Input placeholder="请输入公司地址，100字以内!" />
                    </Form.Item>

                    <Form.Item
                        label="联系人姓名："
                        name="contactName"
                        rules={[{ required: true, message: '请输入联系人姓名，50字以内!', max: 50 }]}
                    >
                        <Input placeholder="请输入联系人姓名，50字以内!" />
                    </Form.Item>

                    <Form.Item
                        label="联系人手机号："
                        name="contactPhone"
                        rules={[{ required: true, message: '请输入手机号', max: 11 }, 
                        {pattern:/^1[0-9]{10}/, message:'请输入正确的手机号'}
                    ]}
                    >
                        <Input placeholder="'请输入联系人手机号!" maxLength={11}/>
                    </Form.Item>

                    <Form.Item
                        label="用户属性:"
                        name="attribute"
                        rules={[{ required: true }]}
                    >
                        <Radio.Group defaultValue={info.attribute} onChange={() => { }} value={'lf'}>
                            <Radio value={1}>矿石企业</Radio>
                            <Radio value={2}>利废企业</Radio>
                            <Radio value={3}>税务局</Radio>
                            <Radio value={4}>金融机构</Radio>
                        </Radio.Group>
                    </Form.Item>


                    <Form.Item
                        label="用户权限:"
                        name="permission"
                        rules={[{ required: true, message: '请选择用户权限' }]}
                    >
                        <Checkbox.Group options={options} />
                    </Form.Item>

                    <Form.Item
                        label="服务期限设置: "
                        name="serverDateLimit"
                        rules={[{ required: true, message:'请设置服务期限' }, 
                    ]}
                    >
                        <Input type='number' value={form.getFieldValue('serverDateLimit')} defaultValue={3}  placeholder="请输入服务期限设置!" onChange={(e:any) => {
                            const { value } = e.target;
                            const tempArr = value.split('.');
                            let val = ''
                            if (tempArr[1]?.length) {
                            val = `${tempArr[0]}.${tempArr[1].slice(0, 1)}`.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g, '');
                            } else {
                            val = value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g, '');
                            }
                            form.setFieldValue('serverDateLimit', val)

                            // const value =e.target.value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g, '')
                            // form.setFieldValue('serverDateLimit', value)
                        }}></Input>
                    </Form.Item>

                    <Row>
                        <Col span={24}>
                            <span className="info">
                                <ExclamationCircleOutlined />可填小数点后一位，例如：0.5年代表服务期限为6个自然月，0年代表当前用户无使用权限。
                            </span>
                        </Col>
                    </Row>

                    <Form.Item
                        label="备注:"
                        name='remark'
                    >
                        <TextArea
                            maxLength={100}
                            style={{ height: 120, resize: 'none' }}
                            placeholder="选填, 备注当前状态便于后续持续跟进"
                        />
                    </Form.Item>
                    <Form.Item label="上传公司标识" valuePropName="characteristic" name ='logo'>
                        <Upload 
                            listType="picture-card"
                            accept='image/jpeg,image/png,image/gif'
                            beforeUpload={beforeUpload}
                            customRequest={(e) => customRequest(e, 'characteristic')}
                            fileList={characteristic}
                            maxCount={1}
                            onPreview={handlePreview}
                            onRemove={() => setCharacteristic([])}
                        >
                            {
                                characteristic.length !==0 ? null :<div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>上传</div>
                                </div>
                            }
                        </Upload>
                    </Form.Item>
                    <Row>
                        <Col span={24}>
                            <span className="info">
                                大小不超过2MB, 支持jpg,png格式.
                            </span>
                        </Col>
                    </Row>

                    <Form.Item label="上传公司营业执照" valuePropName="companyCard" name='businessLicense'>
                        <Upload 
                        listType="picture-card"
                        accept='image/jpeg,image/png,image/gif'
                        beforeUpload={beforeUpload}
                        customRequest={(e) => customRequest(e, 'license') }
                        fileList={fileList}
                        maxCount={1}
                        onPreview={handlePreview}

                        onRemove={() => setFileList([])}
                        >
                            {
                                fileList.length !==0 ? null :<div>
                                    <PlusOutlined />
                                    <div style={{ marginTop: 8 }}>上传</div>
                                </div>
                            }
                        </Upload>
                    </Form.Item>

                    <Row>
                        <Col span={24}>
                            <span className="info">
                                大小不超过2MB, 支持jpg,png格式.
                            </span>
                        </Col>
                    </Row>

                    <Form.Item
                        label="社会信用代码："
                        // name="socialCreditCode"
                    >
                        <Input 
                            value={socialCreditCode}
                            placeholder="请输入客户18位社会信用代码!" maxLength={18} onChange={(e:any) => {
                            const value = e.target.value.replace(/[^\x00-\xff]/g, '')
                            console.log(value, '/c')
                            setSocialCreditCode(value) 
                        }}/>
                    </Form.Item>

                    <Form.Item label="公司成立日期" name='establishmentDate'>
                        <span>
                            {
                            info.establishmentDate? <DatePicker value={dayjs(info?.establishmentDate, dateFormat) || null} format={dateFormat} onChange={(e) => {
                                setInfo({
                                    ...info,
                                    establishmentDate: e
                                })
                                form.setFieldValue('establishmentDate', e)
                            }} />: <DatePicker onChange={(e) => {
                                form.setFieldValue('establishmentDate', e)
                            }}
                            disabledDate={(current) => {
                                return  dayjs(current).format('YYYY-MM-DD') >dayjs(new Date()).format('YYYY-MM-DD')
                            }}
                            ></DatePicker>
                        }
                        </span>
                    </Form.Item>

                    <Form.Item
                        label="所属行业: "
                        name="industry"
                        rules={[{ message: '请输入客户所在行业，50字以内!', max: 50 }]}
                    >
                        <Input placeholder="请输入客户所在行业，50字以内!" />
                    </Form.Item>
                    <Form.Item
                        label="公司简介及官网地址:"
                        name='introduction'
                        rules={[{ message: '请输入客户公司简介及官网地址，50字以内!', max: 50 }]}
                    >
                        <TextArea
                            maxLength={100}
                            style={{ height: 120, resize: 'none' }}
                            placeholder="请输入客户公司简介及官网地址，50字以内!"
                        />
                    </Form.Item>
                    <Row>
                        <Col span={4} offset={16}>
                            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                <Button type="primary" onClick={() => {
                                    if(!location?.state?.id){
                                        console.log('click')
                                        const val = form.getFieldValue('serverDateLimit')
                                        if(!val || val === undefined || val === ''){
                                            form.setFieldValue('serverDateLimit',3)
                                        }
                                        form.validateFields()
                                        .then(res => {
                                            console.log(res, 'mmmm')
                                            setOpen(true)
                                        })
                                    }else{
                                        handleSubmit()
                                    }
                                    
                                    // setOpen(true)
                                }}>
                                    提交
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                <Button onClick={() => {
        navigate(-1)

                                }}>
                                    取消
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col offset={4}>
                            <span className="info">
                                <ExclamationCircleOutlined />
                                用该用户手机号,开通正式账户,用户可以用该手机号登录平台.
                            </span>
                        </Col>
                    </Row>
                </Form>
            </div>
            
            <RederModal open={open} onCancel={() => {
                setOpen(false)
            }}
            submit={() => handleSubmit()}
            >
                <div style={{display:'flex', justifyContent:'space-between', width:'250px'}}>
                    <span>公司名称：</span>
                    <span>{form.getFieldValue('name')}</span>
                </div>
                <div style={{display:'flex', justifyContent:'space-between', width:'250px'}}>
                    <span>手机号(账号)：</span>
                    <span>{form.getFieldValue('contactPhone')}</span>
                </div>
                <div style={{display:'flex', justifyContent:'space-between', width:'250px'}}>
                    <span>初始密码：</span>
                    <span>888888</span>
                </div>
                <Button type ='link'>系统自定发送短通知用户， 请确认信息后开通</Button>
            </RederModal>

            <Modal open={previewOpen} title={null} footer={null} onCancel={() => {
                setPreviewOpen(false)
                }}>
            <img
                alt="example"
                style={{
                width: '100%',
                }}
                src={previewImage}
            />
            </Modal>
        </div>
    )

}
export default ManagementAdd;