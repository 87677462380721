import React, { ReactNode, memo, useEffect, useMemo, useState } from "react";
import classnames from "classnames";
import "./index.scss";
import {
  Button,
  Col,
  Form,
  FormItemProps,
  Image,
  Input,
  Row,
  Select,
  Space,
  Upload,
  message,
} from "antd";
import Dragger from "antd/es/upload/Dragger";
import { CheckOutlined, CloseOutlined, DeleteFilled, DeleteOutlined, FilePdfFilled, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { cross_env } from "@src/services/util";
import { getTaxRegions } from "../../promises";
import { RexChineseName, RexChinesePhone, RexIdNo } from "@src/constant/RegExp";
import { useForm } from "antd/es/form/Form";
import { fileLimitType } from "@src/utils";
import { RcFile } from "antd/es/upload";
const defaultProps = {};
export type EntityFormData = {
// 必须 客户id	
  customerId:	number	
// 必须 营业执照文件id	
  businessLicenseFileId:	number	
// 非必须 合同文件id ,分割	
contractFileId?:	string[]
// 必须 企业类型 ENTERPRISE("ENTERPRISE", "企业"),     INDIVIDUALBUSINESS("INDIVIDUALBUSINESS", "个体户");	
enterpriseType:	string	
// 必须 企业名称	
enterpriseName:	string	
// 必须 统一信用代码	
unifiedCreditCode:	string	
// 必须 开票行名称	
invoicingBankName:	string	
// 必须 开票行账号	
invoicingBankAccountNumber:	string	
// 必须 开票地址	
invoicingBankAddress:	string	
// 必须 开票电话	
invoicingBankPhone:	string	
// 必须 合作税区 ,分割	
cooperateTaxDistrict:	string[]
// 非必须 法人姓名	
legalPersonName:	string	
// 非必须 法人身份证号
legalPersonIdCard:	string	
}

export type EntityUploadData = Omit<EntityFormData,'cooperateTaxDistrict'|'contractFileId'> &{cooperateTaxDistrict: string,contractFileId?:string}
export type EntityResponseData = EntityUploadData &{businessLicenseFile:{id: string,ossUrl:string},contractFile?:{fileName:string,id:string,ossUrl:string}[]}
type props = {
  onFinish?: (values: Partial<EntityUploadData>)=> Promise<void>
  initialValues?: Partial<EntityResponseData>,
  businessLicenseFileUrl?: string
};
const layout = {
  wrapperCol: { span: 8,xl:8,sm:16 },
  labelCol: { span: 2,xl:2,sm:6 },
};
type RenderFormField = FormItemProps & {
  renderControl: (props: any) => ReactNode;
};
export type EntityFormProps = Required<typeof defaultProps> & props;
export const EntityForm: React.FC<EntityFormProps> = memo((props) => {
  const {onFinish: _onFinish,initialValues,
  businessLicenseFileUrl:_businessLicenseFileUrl,
} = props
  const [form] = useForm<EntityFormData>()
  const [businessLicenseFileUrl,setBusinessLicenseFileUrl] = useState<string>()
  useEffect(()=>{
    setBusinessLicenseFileUrl(_businessLicenseFileUrl)
  },[_businessLicenseFileUrl])
  // const businessLicenseFileUrl = useMemo(()=>businessLicenseFileUrlState||_businessLicenseFileUrl,[businessLicenseFileUrlState,_businessLicenseFileUrl])
  const [operationAuthorization] = useState(() =>
    JSON.parse(
      window.localStorage?.getItem("operation_authorization") as string
    )
  );
  const [taxRegions, setTaxRegions] =
    useState<{ name: string; id: string }[]>();
  useEffect(() => {
    getTaxRegions().then((res) => {
      // @ts-ignore
      setTaxRegions(res.data);
    });
  }, []);
  const basicInfo: RenderFormField[] = [
    {
      name: "enterpriseType",
      label: "企业类型",
      rules: [{ required: true }],
      initialValue: "ENTERPRISE",
      renderControl: (props) => (
        <Select
          {...props}
          options={[
            { value: "ENTERPRISE", label: "企业" },
            { value: "INDIVIDUALBUSINESS", label: "个体户" },
          ]}
        />
      ),
    },
    {
      name: "enterpriseName",
      label: "企业名称",
      rules: [{ required: true }],
      renderControl: (props) => <Input {...props} maxLength={50} />,
    },
    {
      name: "businessLicenseFileId",
      label: "上传营业执照",
      rules: [{ required: true }],
      renderControl: () => (
        businessLicenseFileUrl?
        <div className={classnames('entity-form-preview-container')}>
          <Image style={{objectFit:'contain',height:188}} src={businessLicenseFileUrl}></Image>
          <DeleteFilled onClick={()=>{
            setBusinessLicenseFileUrl(undefined)
            form.setFieldValue('businessLicenseFileId',undefined)
          }} className={classnames('entity-form-preview-delete-icon')}/>
          {/* <img src={businessLicenseFileUrl}></img> */}
        </div>:
        <Dragger
          maxCount={1}
          name="file"
          multiple={false}
          showUploadList={ false }
          beforeUpload={async (file)=>{
            await fileLimitType(['image/*'],file).catch((e:any)=>{
              message.error('格式不正确')
              throw e
            })
          }}
          // fileList={[]}
          onChange={(file)=>{
            if(file.file.status==='done'){
              form.setFieldValue('businessLicenseFileId',file.file.response.data.fileId)
              setBusinessLicenseFileUrl(file.file.response.data.uri)
            }else if(file.file.status==='error'){
              form.setFieldValue('businessLicenseFileId',undefined)
              setBusinessLicenseFileUrl(undefined)
            }
          }}
          // listType="picture-card"
          headers={{
            Authorization: `Bearer ${operationAuthorization?.["access_token"]}`,
          }}
          action={cross_env + "/sinzetech-crm" + "/file/upload"}
          accept="image/png,image/jpg,image/jpeg"
        >
          <Row justify={"center"} gutter={[0, 8]}>
            <Col span={24}>
              <PlusOutlined style={{ fontSize: 34 }} />
            </Col>
            <Col span={24}>上传营业执照照片</Col>
          </Row>
        </Dragger>
      ),
    },
    {
      name: "unifiedCreditCode",
      label: "统一信用代码",
      rules: [{ min: 18, max: 18, message: "格式不正确" },{required:true}],
      renderControl: (props) => <Input {...props} max={50} maxLength={18} />,
    },
  ];
  const personInfo: RenderFormField[] = [
    {
      name: "legalPersonName",
      label: "法人姓名",
      renderControl: (props) => (
        <Input
          {...props}
          maxLength={10}
        />
      ),
    },
    {
      name: "legalPersonIdCard",
      label: "身份证号",
      rules: [{ pattern: RexIdNo, message: "格式不正确" }],
      renderControl: (props) => <Input {...props}/>,
    },
  ];
  const invoiceInfo: RenderFormField[] = [
    {
      name: "invoicingBankName",
      label: "开票行名称",
      rules: [{ required: true },{pattern:RexChineseName, message:"格式不正确"}],
      renderControl: (props) => (
        <Input {...props} maxLength={50}/>
      ),
    },
    {
      name: "invoicingBankAccountNumber",
      label: "开票行账号",
      rules: [{ required: true },{pattern:/^\d+$/, message:"请输入数字"}],
      renderControl: (props) => <Input {...props} />,
    },
    {
      name: "invoicingBankAddress",
      label: "开票地址",
      rules: [{ required: true }],
      renderControl: (props) => <Input {...props} max={50} />,
    },
    {
      name: "invoicingBankPhone",
      label: "开票电话",
      rules: [{ required: true },{pattern:RexChinesePhone,message:'请输入正确的电话号码'}],
      renderControl: (props) => <Input {...props} max={11} />,
    },
  ];
  const taxRegionInfo: RenderFormField[] = [
    {
      name: "cooperateTaxDistrict",
      label: "合作税区",
      rules: [{ required: true }],
      renderControl: (props) => (
        <Select
          mode="multiple"
          {...props}
          options={taxRegions?.map((taxRegion) => ({
            value: taxRegion.id,
            label: taxRegion.name,
          }))}
        />
      ),
    },
  ];
  useEffect(()=>{
    if(initialValues?.contractFile)
      setContractFiles(initialValues?.contractFile)
  },[initialValues?.contractFile])
  const [contractFiles,setContractFiles] = useState<EntityResponseData['contractFile']>([])
  const contractInfo: RenderFormField[] = [
    {
      wrapperCol:{
        span: 16
      },
      label: "上传合同",
      renderControl: (props) => (
        <Row gutter={[16,16]}>
          <Col>
          <Space direction="vertical">
          <Upload
            showUploadList={false}
            // @ts-ignore
            // fileList={contractFiles}
            name="file"
            action={`${cross_env}/sinzetech-crm/file/upload`}
            accept="application/pdf"
            headers={{
              Authorization: `Bearer ${operationAuthorization?.["access_token"]}`,
            }}
            beforeUpload={async (file)=>{
              await fileLimitType(['application/pdf'],file).catch((e:any)=>{
                message.error('格式不正确')
                throw e
              })
            }}
  
            onChange={(file)=>{
              if(file.file.status==='done'){
                setContractFiles((prevFiles)=>prevFiles?.concat({id:file.file.response.data.fileId,fileName:file.file.name,ossUrl:file.file.response.data.ossUrl}))
                form.setFieldValue('contractFileId',(form.getFieldValue('contractFileId')||[]).concat(file.file.response.data.fileId))
              }else if(file.file.status==='error'){
                // form.setFieldValue('contractFileId',undefined)
              }
            }}
          >
            <Button type="primary" icon={<UploadOutlined />}>
              上传文件
            </Button>
          </Upload>
          支持扩展名：.pdf
          
        </Space>
          </Col>
          <Col>
          <Space wrap>
            {contractFiles?.map((item)=><div key={item.id} className={classnames('entity-form-pdf-icon-container')}>
              
            <div className={classnames('entity-form-pdf-icon-wrapper')}>
              <CloseOutlined className={classnames('entity-form-pdf-close-icon')} title="删除" onClick={()=>{
                setContractFiles((prevFiles)=>{
                  const newFiles = prevFiles?.filter((file)=>item.id!==file.id)
                  form.setFieldValue('contractFileId',newFiles?.map(((item)=>item.id)))
                  return newFiles
                })
              }}/>
              <FilePdfFilled className={classnames('entity-form-pdf-icon')}/>
            </div>
              <div>{item.fileName}</div>
            </div>)}
          </Space>
          </Col>
        </Row>
       
      ),
    },
    {
      name: "contractFileId",
      noStyle:true,
      renderControl:()=><></>
    }
  ];
  const renderFormField = ({ renderControl, ...info }: RenderFormField) => (
    <Form.Item {...info}>{renderControl({ placeholder: "请输入" })}</Form.Item>
  );
  const onFinish = async (values: EntityFormData)=>{
    _onFinish?.({...values,cooperateTaxDistrict: values.cooperateTaxDistrict.join(','),contractFileId: values.contractFileId?.join(',')})
  }
  return (
    <div className={classnames("add-entity-container")}>
      <div className={classnames("add-entity-content")}>
        <Form<EntityFormData>  {...layout} form={form} onFinish={onFinish} initialValues={initialValues}>
          <div className={classnames('entity-form-subtitle')}>基本信息</div>
          {basicInfo.map(renderFormField)}
          <div className={classnames('entity-form-subtitle')}>法人信息</div>
          {personInfo.map(renderFormField)}
          <div className={classnames('entity-form-subtitle')}>开票信息</div>
          {invoiceInfo.map(renderFormField)}
          <div className={classnames('entity-form-subtitle')}>税区信息</div>
          {taxRegionInfo.map(renderFormField)}
          <div className={classnames('entity-form-subtitle')}>合同信息</div>
          {contractInfo.map(renderFormField)}
          <Row justify="center">
            <Space size={'large'}>
              <Button type="primary" htmlType="submit">确定</Button>
              <Button onClick={()=>history.back()}>取消</Button>
            </Space>
          </Row>
        </Form>
      </div>
    </div>
  );
});
EntityForm.defaultProps = defaultProps;
