import React, { useEffect } from 'react'
import BreadCrumbBar from '@src/components/BreadCrumbBar'
import { Button, DatePicker, Form, Input, Pagination, Select, Table, } from 'antd';
import { useNavigate } from 'react-router-dom';
import { axiosRequrest } from '@src/services/axiosRequest';
import { useSetState } from 'ahooks';
import CommonSearch, { FormItem } from '@src/components/common/CommonSearch';
import ExportStoreList from '@src/components/export/storeList';
import moment from 'moment';
interface LedgerState extends BaseState {
    taxRegion: any[]
    current: number
    total: number
    size: number
    map: any
}
const breads = [
    {
        label: "税票系统",
        path: "/business",
    },
    {
        label: "发票台账",
        path: "",
    },
];
// const invoiceStatusNameMap = {
//     PEDDING: '待开具',
//     ISSUED: '已开具',
//     CANCELED: '已冲红',
//     ERROR: '开票失败',
// }
// const invoiceTypeNameMap = {
//     FULL_GENERAL: '已开具',
//     FULL_SPECIAL: '已开具',
// }
const Ledger = () => {
    const nav = useNavigate()
    let [state, setState] = useSetState<LedgerState>({
        columnsData: [],
        taxRegion: [],
        total: 0,
        current: 1,
        size: 10,
        isRefresh: false,
        searchContent: {},
        isExport: false,
        map: {},
    })
    let columns: any = [{
        title: '开票方',
        dataIndex: 'sellerName',
    }, {
        title: '开票日期',
        dataIndex: 'invoiceDate',
    }, {
        title: '发票号码',
        dataIndex: 'invoiceNo',
    }, {
        title: '合计税额',
        dataIndex: 'taxAmount',
    }, {
        title: '价税合计',
        dataIndex: 'amountWithTax',
    }, {
        title: '买方名称',
        dataIndex: 'buyerName',
    }, {
        title: '订单ID',
        dataIndex: 'orderNo',
    }, {
        title: '发票类型',
        dataIndex: 'invoiceTypeName',
        // render: (t: string) => invoiceTypeNameMap[t] || ''
    }, {
        title: '税率',
        dataIndex: 'taxRate'
    }, {
        title: '开票人',
        dataIndex: 'invoiceIssuer',
    }, {
        title: '开票状态',
        dataIndex: 'invoiceStatusName',
        // render: (t: string) => invoiceStatusNameMap[t] || ''
    }, {
        title: '操作',
        dataIndex: 'operation',
        fixed: "right",
        render: (_: any, item: any) => {
            return (
                <div className='operation'>
                    <span
                        onClick={() => {
                            preview(item.id)
                        }}
                    >预览</span>
                </div>
            )
        }
    },]
    const formList: FormItem[] = [{
        name: 'invoiceStatus',
        label: '开票状态',
        element: (
            <Select placeholder='请选择' allowClear>
                <Select.Option value='ISSUED'>已开具</Select.Option>
                <Select.Option value='CANCELED'>已冲红</Select.Option>
                {/* <Select.Option value=''>负数发票已开具</Select.Option> */}
            </Select>
        ),
    }, {
        name: 'sellerName',
        label: '开票方',
        element: <Input placeholder='请输入' allowClear />,
    }, {
        name: 'invoiceType',
        label: '发票类型',
        element: (
            <Select placeholder='请选择' allowClear>
                <Select.Option value='SPECIAL_VAT_INVOICE_PAPER'>增值税专用发票纸</Select.Option>
                <Select.Option value='SPECIAL_VAT_INVOICE_ELECTRON'>增值税专用发票电子</Select.Option>
                <Select.Option value='GENERAL_VAT_INVOICE_PAPER'>一般增值税发票纸</Select.Option>
                <Select.Option value='GENERAL_VAT_INVOICE_ELECTRON'>一般增值税发票电子</Select.Option>
                <Select.Option value='SPECIAL_ALL_ELECTRIC'>特殊全电</Select.Option>
                <Select.Option value='GENERAL_ALL_ELECTRIC'>一般全电</Select.Option>
            </Select>
        ),
    }, {
        name: 'buyerName',
        label: '买方名称',
        element: <Input placeholder='请输入' allowClear />,
    }, {
        name: 'deregisterStatus',
        label: '开票日期',
        timeKey: ['invoiceDateStartDate', 'invoiceDateEndDate'],
        element: <DatePicker.RangePicker style={{ width: '100%' }} />,
    }, {
        name: 'invoiceIssuer',
        label: '开票人',
        element: <Input placeholder='请输入' allowClear />,
    }, {
        name: 'keyword',
        label: '关键字搜索',
        element: <Input placeholder='请搜索发票号、订单ID...' allowClear />,
    },]
    useEffect(() => {
        getColumnsData()
    }, [state.current, state.size, state.isRefresh])
    const getColumnsData = () => {
        axiosRequrest.get<List>(`/invoices`, {
            params: {
                size: state.size,
                current: state.current,
                ...state.searchContent,
            },
            headers: {
                baseUrl: 'SP'
            }
        }).then(res => {
            setState({ columnsData: res.records, total: res.total, map: res.map })
        })
    }
    const preview = (id: string) => {
        axiosRequrest.get<string>(`/invoices/preview/${id}`, {
            headers: {
                baseUrl: 'SP'
            }
        }).then(res => {
            window.open(res)
        })
    }
    return (
        <div className='public_list_page'>
            <BreadCrumbBar breads={breads}></BreadCrumbBar>
            <CommonSearch
                formList={formList}
                onSearch={(values) => {
                    console.log(values, 'values')
                    setState({ searchContent: values, isRefresh: !state.isRefresh })
                }}
            />
            <div className='table_box'>
                <div className='func'>
                    <Button
                        className='func_item'
                        type='primary'
                        onClick={() => {
                            setState({ isExport: true })
                        }}
                    >导出</Button>
                </div>
                <div className='page_statistics'>
                    <span>不含税开票额：{state.map?.amountWithoutTax}</span>
                    <span>合计税额：{state.map?.taxAmount}</span>
                    <span>价税合计：{state.map?.amountWithTax}</span>
                </div>
                <Table
                    className='public_table'
                    columns={columns}
                    dataSource={state.columnsData}
                    pagination={false}
                    scroll={{ x: true }}
                />
                <Pagination
                    className='page_num'
                    total={state.total}
                    current={state.current}
                    pageSize={state.size}
                    showSizeChanger
                    showTotal={(total) => `共${total}条`}
                    size='small'
                    onChange={(current, size) => {
                        setState({ current, size })
                    }}
                />
            </div>
            {
                state.isExport && (
                    <ExportStoreList
                        columnsKey={() => {
                            let keys = [...columns]
                            keys.pop()
                            return keys
                        }}
                        url={`/invoices`}
                        exportName={`发票台账_${moment().format('YYYY-MM-DD')}.xlsx`}
                        current={state.current}
                        size={state.size}
                        total={state.total}
                        serchObj={{
                            ...state.searchContent
                        }}
                        headers={{ baseUrl: 'SP' }}
                        closeExportStoreList={() => { setState({ isExport: false, }) }}
                    />
                )
            }
        </div>
    )
}

export default Ledger