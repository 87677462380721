import React, { useState, useEffect } from "react";
import { Upload, message, Modal, Progress } from "antd";
import Util from "@src/services/util";
// 识别ocr
const util = Util.getInstance();
// 组件上传文件

const uuid = () => {
    let date = new Date().valueOf(); //获取时间戳
    let txt = "1234567890"; //生成的随机机器码
    let len = 13; //机器码有多少位
    let pwd = ""; //定义空变量用来接收机器码
    for (let i = 0; i < len; i++) {
        pwd += txt.charAt(Math.floor(Math.random() * txt.length)); //循环机器码位数随机填充
    }
    return date + pwd;
};

const UploadCom = (props: any) => {
    const {
        type = "file",
        accept = "",
        maxSize,
        listType = "picture",
        maxCount = 1,
        name = "图片",
        cb,
        directory,
        multiple = false,
        ButtonText = `+上传默认${name}`,
    } = props;
    const [messageApi, contextHolder] = message.useMessage();
    const beforeUpload = (file: any, fileList: any) => {
        const typeList = accept.split(",");
        const fileType = file.name.lastIndexOf(".");
        let str = file.name.substring(fileType);
        if (type !== "file") {
            if (typeList.indexOf(file.type) < 0) {
                messageApi.open({
                    type: "error",
                    content: `请选择正确格式的${name}`,
                });
                return false;
            }
        } else {
            if (typeList.indexOf(str) < 0) {
                messageApi.open({
                    type: "error",
                    content: `请选择正确格式的${name}`,
                });
                return false;
            }
        }
        if (maxSize && maxSize !== 0) {
            const currentSize = file.size / 1024 / 1024;
            if (currentSize > maxSize) {
                messageApi.open({
                    type: "error",
                    content: `大小不能超过${maxSize}M`,
                });
                return false;
            }
        }
        return file;
    };
    // 上传
    const customRequest = (params: any) => {
        const { file, onSuccess, onError } = params;
        const formData = new FormData();
        formData.append("file", file);
        util.httpUpload(
            `/sinzetech-resource/oss/endpoint?contentType=${
                file.type
            }&rename=${uuid()}`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        ).then((res: any) => {
            if (res.code === 200) {
                onSuccess();
                cb && cb(res?.data);
            } else {
                onError();
                messageApi.open({
                    type: "error",
                    content: res.message || "上传服务异常,请重新上传",
                });
            }
        });
    };
    const handleChange = (info: any) => {

    };
    return (
        <div>
            {contextHolder}
            <Upload
                listType={listType}
                accept={accept}
                fileList={[]}
                maxCount={maxCount}
                multiple={multiple}
                beforeUpload={beforeUpload}
                customRequest={customRequest}
                directory={directory}
                onChange={handleChange}
                progress={{
                    showInfo: true,
                    strokeColor: {
                        "0%": "#108ee9",
                        "100%": "#87d068",
                    },
                    strokeWidth: 3,
                    format: (percent: any) => {
                        return percent + "%";
                    },
                }}
            >
                {ButtonText}
            </Upload>
        </div>
    );
};

export default UploadCom;
