// 审核状态
export const auditOptions = [
    {
        label:'委托协议待签署',
        value:'NO_APPROVAL'
    },
    {
        label:'委托协议已签署',
        value:'NO_APPROVAL'
    },
    {
        label:'委托协议签署失败',
        value:'NO_APPROVAL'
    },
    {
        label:'双录视频待录制',
        value:'NO_APPROVAL'
    },
    {
        label:'双录视频已录制',
        value:'NO_APPROVAL'
    },
    {
        label:'双录视频录制失败',
        value:'NO_APPROVAL'
    },

    // {
    //     label:'待提交审核',
    //     value:'NO_APPROVAL'
    // },
    {
        label:'待分配审核员',
        value:'TO_BE_ASSIGNED'
    },
    {
        label:'已分配审核员',
        value:'ASSIGNED'
    },
    {
        label:'审核驳回',
        value:'APPROVAL_REJECTION'
    },
    {
        label:'审核通过',
        value:'APPROVAL_PASS'
    }
]

// 执照办理状态

export const licensingOptions =[
  {
    label:'待办理',
    value:'NO_HANDLED'
  },
  {
    label:'提交工商',
    value:'SUBMIT_BUSINESS'
  },
  {
    label:'办理失败',
    value:'FAIL'
  },
  {
    label:'办理成功',
    value:'PASS'
  },
  {
    label:'待注销',
    value:'PENDING_CANCELLATION'
  },
  {
    label:'已注销',
    value:'CANCELED'
  },
  {
    label:'已终止',
    value:'CANCELED'
  }
]

// 税务办理状态
export const taxStatusOptions = [
  {
    label:'待登记',
    value:'NO_HANDLED'
  },
  {
    label:'登记失败',
    value:'REJECTION'
  },
  {
    label:'登记成功',
    value:'PASS'
  },
  {
    label:'待清税',
    value:'PENDING_TAX_CLEAR'
  },
  {
    label:'已清税',
    value:'TAX_CLEARED'
  },
  {
    label:'登记中',
    value:'REGISTERING'
  },
  {
    label:'已终止',
    value:'REGISTERING'
  }
]

export const lists = []

// 审核状态参数
export const approvalStatusColor = {
  'APPROVAL_PASS':'license_success',
  'APPROVAL_REJECTION':'license_error',
  'ASSIGNED':'license_warning',
  'TO_BE_ASSIGNED':'license_warning',
  'NO_APPROVAL':'license_default',
}

// 办理执照状态
export const licenseProcessStatusColor = {
  'PASS':'license_success',
  'SUBMIT_BUSINESS':'license_success',
  'NO_HANDLED':'license_warning',
  'PENDING_CANCELLATION':'license_warning',
  'CANCELED':'license_error',
  'FAIL':'license_error',
}

export const taxProcessStatusColor = {
  'PASS':'license_success',
  'REGISTERING':'license_success',
  'NO_HANDLED':'license_warning',
  'PENDING_TAX_CLEAR':'license_warning',
  'REJECTION':'license_error',
  'TAX_CLEARED':'license_error',
}

// 申请开办状态
export const applyStatusList = [
    {
        label:'待审核',
        value:'NO_APPROVAL'
    },
    {
        label:'审核中',
        value:'APPROVAL'
    },
    {
        label:'审核驳回',
        value:'APPROVAL_REJECTION'
    },
    {
        label:'执照待办理',
        value:'NO_LICENSE'
    },
    {
        label:'提交工商',
        value:'SUBMIT_BUSINESS'
    },
    {
        label:'执照办理失败',
        value:'LICENSE_FAIL'
    },
    {
        label:'待分配办税员',
        value:'NO_ASSIGN_TAX'
    },{
        label:'税务登记中',
        value:'TAX_REGISTERING'
    },{
        label:'税务登记成功',
        value:'TAX_PASS'
    },{
        label:'税务登记失败',
        value:'TAX_REJECTION'
    },{
        label:'已终止',
        value:'TERMINATION'
    },
]

// 委托协议签署状态
export const signStatusList = [
    {
        label: '未签署',
        value: false
    },
    {
        label: '已签署',
        value: true
    }
]

// 双录视频面签状态
export const dualStatusList = [
    {
        label: '未面签',
        value: false
    },
    {
        label: '已面签',
        value: true
    }
]