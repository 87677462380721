import Util from '../../../../services/util'
import qs from 'qs'

const util = Util.getInstance()
// 营业执照详情
export const getAddministrstionInfo = (id?:string) => {
  return util.httpGet(`/sinzetech-agency-processing/individual-business-deregister/${id}`)
  .then(res => res)
}

// 操作日志 http://yapi.saikul.com/project/748/interface/api/25213
export const getLogs  = (values?:any) => {
  return util.httpGetev(`/api/sinzetech-log/log?${qs.stringify({
        'sorts[0].field':'createTime',
        'sorts[0].direction':'DESCENDING',
    })}`, values)
  .then(res => res)
}

interface Deregister{
    id: string,
    file?: string,
    nextDeregisterStatus?: string,
    rejectReason?: string
}
// 注销流程状态推进
export const agencyDeregister = (params: Deregister) => {
    return util.httpPatch('/sinzetech-agency-processing/individual-business-deregister', params)
    .then(res => res)
}

// 个体户注销协议重发
export const agencySign = (id: string) => {
    return util.httpGet(`/sinzetech-agency-processing/individual-business-deregister/${id}/sign`)
    .then(res => res)
}

// 根据图片id 获取图片的地址
export const getImageUrl = (params:any) => {
  return util.httpGetDomain(`/admin/sinzetech-resource/files/ossUrlZoom`, params)
  .then(res => res)
  .catch(err => err)
}