import BreadCrumbBar from "@src/components/BreadCrumbBar";
import SearchForm from "@src/components/FormItem";
import { useTableHeight } from "@/hooks/useTableHeight";
import {
  queryData,
  breads,
  ISSUE_CHANNEL,
  TAX_FILLING_PERIOD,
} from "./content";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import TabelCom from "@src/components/TableCom";
import { getInvoicingList, upDataCredit, apiUpdateCredit } from "../../promise";
import ModalCom from "@src/components/ModalCom";
import { Form, Input, Button, message, Tooltip, Row, Col } from "antd";
import "./index.scss";
const colProps = {
  span: 8,
};
const TIPS = "查询失败，保证百望云数电链接已登录";
/**
 * 开票方列表
 * @returns
 */
function InvoicingParty() {
  const [pageOptions, setPageOptions] = useState({
    pageSize: 10,
    current: 1,
  });
  const [total, setTotal] = useState(0);
  const [list, setList] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [search, setSearch] = useState({});
  const { h } = useTableHeight({ h: 440 });
  /**
   * 列表查询
   * @param values 查询的参数
   */
  const onSearch = (values: any) => {
    const createTimeBegin = values.createTime
      ? dayjs(values.createTime[0]).format("YYYY-MM-DD") + " 00:00:00"
      : "";
    const createTimeEnd = values.createTime
      ? dayjs(values.createTime[1]).format("YYYY-MM-DD") + " 23:59:59"
      : "";
    const lastUpdateDateBegin = values.lastUpdateDate
      ? dayjs(values.lastUpdateDate[0]).format("YYYY-MM-DD") + " 00:00:00"
      : "";
    const lastUpdateDateEnd = values.lastUpdateDate
      ? dayjs(values.lastUpdateDate[1]).format("YYYY-MM-DD") + " 23:59:59"
      : "";

    const params: Api.InvoicingPartyType.QueryData = {
      createTimeBegin, // 创建时间开始
      createTimeEnd, // 创建时间结束
      lastUpdateDateBegin, // 额度更新时间开始
      lastUpdateDateEnd, // 额度更新时间结束
      taxClerk: values?.taxClerk || "",
      query: values?.query || "",
    };
    setSearch(params);
    getData(params);
  };
  const handleChange = (params?: any) => {
    setPageOptions({
      current: params.current,
      pageSize: params.pageSize,
    });
    getData({
      current: params.current,
      pageSize: params.pageSize,
    });
  };
  const getData = async (params = {}) => {
    const reqData = {
      current: pageOptions.current,
      size: pageOptions.pageSize,
      ...params,
      ...search,
    };
    const res = await getInvoicingList(reqData);
    if (res.code === 200) {
      setList(res.data.records);
      setTotal(res?.data?.total);
    }
  };
  /**
   * 自动更新
   */
  const upDate = async (row: any) => {
    const res: any = await apiUpdateCredit(
      {},
      `/taxpayers/${row.id}/api-update-credit`
    );
    if (res?.code === 200) {
      message.info(res.message);
      setOpen(false);
      getData();
    } else {
      message.error(res.message);
    }
  };
  const columns = [
    {
      title: "开票方主体",
      dataIndex: "taxpayerName",
      width: 150,
    },
    {
      title: "纳税人识别号",
      dataIndex: "taxpayerNo",
      width: 150,
    },
    {
      title: "报税周期",
      render: (record?: any) => {
        return <span>{TAX_FILLING_PERIOD[record.taxFilingPeriod]}</span>;
      },
      width: 150,
    },
    {
      title: "办税人",
      dataIndex: "taxClerk",
      width: 150,
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
      width: 150,
    },
    {
      title: "开票渠道",
      render: (record?: any) => {
        return <span>{ISSUE_CHANNEL[record.issueChannel]}</span>;
      },
      width: 150,
    },
    {
      title: "总授信额度",
      width: 150,
      render: (record?: any) => {
        return (
          <div>
            {record?.lastUpdateStatus === "SUCCESS" ? (
              <span>{record?.totalCredit}</span>
            ) : (
              <div className="credit_fail_box">
                <span className="fail_red">{record?.totalCredit || "---"}</span>
                <Tooltip title={TIPS}>
                  <img src={require("@/assets/tips_icon.png")} alt="" />
                </Tooltip>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "可用授信额度",
      width: 150,
      render: (record?: any) => {
        return (
          <div>
            {record?.lastUpdateStatus === "SUCCESS" ? (
              <span>{record?.availableCredit}</span>
            ) : (
              <div className="credit_fail_box">
                <span className="fail_red">
                  {record?.availableCredit || "---"}
                </span>
                {/* <Tooltip title={TIPS}> */}
                <img src={require("@/assets/tips_icon.png")} alt="" />
                {/* </Tooltip> */}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "额度更新时间",
      dataIndex: "lastUpdateDate",
      width: 150,
    },
    {
      title: "操作",
      width: 100,
      fixed: "right",
      align: "center",
      render: (record?: any) => {
        return (
          <div className="update_control">
            {record?.issueChannel === "MANUAL" ? null : (
              <span onClick={() => upDate(record)}>自动更新</span>
            )}

            <span onClick={() => openToastUpData(record)}>手动更新</span>
          </div>
        );
      },
    },
  ];
  const openToastUpData = (row: any) => {
    form.setFieldsValue({
      totalCredit:
        typeof row?.totalCredit === "number"
          ? row?.totalCredit.toString()
          : row?.totalCredit || "",
      availableCredit:
        typeof row?.availableCredit === "number"
          ? row?.availableCredit.toString()
          : row?.availableCredit || "",
      id: row?.id,
    });
    setOpen(true);
  };
  /**
   * 手动更新
   */
  const handleUpData = async () => {
    try {
      await form.validateFields();
      const params = form.getFieldsValue();
      const res: any = await upDataCredit(params);
      if (res?.code === 200) {
        message.info(res.message);
        setOpen(false);
        getData();
      } else {
        message.error(res.message);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    getData();
  }, [pageOptions.current, pageOptions.pageSize]);
  return (
    <div className="container table_content">
      <ModalCom
        open={isOpen}
        title="手动上传电子税务额度"
        onOk={upDate}
        onCancel={() => setOpen(false)}
        centered
        width={700}
        footer={[
          <Button key="back" onClick={() => setOpen(false)}>
            取消
          </Button>,
          <Button key="submit" type="primary" onClick={handleUpData}>
            保存
          </Button>,
        ]}
      >
        <Form form={form}>
          <Form.Item hidden name="id"></Form.Item>
          <Row>
            <Col {...colProps}>
              <Form.Item
                label="总授信额度"
                name="totalCredit"
                rules={[
                  { max: 18, message: "请输入长度为18的整数或者小数" },
                  {
                    pattern:
                      /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/,
                    message: "请输入整数或小数点保留两位",
                  },
                  { required: true, message: "请输入总授信额度" },
                ]}
              >
                <Input placeholder={"请输入"} />
              </Form.Item>
            </Col>
            <Col {...colProps}></Col>
          </Row>
          <Row>
            <Col {...colProps}>
              <Form.Item
                label="可用授信额度"
                name="availableCredit"
                rules={[
                  { max: 18, message: "请输入长度为18的整数或者小数" },
                  {
                    pattern:
                      /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/,
                    message: "请输入整数或小数点保留两位",
                  },

                  { required: true, message: "请输入可用授信额度" },
                ]}
              >
                <Input placeholder={"请输入"} />
              </Form.Item>
            </Col>
            <Col {...colProps}></Col>
          </Row>
        </Form>
      </ModalCom>
      <BreadCrumbBar breads={breads}></BreadCrumbBar>
      <div className="query_header">
        <SearchForm formParams={queryData} onSearch={onSearch} />
      </div>
      <div className="table_content_list">
        <h2 className="table_title">开票方列表</h2>
        <TabelCom
          rowKey={(record: any) => record.id}
          columns={columns}
          dataSource={list}
          scroll={{
            y: h + "px",
            x: 1400,
          }}
          pagination={{
            ...pageOptions,
            total: total,
          }}
          onChange={handleChange}
        ></TabelCom>
      </div>
    </div>
  );
}

export default InvoicingParty;
