import { Button, Col, Form, Row, Space } from 'antd';
import React, { useEffect } from 'react'
import './common.scss'
import moment from 'moment';
import dayjs from 'dayjs';

export interface FormItem {
    name?: string
    label?: string
    timeKey?: string[]
    element: React.ReactNode
}

export interface PageSearchProps {
    formList: FormItem[]
    onSearch?: (values: any) => void
}

const CommonSearch = ({ formList, onSearch, }: PageSearchProps) => {
    const [form] = Form.useForm()

    useEffect(() => {

    }, [])

    return (
        <div className="common_search">
            <Form form={form} layout='inline' style={{ display: 'block' }}>
                <Row>
                    {
                        formList.map((item, index) => {
                            return (
                                <Col span={8} style={{ marginBottom: 10 }} key={index}>
                                    <Form.Item
                                        labelAlign='right'
                                        labelCol={{ span: 8 }}
                                        name={item.name}
                                        label={item.label}
                                    >{item.element}</Form.Item>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Form>
            <div style={{ textAlign: 'right' }}>
                <Space size="small">
                    <Button
                        onClick={() => {
                            form.resetFields();
                        }}
                    >
                        重置
                    </Button>
                    <Button type="primary"
                        onClick={() => {
                            let values: any = form.getFieldsValue()
                            formList.forEach((item: any) => {
                                if (item?.timeKey) {
                                    if (values[item.name]) {
                                        values[item.timeKey[0]] = `${dayjs(values[item.name][0]).format('YYYY-MM-DD')} 00:00:00`
                                        values[item.timeKey[1]] = `${dayjs(values[item.name][1]).format('YYYY-MM-DD')} 23:59:59`
                                        delete values[item.name]
                                    }
                                }
                            })
                            onSearch && onSearch(values)
                        }}
                    >
                        查询
                    </Button>
                </Space>
            </div>
        </div>
    )
}

export default CommonSearch