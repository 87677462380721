// 操作日志
import React, { useEffect, useState } from "react";
import TableCom from "../../../../../../../components/TableCom";
import { getlogList } from "../../../../../promise";
// 联系人
const Logs = (props?: any) => {
    const { details } = props;
    const [list, setList] = useState([]);
    const [pageOptions, setPageOptions] = useState({
        current: 1,
        pageSize: 50,
    });
    const [total, setTotal] = useState(0);
    const getLogList = (id?: any, object?: any) => {
        if (!id) return;
        const params = {
            serviceId: id,
            size: pageOptions.pageSize,
            operateTypeEnum: "OPERATE_RECORD",
            current: pageOptions.current,
            ...object,
        };
        if (object?.current && object?.size) {
            setPageOptions({
                pageSize: object?.size,
                current: object.current,
            });
        }
        getlogList(params).then((res: any) => {
            if (res.data.code === 200) {
                setList(res.data.data.records);
                setTotal(res?.data?.data?.total);
            }
        });
    };
    const handlePage = (params: any) => {
        getLogList(details?.id, {
            size: params?.pageSize,
            current: params?.current,
        });
    };
    useEffect(() => {
        getLogList(details?.id);
    }, [details]);
    const columns = [
        {
            title: "操作时间",
            dataIndex: "optTime",
        },
        {
            title: "操作人",
            dataIndex: "optUserName",
        },

        {
            title: "操作项",
            dataIndex: "optName",
        },
        {
            title: "操作结果",
            dataIndex: "description",
            width: 350,
        },
    ];
    return (
        <div>
            <TableCom
                columns={columns}
                dataSource={list}
                pagination={{
                    ...pageOptions,
                    total: total,
                }}
                // scroll={{ y: "30vh" }}
                onChange={handlePage}
            ></TableCom>
        </div>
    );
};

export default Logs;
