import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import BreadCrumbBar from "../../../../../../components/BreadCrumbBar";
import {
  Form,
  Input,
  Button,
  message,
  Select,
  Radio,
  Row,
  Col,
  Modal,
} from "antd";
import UploadCom from "../../../../../../components/UploadCom";
import {
  getTaxList,
  addAdministration,
  getAddministrstionInfo,
  getTaxInfo,
} from "../../../promise";
import "./index.scss";

const FormItem = Form.Item;
const Option = Select.Option;

const layout = {
  labelCol: {
    span: 3,
  },
  wrapperCol: {
    span: 10,
  },
};

const breads = [
  {
    label: "代办业务",
    path: "/business",
  },
  {
    label: "工商税务开办",
    path: "/business/administration",
  },
  {
    label: "工商税务开办",
    path: "/business/administration/list",
  },
  {
    label: "新增申请",
    path: "",
  },
];

const radioOption = [
  {
    label: "个体户办理",
    value: "INDIVIDUAL",
  },
  {
    label: "企业办理",
    value: "ENTERPRISE",
  },
];

const uuid = () => {
  let date = new Date().valueOf(); //获取时间戳
  let txt = "1234567890"; //生成的随机机器码
  let len = 13; //机器码有多少位
  let pwd = ""; //定义空变量用来接收机器码
  for (let i = 0; i < len; i++) {
    pwd += txt.charAt(Math.floor(Math.random() * txt.length)); //循环机器码位数随机填充
  }
  return date + pwd;
};

const CreateInput = (props: any) => {
  const { cb, addonBefore = "", addonAfter, value = [] } = props;
  const [list, setList] = useState([
    {
      value: "",
      id: uuid(),
    },
  ]);
  const handleCreate = () => {
    let arr = JSON.parse(JSON.stringify(list));
    arr.push({
      value: "",
      id: uuid(),
    });
    setList(arr);
  };
  const handleInput = (e: any, obj: any) => {
    let arr = JSON.parse(JSON.stringify(list));
    const res = arr.map((item: any) => {
      if (item.id === obj.id) {
        item.value = e.target.value;
      }
      return item;
    });
    setList(arr);
  };
  useEffect(() => {
    const cbData = list.filter((item) => {
      return item.value !== "" || item.value.trim() !== "";
    });
    const res = cbData.map((item: any) => item.value);
    cb && cb(res);
  }, [list]);
  useEffect(() => {
    if (value.length !== 0) {
      const res = value || [];
      let newArr: any = [];
      res.forEach((item: any) => {
        let obj = {
          value: item,
          id: uuid(),
        };
        newArr.push(obj);
      });
      setList(newArr);
    }
  }, [value]);
  return (
    <div style={{ display: "flex", alignItems: "flex-start" }}>
      <div>
        {list.map((item: any) => {
          return (
            <Input
              addonBefore={addonBefore}
              addonAfter={addonAfter}
              key={item.id}
              defaultValue={item.value}
              onChange={(e) => handleInput(e, item)}
              style={{ minWidth: "max-content" }}
            ></Input>
          );
        })}
      </div>
      {list.length === 3 ? null : (
        <Button type="link" onClick={() => handleCreate()}>
          +新增一个备选名称
        </Button>
      )}
    </div>
  );
};

const AddAdministration = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const searchParams = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [certificateForm] = Form.useForm();
  const [defaultRadio, setDefaultRadio] = useState("INDIVIDUAL");
  // 当前所选择税区
  const [currentSelect, setCurrentSelect] = useState<any>({});
  const [taxList, setTaxList] = useState<any>([]);
  const [details, setDetails] = useState<any>({});
  // 前缀和后缀默认值
  const [addonAfter, setAddonAfter] = useState("再生资源回收站");
  const [addonBefore, setAddonBefore] = useState("");
  // 创建确认框
  // 根据执照办理地 获取详情
  const getTaxInfos = (id?: any, obj?: any) => {
    if (!id || id === "") return;
    getTaxInfo(id).then((res: any) => {
      if (res.code === 200) {
        const val = res?.data?.businessNamePrefix || res.data.taxName;
        const startLen = val.length;
        const endStr = res?.data?.businessNameSuffix || "再生资源回收站";
        if (obj?.enterpriseNameList) {
          let newArr = JSON.parse(JSON.stringify(obj?.enterpriseNameList));
          const data = newArr.map((item: any) => {
            let lastIndex = item.lastIndexOf(endStr);
            item = item.substring(startLen, lastIndex);
            return item;
          });
          setDetails({
            ...details,
            ...obj,
            enterpriseNameList: data,
          });
        }
        setCurrentSelect(res.data);
        if (
          res?.data?.businessNameSuffix &&
          res?.data?.businessNameSuffix !== null
        ) {
          setAddonAfter(res?.data?.businessNameSuffix);
        }
        // setAddonBefore
        if (
          res?.data?.businessNameSuffix &&
          res?.data?.businessNameSuffix !== null
        ) {
          setAddonBefore(res?.data?.businessNamePrefix);
        } else {
          setAddonBefore(res?.data?.taxName);
        }
      }
    });
  };
  const getInfo = (id?: any) => {
    if (!id || id === "") return;
    getAddministrstionInfo(id).then((res: any) => {
      if (res.code === 200) {
        setDetails(res.data);
        form.setFieldsValue(res.data);
        certificateForm.setFieldsValue(res.data);
        if (res.data?.taxScreenshotList && res.data?.taxScreenshotList[0]) {
          certificateForm.setFieldValue(
            "taxScreenshotList1",
            res.data?.taxScreenshotList[0]
          );
          certificateForm.setFieldValue(
            "taxScreenshotList2",
            res.data?.taxScreenshotList[1]
          );
          certificateForm.setFieldValue(
            "taxScreenshotList3",
            res.data?.taxScreenshotList[2]
          );
        }
        getTaxInfos(res.data.taxRegionId, res.data);
      } else {
        message.error(res.message || "请求异常");
      }
    });
  };
  useEffect(() => {
    getInfo(searchParams?.id);
  }, [searchParams]);
  // 获取税区数据
  const getTaxLists = () => {
    const data = {
      size: 99999,
      current: 1,
    };
    getTaxList(data).then((res: any) => {
      if (res.code === 200) {
        setTaxList(res.data.records);
      }
    });
  };
  const handleSubmit = () => {
    const val = form.getFieldValue("applyType") || defaultRadio;
    const cal = form.validateFields().then((res: any) => {
      // return res
      let param = {
        ...res,
      };
      certificateForm.validateFields().then((data: any) => {
        // 新增
        if (!searchParams?.id) {
          setOpen(true);
          return;
        }
        param = {
          ...param,
          ...data,
        };
        let obj = {
          ...certificateForm.getFieldsValue(),
        };
        let arr: any = [];
        if (currentSelect?.isUploadTaxScreenshot) {
          arr.push(certificateForm.getFieldValue("taxScreenshotList1"));
          arr.push(certificateForm.getFieldValue("taxScreenshotList2"));
          arr.push(certificateForm.getFieldValue("taxScreenshotList3"));
          delete obj.taxScreenshotList1;
          delete obj.taxScreenshotList2;
          delete obj.taxScreenshotList3;
        }

        let params = {
          ...form.getFieldsValue(),
          taxScreenshotList: arr,
          ...obj,
          applyType: val,
          id: searchParams?.id,
          source: "运营中心",
        };
        addAdministration(params).then((res: any) => {
          if (res.code === 200) {
            message.success("编辑成功");
            navigate(-1);
          } else {
            message.error(res.message || "操作失败");
          }
        });
      });
    });
  };
  const handleOk = () => {
    const val = form.getFieldValue("applyType") || defaultRadio;
    const cal = form.validateFields().then((res: any) => {
      // return res
      let param = {
        ...res,
      };
      certificateForm.validateFields().then((data: any) => {
        param = {
          ...param,
          ...data,
        };
        let obj = {
          ...certificateForm.getFieldsValue(),
        };
        let arr: any = [];
        if (currentSelect?.isUploadTaxScreenshot) {
          arr.push(certificateForm.getFieldValue("taxScreenshotList1"));
          arr.push(certificateForm.getFieldValue("taxScreenshotList2"));
          arr.push(certificateForm.getFieldValue("taxScreenshotList3"));
          delete obj.taxScreenshotList1;
          delete obj.taxScreenshotList2;
          delete obj.taxScreenshotList3;
        }

        let params = {
          ...form.getFieldsValue(),
          taxScreenshotList: arr,
          ...obj,
          applyType: val,
          source: "运营中心",
        };
        addAdministration(params).then((res: any) => {
          if (res.code === 200) {
            message.success("新增成功");
            navigate(-1);
          } else {
            message.error(res.message || "操作失败");
          }
        });
      });
    });
  };
  useEffect(() => {
    // 获取所有的税区列表数据
    getTaxLists();
  }, []);
  return (
    <div className="container">
      {contextHolder}
      <div className="content">
        <BreadCrumbBar breads={breads}></BreadCrumbBar>
        <div>
          <h3>主体基本信息</h3>
          <Form {...layout} form={form}>
            <div>
              <FormItem label="办理类型" name="applyType">
                <Radio.Group
                  defaultValue={defaultRadio}
                  onChange={(e: any) => {
                    setDefaultRadio(e);
                  }}
                >
                  {radioOption.map((item: any) => {
                    return (
                      <Radio key={item.value} value={item.value}>
                        {item.label}
                      </Radio>
                    );
                  })}
                </Radio.Group>
              </FormItem>
            </div>

            <FormItem
              label="执照办理地"
              name="taxRegionId"
              rules={[{ required: true, message: "请选择执照办理地" }]}
            >
              <Select
                defaultValue={taxList[0] ? taxList[0].id : ""}
                onChange={(e) => {
                  const obj = taxList.find((item: any) => item.id === e) || {};
                  // const obj = JSON.parse(e)
                  // form.setFieldValue('taxRegionId', obj?.id)

                  getTaxInfos(e);
                }}
              >
                {taxList.map((item: any) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </FormItem>
            <FormItem
              label="申请人姓名"
              name="legal"
              rules={[
                {
                  required: true,
                  message: "请输入个体户姓名",
                },
              ]}
            >
              <Input placeholder="请输入个体户姓名"></Input>
            </FormItem>
            <FormItem
              label="联系电话"
              name="legalPhone"
              rules={[
                {
                  required: true,
                  message: "请输入联系电话",
                },
                {
                  pattern: /^1[0-9]{10}/,
                  message: "请输入正确的手机号",
                },
              ]}
            >
              <Input placeholder="请输入电话" maxLength={11}></Input>
            </FormItem>
            <FormItem
              label="身份证号"
              name="idNo"
              rules={[
                {
                  required: true,
                  message: "请输入身份证",
                },
                {
                  pattern:
                    /^[1-9]\d{5}(19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
                  message: "请输入正确的身份证号",
                },
              ]}
            >
              <Input maxLength={18} placeholder="请输入身份证号"></Input>
            </FormItem>
            <FormItem label="申请人住址" name="detailAddress">
              <Input></Input>
            </FormItem>
            <FormItem
              label="企业/个体户名称"
              name="enterpriseNameList"
              rules={[
                {
                  required: true,
                  message: "请输入企业名称",
                },
              ]}
            >
              <span>
                <CreateInput
                  value={details.enterpriseNameList}
                  addonBefore={addonBefore}
                  addonAfter={addonAfter}
                  cb={(value: any) => {
                    let res: any = [];
                    if (value && value.length !== 0) {
                      res = value.map((item: any) => {
                        item = addonBefore + item + addonAfter;
                        return item;
                      });
                    }

                    form.setFieldValue("enterpriseNameList", res);
                  }}
                ></CreateInput>
              </span>
            </FormItem>
            <FormItem label="关联集团" name="linkOrganization">
              <Input></Input>
            </FormItem>
            <h3>银行信息</h3>
            <FormItem
              label="银行账号"
              name="bankAccountNo"
              rules={[
                {
                  required: true,
                  message: "请输入银行账号",
                },
                {
                  pattern: /^\d{12,19}$/,
                  message: "请输入12-19位银行账号",
                },
              ]}
            >
              <Input
                minLength={12}
                maxLength={19}
                placeholder="请输入银行账号"
              ></Input>
            </FormItem>
            <FormItem
              label="银行预留手机号"
              name="bankPhoneNo"
              rules={[
                {
                  required: true,
                  message: "请输入银行预留手机号",
                },
                {
                  pattern: /^1[0-9]{10}/,
                  message: "请输入正确的手机号",
                },
              ]}
            >
              <Input placeholder="银行预留手机号" maxLength={11}></Input>
            </FormItem>
            <FormItem
              label="开户银行"
              name="bankName"
              rules={[
                {
                  required: true,
                  message: "请输入开户银行",
                },
              ]}
            >
              <Input placeholder="请输入开户银行"></Input>
            </FormItem>
            <FormItem
              label="开户支行"
              name="bankBranchName"
              rules={[
                {
                  required: true,
                  message: "请输入开户支行",
                },
              ]}
            >
              <Input placeholder="请输入开户支行"></Input>
            </FormItem>
          </Form>
          <h3>证照信息</h3>
          <Form
            form={certificateForm}
            initialValues={{
              layout: "inline",
            }}
          >
            <Row>
              <Col span={6}>
                <FormItem
                  name="idCardUrl"
                  rules={[
                    {
                      required: true,
                      message: "请上传身份证正面",
                    },
                  ]}
                >
                  <UploadCom
                    value={details?.photoUrlName?.idCardUrlName}
                    ButtonText="点击上传"
                    cb={(value: any) => {
                      const res = value[0] && value[0]?.filePath;
                      certificateForm.setFieldValue("idCardUrl", res);
                    }}
                  ></UploadCom>
                  <span className="rules_icon">*</span>
                  身份证正面
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem
                  name="idCardBackUrl"
                  rules={[
                    {
                      required: true,
                      message: "请上传身份证反面",
                    },
                  ]}
                >
                  <UploadCom
                    value={details?.photoUrlName?.idCardBackUrlName}
                    ButtonText="点击上传"
                    cb={(value: any) => {
                      const res = (value[0] && value[0]?.filePath) || "";
                      certificateForm.setFieldValue("idCardBackUrl", res);
                    }}
                  ></UploadCom>
                  <span className="rules_icon">*</span>
                  身份证反面
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem name="bankCardUrl">
                  <UploadCom
                    value={details?.photoUrlName?.bankCardUrlName}
                    ButtonText="点击上传"
                    cb={(value: any) => {
                      const res = value[0] && value[0]?.filePath;

                      certificateForm.setFieldValue("bankCardUrl", res);
                    }}
                  ></UploadCom>
                  银行卡正面
                </FormItem>
              </Col>

              <Col span={6}>
                <FormItem name="bankCardBackUrl">
                  <UploadCom
                    value={details?.photoUrlName?.bankCardBackUrlName}
                    ButtonText="点击上传"
                    cb={(value: any) => {
                      const res = value[0] && value[0]?.filePath;

                      certificateForm.setFieldValue("bankCardBackUrl", res);
                    }}
                  ></UploadCom>
                  银行卡反面
                </FormItem>
              </Col>
            </Row>
            {currentSelect?.isUploadTaxScreenshot ? (
              <>
                <h3>截图</h3>
                <Row>
                  <Col span={6}>
                    <FormItem
                      name="taxScreenshotList1"
                      rules={[
                        {
                          required: true,
                          message: "请上传第一张税祱通截图",
                        },
                      ]}
                    >
                      {details?.photoUrlName?.taxScreenshotListUrlNames ? (
                        <UploadCom
                          value={
                            details?.photoUrlName?.taxScreenshotListUrlNames[0]
                          }
                          ButtonText="点击上传"
                          cb={(value: any) => {
                            const res = value[0] && value[0]?.filePath;

                            certificateForm.setFieldValue(
                              "taxScreenshotList1",
                              res
                            );
                          }}
                        ></UploadCom>
                      ) : (
                        <UploadCom
                          ButtonText="点击上传"
                          cb={(value: any) => {
                            const res = value[0] && value[0]?.filePath;

                            certificateForm.setFieldValue(
                              "taxScreenshotList1",
                              res
                            );
                          }}
                        ></UploadCom>
                      )}
                      <span className="rules_icon">*</span>
                      税祱通截图1
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      name="taxScreenshotList2"
                      rules={[
                        {
                          required: true,
                          message: "请上传第二张税祱通截图",
                        },
                      ]}
                    >
                      {details?.photoUrlName?.taxScreenshotListUrlNames ? (
                        <UploadCom
                          value={
                            details?.photoUrlName?.taxScreenshotListUrlNames[1]
                          }
                          ButtonText="点击上传"
                          cb={(value: any) => {
                            const res = value[0] && value[0]?.filePath;

                            certificateForm.setFieldValue(
                              "taxScreenshotList2",
                              res
                            );
                          }}
                        ></UploadCom>
                      ) : (
                        <UploadCom
                          ButtonText="点击上传"
                          cb={(value: any) => {
                            const res = value[0] && value[0]?.filePath;

                            certificateForm.setFieldValue(
                              "taxScreenshotList2",
                              res
                            );
                          }}
                        ></UploadCom>
                      )}
                      <span className="rules_icon">*</span>
                      税祱通截图2
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      name="taxScreenshotList3"
                      rules={[
                        {
                          required: true,
                          message: "请上传第三张税祱通截图",
                        },
                      ]}
                    >
                      {details?.photoUrlName?.taxScreenshotListUrlNames ? (
                        <UploadCom
                          value={
                            details?.photoUrlName?.taxScreenshotListUrlNames[2]
                          }
                          ButtonText="点击上传"
                          cb={(value: any) => {
                            const res = value[0] && value[0]?.filePath;

                            certificateForm.setFieldValue(
                              "taxScreenshotList3",
                              res
                            );
                          }}
                        ></UploadCom>
                      ) : (
                        <UploadCom
                          ButtonText="点击上传"
                          cb={(value: any) => {
                            const res = value[0] && value[0]?.filePath;

                            certificateForm.setFieldValue(
                              "taxScreenshotList3",
                              res
                            );
                          }}
                        ></UploadCom>
                      )}
                      <span className="rules_icon">*</span>
                      税祱通截图3
                    </FormItem>
                  </Col>
                </Row>
              </>
            ) : null}
          </Form>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => {
                navigate(-1);
              }}
            >
              关闭
            </Button>
            <Button type="primary" onClick={() => handleSubmit()}>
              保存
            </Button>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        title="确认保存"
        onCancel={() => {
          setOpen(false);
        }}
        onOk={handleOk}
        okText="确认保存"
        cancelText="取消"
        width={550}
      >
        <p>
          <label>个体户姓名:</label>
          {form.getFieldValue("legal")}
        </p>
        <p>手机号(账号):{form.getFieldValue("legalPhone")}</p>
        <Button type="link">
          系统自动发送短信通知个体户，个体户在短信中签署协议后，启动营业执照办理
        </Button>
      </Modal>
    </div>
  );
};

export default AddAdministration;
