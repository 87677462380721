import React, { useEffect, useState } from "react";
import { getLogs } from "../../promise";
import Tables from "../../../../../../components/TableCom";
import "./index.scss";
const Logs = (props: any) => {
    const { details } = props;
    const [list, setList] = useState([]);
    const [pageOptions, setPageOptions] = useState({
        pageSize: 50,
        current: 1,
    });
    const [total, setTotal] = useState(0);
    const getLogList = (object?: any) => {
        if (!details?.id || details?.id === "") return;
        const params = {
            serviceId: details?.id,
            operateTypeEnum: "OPERATE_RECORD",
            size: pageOptions.pageSize,
            current: pageOptions.current,
            ...object,
        };
        getLogs(params).then((res: any) => {
            if (res.data.code === 200) {
                setTotal(res?.data?.data?.total);
                setList(res.data.data.records);
            }
        });
    };
    // 分页查询
    const handleChange = (params?: any) => {
        setPageOptions({
            pageSize: params?.pageSize,
            current: params?.current,
        });
        getLogList({
            size: params.pageSize,
            current: params.current,
        });
    };
    useEffect(() => {
        getLogList();
    }, [details]);
    const columns = [
        {
            title: "操作时间",
            dataIndex: "optTime",
        },
        {
            title: "操作人",
            dataIndex: "optUserName",
        },
        {
            title: "操作步骤",
            dataIndex: "optName",
        },
        {
            title: "详细内容",
            width: "300",
            dataIndex: "description",
        },
    ];

    return (
        <div className="licens_info_details_log">
            <div className="licens_info_details_log_title">操作日志</div>
            <div className="licens_info_details_log_table">
                <Tables
                    rowKey={(record: any) => record.id}
                    columns={columns}
                    dataSource={list}
                    onChange={handleChange}
                    pagination={{
                        ...pageOptions,
                        total: total,
                    }}
                ></Tables>
            </div>
        </div>
    );
};

export default Logs;
