export const statusOptions = [
  {
    label: "审核通过",
    value: "APPROVED",
  },
  {
    label: "审核拒绝",
    value: "REJECTED",
  },
  {
    label: "待审核",
    value: "TO_BE_REVIEWED",
  },
];

// 企业类型
export const enterpriseTypes = [
  {
    label: "金融机构",
    value: "FINANCIAL_INSTITUTIONS",
  },
  {
    label: "事业单位",
    value: "INSTITUTIONS",
  },
  {
    label: "股份有限公司(上市)",
    value: "LISTING_COMPANY",
  },
  {
    label: "股份有限公司(非上市)",
    value: "NOT_LISTING_COMPANY",
  },
  {
    label: "有限责任公司",
    value: "LIMITED_LIABILITY_COMPANY",
  },
  {
    label: "合伙企业",
    value: "PARTNERSHIP",
  },
  {
    label: "个体工商户",
    value: "INDIVIDUAL_INDUSTRIAL",
  },
  {
    label: "其他",
    value: "OTHER",
  },
];

export const uploadSelect = [
  {
    label: "营业执照",
    value: "BUSINESS_LICENSE",
  },
  {
    label: "法人身份证正面",
    value: "LEGAL_PERSON_IDENTITY_CARD_FRONT",
  },
  {
    label: "征信授权书",
    value: "CREDIT_AUTHORIZATION_LETTER",
  },
  {
    label: '法人身份证反面',
    value:'LEGAL_PERSON_IDENTITY_CARD_OPPOSITE'
  }
  // {
  //     label: '发票',
  //     value: 'INVOICE'
  // },
  // {
  //     label: '贸易合同',
  //     value: 'TRADE_CONTRACT'
  // },
  // {
  //     label: '订单附件',
  //     value: 'ORDER_ATTACHMENTS'
  // },
  // {
  //     label: '借款合同',
  //     value: 'LOAN_CONTRACT'
  // },
  // {
  //     label: '担保函',
  //     value: 'LETTER_OF_GUARANTEE'
  // },
];
