import React from "react";
import { Table, Pagination, Spin } from "antd";
import "./index.scss";

const TableCom = (props: any) => {
    const {
        columns,
        dataSource,
        onChange,
        pagination,
        scroll,
        rowKey,
        showQuickJumper = true,
        total,
        showSizeChanger = true,
        style = {
            // maxHeight:'60vh',
            // minHight:'200px',
            // overflow:'auto'
        },
        defaultPageSize = 10,
        pageSizeOptions = [10, 20, 50, 100],
        params,
        changeColumn,
        loading = false,
    } = props;
    return (
        <Spin spinning={loading}>
            <div>
                <Table
                    style={style}
                    rowClassName="rowClassName"
                    rowKey={rowKey}
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    scroll={scroll}
                    {...params}
                    size="small"
                    onChange={(pagination, filters, sorter, extra) => {
                        changeColumn &&
                            changeColumn(pagination, filters, sorter, extra);
                    }}
                />
                {total === 0 ? null : (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: "5px",
                        }}
                    >
                        <Pagination
                            size="small"
                            showQuickJumper={showQuickJumper}
                            total={total}
                            showSizeChanger={showSizeChanger}
                            {...pagination}
                            showTotal={(total: number) => {
                                return `总共${total}条`;
                            }}
                            onChange={(current, pageSize) => {
                                const params = {
                                    current: current,
                                    pageSize: pageSize,
                                };
                                onChange && onChange(params);
                            }}
                            defaultPageSize={defaultPageSize}
                            defaultCurrent={1}
                            pageSizeOptions={pageSizeOptions}
                        ></Pagination>
                    </div>
                )}
            </div>
        </Spin>
    );
};

export default TableCom;
