import React, { useState, useEffect } from "react";
import { Button, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import classnames from "classnames";
import TableCom from "@src/components/TableCom";
import SearchForm from "@src/components/FormItem";
import BreadCrumbBar from "@src/components/BreadCrumbBar";
import {
  statusList,
  taskSourceObjs,
  statusObjs,
  invoiceTypeObjs,
} from "./config";
import dayjs from "dayjs";
import { getInvocesList, reInvoicing, createInvoces } from "./promise";
import { useNavigate } from "react-router-dom";
import "./index.scss";
const breads = [
  {
    label: "税票系统",
    path: "",
  },
  {
    label: "开票任务",
    path: "",
  },
];
const InvoicingTask = () => {
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pageOptions, setPageOptions] = useState({
    current: 1,
    pageSize: 20,
  });
  const [list, setList] = useState([]);
  const [search, setSearch] = useState({});
  // 搜索
  const searchColumns = [
    {
      label: "状态",
      type: "Select",
      dataIndex: "taskStatus",
      params: {
        placeholder: "请选择",
        options: statusList,
      },
    },
    {
      label: "创建时间",
      dataIndex: "createTime",
      type: "DatePickers",
      params: {},
    },
    {
      label: "完成时间",
      dataIndex: "completeTime",
      type: "DatePickers",
      params: {},
    },
    {
      label: "开票员",
      dataIndex: "taxClerk",
      type: "Input",
      params: {
        placeholder: "请输入",
      },
    },
    {
      label: "关键字搜索",
      dataIndex: "query",
      type: "Input",
      params: {
        placeholder: "请输入任务编号、开票方、订单ID",
        suffix: <SearchOutlined style={{ color: "#ccc" }} />,
        style: {
          width: "300px",
        },
      },
    },
  ];
  const columns = [
    {
      title: "任务编号",
      dataIndex: "taskId",
    },
    {
      title: "来源",
      dataIndex: "taskSource",
      render: (record: any) => {
        return <span>{taskSourceObjs[record]}</span>;
      },
    },
    {
      title: "订单ID",
      dataIndex: "orderNo",
    },
    {
      title: "开票方",
      dataIndex: "sellerName",
    },
    {
      title: "发票模式",
      dataIndex: "invoiceType",
      render: (record: any) => {
        return <span>{invoiceTypeObjs[record]}</span>;
      },
    },
    {
      title: "金额",
      dataIndex: "amountWithTax",
    },
    {
      title: "已开金额",
      dataIndex: "invoicedAmount",
    },
    {
      title: "开票员",
      dataIndex: "invoiceIssuer",
    },
    {
      title: "状态",
      dataIndex: "taskStatus",
      render: (record: any) => {
        return (
          <div className={classnames("invoces_container_list_status")}>
            <span
              className={classnames(
                `default_icon_span ${statusObjs[record]?.color}`
              )}
            ></span>
            {statusObjs[record]?.label}
          </div>
        );
      },
    },
    {
      title: "备注",
      dataIndex: "failReason",
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
    },
    {
      title: "完成时间",
      dataIndex: "updateTime",
    },
    {
      title: "操作",
      fixed: "right",
      width: 180,
      render: (record: any) => {
        return (
          <div>
            {["PENDING", "PROCESSING"].includes(record?.taskStatus) && (
              <Button type="link" onClick={() => handleInvoicing(record)}>
                重开票
              </Button>
            )}

            <Button
              type="link"
              onClick={() => {
                navigate(`/ticket/InvoicingTask/info/${record?.id}`);
              }}
            >
              查看详情
            </Button>
          </div>
        );
      },
    },
  ];
  // 重开票
  const handleInvoicing = async (record?: any) => {
    if (!record?.id) return;
    const res: any = await createInvoces(record?.id, {
      fileId: null,
      vatInvoiceResponse: null,
    });
    if (res?.code === 200) {
      message.success("重开成功");
      getList({
        ...search,
        current: pageOptions?.current,
        size: pageOptions?.pageSize,
      });
    }
  };
  // 搜索
  const getList = async (object?: any) => {
    setLoading(true);
    const params = {
      current: pageOptions?.current,
      size: pageOptions?.pageSize,
      ...object,
    };
    const res: any = await getInvocesList(params);
    setLoading(false);
    if (res?.code === 200) {
      setTotal(res?.data?.total);
      setList(res?.data?.records);
    }
  };
  useEffect(() => {
    getList();
  }, [pageOptions?.current, pageOptions?.pageSize]);
  const onSearch = (values: any) => {
    let params: any = {
      ...values,
    };
    if (values?.createTime) {
      params.createTimeBegin =
        dayjs(values.createTime[0]).format("YYYY-MM-DD") + " 00:00:00";
      params.createTimeEnd =
        dayjs(values.createTime[1]).format("YYYY-MM-DD") + " 23:59:59";
      delete params.createTime;
    }
    if (values?.completeTime) {
      params.completeTimeBegin =
        dayjs(values.completeTime[0]).format("YYYY-MM-DD") + " 00:00:00";
      params.completeTimeEnd =
        dayjs(values.completeTime[1]).format("YYYY-MM-DD") + " 23:59:59";
      delete params.completeTime;
    }
    setSearch(params);
    getList(params);
  };
  const handleChange = (params: any) => {
    setPageOptions({
      current: params.current,
      pageSize: params.pageSize,
    });
  };
  return (
    <div className={classnames("invoices_container")}>
      <BreadCrumbBar breads={breads}></BreadCrumbBar>
      <div className={classnames("invoices_search")}>
        <SearchForm formParams={searchColumns} onSearch={onSearch}></SearchForm>
      </div>
      <div className={classnames("invoices_table_container")}>
        <div className={classnames("invoices_table_container_title")}>
          开票任务列表
        </div>
        <TableCom
          rowKey={(record: any) => record?.id}
          columns={columns}
          dataSource={list}
          pageOptions={{
            ...pageOptions,
          }}
          total={total}
          loading={loading}
          onChange={handleChange}
          scroll={{
            x: true,
          }}
        ></TableCom>
      </div>
    </div>
  );
};

export default InvoicingTask;
