import { useRequest } from "ahooks";
import { createContext } from "react";
import { getEntityById, getOrderDetailById, getSupplyContracts } from "./service";
import { convertData } from './utils';
import { useParams } from "react-router-dom";

export const useOrderDetail = ()=>{
  const params = useParams<{id:string}>();

  const orderDetailService = useRequest(getOrderDetailById,{
    defaultParams:[params.id!],
    onSuccess:(res)=>{
      if( res.data.sellerId&& res.data.buyerId){
        entitiesService.run({sellerId: res.data.sellerId,buyerId: res.data.buyerId})
        supplyContractsService.run({sellerId: res.data.sellerId,buyerId: res.data.buyerId,effective:1,current: 1,size:999})
      }
    }
  })

  const supplyContractsService = useRequest(getSupplyContracts,{
    manual:true,
  })

  const entitiesService = useRequest(async (params:{sellerId: number,buyerId:number})=>{
    const results = await Promise.allSettled([getEntityById(params.sellerId.toString()),getEntityById(params.buyerId.toString())])
    const [seller,buyer] = results.map((result)=>result.status==='fulfilled'?result.value.data:undefined)
    return {
      seller,
      buyer
    }
  },{
    manual:true,
  })
  console.log(orderDetailService,'orderDetailService')
  const data = convertData(orderDetailService.data?.data,supplyContractsService.data?.data.records,entitiesService.data)
  const loading = orderDetailService.loading || supplyContractsService.loading || entitiesService.loading
  const refresh = async ()=>{
   return await orderDetailService.refreshAsync()
  }
  return {data,loading,refresh}
}

export const OrderDetailContext = createContext<ReturnType<typeof useOrderDetail>|null>(null)



