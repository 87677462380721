import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
    getInvocesInfo,
    getIdentifyInfoOCR,
    getInvoices,
    createInvoces,
} from "../promise";
import classnames from "classnames";
import { Button, Row, Col, Table, message } from "antd";
import TableCom from "@src/components/TableCom";
import SearchForm from "@src/components/FormItem";
import BreadCrumbBar from "@src/components/BreadCrumbBar";
import dayjs from "dayjs";
import { statusObjs, invoiceTypeObjs } from "../config";
import InvoicesDetails from "./Components/InvoiceDetails";
import UploadCom from "./Components/UploadCostom";
import "./index.scss";
const breads = [
    {
        label: "税票系统",
        path: "",
    },
    {
        label: "开票任务",
        path: "",
    },
    {
        label: "详情页",
        path: "",
    },
];
const InvocesInfo = () => {
    const searchParams: any = useParams();
    const local = JSON.parse(
        localStorage.getItem("operation_authorization") || "{}"
    );
    const invoces = JSON.parse(localStorage.getItem("invoces") || "[]");
    const [details, setDetails] = useState<any>({});
    // 发票信息列表
    const [invocesList, setInvocesList] = useState([]);
    const [InvoiceBackup, setInvoiceBackup] = useState([]);
    // 上传的文件信息
    const [files, setFils] = useState<any>({});
    // ocr识别信息
    const [ocrFiles, setOCRFiles] = useState<any>({});
    const [loading, setLoading] = useState(false);
    // 获取当前用户信息  以及对应的id
    const isCache = useMemo(() => {
        const localCache = JSON.parse(
            localStorage.getItem("cacheInformation") || "{}"
        );
        return (
            localCache?.invoces_task_id === searchParams?.id &&
            localCache?.userId === local?.userId
        );
    }, [searchParams, local]);
    // 待保存 数据
    const isSavedList = useMemo(() => {
        if (isCache && invoces.length) {
            return invoces;
        } else {
            return InvoiceBackup;
        }
    }, [invoces, InvoiceBackup, isCache]);
    // 是否待保存  缓存数据大于后端返回数据
    const isSaved = useMemo(() => {
        return (
            isCache && isSavedList && isSavedList.length > InvoiceBackup.length
        );
    }, [isSavedList, InvoiceBackup, isCache]);
    // 清除本地缓存
    const removeItem = () => {
        localStorage.removeItem("invoces");
        localStorage.removeItem("cacheInformation");
    };
    // 添加本地缓存
    const localSetItem = (list: any = []) => {
        localStorage.setItem("invoces", JSON.stringify(list));
    };
    // 获取当前开票任务详情
    const getInfo = async (id?: any) => {
        if (!id) return;
        const res = await getInvocesInfo(id);
        if (res?.code === 200) {
            setDetails(res?.data);
            getInvocesList(res?.data?.taskId);
        }
    };
    // 获取当前开票任务  开票列表
    const getInvocesList = async (taskId?: any) => {
        if (!taskId) return;
        setLoading(true);
        const res = await getInvoices(taskId);
        if (res?.code === 200) {
            setLoading(false);
            const list = res?.data?.map((item: any) => {
                item.customTotalTaxAmount = (
                    Number(item.taxAmount) + Number(item.amountWithoutTax)
                ).toFixed(2);
                return item;
            });
            setInvoiceBackup(list);
        }
    };
    // 发票上传信息识别 成功后保存
    const handleOCR = (value: any) => {
        if (!value?.filePath) return;
        setLoading(true);
        setFils(value);
        getIdentifyInfoOCR(value?.filePath).then((res: any) => {
            setLoading(false);
            if (res?.code === 200) {
                setOCRFiles(res?.data);
                const list: any = [...isSavedList];
                // 取ocr识别的第一条数据
                const currentObjs = res?.data?.items ? res?.data?.items[0] : {};
                const params = {
                    ...res.data,
                    id: value.filePath,
                    ...currentObjs,
                    amountWithTax: (
                        Number(currentObjs?.taxAmount) +
                        Number(currentObjs?.amountWithoutTax)
                    ).toFixed(2),
                    itemQuantity: currentObjs?.quantity,
                    // invoiceDate:
                };
                list.unshift(params);
                localSetItem(list);
                // 缓存当前用户信息  以及对应的开票任务详情task—id
                localStorage.setItem(
                    "cacheInformation",
                    JSON.stringify({
                        invoces_task_id: searchParams.id,
                        userId: local?.userId,
                    })
                );
            }
        }).catch(() => setLoading(false));
    };
    // 表格数据取消保存
    const handleCancel = () => {
        removeItem();
        getInvocesList(details?.taskId);
    };
    // 重开票
    const handleInvoicing = async () => {
        if (!searchParams?.id) return;
        const res: any = await createInvoces(searchParams?.id, {
            fileId: null,
            vatInvoiceResponse: null,
        });
        if (res?.code === 200) {
            message.success("重开成功");
            getInfo(searchParams?.id);
        }
    };
    // 保存数据
    const handleSave = (values: any) => {
        if (!details?.taskId) return;
        const params = {
            fileId: files?.filePath,
            // vatInvoiceResponse: values,
            ...values,
        };
        // // 带有ocr识别的数据
        if (isSaved) {
            params.fileId = isSavedList[0]?.id;
        }
        createInvoces(searchParams?.id, params).then((res: any) => {
            if (res?.code === 200) {
                // 保存成功后  需将数据清除
                removeItem();
                getInvocesList(details?.taskId);
            }
        });
    };
    // 删除数据
    const handleDelete = () => { };
    useEffect(() => {
        getInfo(searchParams?.id);
    }, [searchParams]);
    // 发票信息
    const columns = [
        {
            title: "序号",
            render: (record: any, text: any, index?: number) => {
                return <span>{index ? index + 1 : 1}</span>;
            },
        },
        {
            title: "税收分类编码",
            dataIndex: "itemTaxCode",
        },
        {
            title: "分类简称",
            dataIndex: "itemTaxName",
        },
        {
            title: "商品名称",
            dataIndex: "itemProjectName",
        },
        {
            title: "规格型号",
            dataIndex: "itemSpecification",
        },
        {
            title: "数量",
            dataIndex: "itemQuantity",
        },
        {
            title: "单位",
            dataIndex: "itemUnit",
        },
        {
            title: "商品单价(不含税)",
            dataIndex: "itemPrice",
        },
        {
            title: '金额',
            dataIndex: 'amountWithoutTax'
        },
        {
            title: '税率',
            dataIndex:'taxRate'
        },
        {
            title: '税额',
            dataIndex:'taxAmount'
        }
    ];
    return (
        <div className={classnames("invoces_info_container")}>
            <BreadCrumbBar breads={breads}></BreadCrumbBar>
            {/* 任务信息 */}
            <div className={classnames("invoces_info_content")}>
                <div className={classnames("invoces_info_content_title")}>
                    任务编号：{details?.taskId}{" "}
                    <span className={classnames("invoces_info_content_title_desc")}>
                        电子发票(增值税{invoiceTypeObjs[details?.invoiceType]})
                    </span>
                </div>
                <div className={classnames("invoces_info_content_html")}>
                    <div className={classnames("details_task_status_content")}>
                        开票进度{" "}
                        <div
                            className={classnames(
                            `details_task_status ${
                                statusObjs[details?.taskStatus]?.color
                            }_bg`
                            )}
                        >
                            <span
                            className={classnames(
                                `details_task_status_icon ${
                                statusObjs[details?.taskStatus]?.color
                                }`
                            )}
                            ></span>
                            {statusObjs[details?.taskStatus]?.label}
                        </div>
                    </div>
                    {["PENDING", "PROCESSING"].includes(details?.taskStatus) && (
                    <Button type="primary" onClick={() => handleInvoicing()}>
                        重开票
                    </Button>
                    )}
                </div>
            </div>
            {/* 开票方信息 */}
            <div className={classnames("invoces_info_content")}>
            <div className={classnames("invoces_info_content_title")}>
                开票方信息
            </div>
            <div className={classnames("invoces_info_content_text")}>
                <div className={classnames("text_title")}>购方信息</div>
                <div className={classnames("mar_btm_16")}>
                <Row>
                    <Col span="8">
                    <div
                        className={classnames("invoicing_information_container")}
                    >
                        购方单位：
                        <span
                        className={classnames(
                            "invoicing_information_container_value"
                        )}
                        >
                        {details?.buyerName}
                        </span>
                    </div>
                    <div
                        className={classnames("invoicing_information_container")}
                    >
                        购方电话：
                        <span
                        className={classnames(
                            "invoicing_information_container_value"
                        )}
                        >
                        {details?.buyerPhone}
                        </span>
                    </div>
                    </Col>
                    <Col span="8">
                    <div
                        className={classnames("invoicing_information_container")}
                    >
                        税号：
                        <span
                        className={classnames(
                            "invoicing_information_container_value"
                        )}
                        >
                        {details?.buyerTaxNo}
                        </span>
                    </div>
                    <div
                        className={classnames("invoicing_information_container")}
                    >
                        开户行：
                        <span
                        className={classnames(
                            "invoicing_information_container_value"
                        )}
                        >
                        {details?.buyerBankBranch}
                        </span>
                    </div>
                    </Col>
                    <Col span="8">
                    <div
                        className={classnames("invoicing_information_container")}
                    >
                        购方地址：
                        <span
                        className={classnames(
                            "invoicing_information_container_value"
                        )}
                        >
                        {details?.buyerAddress}
                        </span>
                    </div>
                    <div
                        className={classnames("invoicing_information_container")}
                    >
                        账号：
                        <span
                        className={classnames(
                            "invoicing_information_container_value"
                        )}
                        >
                        {details?.buyerBankNo}
                        </span>
                    </div>
                    </Col>
                </Row>
                </div>
                <div className={classnames("text_title")}>销方信息</div>
                <Row>
                <Col span="8">
                    <div className={classnames("invoicing_information_container")}>
                    销方单位：
                    <span
                        className={classnames(
                        "invoicing_information_container_value"
                        )}
                    >
                        {details?.sellerName}
                    </span>
                    </div>
                    <div className={classnames("invoicing_information_container")}>
                    销方电话：
                    <span
                        className={classnames(
                        "invoicing_information_container_value"
                        )}
                    >
                        {details?.sellerPhone}
                    </span>
                    </div>
                </Col>
                <Col span="8">
                    <div className={classnames("invoicing_information_container")}>
                    税号：
                    <span
                        className={classnames(
                        "invoicing_information_container_value"
                        )}
                    >
                        {details?.sellerTaxNo}
                    </span>
                    </div>
                    <div className={classnames("invoicing_information_container")}>
                    开户行：
                    <span
                        className={classnames(
                        "invoicing_information_container_value"
                        )}
                    >
                        {details?.sellerBankBranch}
                    </span>
                    </div>
                </Col>
                <Col span="8">
                    <div className={classnames("invoicing_information_container")}>
                    销方地址：
                    <span
                        className={classnames(
                        "invoicing_information_container_value"
                        )}
                    >
                        {details?.sellerAddress}
                    </span>
                    </div>
                    <div className={classnames("invoicing_information_container")}>
                    账号：
                    <span
                        className={classnames(
                        "invoicing_information_container_value"
                        )}
                    >
                        {details?.sellerBankNo}
                    </span>
                    </div>
                </Col>
                </Row>
            </div>
            </div>
            {/* 发票信息 */}
            <div className={classnames("invoces_info_content")}>
            <div className={classnames("invoces_info_content_title")}>
                <span>发票信息</span>
            </div>
            <div className={classnames("invoces_info_content_text")}>
                <Table
                columns={columns}
                dataSource={[details]}
                pagination={false}
                size="small"
                ></Table>
                {/* <div className={classnames("pad_top_16")}></div> */}
                <Row className={classnames("pad_top_16")}>
                <Col span="8">
                    <div className={classnames("invoicing_information_container")}>
                    合计金额：
                    <span
                        className={classnames(
                        "invoicing_information_container_value"
                        )}
                    >
                        {details?.amountWithoutTax}
                    </span>
                    </div>
                    <div className={classnames("invoicing_information_container")}>
                    开票流水号：
                    <span
                        className={classnames(
                        "invoicing_information_container_value"
                        )}
                    >
                        {details?.cashReceiptChecker}
                    </span>
                    </div>
                </Col>
                <Col span="8">
                    <div className={classnames("invoicing_information_container")}>
                    合计税额：
                    <span
                        className={classnames(
                        "invoicing_information_container_value"
                        )}
                    >
                        {details?.taxAmount}
                    </span>
                    </div>
                    <div className={classnames("invoicing_information_container")}>
                    开票人：
                    <span
                        className={classnames(
                        "invoicing_information_container_value"
                        )}
                    >
                        {details?.invoiceIssuer}
                    </span>
                    </div>
                </Col>
                <Col span="8">
                    <div className={classnames("invoicing_information_container")}>
                    价税合计：
                    <span
                        className={classnames(
                        "invoicing_information_container_value"
                        )}
                    >
                        {details?.amountWithTax}
                    </span>
                    </div>
                    <div className={classnames("invoicing_information_container")}>
                    备注：
                    <span
                        className={classnames(
                        "invoicing_information_container_value"
                        )}
                    >
                        {details?.notes}
                    </span>
                    </div>
                </Col>
                </Row>
            </div>
            </div>
            {/* 发票详情     列表 */}
            <div className={classnames("invoces_info_content")}>
            <div className={classnames("invoces_info_content_title flex")}>
                发票详情
                {/* 待保存缓存数据之前是不允许再次上传发票 */}
                {loading || isSaved ? (
                <Button disabled type="primary">
                    上传发票
                </Button>
                ) : (
                <UploadCom
                    name="发票"
                    ButtonText={<Button type="primary">上传发票</Button>}
                    accept=".pdf"
                    cb={handleOCR}
                ></UploadCom>
                )}
            </div>
            <InvoicesDetails
                detailData={details}
                onCancel={handleCancel}
                onSave={handleSave}
                onDelete={handleDelete}
                isSavedList={isSavedList}
                isSaved={isSaved}
                loading={loading}
            ></InvoicesDetails>
            </div>
        </div>
    );
};

export default InvocesInfo;
