export const contractStatusOptions = [
    {
        label: '未签署',
        value:'PENDING'
    },
    {
        label: '卖家已签署',
        value:'SELLER_COMPLETED'
    },
    {
        label: '买家已签署',
        value:'BUYER_COMPLETED'
    },
    {
        label: '已完成',
        value:'ALL_COMPLETED'
    },
    {
        label: '已撤销',
        value: 'REVOKED'
    }
]
export const contractStatusColors = {
    PENDING: {
        label: '未签署',
        color:'default'
    },
    PROCESSING: {
        label: "签署中",
        color:'pedding'
    },
    ALL_COMPLETED:{
        label: "已完成",
        color:'success'
    },
    SELLER_COMPLETED: {
        label: "卖家已签署",
        color:'pedding'
    },
    BUYER_COMPLETED: {
        label: "买家已签署",
        color:'pedding'
    },
    REVOKED: {
        label: '已撤销',
        value: 'default'
    }
}

// 开票状态
export const orderInvoiceStatusOptions = [
    {
        label: '待开票',
        value:'PENDING'
    },
    {
        label: '开票中',
        value:'PROCESSING'
    },
    {
        label: '已完成',
        value:'COMPLETED'
    }
]

// 订单类型
export const orderTypesOptions = [
    {
        label: '采购订单',
        value:'PURCHASE_ORDER'
    },
    {
        label: '奖励订单',
        value:'AWARD_ORDER'
    },
    {
        label: '补税订单',
        value:'TAX_ORDER'
    }
]