import { message } from "antd";

// 生成唯一uuid
export const uuid = () => {
    let date = new Date().valueOf(); //获取时间戳
    let txt = "1234567890"; //生成的随机机器码
    let len = 13; //机器码有多少位
    let pwd = ""; //定义空变量用来接收机器码
    for (let i = 0; i < len; i++) {
        pwd += txt.charAt(Math.floor(Math.random() * txt.length)); //循环机器码位数随机填充
    }
    return date + pwd;
};

// form  多个参数转换为数组

export const objToArr = (object = {}, num = 2) => {
    const obj = {
        type_0: "123",
        type_1: "234",
        position_0: "34567890",
        position_1: "-----",
    };
    let arr: any = [];
    const keys = Object.keys(object);
    for (let w = 0; w < num; w++) {
        let newObj = {};
        for (let i = 0; i < keys.length; i++) {
            let spl = keys[i].split("_");
            if (spl[0] + `_${w}` === keys[i]) {
                const res = arr.some((item: any) => {
                    return item[spl[0]] === object[spl[0] + `_${w}`];
                });
                if (!res) {
                    newObj[spl[0]] = object[spl[0] + `_${w}`];
                }
                arr[w] = newObj;
            }
        }
    }

    console.log(arr, "arrr---------------");
    return arr;
};
// 数组转换为form

export const arrToObject = (arr?: any) => {
    const data = [
        {
            type: "12443234",
            position: "4444",
        },
        {
            type: "1111",
            position: "2222",
        },
    ];

    let obj: any = {};
    for (let i = 0; i < data.length; i++) {
        for (let item in data[i]) {
            obj[item + "_" + i] = data[i][item];
        }
    }
    return obj;
};

export const downloadUrlFile = (url: string) => {
    var download_a = document.createElement("a");
    download_a.setAttribute("href", url);
    download_a.setAttribute("target", "_blank");
    download_a.setAttribute("id", "camnpr");
    document.body.appendChild(download_a);
    download_a.click();
    document.body.removeChild(download_a);
};

export const downloadFile = (url: string, name: string) => {
    const x = new window.XMLHttpRequest();
    x.open("GET", url, true);
    x.responseType = "blob";
    x.onload = () => {
        const url = window.URL.createObjectURL(x.response);
        const a = document.createElement("a");
        a.href = url;
        a.target = "_blank";
        a.download = name;
        a.style.display = "none";
        document.body.append(a);
        a.click();
    };
    x.send();
};

// 复制
export const copyText = async (val: string) => {
    // 判断当前是否存在复制方法
    if (navigator.clipboard && navigator.permissions) {
        await navigator.clipboard.writeText(val);
    } else {
        const textArea: any = document.createElement("textArea");
        textArea.value = val;
        textArea.style.width = 0;
        textArea.style.position = "fixed";
        textArea.style.left = "-999px";
        textArea.style.top = "10px";
        textArea.setAttribute("readonly", "readonly");
        document.body.appendChild(textArea);

        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
    }
    message.success("复制成功");
};

// 下载图片
export const downloadImage = (url: string, filename: string = uuid()): void => {
    const canvas = document.createElement("canvas");
    const ctx: any = canvas.getContext("2d");
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.onload = function () {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        console.log(canvas, "cnavas");
        const dataURL = canvas.toDataURL("image/png");

        const link: any = document.createElement("a");
        link.download = filename;
        link.href = dataURL;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    img.src = url;
};
export const fileLimitType = (accept: string[] = ["*/*"], file: any) => {
    const isAccept =
        (accept?.filter((type) => {
            const targetType = type.split("/");
            const currentType = file.type.split("/");
            return (
                (targetType[0] === currentType[0] || targetType[0] === "*") &&
                (targetType[1] === currentType[1] || targetType[1] === "*")
            );
        }).length || 0) > 0;
    return isAccept ? Promise.resolve(true) : Promise.reject("文件类型不合法");
};
