import React, { useState, useEffect, useMemo } from "react";
import styles from "./index.module.scss";
import BreadCrumbBar from "../../../../components/BreadCrumbBar";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAddministrstionInfo, getImageUrl } from "./promise";
import BasicInfo from "./components/BasicInfo";
import StepCom from "./components/StepCom";
import Logs from "./components/Logs";
import SubmitInfo from "./components/SubmitInfo";

const breads = [
    {
        label: "代办业务",
        path: "/business",
    },
    {
        label: "工商税务开办",
        path: "/business/administration/list",
    },
];
// 注销流程
const AgencyInfo = () => {
    // 角色code
    const roleList = useSelector((state: any) => state.roleCodes);
    const searchParams = useParams();
    
    const [details, setDetails] = useState<any>({});
    const [isSuc, setIsSuc] = useState(false);
    const local: any = localStorage.getItem("operation_authorization") || "{}";
    // 注销创建人  发起注销申请书  权限
    const logOff = useMemo(() => {
        return details?.createUser === JSON.parse(local)?.userId;
    }, [details]);
    // 办税人权限
    const taxRole = useMemo(() => {
        return details?.taxAgentId === JSON.parse(local)?.userId;
    }, [details]);
    // 营业执照办理人权限
    const licenseRole = useMemo(() => {
        return details?.licenseAgentId === JSON.parse(local)?.userId;
    }, [details]);
    // 身份证 正面
    const [idCardUrl, setIdCardUrl] = useState("");
    // 身份证 反面
    const [idCardBackUrl, setIdCardBackUrl] = useState("");
    // 获取身份证  正面 或者反面的信息
    const getImage = (str: string, type: string) => {
        const obj = {
            zoom: false,
            fileIds: str,
        };
        getImageUrl(obj).then((res: any) => {
            if (res.success) {
                if (type === "straight") {
                    // 正面
                    setIdCardUrl(res?.data[0]);
                }
                if (type === "reverse") {
                    // 反面
                    setIdCardBackUrl(res?.data[0]);
                }
            }
        });
    };
    // 获取详情
    const getInfo = (id?: any) => {
        setIsSuc(false);
        if (!id || id === "") return;
        getAddministrstionInfo(id).then((res: any) => {
            if (res.code === 200) {
                setIsSuc(true)
                setDetails(res.data);
                // 正面身份证
                if (res?.data?.idCardUrl) {
                    getImage(res?.data?.idCardUrl, "straight");
                }
                // 反面身份证
                if (res?.data?.idCardBackUrl) {
                    getImage(res?.data?.idCardBackUrl, "reverse");
                }
            }
        });
    };
    useEffect(() => {
        getInfo(searchParams?.id);
    }, [searchParams]);
    return (
        <div className={styles.agency_info}>
            <BreadCrumbBar breads={breads}></BreadCrumbBar>
            <StepCom
                details={details}
                update={getInfo}
                logOff={logOff}
                taxRole={taxRole}
            ></StepCom>
            {/* 注销完成之前  需要提交各个环节注销参数 */}
            {details?.deregisterStatus !== "DEREGISTERED" ? (
                <SubmitInfo
                    details={details}
                    update={getInfo}
                    logOff={logOff}
                    taxRole={taxRole}
                    licenseRole={licenseRole}
                    isSuc={isSuc}
                ></SubmitInfo>
            ) : null}

            <BasicInfo
                details={details}
                idCardUrl={idCardUrl}
                idCardBackUrl={idCardBackUrl}
            ></BasicInfo>
            <Logs details={details}></Logs>
        </div>
    );
};

export default AgencyInfo;
