import React from "react";
import Notification from "../Notification";
import Permission from "../Permission";
const PermissionSetting = (props: any) => {
    const { isShowEdit, details } = props;
    return (
        <div>
            <h4>通知范围:</h4>
            <Notification
                isShowEdit={isShowEdit}
                details={details}
            ></Notification>
            <h4>展示范围:</h4>
            <Permission isShowEdit={isShowEdit} details={details}></Permission>
        </div>
    );
};
export default PermissionSetting;
