import Util from '../../../../services/util'

const util = Util.getInstance()

// 获取税区列表
export const getTaxList = (values:any) => {
  return util.httpGet('/sinzetech-tax/tax-region', values)
  .then(res => res)
}

// 税区编辑和新增
export const addTax = (values:any) => {
  return util.httpPost('/sinzetech-tax/tax-region', values)
  .then(res => res)
}

// 税区详情
export const getTaxInfo = (id:any) => {
  return util.httpGet(`/sinzetech-tax/tax-region/${id}`)
  .then(res => res)
}

// 获取税区的用户列表
/**
 * 营业执照办理人：LicenseOfficer
税区业务负责人：BusinessManager
税区财务负责人：FinancialManager
 * */  
export const getUserList = (values:any) => {
  return util.httpGet('/sinzetech-admin-user/users/fill', values)
  .then(res => res)
}


// 保存税区香瓜文件
export const saveDocument = (id?:string|number,params?:any) => {
  return util.httpPost(`/sinzetech-tax/tax-region/${id}/document`, params)
  .then(res => res)
}

// 获取税区文件详情
export const getDocumentDetails = (id?:string| number) => {
  return util.httpGet(`/sinzetech-tax/tax-region/${id}/document`)
  .then(res => res)
}

// 根据图片id 获取图片的地址
export const getImageUrl = (params:any) => {
  return util.httpGetDomain(`/admin/sinzetech-resource/files/ossUrlZoom`, params)
  .then(res => res)
  .catch(err => err)
}

// 交税公司信息维护
interface COMPANY{
  taxRegionId: string,
  accountName: string,
  account: string,
  branchName: string,
  branchNum: string,
  id?: string
}
export const editCompany = (params: COMPANY) => {
  return util.httpPost('/sinzetech-tax/tax-region/company', params)
}
// 公司信息详情
export const companyInfo = (id: string) => {
  return util.httpGet(`/sinzetech-tax/tax-region/company/${id}`)
}