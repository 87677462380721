import React, {useEffect, useState} from "react";
// import { useParams } from "react-router"; 
import { useNavigate, useLocation} from 'react-router-dom'
import {getEvidenceTemplateList, getAllEvi, getEvidenInfo, editEvidence, getEvidenceInfo, getManageTempalteInfo} from '../Promise'
import {Form, Input, Button, Table, message} from 'antd'
import BreadCrumbBar from '../../../../../../components/BreadCrumbBar'
import './index.scss'


const layout={
  label:{
    span: 6
  },
  wrapperCol:{
    span: 16
  }
}
// const StepItem = Steps.Step
const CurrentStep = (props: any) => {
  const {index , title, children, height, desc, step=3} = props
  return <div className="step_container">
    <div className='title'>
      <span className='circle'>{index}</span>  <span className='title'>{title}</span> 
      {
        desc ? <span className='desc'>{desc}</span> : null
      }
      
    </div>
    <div className={`${index < step ? 'border_left_line':'border_default'}`} style={{minHeight: height}}>
      {children}
    </div>
  </div>
}

const AddEdvidence = () => {
  // const searchParams = useParams()
  const [pageOptions, setPageOptions] = useState({
    pageSize: 10,
    current: 1
  })
  const [total, setTotal] = useState(0)
  const location = useLocation()
  const [messageApi, contextHolder] = message.useMessage()
  const navigate = useNavigate()
  const [form]= Form.useForm()
  const [list, setList] = useState<any>([])
  const [allList, setAllList] = useState<any>([])
  // 保存当前存证参数
  const [params, setParams] = useState<any>({})
  // 保存当前选择的场景模板的相信参数
  const [current, setCurrent] = useState<any>({
    linkEntities:[]
  })
  // 获取当前客户详情
  const [details, setDetail] = useState<any>({})
  // 获取客户模板详情
  const [manTemplateDetails, setManTemplateDetails] = useState({
    secureEvidenceSceneTemplateDescription:''
  })
  const breadsList=[
    {
      path:'/evidence',
      label:"存证平台"
    },
    {
        path:'/evidence/account',
        label:"客户管理"
    },
    {
        path:'/evidence/account/management',
        label:"存证平台-客户管理"
    },
    {
      label:'客户详情',
      path:`/evidence/account/management/manage_info`
    }
  ]
  // 获取客户详情
  const getManageDetails = (id:any) => {
    if(!id || id === '') return 
    getEvidenceInfo(id)
    .then((res:any) => {
      if(res.code === 200){
        setDetail(res.data)
        // form.setFieldValue('secureEvidenceSceneTemplateDescription', '')
      }else{
        messageApi.open({
          type:'error',
          content:res.message || '客户详情请求异常'
        })
      }
    })
  }
  // 获取客户管理-模板-详情
  const getManTemplates = (id:any) => {
    if(!id || id === '') return 
    getManageTempalteInfo(id)
    .then((res:any) => {
      if(res.code === 200){
        form.setFieldValue('secureEvidenceSceneTemplateDescription', res.data.secureEvidenceSceneTemplateDescription)
        setList(res.data.linkEntities)
        // getEvidenceDetail(res?.data?.id)
        setCurrent(res?.data)
        setParams(res.data)
        setManTemplateDetails(res.data)
        // form.setFieldValue('secureEvidenceSceneTemplateDescription')
      }
      console.log(res, '// 获取客户管理-模板-详情')
    })
  }
  // 获取当前客户的使用存证列表
  // const getList = (id:any) => {
  //   if(!id || id === '') return 
  //   const data={
  //     current:1,
  //     size:999999,
  //     customerId: id
  //   }
  //   getEvidenceTemplateList(data)
  //   .then((res:any) => {
  //     if(res?.successful){
  //       // setList(res?.data?.records)
  //       // getEvidenceDetail(res?.data?.records[0]?.id)
  //     }
  //   })
  // }
  const onChange = (params:any) => {
    console.log(params, 'ppppppp')
    setPageOptions({
      pageSize: params.pageSize,
      current:params.current
    })
    getAllEviden({
      current:params.current,
      size:params.pageSize
    })
  }
  // 获取所有的存证列表
  const getAllEviden = (object?:any) => {
    const data={
      size: 10,
      current:1,
      ...object
    }
    getAllEvi(data)
    .then((res:any) => {
      if(res.successful){
        setAllList(res?.data?.records)
        setTotal(res.data.total)
      }
    })
  }
  // 根据选择使用的存证场景模板显示对应的存证方案
  const getEvidenceDetail = (id:any) => {
    setCurrent({
      linkEntities:[]
    })
    if(!id || id === '') return 
    getEvidenInfo(id)
    .then((res:any) => {
      if(res.successful){
        console.log('////////////', res)
        setCurrent({
          linkEntities:res.data.linkList
        })
        setParams(res.data)
      }
    })
  }
  const handleUse= (record:any) => {
    getEvidenceDetail(record?.id)
    setParams({
      secureEvidenceTemplateId: record?.id
    })
    let arr:any = []
    arr.push(record)
    setList(arr)
    // setCurrent({
    //   linkEntities:[]
    // })
  }
  const handleSubmit = () => {
    if(!params?.secureEvidenceTemplateId || params?.secureEvidenceTemplateId === ''){
      messageApi.open({
        type:'error',
        content:'请选择模板'
      })
      return 
    }
    form.validateFields()
    .then(res => {
      let data ={
        secureEvidenceSceneTemplateDescription: res.secureEvidenceSceneTemplateDescription,
        customerId: location?.state?.customerId,
        secureEvidenceTemplateId: params?.secureEvidenceTemplateId,
        id: location?.state?.id,
      }
    console.log(params,data, 'submit')
      editEvidence(data)
      .then((res:any) => {
        if(res?.successful){
          messageApi.open({
            type:'success',
            content:'关联成功',
            onClose:() => {
              navigate(-1)
            }
          })
        }else{
          messageApi.open({
            type:'error',
            content: res.message || '关联失败'
          })
        }
      })
    })
  }
  const handleReset= () => {
    form.resetFields()
    setCurrent({
      linkEntities:[]
    })
    setList([])
    setParams({})
  }
  useEffect(() => {
    getManTemplates(location?.state?.id)
    // getList(location?.state?.id)
    getManageDetails(location?.state?.customerId)
  }, [location]) // eslint-disable-line
  useEffect(() => {
    getAllEviden({})
  },[])
  const columns = [
    {
        title:'存证场景',
        dataIndex:'name',
    },{
        title:'配置人',
        dataIndex: 'createUserName'
    },
    {
        title:'匹配企业数',
        dataIndex:'matchCustomer'
    },{
        title:'存证链阶段概述',
        dataIndex:'templateLinkNames'
    },
    {
        title:'操作',
        render:(record:any) => {
          const res = list.find((item:any) => {
            if(item.secureEvidenceTemplateId !== undefined){
              return item.secureEvidenceTemplateId === record.id
            }else{
              return item.id === record.id
            }
            
          })
           return <div>
            {
              res?.id ? '当前使用模板': <Button type="link" onClick={() => handleUse(record)}>使用该模板</Button>
            }
           </div>
        }
    }
]
useEffect(() => {
  console.log(current, 'c')
}, [current])
  return <div className="container">
    {contextHolder}
    <div className="content">
      <BreadCrumbBar breads={breadsList}></BreadCrumbBar>
      <div style={{paddingLeft: '100px'}}>
        公司名称: <span>{details.name}</span>匹配场景
      </div>
      <div>
          <CurrentStep index={1} height={50}>
            <Form form={form} {...layout}>
              <Form.Item name='secureEvidenceSceneTemplateDescription' label='存证场景名称' rules={[
                {
                  required: true, message:'请输入场景名称'

                }
              ]}>
                <Input value={manTemplateDetails?.secureEvidenceSceneTemplateDescription} maxLength={100} style={{width:'200px'}}></Input>
              </Form.Item>
            </Form>
          </CurrentStep>
          <CurrentStep index={2} title='选择模板:'>
            <div>
              <Input maxLength={100}  onChange={(e) => {
                getAllEviden({
                  current:1,
                  size:10,
                  templateName: e.target.value
                })
              }} style={{width:'200px'}}></Input>
              <span>若没有合适当前客户的存证场景模板，请移步 <Button type="link" onClick={() => {
                navigate('/evidence/platform/template')
              }}>平台配置-存证场景模板</Button>配置，然后返回这里重新配置</span>
            </div>

            <div>
              <Table rowKey={(record:any) => record?.id} style={{minHeight:'100px', overflow:'scroll'}} dataSource={allList} columns={columns} pagination={{
                ...pageOptions,
                total: total
              }} onChange={onChange}></Table>
            </div>
          </CurrentStep>
          <CurrentStep index={3} title='当前存证方案:'>
            <div style={{display:'flex', flexWrap:'wrap'}}>
            { 
                 current?.linkEntities.map((item:any, index:number) => {
                  return <div key={index} className='manage_step_container'>
                    <div>
                      <div className="manage_step">
                        <span>{`阶段${index+1}`}</span>
                        <div className={`${index !== current.linkEntities.length-1 ? "manage_step_line" : ''}` }></div>
                      </div>
                      <span>阶段名称：</span>
                      <Input disabled value={item.name} style={{width:'150px'}}></Input>
                    </div> 
                  </div>
                })
              }
            </div>
            {/* <Steps current={current?.linkList.length-1}>
              { 
                 current?.linkList.map((item:any, index:number) => {
                  return <StepItem key={item.secureEvidenceTemplateId} title={`阶段${index+1}`} description={
                    <div>
                      <span>阶段名称：</span>
                      <Input disabled value={item.name} style={{width:'150px'}}></Input>
                    </div> 
                  }></StepItem>
                })
              }
            </Steps> */}
          </CurrentStep>
          <div style={{display:'flex', justifyContent:'flex-end'}}>
            <Button onClick={() => handleReset()}>清空配置</Button>
            <Button type="primary" onClick={() => handleSubmit()}>提交保存</Button>
          </div>
      </div>
    </div>
  </div>
}

export default AddEdvidence