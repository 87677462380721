import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import {
    EditOutlined,
    ToolOutlined,
    UserOutlined,
    SnippetsOutlined,
    SettingOutlined,
} from "@ant-design/icons";
import TopNavbar from "../../components/TopNavbar";
import LeftNavbar from "../../components/LeftNavbar";
import { useSelector } from "react-redux";
import "./index.scss";
import { message } from "antd";

const list = [
    {
        name: "用户管理",
        path: "/system/user",
        icon: <UserOutlined />,
        code: "admin_user_user_list",
    },
    {
        name: "用户组管理",
        path: "/system/userGroup",
        icon: <UserOutlined />,
        code: "admin_user_user_group_list_ui",
    },
    {
        name: "权限管理",
        path: "/system/authority",
        icon: <EditOutlined />,
        code: "admin_user_authority_list",
    },
    {
        name: "角色管理",
        path: "/system/role",
        icon: <ToolOutlined />,
        code: "admin_user_role_list",
    },
    {
        name: "税区管理",
        path: "/system/tax",
        icon: <SnippetsOutlined />,
    },
    {
        name: "开票配置",
        path: "/system/invoiceSetting",
        icon: <SettingOutlined />,
    },
];

const System = () => {
    const codeList: any =
        useSelector<any>((state: any) => state.codeList) || [];
    console.log(codeList,'codeList')
    const navigate = useNavigate();
    const location = useLocation();
    const [lists, setLists] = useState<any>([]);
    // const roleList = useSelector((state: any) => state.roleCodes);

    useEffect(() => {
        let arr: any = [];
        for (let i = 0; i < list.length; i++) {
            if (!list[i].code || list[i].code === "") {
                arr.push(list[i]);
            } else {
                if (!!codeList && codeList.includes(list[i].code)) {
                    arr.push(list[i]);
                }
            }
        }
        // if (!!roleList && roleList.includes("admin")) {
        //     arr.push({
        //         name: "税区管理",
        //         path: "/system/tax",
        //         icon: <SnippetsOutlined />,
        //     });
        // }
        if (arr.length) {
            let path = location?.pathname;
            if (path === "/system") {
                navigate(arr[0]?.path);
            }
        }
        console.log(arr,'arr')
        setLists(arr);
    }, [codeList, location]);

    // useEffect((): void => {
    //     const pathName = location.pathname.match(/\/system\/(.*)/);
    //     const systemUrlPaths = ["role", "authority", "user"];
    //     let urlName = "";
    //     if (pathName) {
    //         urlName = pathName![1];
    //         if (systemUrlPaths.includes(urlName)) {
    //             // setPathName(urlName);
    //         }
    //     }
    // }, [location]); // eslint-disable-line
    return (
        <div className="system-container">
            <TopNavbar config={{ value: "system" }} />
            <div className="system-container-content">
                {lists.length !== 0 ? <LeftNavbar lists={lists} /> : null}

                <Outlet></Outlet>
            </div>
        </div>
    );
};
export default System;
