import React, { useState } from 'react';
import ManagementHome from './pages/Home';
// import ManagementAdd from './pages/Add';
// import { Switch } from 'antd';
// import {BrowserRouter } from 'react-router-dom'
import './index.scss';
const Management = () => {
    const [isHome, setIsHome] = useState(false);
    
    return (
        <div className="evidence-management">
            <div className="evidence-management-content">
                <ManagementHome isHome={!isHome} toggle={() => { setIsHome(isHome) }} />
                {/* <ManagementAdd isHome={!isHome} toggle={() => { setIsHome(isHome) }} /> */}

            </div>

        </div>
    )
}
export default Management;