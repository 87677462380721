import Util from '../../../util';
const util = Util.getInstance();

const usersList: (current: number, pageSize: number) => Promise<any> = (current, pageSize) => {
    return util.httpGet(`/sinzetech-admin-user/users?current=${current}&size=${pageSize}`).then(data => {
        return data;
    }, err => err.response.data)
}

const changeStatus: (id: string, value: any) => Promise<any> = (id, value) => {
    return util.httpPutUrl(`/sinzetech-admin-user/users/status/${id}?status=${value}`).then(data => {
        return data;
    }, err => err.response.data);
}

export {
    usersList,
    changeStatus
}