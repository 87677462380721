import BreadCrumbBar from "@src/components/BreadCrumbBar";
import { CommonStatus } from "@src/components/common";
import CommonSearch, { FormItem } from "@src/components/common/CommonSearch";
import ExportStoreList from "@src/components/export/storeList";
import { useTable } from "@src/hooks/useTable";
import { axiosRequrest } from "@src/services/axiosRequest";
import { useLocalStorageState, useSetState } from "ahooks";
import {
  Button,
  Select,
  Table,
  Pagination,
  Input,
  DatePicker,
  message,
  Switch,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { omit } from "lodash";
import React, { memo, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getRecoverList, updateRecoverGroup } from "../service";
import { getRangeDateOnUnitOfTime } from "@src/utils/date/utils";
import { confirmToContinue } from "@src/utils/utils";
import { recoverGroup } from "@src/constant/recoverGroup";
import ExportList from "@src/components/exportExcelByBroswer";
import { TableToolBar } from "@src/components/TableToolBar";
import { SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { formatSplit } from "@src/utils/number/utils";

const breads = [
  {
    label: "回收小组",
    path: "/consignor/recover/list",
  },
  {
    label: "小组列表",
    path: "/consignor/recover/list",
  },
];

const defaultProps = {};
type props = {};
export type RecoverListProps = Required<typeof defaultProps> & props;
export const RecoverList: React.FC<RecoverListProps> = memo((props) => {
  let userId = JSON.parse(
    localStorage.getItem("operation_authorization") as string
  ).userId;
  const nav = useNavigate();
  let [state, setState] = useSetState<any>({
    columnsData: [],
    taxRegion: [],
    total: 0,
    current: 1,
    size: 10,
    isRefresh: false,
    searchContent: {},
  });
  const [recoverGroupDetail, setRecoverGroupDetail] = useLocalStorageState(
    "recoverGroupDetail",
    { defaultValue: {} }
  );
  const enable = async (item: API.Recover.Item) => {
    await confirmToContinue({
      title: "提示",
      content: `是否启用小组：${item.name}`,
    });
    await updateRecoverGroup({
      id: item.id,
      status: "NORMAL",
      name: item.name,
    });
    await groupTableService.run();
    message.success("操作成功");
  }
  const disable = async (item: API.Recover.Item) => {
    await confirmToContinue({
      title: "提示",
      content: `是否禁用小组：${item.name}`,
    });
    await updateRecoverGroup({
      id: item.id,
      status: "DISABLED",
      name: item.name,
    });
    await groupTableService.run();
    message.success("操作成功");
  }
  let columns: ColumnsType<API.Recover.Item> = [
    {
      title: "id",
      dataIndex: "id",
    },
    {
      title: "名称",
      dataIndex: "name",
    },
    {
      title: "成员数",
      dataIndex: "memberCount",
    },
    {
      title: "销售订单",
      dataIndex: "saleOrderCount",
    },
    {
      title: "累计交易额（元）",
      dataIndex: "transAmountTotal",
      render:(text)=> text?formatSplit(Number(text).toFixed(2)):'0.00'
    },
    {
      title: "创建时间",
      dataIndex: "createTime",
    },
    {
      title: "小组状态",
      dataIndex: "status",
      // @ts-ignore
      valueEnum: {
        NORMAL:'正常',
        DISABLED:'禁用',
      },
      render: (t: string, item: any) => {
        // return (
        //   <CommonStatus
        //     color={recoverGroup.statusMap[item.status].style}
        //     text={recoverGroup.statusMap[item.status].text}
        //   />
        // );
        return (<>
        <Switch checked={item.status === "NORMAL"} checkedChildren="开启" unCheckedChildren="禁用" onChange={(value)=>{
          if(value){
            enable(item)
          }else{
            disable(item)
          }
        }}></Switch>
        </>)
      },
    },
    {
      title: "创建账号",
      dataIndex: "createAccount",
    },
    {
      title: "操作",
      dataIndex: "operation",
      render: (_: any, item: any) => {
        return (
          <div className="operation">
            <span
              onClick={() => {
                setRecoverGroupDetail((prev) => {
                  return { ...prev, [item.id]: item };
                });
                nav(`/consignor/recover/management/${item.id}`);
              }}
            >
              详情
            </span>
          </div>
        );
      },
    },
  ];
  const formList: FormItem[] = [
    {
      name: "createDate",
      label: "创建日期",
      element: <DatePicker.RangePicker></DatePicker.RangePicker>,
    },
    {
      name: "status",
      label: "小组状态",
      element: (
        <Select placeholder="请选择" allowClear defaultValue={'ALL'}>
          <Select.Option value="ALL">全部</Select.Option>
          <Select.Option value="NORMAL">正常</Select.Option>
          <Select.Option value="DISABLED">禁用</Select.Option>
          {/* {
                  state.taxRegion.map((item, index) => {
                      return (
                          <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                      )
                  })
              } */}
        </Select>
      ),
    },
    {
      name: "keywords",
      label: "搜索",
      element: (
        <Input placeholder="输入id，小组名称，创建账号搜索" allowClear  suffix={
            <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
        }/>
      ),
    },
  ];
  // @ts-ignore
  const groupTableService = useTable(
    async (params) => {
      const { current,status, size, createDate, ...rest } = params;
      console.log(params, "params-----------<>");
      groupTableService.paramsRef.current = {
        ...rest,
        ...getRangeDateOnUnitOfTime(
          {
            createTimeStart: createDate?.[0],
            createTimeEnd: createDate?.[1],
          },
          { format: "YYYY-MM-DD HH:mm:ss" }
        ),
        ...(status==='ALL' ?{}:{status}),
        "sorts[0].direction":'DESCENDING',
        "sorts[0].field":'createTime',
        pageSize: size,
        pageNumber: current,
        withTotal: true,
      };
      // @ts-ignore
      const res = await getRecoverList(groupTableService.paramsRef.current);
      return {
        data: res.list,
        total: Number(res.total),
      };
    },
    { defaultCurrent: 1, defaultSize: 20 }
  );
  const [isExport, setIsExport] = useState(false);

  return (
    <div className="public_list_page">
      {isExport && (
        <ExportList
          columnsKey={() => {
            let keys = [...columns];
            return keys;
          }}
          exportName={`回收小组列表_${dayjs().format('YYYY-MM-DD')}.xlsx`}
          url={"/consignor/recovery/admin/recovery-group"}
          total={groupTableService.data?.total || 0}
          size={groupTableService.paramsRef.current.pageNumber || 0}
          current={groupTableService.paramsRef.current.pageSize || 0}
          serchObj={{
            ...omit(groupTableService.paramsRef.current, "current", "size"),
          }}
          cancelModal={() => {
            setIsExport(false);
          }}
        />
      )}
      <BreadCrumbBar breads={breads}></BreadCrumbBar>
      <CommonSearch {...groupTableService.getSearchFormProps?.({ formList })} />
      <div className="table_box">
        <TableToolBar
        {...groupTableService.getToolBarProps?.({
          title: "小组列表",
          action:<>
            <Button
              className="func_item"
              type="primary"
              onClick={() => {
                setIsExport(true);
              }}
            >
              导出
            </Button>
          </>
        })}
      />
        <Table
          rowKey={"id"}
          pagination={groupTableService.getPaginationProps?.({
            className: "page_num",
          })}
          {...groupTableService.getTableProps({
            className: "public_table",
            scroll: { y: "40vh" },
          })}
          columns={columns}
        />
      </div>
    </div>
  );
});
RecoverList.defaultProps = defaultProps;
