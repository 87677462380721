import { TeamOutlined, UsergroupAddOutlined } from '@ant-design/icons'
import LeftNavbar from '@src/components/LeftNavbar'
import TopNavbar from '@src/components/TopNavbar'
import React,{memo} from 'react'
import {Outlet} from 'react-router-dom'
import './index.scss'

const defaultProps = {}
type props = {}
export type ConsignorProps = Required<typeof defaultProps> & props
export const Consignor:React.FC<ConsignorProps> = memo((props)=>{
  
  return         <div className="consignor-container">
  <TopNavbar config={{ value: "consignor" }} />
  <div className="consignor-container-content">
      {<LeftNavbar lists={[{
            name: '回收小组',
            path: '/consignor/recover',
            icon: <TeamOutlined />,
            children: [
              {
                name: '小组管理',
                path: '/consignor/recover/list',
                code:'recover_management',
                key:'recover_management'
            }
            ],
            code:'recover',
            key:'recover'
      }]} />}
      <Outlet />
  </div>
</div>
})
export default Consignor
Consignor.defaultProps = defaultProps