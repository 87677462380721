import Util from '../../../../../services/util'
const util = Util.getInstance()

type get_evidence_list={
  name?:string,
  id?: string| number,
  attribute?: string|number,
  contactName?:string,
  contactPhone?:string|number,
  startTime?:string|number,
  endTime?:string|number,
  size: string|number,
  current: string|number
}

type add_evidence={
  name: string,
  address?:string,
  contactName: string,
  contactPhone:string,
  attribute:number,
  permission:string,
  serverDateLimit: number,
  remark?: string,
  logo?:string,
  businessLicense?:string,
  socialCreditCode?:string,
  establishmentDate?:string,
  industry?:string,
  introduction?:string,
  id?:string
}
const getEvidenceList:(values:get_evidence_list) => Promise<any> =(values:get_evidence_list) => {
  return util.httpGet('/sinzetech-evidence-customer/customers',values).then(res => res)
}

const addEvidence:(values: add_evidence) => Promise<any> = (values: add_evidence) => {
  return util.httpPost('/sinzetech-evidence-customer/customers', values).then(res => res )
  .catch(msg => msg.response.data)
}

// 获取客户详情
const getEvidenceInfo:(id:string) => Promise<any> = (id:string) => {
  return util.httpGet(`/sinzetech-evidence-customer/customers/${id}`).then(res => res)
}
// 根据图片id 获取图片的地址
const getImageUrl = (params:any) => {
  return util.httpGetDomain(`/admin/sinzetech-resource/files/ossUrlZoom`, params)
  .then(res => res)
  .catch(err => err)
}

const getEvidenceTemplateList =(values:any) => {
  return util.httpGet('/sinzetech-evidence/customer',values).then(res => res)
}

// 获取所有的村正场景
const getAllEvi = (params:any) => {
  console.log(params, '/params')
  return util.httpGet('/sinzetech-evidence/secure-evidence-templates',params).then(res => res)
  .catch(err => err)
}
// 获取存证详情
const getEvidenInfo = (id:any) => {
  return util.httpGet( `/sinzetech-evidence/secure-evidence-templates/${id}`)
  .then(res => res)
  .catch(err => err)
} 
// 客户详情-新增或者编辑存证
const editEvidence = (params:any) => {
  return util.httpPost('/sinzetech-evidence/customer', params)
  .then(res => res)
  .catch(err => err)
}

// 获取客户-> 模板-> 详情
const getManageTempalteInfo = (id:any) => {
  return util.httpGet(`/sinzetech-evidence/customer/${id}`)
  .then(res => {
    return res
  })
}
export{
  getEvidenceList,
  addEvidence,
  getEvidenceInfo,
  getImageUrl,
  getEvidenceTemplateList,
  getAllEvi,
  getEvidenInfo,
  editEvidence,
  getManageTempalteInfo
}