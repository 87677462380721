import React, { useMemo, useState } from "react";
import styles from "./index.module.scss";
import { Button, Image, message } from "antd";
import LeftIcon from "../../../../../../../../assets/left.png";
import RightIcon from "../../../../../../../../assets/right.png";
import { licenseSign } from "../../../../../promise";
import { copyText } from "../../../../../../../../utils/index";

// 代办事项
const Todo = (props: any) => {
    const { details, update } = props;
    const handleSign = async (type: string | number) => {
        if (!details?.id) return;
        // const params = {
        //     type: type,
        // };
        const res: any = await licenseSign(details?.id, type);
        if (res?.code === 200) {
            if (type === 1) {
                message.success("重发短信成功");
            } else {
                message.success("重发双录视频成功");
            }
            update(details?.id);
        }
    };
    // 复制
    const handleCopy = (value?: string) => {
        if (value) {
            copyText(value);
        } else {
            message.error("链接不存在");
        }
    };
    const list = [
        {
            icon: (
                <Image
                    src={LeftIcon}
                    className={styles.icon}
                    preview={false}
                ></Image>
            ),
            label: <span className={styles.text}>签署文件协议</span>,
            descript: (
                <div
                    className={`${styles.desc} ${
                        details?.signStatus && styles.success_bg
                    }`}
                >
                    <span
                        className={`${styles.desc_icon} ${
                            details?.signStatus && styles.success_icon
                        }`}
                    ></span>
                    {details?.signStatusName}
                    {/* {details.signStatus ? "已签署" : "待签署"} */}
                </div>
            ),
            btnList: [
                <Button type="primary" onClick={() => handleSign(1)}>
                    重发委托协议短信
                </Button>,
                <Button
                    onClick={() =>
                        handleCopy(details?.delegationAgreementMessageUrl)
                    }
                >
                    复制委托协议链接
                </Button>,
            ],
        },
        {
            icon: (
                <Image
                    src={RightIcon}
                    className={styles.icon}
                    preview={false}
                ></Image>
            ),
            label: <span className={styles.text}>双录视频录制</span>,
            descript: (
                <div
                    className={`${styles.desc} ${
                        details?.dualStatus && styles.success_bg
                    }`}
                >
                    <span
                        className={`${styles.desc_icon} ${
                            details?.dualStatus && styles.success_icon
                        }`}
                    ></span>
                    {/* 待录制 */}
                    {details?.dualStatusName}
                </div>
            ),
            btnList: [
                <Button type="primary" onClick={() => handleSign(2)}>
                    重发双录视频短信
                </Button>,
                // <Button onClick={() => handleCopy(details?.faceVideoUrlName)}>
                //     复制双录视频链接
                // </Button>,
            ],
        },
    ];
    return (
        <div className={styles.to_do_container}>
            <div className={styles.to_do_title}>待办事项</div>
            <div className={styles.to_do_list}>
                {list.map((item: any, index: number) => {
                    return (
                        <div key={index} className={styles.list_container}>
                            <div className={styles.list_item_container}>
                                {item.icon}
                                {item.label}
                                {item.descript}
                            </div>
                            <div className={styles.btn_list}>
                                {item.btnList.map((k: any, i: number) => {
                                    return (
                                        <div
                                            key={i}
                                            className={styles.item_btn}
                                        >
                                            {k}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Todo;
