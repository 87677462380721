import { TableToolBarProps } from "@src/components/TableToolBar";
import { PageSearchProps } from "@src/components/common/CommonSearch";
import { useRequest } from "ahooks";
import { PaginationProps } from "antd";
import { TableProps } from "rc-table";
import { useMemo, useRef, useState } from "react";
type ParamsWithPagination<P extends Record<string, any>> = Partial<P> & { current?: number; size?: number };
type UseTableOption<P extends Record<string, any>> = {
  params?: P;
  defaultSize?: number;
  defaultCurrent?: number;
};
type GetProps<P, R> = (props: P) => R;
export const useTable = <P extends Record<string, any>, D>(
  service: (
    params: ParamsWithPagination<P>
  ) => Promise<{ data: D[]; total: number }>,
  option?: UseTableOption<P>
): {
  getTableProps: GetProps<TableProps<D> | undefined, TableProps<D>>;
  getPaginationProps: GetProps<PaginationProps | undefined, PaginationProps>;
  getSearchFormProps: GetProps<PageSearchProps, PageSearchProps>;
  getToolBarProps: GetProps<TableToolBarProps, TableToolBarProps>;
  // pagination: { size?: number; current?: number; total?: number };
  paramsRef: React.MutableRefObject<ParamsWithPagination<P>>;
  // params?: Partial<ParamsWithPagination<P>>;
  run: (params?: ParamsWithPagination<P>) => Promise<void>;
  data?: {data:D[],total:number}
} => {
  const { defaultCurrent = 1, defaultSize = 20 } = option || {};
  const paramsRef = useRef<ParamsWithPagination<P>>({})
  const dataSourceService = useRequest(
    async (params) => {
      paramsRef.current = {
        current: defaultCurrent,
        size: defaultSize,
        ...option?.params,
        ...params,
      };

      return service(paramsRef.current);
    },
    {
      refreshDeps: option?.params ? [Object.values(option?.params)] : [],
    }
  );

  const run = async (params?: ParamsWithPagination<P>) => {
    await dataSourceService.run({ ...dataSourceService.params[0], ...params });
  };
  const requestParams = useMemo<ParamsWithPagination<P> | undefined>(
    () => dataSourceService.params[0],
    [dataSourceService.params[0]]
  );
  console.log(requestParams,'params request')
  return {
    data:  dataSourceService.data,
    // pagination: {
    //   size: requestParams?.size,
    //   current: requestParams?.current,
    //   total: dataSourceService.data?.total,
    // },
    // params: requestParams,
    paramsRef,
    run,
    getToolBarProps:(props)=>{
      return {
        ...props,
        // title:dataSourceService.data?.total?`${props.title}/${dataSourceService.data?.total}`:props.title,
        title: props.title,
        // @ts-ignore
        search:props.search? {onSearch: (value)=>run({keywords:value}), ...props.search}:undefined
      }
    },
    getTableProps: (props) => {
      return {
        dataSource: dataSourceService.data?.data,
        ...props,
        scroll: {
          // x: 'max-content',
          ...props?.scroll,
        },
      };
    },
    getPaginationProps: (props) => {
      return {
        defaultCurrent,
        defaultPageSize:defaultSize,
        showSizeChanger: true,
        showTotal: (total) => `共${total}条`,
        onChange: (page, pageSize) => {
          // @ts-ignore
          run({ current: page, size: pageSize });
        },
        total: dataSourceService.data?.total,
        ...props,
      };
    },
    getSearchFormProps: (props) => {
      return {
        onSearch: (values) => {
          run(values);
        },
        ...props,
      };
    },
  };
};
