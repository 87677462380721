import React, { useState, useEffect, useMemo } from "react";
import { Button, Table, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import BreadCrumbBar from "../../../../components/BreadCrumbBar";
import { getTaxList } from "./promise";
import TableCom from "../../../../components/TableCom";
import { useSelector } from "react-redux";
import "./index.scss";

const statusColor = {
    1: "success",
    0: "error",
};

const breads = [
    {
        label: "组织管理",
        path: "/system/tax",
    },
    {
        label: "税区管理",
        path: "/system/tax",
    },
];
const TaxManagement = () => {
    const [messageApi, contextHolder] = message.useMessage();
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [pageOptions, setPageOptions] = useState({
        pageSize: 10,
        current: 1,
    });
    const roleList = useSelector((state: any) => state.roleCodes);
    const isAdmin = useMemo(() => {
        return roleList.includes("admin");
    }, [roleList]);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        getList();
    }, []);

    const getList = (params?: any) => {
        const data = {
            size: pageOptions.pageSize,
            current: pageOptions.current,
            ...params,
        };
        getTaxList(data).then((res: any) => {
            if (res.successful) {
                setTotal(res.data.total);
                setList(res?.data?.records);
            } else {
                messageApi.open({
                    type: "error",
                    content: res?.message || "接口异常",
                });
            }
        });
    };
    const handlePage = (params?: any) => {
        setPageOptions({
            current: params.current,
            pageSize: params.pageSize,
        });
        getList({
            current: params.current,
            size: params.pageSize,
        });
    };
    const columns = [
        {
            title: "税区名称",
            dataIndex: "name",
        },
        {
            title: "税区ID",
            dataIndex: "id",
        },
        {
            title: "状态",
            dataIndex: "status",
            render: (record: any) => {
                return (
                    <div>
                        <span className={`${statusColor[record]}`}></span>
                        <span>{record ? "运营中" : "已撤销"}</span>
                    </div>
                );
            },
        },
        {
            title: "办公地址",
            dataIndex: "officeAddress",
        },
        {
            title: "负责人",
            dataIndex: "operatorName",
        },
        {
            title: "负责人手机号",
            dataIndex: "phone",
        },
        {
            title: "操作",
            render: (record: any) => {
                return <Link to={`/system/tax/info/${record.id}`}>详情</Link>;
            },
        },
    ];

    return (
        <div className="tax_container">
            {contextHolder}
            <div className="content">
                <BreadCrumbBar breads={breads}></BreadCrumbBar>
                {isAdmin && (
                    <div className="flex_end">
                        <Button
                            type="primary"
                            onClick={() => {
                                navigate("/system/tax/add");
                            }}
                        >
                            +新增税区
                        </Button>
                    </div>
                )}

                <div className="table_container">
                    {/* <Table ></Table> */}
                    <TableCom
                        columns={columns}
                        onChange={handlePage}
                        dataSource={list}
                        pagination={{
                            ...pageOptions,
                            total: total,
                        }}
                        scroll={{
                            y: "calc(100vh - 280px)",
                            x: 1200,
                        }}
                    ></TableCom>
                </div>
            </div>
        </div>
    );
};

export default TaxManagement;
