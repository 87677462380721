import Util from '../../../util';
const util = Util.getInstance();

type type_add_account = {
    account: string;
    roleIds: string[];
    department: string;
    email: string;
    name: string;
    position: string;
    wxAccount: string;
}
const getRoleLists: () => Promise<any> = () => {
    return util.httpGet(`/sinzetech-admin-user/roles?current=1&size=100`).then(data => {
        return data;
    }, err => err.response.data)
}

const getJobOrDepLists: (value: string) => Promise<any> = (value: string) => {
    return util.httpGet(`/sinzetech-admin-user/dropDownData/${value}`).then(
        data => {
            return data;
        }, err => err.response.data
    )
}

const addAccount: (value: type_add_account) => Promise<any> = (value: type_add_account) => {
    return util.httpPost(`/sinzetech-admin-user/users`, value).then(
        data => {
            return data;
        }, err => err.response.data
    )
}

const editAccount: (value: type_add_account) => Promise<any> = (value: type_add_account) => {
    return util.httpPost(`/sinzetech-admin-user/users`, value).then(
        data => {
            return data;
        }, err => err.response.data
    )
}
export {
    getRoleLists,
    getJobOrDepLists,
    addAccount,
    editAccount
}