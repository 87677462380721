import React, { useState, useEffect, useRef } from "react";
import { Space, Button, message, Popconfirm } from "antd";
// import { PlusCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from "antd/es/table";
import AuthorityModal from "../../../../components/AuthorityModal";
import BreadCrumbBar from "../../../../components/BreadCrumbBar";
import {
    authorityList,
    deleteAuthor,
} from "../../../../services/api/system/page/authority";
import TableCom from "../../../../components/TableCom";
import "./index.scss";
import { useSelector } from "react-redux";
type type_records = {
    key: number;
    code: string;
    uri?: string;
    createTime?: string;
    createUser?: string;
    id: number;
    name: string;
};
const Authority = () => {
    const codeList: any =
        useSelector<any>((state: any) => state.codeList) || [];
    // 新增或者编辑
    const isUpdateCode = codeList.includes(
        "admin_user_authority_add_or_update"
    );
    const isRemoveCode = codeList.includes("admin_user_authority_delete");
    const [messageApi, contextHolder] = message.useMessage();

    const [scrollY, setScrollY] = useState<number>(0);
    const [openModal, setOpenModal] = useState<boolean>(false);
    // const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<type_records[]>([]);
    const [currentObj, setCurrnetObj] = useState<any>({});
    const [paginationProps, setPaginationProps] = useState<{
        showQuickJumper: boolean;
        pageSize: number;
        current: number;
        total: number;
    }>({
        showQuickJumper: true,
        pageSize: 10,
        current: 1,
        total: 0,
    });
    const handleTableChange = (obj: any) => {
        setPaginationProps({
            showQuickJumper: true,
            pageSize: obj.pageSize ?? 10,
            current: obj.current ?? 1,
            total: obj.total ?? 0,
        });
        getAuthorityList({
            pageSize: obj?.pageSize,
            current: obj?.current,
        });
    };

    const tableRef = useRef<null | HTMLDivElement>(null);

    const getAuthorityList = (obj?: any) => {
        let params = {
            current: paginationProps.current,
            pageSize: paginationProps.pageSize,
        };
        if (obj !== undefined && obj.current) {
            params = {
                ...obj,
            };
        }
        authorityList(params?.current, params?.pageSize).then((data) => {
            if (data.code === 200) {
                // setLoading(true);
                const records: type_records[] = [];
                data.data.records.forEach((i: any, index: number) => {
                    const record: type_records = {
                        key: i.number,
                        code: i.code,
                        uri: i.uri,
                        createTime: i.createTime,
                        createUser: i.creatUser,
                        id: i.id,
                        name: i.name,
                    };
                    records.push(record);
                    setData(records);
                });
                setPaginationProps({
                    ...paginationProps,
                    total: +data.data.total,
                    current: +data.data.current,
                    pageSize: +data.data.size,
                });
                // setLoading(false);
            } else {
                messageApi.open({
                    type: "error",
                    content: data.message || "请求异常",
                });
            }
        });
    };

    useEffect((): void => {
        const y = tableRef?.current?.clientHeight ?? 0;
        setScrollY(y);
    }, []);

    useEffect(() => {
        getAuthorityList();
    }, []); // eslint-disable-line
    const handleDelete = (id: string | number) => {
        deleteAuthor(id).then((res) => {
            if (res.code === 200) {
                getAuthorityList();
                // alert('删除成功')
                messageApi.open({
                    type: "success",
                    content: "删除成功",
                });
            } else {
                messageApi.open({
                    type: "error",
                    content: res?.message || "删除失败",
                });
            }
        });
    };
    const handleEdit = (obj: any) => {
        setCurrnetObj(obj);
        setOpenModal(true);
    };
    const columns: ColumnsType<type_records> = [
        {
            title: "权限编码",
            dataIndex: "code",
            key: "code",
            align: "center",
        },
        {
            title: "权限名称",
            dataIndex: "name",
            key: "name",
            align: "center",
        },
        {
            title: "操作",
            key: "operator",
            align: "center",
            render: (_, record) => (
                <Space size="middle">
                    {isUpdateCode && (
                        <Button type="link" onClick={() => handleEdit(record)}>
                            编辑
                        </Button>
                    )}

                    {isRemoveCode && (
                        <Popconfirm
                            title="确定要删除吗？"
                            onConfirm={() => handleDelete(record.id)}
                        >
                            <Button type="link">删除</Button>
                        </Popconfirm>
                    )}
                </Space>
            ),
        },
    ];
    return (
        <div className="system-authority" ref={tableRef}>
            {contextHolder}
            <div className="content">
                <BreadCrumbBar
                    breads={[
                        { label: "组织管理", path: "" },
                        { label: "权限管理", path: "" },
                    ]}
                />
                {isUpdateCode && (
                    <div className="system-authority-add">
                        <Button
                            type="primary"
                            onClick={() => {
                                setOpenModal(true);
                                setCurrnetObj({});
                            }}
                        >
                            +新增
                        </Button>
                    </div>
                )}

                <TableCom
                    rowKey={(record: any) => record.id}
                    columns={columns}
                    dataSource={data}
                    pagination={paginationProps}
                    onChange={handleTableChange}
                    scroll={{
                        y: "calc(100vh - 300px)",
                    }}
                ></TableCom>
                <AuthorityModal
                    currentObj={currentObj}
                    isOpen={openModal}
                    openModal={(value: boolean) => {
                        setOpenModal(value);
                    }}
                    cb={getAuthorityList}
                />
                {/* <Loading status={loading} /> */}
            </div>
        </div>
    );
};
export default Authority;
