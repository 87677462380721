import React, { useState, useEffect } from "react";
import { Button, Space, Table } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import "./index.scss";
import BreadCrumbBar from "../../../../../../components/BreadCrumbBar";
import FormItemComponent from "../../../../../../components/FormItem";
import { getEvidenceList } from "../Promise";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import TableCom from "../../../../../../components/TableCom";
type type_datatype = {
    contactName: string;
    name: string;
    id: string;
    contactPhone: string;
    createTime: string;
    attributeName: string;
    permission: string;
    evidenceCount: string;
};
// ['存证平台', '客户管理', '存证平台-客户管理']
const breadsList = [
    {
        path: "/evidence",
        label: "存证平台",
    },
    {
        path: "/evidence/account",
        label: "客户管理",
    },
    {
        path: "/evidence/account/management",
        label: "存证平台-客户管理",
    },
];

const attriObj = {
    "1": "矿石企业",
    "2": "利废企业",
    "3": "税务局",
    "4": "金融机构",
};
const ManagementHome: React.FC<{ isHome: boolean; toggle: () => void }> = ({
    isHome,
    toggle,
}) => {
    const navigate = useNavigate();
    const [pageOptions, setPageOptions] = useState<any>({
        showQuickJumper: false,
        pageSize: 10,
        current: 1,
        total: 0,
    });
    const [data, setData] = useState<any>([]);
    const [search, setSearchParams] = useState<any>({});

    const handleInfo = (record: any) => {
        navigate(`/evidence/account/management/manage_info`, {
            state: {
                id: record?.id,
            },
        });
    };
    const columns: ColumnsType<type_datatype> = [
        {
            title: "公司名称",
            dataIndex: "name",
            key: "name",
            align: "center",
        },
        {
            title: "客户ID",
            dataIndex: "id",
            key: "id",
            align: "center",
            sorter: (a: any, b: any) => a.id - b.id,
        },
        {
            title: "联系人",
            dataIndex: "contactName",
            key: "contactName",
            align: "center",
        },
        {
            title: "联系电话",
            dataIndex: "contactPhone",
            key: "contactPhone",
            align: "center",
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
            key: "createTime",
            align: "center",
        },
        {
            title: "用户属性",
            dataIndex: "attribute",
            key: "attribute",
            align: "center",
            render: (record: any) => {
                return <span>{record ? attriObj[record] : ""}</span>;
            },
        },
        {
            title: "用户权限",
            dataIndex: "permission",
            key: "permission",
            align: "center",
        },
        {
            title: "存证数量",
            dataIndex: "evidenceCount",
            key: "evidenceCount",
            align: "center",
        },
        {
            title: "操作",
            key: "operate",
            align: "center",
            render: (_, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => handleInfo(record)}>
                        详情
                    </Button>
                </Space>
            ),
        },
    ];
    const formParams = [
        {
            label: "公司名称",
            dataIndex: "name",
            type: "Input",
            params: {
                placeholder: "请输入",
                maxLength: 100,
                allowClear: true,
            },
        },
        {
            label: "客户ID",
            dataIndex: "id",
            type: "Input",
            params: {
                placeholder: "请输入",
            },
        },
        {
            label: "用户属性",
            dataIndex: "attribute",
            type: "Select",
            params: {
                placeholder: "请选择",
                options: [
                    {
                        value: "1",
                        label: "矿石企业",
                    },
                    {
                        value: "2",
                        label: "利废企业",
                    },
                    {
                        value: "3",
                        label: "税务局",
                    },
                    {
                        value: "4",
                        label: "金融机构",
                    },
                ],
            },
        },
        {
            label: "联系人",
            dataIndex: "contactName	",
            type: "Input",
            params: {
                placeholder: "请输入",
                maxLength: 50,
                allowClear: true,
            },
        },
        {
            label: "联系电话",
            dataIndex: "contactPhone",
            type: "Input",
            params: {
                placeholder: "请输入",
                maxLength: 11,
                allowClear: true,
            },
        },
        {
            label: "创建时间",
            dataIndex: "time",
            type: "DatePickers",
            params: {
                placeholder: "请选择",
            },
        },
    ];
    const getEvidences = (object: any) => {
        const data = {
            size: object?.pageSize || 10,
            current: object?.current || 1,
            ...object,
        };
        getEvidenceList(data).then((res) => {
            if (res?.code === 200) {
                setData(res?.data?.records);
                setPageOptions({
                    // ...,
                    ...object,
                    total: res?.data?.total,
                });
            }
        });
    };
    const onSearch = (object: any) => {
        const params = {
            ...object,
            startTime:
                object?.time && object?.time[0]
                    ? dayjs(new Date(object?.time[0])).format("YYYY-MM-DD")
                    : "",
            endTime:
                object?.time && object?.time[1]
                    ? dayjs(new Date(object?.time[1])).format("YYYY-MM-DD")
                    : "",
            size: 10,
            current: 1,
        };
        if (object?.time && object?.time[0]) {
            console.log("时间相等");
            params.startTime = params.startTime + " 00:00:00";
        }
        if (object?.time && object?.time[1]) {
            params.endTime = params.endTime + " 23:59:59";
        }
        setSearchParams({
            name: object.name,
            id: object.id,
            attribute: object.attribute,
            contactName: object.contactName,
            contactPhone: object.contactPhone,
            startTime:
                object?.time && object?.time[0]
                    ? dayjs(new Date(object?.time[0])).format("YYYY-MM-DD")
                    : "",
            endTime:
                object?.time && object?.time[1]
                    ? dayjs(new Date(object?.time[1])).format("YYYY-MM-DD")
                    : "",
        });
        delete params.time;
        getEvidences(params);
    };
    const handlePage = (object: any) => {
        const params = {
            ...search,
            pageSize: object.pageSize,
            current: object.current,
        };
        setPageOptions({
            ...pageOptions,
            ...object,
        });
        getEvidences({
            size: object.pageSize,
            current: object.current,
        });
    };
    useEffect((): void => {
        getEvidences({
            current: 1,
            pageSize: 10,
        });
    }, []);
    if (isHome) {
        return (
            <div className="evidence-management-content-home">
                <BreadCrumbBar breads={breadsList} />
                <div className="evidence-management-content-home-search">
                    <FormItemComponent
                        formParams={formParams}
                        onSearch={onSearch}
                    ></FormItemComponent>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            marginBottom: "15px",
                        }}
                    >
                        <Button
                            type="primary"
                            onClick={() => {
                                // window.location.href='/evidence/account/management/add'
                                navigate("/evidence/account/management/add");
                            }}
                        >
                            +新增客户
                        </Button>
                    </div>
                </div>
                <div className="evidence-management-content-home-table">
                    {/* <Table columns={columns} dataSource={data} pagination={pageOptions} onChange={handlePage} /> */}
                    <TableCom
                        columns={columns}
                        dataSource={data}
                        pagination={pageOptions}
                        onChange={handlePage}
                        scroll={{
                            y: "calc(100vh - 370px)",
                        }}
                    ></TableCom>
                </div>
            </div>
        );
    } else {
        return <></>;
    }
};

export default ManagementHome;
