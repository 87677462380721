import React, { useEffect, useState } from "react";
import { Button, message, Form, Select, DatePicker, Input, Checkbox } from 'antd'
import './index.scss'
import BreadCrumbBar from '../../../../../../../components/BreadCrumbBar'
import EditBaseInfo from "./edit";
import Util from '../../../../../../../services/util';
import { useParams } from "react-router-dom";
import ModalCom from '../../../../../../../components/ModalCom'
import { getCustomerNodityInfo, addCustomerFollow } from '../../../../../promise'
import { followWayList } from '../../config'
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_FOLLOW } from '../../../../../../../store/constants'
const util = Util.getInstance();
const breads = [
    {
        label: '客户管理',
        path: ''
    },
    {
        label: '详情',
        path: ''
    },
]
const sourceMap = {
    SELF_EXCAVATION: '自行发掘',
    CUSTOMER_INTRODUCTION: '客户介绍',
    NETWORK_PROMOTION: '网络推广',
    SOCIAL_ACTIVITIES: '社会活动',
    CUSTOMER_PROACTIVELY_CONTACTED: '客户主动联系',
    OTHER: '其他',
}
const companyMap = {
    CONSERVATION_PLANT: '利废工厂',
    TRADING_COMPANY: '贸易公司',
    FREIGHT_YARD_CATTLE: '货场黄牛',
}
const tradeMap = {
    RENEWABLE_RESOURCES: '再生资源',
    MINERAL: '矿石',
}
const categoryMap = {
    STEEL_SCRAP: '废钢',
    BROKE: '废纸',
    WASTE_PLASTICS: '废塑料',
    WASTE_COLOR: '废有色',
    ORE: '矿石',
    OTHER: '其他',
}
const statusMap = {
    RESERVE_CUSTOMERS: '储备客户',
    CONTINUOUS_FOLLOW_UP: '持续跟进',
    SIGNED: '已签约',
}
const unitMap = {
    KG: '千克',
    G: '克',
    T: '吨',
    TOWER: '台',
    PIECE: '个',
}
const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 },
}
const uuid = () => {
    let date = (new Date()).valueOf();//获取时间戳
    let txt = '1234567890';//生成的随机机器码
    let len = 13;//机器码有多少位
    let pwd = '';//定义空变量用来接收机器码
    for (let i = 0; i < len; i++) {
        pwd += txt.charAt(Math.floor(Math.random() * txt.length));//循环机器码位数随机填充
    }
    return date + pwd;
}
const importantMap = {
    1: '极高',
    2: '高',
    3: '中',
    4: '低',
    5: '极低',
}
const Option = Select.Option
const BasicForm = (props: any) => {
    const getSession = localStorage.getItem('operation_authorization') || '{}'
    const { isShowEdit } = props
    const dispatch = useDispatch()
    const updateNotify = useSelector((state: any) => state.updateNotify)
    const [formModal] = Form.useForm()
    // 用户组数据查询
    const [notifyObj, setNotifyObj] = useState<any>({
        groups: [],
        users: []
    })
    const [open, setOpen] = useState(false)
    const [uList, setUList] = useState<any>([])
    let params = useParams<{ id: string }>()
    let [isEdit, setIsEdit] = useState<boolean>(false)
    let [detailData, setDetailData] = useState<any>({})
    // 获取通知用户组和个人信息
    const getNodityInfo = (id?: any) => {
        if (!id || id === '') return
        getCustomerNodityInfo(id)
            .then((res: any) => {
                if (res.code === 200) {
                    setNotifyObj(res.data)
                    const grops = res.data.groups.map((item: any) => item.groupId) || []
                    formModal.setFieldValue('notifyGroups', grops)
                    // 通知个人
                    const users = res.data.users.map((item: any) => item.userId) || []
                    formModal.setFieldValue('notifyUsers', users)
                }
            })
    }
    const handleOk = () => {
        formModal.validateFields()
            .then((res: any) => {
                const params = {
                    ...res,
                    customerId: detailData.id,
                    notifyGroups: res.notifyGroups.toString(),
                    notifyUsers: res.notifyUsers.toString(),
                    followTime: dayjs(res.followTime).format('YYYY-MM-DD HH:mm:ss')
                }
                addCustomerFollow(params)
                    .then((response: any) => {
                        if (response.code === 200) {
                            message.success('新增跟进记录成功')
                            setOpen(false)
                            dispatch({
                                type: UPDATE_FOLLOW,
                                payload: {
                                    value: uuid()
                                }
                            })
                            formModal.resetFields()
                        } else {
                            message.error(response.message || '新增跟进记录失败')
                        }
                    })
                    .catch(err => {
                        console.log(err, 'err')
                    })
            })
    }
    useEffect(() => {
        getDetail()
    }, [])
    useEffect(() => {
        getNodityInfo(params.id)
    }, [params, updateNotify])
    const getDetail = () => {
        util.httpGet(`/sinzetech-crm/customer/${params.id}`).then((res) => {
            setDetailData(res.data)
            if (res.data && (res.data?.businessSalesmanList || res.data?.businessOwner)) {
                // const res= details?.businessOwner || [].concat(details?.businessSalesmanList || [])
                const arr = JSON.parse(JSON.stringify(res.data?.businessSalesmanList))
                if (!arr.some((item: any) => item.id === res.data?.businessOwner?.id)) {
                    arr.push(res.data?.businessOwner)
                }
                setUList(arr)
            }
        })
    }
    const submit = (customerStatus: string) => {
        let obj = { ...detailData }
        delete obj.id
        delete obj.createUser
        util.httpPutUrl(`/sinzetech-crm/customer/${params.id}`, {
            ...obj,
            businessSalesmanList: obj.businessSalesmanList.map((item: any) => item.id),
            businessOwner: obj.businessOwner.id || '',
            customerStatus,
        }).then((res) => {
            message.success('操作成功')
            getDetail()
        })
    }
    return (
        <div className="basic_info">
            <BreadCrumbBar breads={breads}></BreadCrumbBar>
            <div className="basic_content">
                <div className="top">
                    <h3>基本信息
                        {
                            isShowEdit && <span
                                onClick={() => {
                                    setIsEdit(true)
                                }}
                            >编辑</span>
                        }

                    </h3>
                    {
                        isShowEdit ? <div className="btn_box">
                            {
                                detailData?.customerStatus == 'CONTINUOUS_FOLLOW_UP' &&
                                <Button
                                    className="btn"
                                    style={{ marginTop: 0 }}
                                    type="primary"
                                    onClick={() => {
                                        submit('RESERVE_CUSTOMERS')
                                    }}
                                >转为储备客户</Button>
                            }
                            {
                                (detailData?.customerStatus == 'CONTINUOUS_FOLLOW_UP' || detailData?.customerStatus == 'RESERVE_CUSTOMERS') &&
                                <Button
                                    className="btn"
                                    style={{ marginTop: 0 }}
                                    type="primary"
                                    onClick={() => {
                                        submit('SIGNED')
                                    }}
                                >完成签约</Button>
                            }
                            <Button type="primary" onClick={() => {
                                const grops = notifyObj.groups.map((item: any) => item.groupId) || []
                                // 通知个人
                                const users = notifyObj.users.map((item: any) => item.userId) || []
                                formModal.setFieldValue('followWay', 'PHONE')
                                formModal.setFieldValue('followUser', JSON.parse(getSession)?.userName)
                                formModal.setFieldValue('notifyUsers', users)
                                formModal.setFieldValue('notifyGroups', grops)
                                setOpen(true)
                            }}>新增跟进记录</Button>
                        </div> : null
                    }

                </div>
                <div className="infos">
                    <div className="info_item">
                        <div className="info_item_tip">客户名称：</div>
                        <div className="info_item_content">{detailData?.companyName}</div>
                    </div>
                    <div className="info_item">
                        <div className="info_item_tip">创建人：</div>
                        <div className="info_item_content">{detailData?.createUser?.name}</div>
                    </div>
                    <div className="info_item">
                        <div className="info_item_tip">创建时间：</div>
                        <div className="info_item_content">{detailData?.createTime}</div>
                    </div>
                    <div className="info_item">
                        <div className="info_item_tip">状态：</div>
                        <div className="info_item_content">{statusMap[detailData?.customerStatus]}</div>
                    </div>
                    <div className="info_item">
                        <div className="info_item_tip">来源：</div>
                        <div className="info_item_content">{sourceMap[detailData?.customerSource]}</div>
                    </div>
                    <div className="info_item">
                        <div className="info_item_tip">客户类型：</div>
                        <div className="info_item_content">{companyMap[detailData?.companyType]}</div>
                    </div>
                    <div className="info_item">
                        <div className="info_item_tip">重要程度：</div>
                        <div className="info_item_content">{importantMap?.[detailData?.importance]}</div>
                    </div>
                    <div className="info_item">
                        <div className="info_item_tip">行业：</div>
                        <div className="info_item_content">{tradeMap[detailData?.trade]}</div>
                    </div>
                    <div className="info_item">
                        <div className="info_item_tip">采购品类：</div>
                        <div className="info_item_content">{categoryMap[detailData?.purchaseCategory]}</div>
                    </div>
                    <div className="info_item">
                        <div className="info_item_tip">年采购量：</div>
                        <div className="info_item_content">{detailData?.yearPurchase}{unitMap[detailData?.yearPurchaseUnit]}</div>
                    </div>
                    <div className="info_item">
                        <div className="info_item_tip">经营地：</div>
                        <div className="info_item_content">{detailData?.manageAddress}</div>
                    </div>
                    <div className="info_item">
                        <div className="info_item_tip">基本情况：</div>
                        <div className="info_item_content">{detailData?.mainAppeal}</div>
                    </div>
                    <div className="info_item">
                        <div className="info_item_tip">业务负责人：</div>
                        <div className="info_item_content">{detailData?.businessOwner?.name}</div>
                    </div>
                    <div className="info_item">
                        <div className="info_item_tip">业务员：</div>
                        <div className="info_item_content">{detailData?.businessSalesmanList?.map((item: any) => item.name)?.join(',')}</div>
                    </div>
                    <div className="info_item">
                        <div className="info_item_tip">初次接触日期：</div>
                        <div className="info_item_content">{detailData?.firstContactTime && dayjs(detailData?.firstContactTime).format('YYYY-MM-DD')}</div>
                    </div>
                </div>
            </div>
            {
                isEdit &&
                <EditBaseInfo
                    infos={detailData}
                    cancelModal={(refresh?: boolean) => {
                        if (refresh) {
                            getDetail()
                        }
                        setIsEdit(false)
                    }}
                />
            }
            {
                open && <ModalCom open={open} title='新增跟进记录' onCancel={() => {
                setOpen(false)
                formModal.resetFields()
            }}
                onOk={() => handleOk()}
                okText='保存'
            >
                <Form {...layout} form={formModal}>
                    <Form.Item label='跟进方式' name='followWay' rules={
                        [
                            {
                                required: true, message: '请选择跟进方式'
                            }
                        ]
                    }>
                        <Select>
                            {
                                followWayList.map((item: any) => {
                                    return <Option key={item.value} value={item.value}>{item.label}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item label='跟进人' name='followUser' rules={
                        [
                            {
                                required: true, message: '请选择跟进人'
                            }
                        ]
                    }>
                        <Select>
                            {
                                uList.map((item: any) => {
                                    return <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                })
                            }
                            {/* 客户详情里面的业务员和业务负责人数据选择 */}
                        </Select>
                    </Form.Item>
                    <Form.Item label='发生时间' name='followTime' rules={
                        [
                            {
                                required: true, message: '请选择跟进时间'
                            }
                        ]
                    }>
                        <DatePicker showTime/>

                    </Form.Item>
                    <Form.Item label='跟进摘要' name='followSummary' rules={
                        [
                            {
                                required: true, message: '请输入跟进摘要'
                            }
                        ]
                    }>
                        <Input placeholder="请输入"></Input>
                    </Form.Item>
                    <Form.Item label='跟进详情' name='followDetail' rules={
                        [
                            {
                                required: true, message: '请输入跟进详情'
                            }
                        ]
                    }>
                        <Input.TextArea rows={3} placeholder="请输入" ></Input.TextArea>
                    </Form.Item>
                    <Form.Item label='通知用户组' name='notifyGroups' >
                        <Checkbox.Group>
                            {
                                notifyObj.groups.map((item: any) => {
                                    return <Checkbox value={item.groupId}>{item.name}</Checkbox>
                                })
                            }
                        </Checkbox.Group>
                    </Form.Item>
                    <Form.Item label='通知个人' name='notifyUsers' >
                        <Checkbox.Group >
                            {
                                notifyObj.users.map((item: any) => {
                                    return <Checkbox value={item.userId}>{item.name}</Checkbox>
                                })
                            }
                        </Checkbox.Group>
                    </Form.Item>
                </Form>
            </ModalCom>
            }
            
        </div>
    )
}

export default BasicForm