import React, { useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload, Modal, Image } from "antd";
import { uuid } from "@src/utils";
import { Page, Document, pdfjs } from "react-pdf";
import { uploadFile } from "../../promise";
import { uploadURL } from "@src/utils/invoiceURL";
const baseURL = uploadURL();
import Util from "@src/services/util";
const util = Util.getInstance();

const { Dragger } = Upload;
const accepts = ["png", "jpg", "pdf"];
const DraggerUpload = (props: any) => {
    const { callback } = props;
    const [open, setOpen] = useState(false);
    const [fileType, setFileType] = useState<any>({});
    const [numPages, setNumPages] = useState(0);
    const [file, setFile] = useState<any>([]);
    const uploadFun = (info: any) => {
        console.log("info", info);

        const { file, onSuccess, onError } = info;
        const str = file.name.split(".");
        const type = str[str.length - 1];
        console.log(type, accepts.includes(type), "accepts.includes(type)");
        if (accepts.includes(type)) {
            const formData = new FormData();
            formData.append("file", file);
            util.httpPost(
                `/file/upload`,
                formData,
                // {},
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
                baseURL
            ).then((res: any) => {
                console.log(res, "res");
                if (res?.code === 200) {
                    const list = [
                        {
                            url: res.data.downloadLink,
                            downloadLink: res.data.downloadLink,
                            filePath: res.data.filePath,
                            mimeType: type,
                            fileName: file.name,
                            size: file.size / 1024 / 1024,
                            fileId: res.data.filePath,
                        },
                    ];
                    setFile(list);
                    onSuccess(list);
                    callback &&
                        callback({
                            ...res?.data,
                            fileName: file.name,
                        });
                }
            });
        } else {
            onError();
        }
    };
    const params = {
        // name: "file",
        multiple: false,
        accept: ".png, .jpg,.pdf",
        // fileList: file,
        maxCount: 1,
        customRequest: uploadFun,
        onDrop(e: any) {
            const file = e.dataTransfer.files[0];
            const str = file.name.split(".");
            const type = str[str.length - 1];
            console.log("Dropped files", str, e.dataTransfer.files[0]);
            if (!accepts.includes(type)) {
                message.error("请拖拽正确格式的文件");
                return null;
            }
        },
        // onPreview: (info: any) => {
        //     console.log("click", info);
        //     if (info?.status === "done") {
        //         setOpen(true);
        //         setFileType(info?.response[0]);
        //     }
        // },
    };
    return (
        <div>
            <Dragger {...params}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                <p className="ant-upload-hint">支持扩展名: .jpg .png .pdf</p>
            </Dragger>
            <Modal
                open={open}
                title={null}
                footer={null}
                onCancel={() => {
                    setOpen(false);
                }}
                width={800}
            >
                {fileType?.mimeType === "pdf" ? (
                    <Document
                        file={fileType?.downloadLink}
                        loading="努力加载中..."
                        renderMode="canvas"
                        onLoadSuccess={(e: any) => {
                            setNumPages(e.numPages);
                        }}
                        options={{
                            // 解决电子发票部分样式 无法识别
                            cMapUrl: `https://cdn.jsdelivr.net/npm/pdfjs-dist@2.16.105/cmaps/`,
                            cMapPacked: true,
                            disableWorker: true,
                        }}
                    >
                        {new Array(numPages).fill("").map((item, index) => {
                            return (
                                <Page
                                    key={index}
                                    pageNumber={index + 1}
                                    width={750}
                                    loading="努力加载中..."
                                    renderMode="canvas"
                                    renderInteractiveForms={true}
                                    renderAnnotationLayer={false}
                                    renderTextLayer={false}
                                />
                            );
                        })}
                    </Document>
                ) : (
                    <Image src={fileType?.downloadLink} preview={false}></Image>
                )}
            </Modal>
        </div>
    );
};
export default DraggerUpload;
