import { Badge, Table } from "antd";
import React, { memo } from "react";
import { getGoodsByGroupId } from "../../service";
import { useTable } from "@src/hooks/useTable";
import { ColumnType } from "antd/es/table";
import { useParams } from "react-router-dom";
import { TableToolBar } from "@src/components/TableToolBar";
import { GOODS } from "@src/constant/goods";
import './index.scss'

const defaultProps = {};
type props = {};
export type GoodsProps = Required<typeof defaultProps> & props;
export const Goods: React.FC<GoodsProps> = memo((props) => {
  const routerParams = useParams();
  const columns: ColumnType<API.Goods.Item>[] = [
    { title: "序号", dataIndex: "id" , render: (_, _1, index) => index + 1 },
    { title: "货品名称", dataIndex: "name" },
    { title: "数量单位", dataIndex: "goodsUnit",render:(value)=>{
      return GOODS.unitOfMeasure[value]||value
    } },
    { title: "货品分类", dataIndex: "goodsCategoryName" },
    { title: "开票名称", dataIndex: "invoiceName" },
    { title: "规格", dataIndex: "showSpecification",render(value, record, index) {
        return value?'显示规格':'不显示规格'
    }, },
    {
      title: "状态",
      dataIndex: "isEnable",
      render: (value) => {
        return value ? <Badge color="#00d18b" text="正常"></Badge> : <Badge color="#f53f3f" text="已禁用"></Badge>;
      },
    },
  ];
  const tableService = useTable(
    async (params) => {
      const { current, size, createDate, ...rest } = params;
      const res = await getGoodsByGroupId({
        id: routerParams.id,
        ...rest,
        pageSize: size,
        pageNumber: current,
        withTotal: true
      });
      return {
        data: res.list,
        total: Number(res.total),
      };
    },
    { defaultCurrent: 1, defaultSize: 20 }
  );
  return (
    <>
    
      <TableToolBar
        {...tableService.getToolBarProps?.({
          search: {
            placeholder: "输入货品名称，货品分类搜索",
          },
          // title: "货品列表",
        })}
      />
      <Table
        rowKey={"id"}
        pagination={tableService.getPaginationProps?.({
          className: "page_num",
        })}
        {...tableService.getTableProps({
          rowClassName:(record)=>{
            return record.isEnable ? "" : "xz-table-row__disabled"
          },
          className: "public_table",
          scroll: { y: "34vh" },
        })}
        columns={columns}
      />
    </>
  );
});
Goods.defaultProps = defaultProps;
