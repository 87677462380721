/**
 * @author 阮东辉
 * @time 2023/3/15
 * @type 图片/视频/音频
 * */ 
import React, { useEffect, useState, useImperativeHandle, forwardRef } from "react";
import { Form, Input, Checkbox, DatePicker   } from "antd";
// import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import './index.scss'
import { useDispatch, useSelector} from 'react-redux'
import {UPDATE_BASIC, UPDATE_RULE} from '../../../../store/constants'
import dayjs from "dayjs";
const FormItem = Form.Item
/**
 * @param{type} string 区别是限制条件或者基本类型数据 
 * */ 

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 24,
  },
};

const formatDate = {
  'date':'YYYY-MM-DD',
  'time':"YYYY-MM-DD HH:MM:ss"
}


const CustomerDate = (props:any) => {
  const {type= 'date', onChange, defaultValue, disabled} = props
  if( defaultValue && defaultValue !== undefined ){
    return  <DatePicker
    disabled={disabled}
    defaultValue={dayjs(defaultValue, formatDate[type])}
    format={formatDate[type]}
    onChange={(e:any) => {
      console.log(e, 'eeeeeeee')
      onChange && onChange(e)
    }}
    // showTime={type !== 'date'?formatDate[type] : '' }
  ></DatePicker>
  }else{
    return <DatePicker
    disabled={disabled}
    format={formatDate[type]}
    onChange={(e:any) => {
      onChange && onChange(e)
    }}
    showTime={type !== 'date'?formatDate[type] : '' }
  ></DatePicker>
  }
}


const TimeTemplate = (props:any, ref:any) => {
  const {infoType = 'basicInfo', type = 'date', infoData, isInfo} = props
  const dispatch = useDispatch<any>()
  const selector:any = useSelector(state => state)
  const [formBacis] = Form.useForm();
  const [formCondition] = Form.useForm()
  // const basicForm = useRef<any>({})
  const [basic, setBasic] = useState<any>({
    ...selector.basicForm
  })

  // rules
  const [rules, setRules] = useState<any>({
    maxValue:'',
    minValue:'',
    ...selector.ruleForm,
  })
  const updateState = ( type:string, value:any) => {
    if(infoType === 'basicInfo'){
      setBasic({
        ...basic,
        [type]:value
      })
      dispatch({
        type:UPDATE_BASIC, payload:{
          [type]:value
        }
      })
    }
    if(infoType === 'condition'){
      setRules({
        ...rules,
        [type]:value
      })
      dispatch({
       type:UPDATE_RULE, payload:{
          [type]:value
        }
      })
    }
  }  
  const reset= () => {
    formBacis.resetFields()
    formCondition.resetFields()
  }
  const formValidate = () => {
    formBacis.validateFields()
    formCondition.validateFields()
  }
  useImperativeHandle(ref, () =>({
    reset,
    formValidate
  }))
  useEffect(() => {
    if(infoData?.name && infoData?.name !==''){
      formBacis.setFieldValue('name', infoData.name)
    }
  }, [infoData])// eslint-disable-line
  useEffect(() => {
    console.log(basic, rules,selector, 'wwwwwwwwwwwwww')
  }, [basic, rules, selector])
  if(infoType === 'basicInfo'){
    return <div>
      <Form {...layout} form={formBacis}>
        <FormItem name='name' label='字段名称' rules={[
          {
            required: true, message:'请输入字段名称'
          }
        ]}>
          <Input  maxLength={10} disabled={isInfo} defaultValue={infoData?.name} className="ipt" onChange={(e) => {
            updateState( 'name', e.target.value)
            formBacis.setFieldValue('name', e.target.value)
          }}></Input>
          <span>不超过10个字，支持中文，英文，数字，字母组合</span>
        </FormItem>
        <FormItem label='默认值'>
          <span>
            {/* {infoData.defaultValue.dataValue} */}
            <CustomerDate defaultValue={infoData?.defaultValue?.dataValue} type={type} disable={isInfo} onChange={(e:any) => {
              if(e === null){
                console.log('null')
                dispatch({
                  type:UPDATE_BASIC,
                  payload:{
                    dataValue: ''
                  }
                })
              }else{
                dispatch({
                  type:UPDATE_BASIC,
                  payload:{
                    dataValue: type === 'date'?  dayjs(new Date(e)).format('YYYY-MM-DD'): dayjs(new Date(e)).format('YYYY-MM-DD HH:MM:ss')
                  }
                })
              }
              
            }}></CustomerDate>
          {/* {infoData.defaultValue?.dataValue && infoData.defaultValue?.dataValue  !== undefined ?
            <DatePicker
            disabled={isInfo}
              defaultValue={dayjs(infoData.defaultValue?.dataValue, formatDate[type])}
              format={formatDate[type]}
              onChange={(e:any) => {
                updateState('dataValue', dayjs(new Date(e)).format('YYYY-MM-DD'))
              }}
              showTime={type !== 'date'?formatDate[type] : '' }
            ></DatePicker> :  <DatePicker
            disabled={isInfo}
            format={formatDate[type]}
            onChange={(e:any) => {
              updateState('dataValue', dayjs(new Date(e)).format('YYYY-MM-DD'))
            }}
            showTime={type !== 'date'?formatDate[type] : '' }
          ></DatePicker>} */}
          </span>
          
            
        </FormItem>
        <FormItem label='占位内容'>
          <Input disabled={isInfo} className="ipt"  defaultValue={infoData?.placeholder}  onChange={(e) => {
            updateState('placeholder', e.target.value)
          }}></Input>
          <span>占位内容,显示在输入框中每用户点击输入框或有内容输入后消失</span>
        </FormItem>
        <FormItem label='字段说明'>
          <Input disabled={isInfo} className="ipt" defaultValue={infoData?.comment} onChange={e => {
            updateState('comment', e.target.value)
          }} ></Input>
          <span>字段说明,显示在字段输入框之后(移动端在输入框下方),用于提示输入框及规则</span>
        </FormItem>
      </Form>
    </div>
  }else{
    return <div>
      <Form {...layout} form={formCondition}>
        <FormItem label='限制值范围'>
            <span>
              最早:
              <CustomerDate type={type} defaultValue={infoData.rules?.limitDateRange?.minValue} disable={isInfo} onChange={(e:any) => {
                  if(e === null){
                    setRules({
                      ...rules,
                      minValue: ''
                    })
                    dispatch({
                      type:UPDATE_RULE,
                      payload:{
                        limitDateRange:{
                          maxValue: rules.limitDateRange?.maxValue,
                          minValue: ''
                        }
                      }
                    })
                  }else{
                    setRules({
                      ...rules,
                      minValue: type === 'date'?  dayjs(new Date(e)).format('YYYY-MM-DD'): dayjs(new Date(e)).format('YYYY-MM-DD HH:MM:ss')
                    })
                    dispatch({
                    type:UPDATE_RULE,
                    payload:{
                      limitDateRange:{
                        maxValue: rules.limitDateRange?.maxValue,
                        minValue: type === 'date'?  dayjs(new Date(e)).format('YYYY-MM-DD'): dayjs(new Date(e)).format('YYYY-MM-DD HH:MM:ss')
                      }
                    }
                  })
                  }
                  
                  
                }}></CustomerDate>
            </span>
              
            <span>  
              最晚:
              <CustomerDate type={type} disable={isInfo} defaultValue={infoData.rules?.limitDateRange?.maxValue} onChange={(e:any) => {
                // updateState('limitDateRange', {
                //   ...rules.limitDateRange,
                //   maxValue: type === 'date'?  dayjs(new Date(e)).format('YYYY-MM-DD'): dayjs(new Date(e)).format('YYYY-MM-DD')
                // })
                if(e === null){
                  setRules({
                    ...rules,
                    maxValue: ''
                  })
                  dispatch({
                    type:UPDATE_RULE,
                    payload:{
                      limitDateRange:{
                        minValue: rules.minValue,
                        maxValue: ''
                      }
                    }
                  })
                }else{
                  setRules({
                  ...rules,
                  maxValue: type === 'date'?  dayjs(new Date(e)).format('YYYY-MM-DD'): dayjs(new Date(e)).format('YYYY-MM-DD ')
                })
                dispatch({
                  type:UPDATE_RULE,
                  payload:{
                    limitDateRange:{
                      minValue: rules.minValue,
                      maxValue: type === 'date'?  dayjs(new Date(e)).format('YYYY-MM-DD'): dayjs(new Date(e)).format('YYYY-MM-DD ')
                    }
                  }
                })
                }
                
                
              }}></CustomerDate>
              </span> 
        </FormItem>
        {/* <FormItem label={<Checkbox>限制唯一性</Checkbox> }>
          <Radio.Group>
            {
              [{label:'全平台唯一', value:'1'},{label:'当前客户唯一', value:'2'}].map((item:any) => <Radio key={item.value} value={item.value}>{item.label}</Radio>)
            }
          </Radio.Group>
        </FormItem>
        <FormItem label='配置警示文案'>
          <Input placeholder="请输入警示文案内容，不超过20个汉字" maxLength={20} className="ipt"></Input>
          <span>警示文案可不填，按照限制条件的默认规则进行警示</span>
        </FormItem> */}
      </Form>
    </div>
  }

}

export default forwardRef(TimeTemplate) 




