import React, { useEffect, useState, useMemo } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import TopNavbar from "../../components/TopNavbar";
import LeftNavbar from "../../components/LeftNavbar";
import { TableOutlined, DashboardOutlined } from "@ant-design/icons";
import "./index.scss";
import { useSelector } from "react-redux";

const BusinessAgent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const roleList = useSelector((state: any) => state?.roleCodes) || [];
  console.log(roleList, "rrrrrrrrrrr");
  useEffect(() => {
    const pathName = location.pathname.match(/\/business\/(.*)/);
    if (pathName) {
    }
    navigate("/business/administration");
  }, []);
  const [menuList, setMenuList] = useState<any>([]);
  useEffect(() => {
    let arr: any = [
      {
        name: "工商税务开办",
        path: "/business/administration",
        icon: <DashboardOutlined />,
        key: "business",
        children: [
          {
            name: "工商税务开办",
            path: "/business/administration/list",
            key: "/business/administration",
          },
          {
            name: "个体户注销申请",
            path: "/business/logout/apply",
            key: "/business/administration",
          },
        ],
      },
    ];
    if (!!roleList && roleList.includes("admin")) {
      arr.push({
        name: "代办业务配置",
        path: "/business/agency",
        code: "admin",
        icon: <TableOutlined />,
      });
    }
    setMenuList(arr);
  }, [roleList]);
  return (
    <div className="evidence-container">
      <TopNavbar config={{ value: "business" }} />
      <div className="evidence-container-content">
        {menuList.length !== 0 && <LeftNavbar lists={menuList} />}
        <Outlet></Outlet>
      </div>
    </div>
  );
};

export default BusinessAgent;
