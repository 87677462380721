import React, { useState, useMemo, useEffect } from "react";
import UploadFile from "../../../../../../components/UploadFile";
import { Button, message, Modal } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { agencyDeregister } from "../../promise";
import "./index.scss";
import { useParams } from "react-router-dom";

const SubmitInfo = (props: any) => {
    const { details, update, logOff, taxRole, licenseRole, isSuc } = props;
    const searchParams: any = useParams();
    const [form, setForm] = useState<any>({});
    const [openParams, setOpenParams] = useState<any>({
        title: "",
        open: false,
    });
    // button按钮操作权限
    // 状态推进   每次的更改  传下一个状态的参数即可
    const handleSubmit = async (isModal: boolean = false) => {
        if (!form?.fileId) {
            message.error("请上传图片");
            return;
        }
        if (
            !isModal &&
            ["WAIT_DEREGISTER_LICENSE", "DEREGISTERED"].includes(
                renderInfo?.value
            )
        ) {
            setOpenParams({
                title:
                    renderInfo?.value === "WAIT_DEREGISTER_LICENSE"
                        ? "是否确认提交"
                        : "是否完成注销",
                open: true,
            });
            return;
        }
        let params = {
            id: searchParams?.id,
            nextDeregisterStatus: renderInfo?.value,
            file: form?.fileId,
        };
        const res: any = await agencyDeregister(params);
        if (res?.code === 200) {
            setForm({});
            update(searchParams?.id);
            setOpenParams({
                open: false,
                title: "",
            });
        }
    };
    useEffect(() => {
        setForm({});
        return () => {
            setForm({});
        };
    }, [details]);
    // 上传文件后 存储图片数据
    const handleUpload = (value: any) => {
        if (value[0] && value[0]?.fileId) {
            setForm(value[0]);
        } else {
            setForm({});
        }
    };
    // 根据不同的权限  来控制是否可以上传
    const renderUpload = (bool: boolean) => {
        return bool ? (
            <div>
                <Button disabled={bool} icon={<UploadOutlined />}>
                    上传文件
                </Button>
            </div>
        ) : (
            <UploadFile
                ButtonText={<Button icon={<UploadOutlined />}>上传文件</Button>}
                accept="image/jpeg,image/png,image/jpg,"
                cb={(value: any) => handleUpload(value)}
                listType="text"
                type="image"
            ></UploadFile>
        );
    };
    const renderDesc = useMemo(() => {
        return (
            <span className="agency_sumit_info_content_desc">
                支持扩展名: .jpg、 .jpeg、 .png
            </span>
        );
    }, []);
    const renderInfo = useMemo(() => {
        let objs = {
            WAIT_SIGN: {
                title: "上传已签署的注销申请",
                formLabel: "上传注销申请书",
                upload: renderUpload(!logOff),
                desc: renderDesc,
                value: "WAIT_CLEAR_TAX", // 存储用于提交数据的状态参数
                disabled: !logOff,
            },
            WAIT_CLEAR_TAX: {
                title: "清税证明",
                formLabel: "上传清税证明",
                upload: renderUpload(!taxRole),
                desc: renderDesc,
                value: "WAIT_DEREGISTER_LICENSE",
                disabled: !taxRole,
            },
            WAIT_DEREGISTER_LICENSE: {
                title: "执照注销证明",
                formLabel: "上传执照注销证明",
                upload: renderUpload(!licenseRole),
                desc: renderDesc,
                value: "DEREGISTERED",
                disabled: !licenseRole, // 控制按钮是否可以操作
            },
            DEREGISTERED: {},
        };
        let keys = details?.deregisterStatus || "WAIT_SIGN";
        return objs[keys];
    }, [details, logOff, taxRole, licenseRole]);
    return (
        <div className="agency_sumit_info">
            <div className="agency_sumit_info_title">{renderInfo?.title}</div>
            <div className="agency_sumit_info_content">
                <span className="agency_sumit_info_content_label">
                    {renderInfo?.formLabel}
                </span>
                <div>
                    {isSuc && renderInfo?.upload}
                    {renderInfo?.desc}
                </div>
            </div>
            <div className="agency_sumit_info_btn">
                <Button
                    onClick={() => handleSubmit()}
                    disabled={renderInfo?.disabled}
                >
                    确认提交
                </Button>
            </div>
            {/* 弹窗二次确认 */}
            <Modal
                title={openParams?.title}
                open={openParams?.open}
                onCancel={() => {
                    setOpenParams({
                        open: false,
                        title: "",
                    });
                }}
                onOk={() => handleSubmit(true)}
            >
                <div className="form_item_m">
                    <span className="form_item_label">个体户名称:</span>
                    <div>
                        {details?.enterpriseNameList &&
                        details?.enterpriseNameList.length !== 0
                            ? details?.enterpriseNameList[0]
                            : ""}
                    </div>
                </div>
                <div className="form_item_m">
                    <span className="form_item_label">法人姓名:</span>
                    <div>{details?.legal}</div>
                </div>
                <div className="form_item_m">
                    <span className="form_item_label">法人身份证号:</span>
                    <div>{details?.idNo}</div>
                </div>
                <div className="form_item_m">
                    <span className="form_item_label">社会统一信用代码:</span>
                    <div>{details?.licenseNo}</div>
                </div>
            </Modal>
        </div>
    );
};

export default SubmitInfo;
