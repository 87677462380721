import Util from "../../services/util";
import qs from "qs";
import {message} from 'antd'
const util = Util.getInstance()
let basic_url ='/sinzetech-crm'

// 获取客户列表
export const getList = (values?: any) => {
  return util.httpGet(`/sinzetech-crm/customer?${qs.stringify({
    'sorts[0].field':'createTime',
    'sorts[0].direction':'DESCENDING',
})}`, values)
    .then(res => res)
    .catch((err) => {
      message.error(err.response.data.message)
    })
}
// 添加客户
export const addCustomer = (values?: any) => {
  return util.httpPost('/sinzetech-crm/customer', values)
    .then(res => res)
}
// 修改客户
export const editCustomer = (id?: any, values?: any) => {
  return util.httpPutUrl(`/customer/${id}`, values)
    .then(res => res)
}
// 获取客户
export const getCustomerInfo = (id?: any) => {
  return util.httpGet(`/sinzetech-crm/customer/${id}`)
    .then(res => res)
}


// 新建联系人
export const addCustomerContact = (value?:any) => {
  return util.httpPost(`${basic_url}/customer/contact`, value)
  .then(res => res)
}
// 修改联系人
export const editCustomerContact = (id?:any,value?:any) => {
  return util.httpPutBody(`${basic_url}/customer/contact/${id}`, value)
  .then(res => res)
}
// 删除联系人
export const deleteCustomerContact =(id?:any) => {
  return util.httpDeletel(`${basic_url}/customer/contact/${id}`)
  .then(res => res)
}
// 联系人详情
export const customerContactInfo = (id?:any) => {
  return util.httpGet(`${basic_url}/customer/contact/${id}`)
  .then(res => res)
}
// 联系人列表
export const customerContactList = (value?:any) => {
  return util.httpGet(`${basic_url}/customer/contact/list`, value)
  .then(res => res)
}

// 创建跟进记录
export const addCustomerFollow = (value:any) => {
  return util.httpPost(`${basic_url}/customer/follow`, value)
  .then(res => res)
}
// 编辑跟进记录
export const editCustomerFollow = (id:any, value:any) => {
  return util.httpPutUrl(`${basic_url}/customer/follow/${id}`,value)
  .then(res => res)
}
// 跟进记录列表
export const customerFollowList = (value:any) => {
  return util.httpGet(`${basic_url}/customer/follow/list`, value)
  .then(res => res)
}
// 跟进记录详情
export const getCustomerFollowInfo = (id:any) => {
  return util.httpGet(`${basic_url}/customer/follow/${id}`)
  .then(res => res)
}
// 通知范围更新
export const editCustomerNodify = (id:any, value:any) => {
  return util.httpPutUrl(`${basic_url}/customer/${id}/nodify`, value)
  .then(res => res)
}
// 通知范围查询
export const getCustomerNodityInfo = (id:any) => {
  return util.httpGet(`${basic_url}/customer/${id}/notify`)
  .then(res => res)
}
// 权限设置更新
export const editCustomerPermission =(id:any, value:any) => {
  return util.httpPutUrl(`${basic_url}/customer/${id}/permission`, value)
  .then(res => res)
}
// 权限设置查询
export const getCustomerPermissionInfo = (id:any) => {
  return util.httpGet(`${basic_url}/customer/${id}/permission`)
  .then(res => res)
}

// 获取用户个人数据
export const getUsersList = (values:any) => {
  return util.httpGet(`/sinzetech-admin-user/users`, values).then(data => {
      return data;
  }, err => err.response.data)
}

// 获取用户组数据
export const getUsersGrops = (values?:any ) => {
  return util.httpGet('/sinzetech-admin-user/user-groups',values)
  .then(res => res)
}

// 操作日志
export const getlogs = (value?:any) => {
  return util.httpGet('/log/list', value)
  .then(res => res)
}

// 获取操作
export const getlogList = (values:any) =>{
  return util.httpGetev(`/api/sinzetech-log/log?${qs.stringify({
    'sorts[0].field':'createTime',
    'sorts[0].direction':'DESCENDING',
})}`, values)
  .then(res => res)
}

// 根据客户id 获取当前客户的权限信息
export const customerRoleInfo = (params?: any) => {
    return util.httpGet('/sinzetech-admin-user/user-groups/by-user-id', params)
    .then(res => res)
}

// 关联实体列表
export const customerRelatedEntityList = (value: any) => {
  return util.httpGet(`${basic_url}/corporealEntity?${qs.stringify({
    'sorts[0].field': 'createTime',
    'sorts[0].direction': 'DESCENDING',
  })}`, value)
    .then(res => res)
}
// 关联实体详情
export const customerRelatedEntityDetail = (id: any, value?: any) => {
  return util.httpGet(`${basic_url}/corporealEntity/${id}`, value)
    .then(res => res)
}

// 交易列表
interface Entry {
  current: string | number,
  size: string | number,
  level?: string | null,
  sourceType?: string|null,
  status?: string| null,
  businessLeaderId?: string | null,
  startTime?: string | null,
  endTime?: string | null,
  regionId?: string | null,
  keyword?: string | number,
  type?: string | null,
  taxpayerId?: string | null,
  sourceId: string 
}
export const getEntryList = (params: Entry) => {
  return util.httpGet('/trade-entity/entity', params)
}

// 获取所有税区
export const getTaxs = (values: any) => {
  return util.httpGet("/sinzetech-tax/tax-region", values);
};