import React from "react";
import { Row, Col, Image } from "antd";
import "./index.scss";
interface BasicDetailsProps {
    details: any;
    idCardUrl?: string;
    idCardBackUrl?: string;
}

const BasicDetails = (props: BasicDetailsProps) => {
    const { details, idCardUrl, idCardBackUrl } = props;
    return (
        <div className="agency_info_details_container">
            <div className="h4_title border_btm">主体基本信息</div>
            <div className="basic_form_container">
                <Row>
                    <Col span={8}>
                        <div className="form_item">
                            <span className="form_item_label">个体户名称:</span>
                            <div>
                                {details?.enterpriseNameList &&
                                details?.enterpriseNameList.length !== 0
                                    ? details?.enterpriseNameList[0]
                                    : ""}
                            </div>
                        </div>
                        <div className="form_item">
                            <span className="form_item_label">所属税区:</span>
                            <div>{details?.taxRegionName}</div>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className="form_item">
                            <span className="form_item_label">法人姓名:</span>
                            <div>{details?.legal}</div>
                        </div>
                        <div className="form_item">
                            <span className="form_item_label">来源名称:</span>
                            <div>{'-'}</div>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className="form_item">
                            <span className="form_item_label">联系电话:</span>
                            <div>{details?.legalPhone}</div>
                        </div>
                        <div className="form_item">
                            <span className="form_item_label">业务员:</span>
                            <div>{details?.clerkUserName}</div>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="h4_title">证照信息</div>
            <div className="certificate_container">
                <div className="flex_phone_c">
                    <Image src={idCardUrl} width={104} height={104}></Image>
                    <span className="phone_desc">身份证正面</span>
                </div>
                <div className="flex_phone_c">
                    <Image src={idCardBackUrl} width={104} height={104}></Image>
                    <span className="phone_desc">身份证反面</span>
                </div>
                {details?.clearTaxFileUrl && details?.clearTaxFileUrl !== "" ? (
                    <div className="flex_phone_c">
                        <Image
                            src={details?.clearTaxFileUrl}
                            width={104}
                            height={104}
                        ></Image>
                        <span className="phone_desc">清税证明</span>
                    </div>
                ) : null}
                {details?.licenseCancelFileUrl &&
                details?.licenseCancelFileUrl !== "" ? (
                    <div className="flex_phone_c">
                        <Image
                            src={details?.licenseCancelFileUrl}
                            width={104}
                            height={104}
                        ></Image>
                        <span className="phone_desc">执照注销证明</span>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default BasicDetails;
