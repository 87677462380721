import React,{ReactNode, memo} from 'react'
import classname from 'classnames'
import { Progress } from 'antd'
import './index.scss'
import numeral from 'numeral'

const defaultProps = {}
type props = {
  title?: ReactNode,
  headerRight?: ReactNode,
  totalAmount?: number,
  paidAmount?: number,
  unpaidAmount?: number,
  deductAmount?: number
}
const classPrefix = 'amount-card-'
const calcPercent = (dividend: number=0, divisor: number=0)=>{
  if (divisor === 0) return 0;
  if (dividend > divisor) return 100;
  const rate = dividend/divisor
  if (rate > 0 && rate < 0.01) return 1;
  // 保留两位小数
  // return numeral(Math.floor(numeral(rate).multiply(100).multiply(100).value())).divide(100).value()
  return Math.floor(rate*100);
}
const formatAmount = (amount: number|undefined)=>{
  return numeral(amount).format('0,0.00')
}
export type AmountCardProps = Required<typeof defaultProps> & props & React.HTMLAttributes<HTMLDivElement>
export const AmountCard:React.FC<React.PropsWithChildren<AmountCardProps>> = memo((props)=>{
  const {title,headerRight,totalAmount,paidAmount,unpaidAmount,deductAmount} = props
  const percent = calcPercent(paidAmount,totalAmount)
  return <div className={classname(classPrefix)}>
    <div className={classname(classPrefix + 'header')}>
      <div className={classname(classPrefix + 'title')}>{title}</div>
      <div className={classname(classPrefix + 'header-right')}>{headerRight}</div>
    </div>
    <div className={classname(classPrefix + 'content')}>
      <div className={classname(classPrefix + 'total-amount')}>{formatAmount(totalAmount)}<span className={classname(classPrefix + 'unit')}>元</span></div>
      <div className={classname(classPrefix + 'progress-bar')}>
        <Progress 
          strokeLinecap="round" 
          status={percent===100 ? 'success' : 'active'}
          percent={percent}
        />
      </div>
    </div>
    <div className={classname(classPrefix + 'footer')}>
      <div className={classname(classPrefix + 'unpaid-amount')}><span className={classname(classPrefix + 'amount-label')}>未支付</span> {formatAmount(unpaidAmount)}</div>
      <div className={classname(classPrefix + 'paid-amount')}><span className={classname(classPrefix + 'amount-label')}>已支付</span>{formatAmount(paidAmount)}</div>
      <div className={classname(classPrefix + 'deduct-amount')}>{deductAmount?<><span className={classname(classPrefix + 'amount-label')}>扣款</span> {formatAmount(deductAmount)}</>:''}</div>
    </div>
  </div>
})
AmountCard.defaultProps = defaultProps