import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import TableCom from "../../../../components/TableCom";
import BreadCrumbBar from "../../../../components/BreadCrumbBar";
import "./index.scss";
import { Button } from "antd";
import { getList } from "./promise";

const breads = [
    {
        label: "组织管理",
        path: "/system",
    },
    {
        label: "用户组管理",
        path: "/system/user_group",
    },
];
const UserGroup = () => {
    let nav = useNavigate();
    const [total, setTotal] = useState(0);
    const [pageOptions, setPageOptions] = useState({
        pageSize: 10,
        current: 1,
    });

    const [list, setList] = useState([]);
    const getUserList = (object?: any) => {
        const params = {
            current: pageOptions.current,
            size: pageOptions.pageSize,
            ...object,
        };
        getList(params).then((res: any) => {
            if (res.code === 200) {
                setList(res.data.records);
                setTotal(res.data.total);
            }
        });
    };
    const handlePage = (params?: any) => {
        setPageOptions({
            pageSize: params.pageSize,
            current: params.current,
        });
        getUserList({
            size: params.pageSize,
            current: params.current,
        });
    };
    useEffect(() => {
        getUserList();
    }, []);
    const columns = [
        {
            title: "用户组名称",
            dataIndex: "name",
        },
        {
            title: "创建时间",
            dataIndex: "createTime",
        },
        {
            title: "操作",
            render: (record: any, item: any) => {
                return <Link to={`/system/userGroup/${item.id}`}>详情</Link>;
            },
        },
    ];
    return (
        <div className="container">
            <div className="content">
                <BreadCrumbBar breads={breads}></BreadCrumbBar>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginBottom: "15px",
                    }}
                >
                    <Button
                        type="primary"
                        onClick={() => nav("/system/userGroup/add")}
                    >
                        +新增用户组
                    </Button>
                </div>
                <TableCom
                    dataSource={list}
                    columns={columns}
                    onChange={handlePage}
                    pagination={{
                        ...pageOptions,
                        total: total,
                    }}
                    scroll={{
                        y: "calc(100vh - 300px)",
                        w: 1200,
                    }}
                ></TableCom>
            </div>
        </div>
    );
};

export default UserGroup;
