import React, { useEffect, useState, useMemo } from "react";
import { Form, Row, Col, Image, Tabs } from "antd";
import "./index.scss";
import { applyTypeObjet } from "../../config";
import { Page, Document, pdfjs } from "react-pdf";
import { getImageUrl } from "../../../../../promise";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

// 代办详情基本信息
const BasicDetails = (props: any) => {
    const { details, licenseName, fileDownUrl } = props;
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const [numPage, setNumPage] = useState<number>(1);
    // 委托协议
    const [numPages, setNumPages] = useState<number>(1);
    const [licenseCanceUrl, setLicenseCancelUrl] = useState("");
    useEffect(() => {
        form.setFieldsValue(details);
        getLicenseCancelFile(details?.clearTaxFileId);
    }, [details]);
    useEffect(() => {}, []);
    const getLicenseCancelFile = (id?: any) => {
        if (!id) return;
        const params = {
            zoom: false,
            fileIds: id,
        };
        getImageUrl(params).then((res: any) => {
            if (res.success) {
                setLicenseCancelUrl(res.data[0]);
            }
        });
    };
    // 是否显示视频
    const shoeVideo = useMemo(() => {
        return details?.faceVideoUrlName || details?.dualreCordingVideoName;
    }, [details]);
    // 是否显示
    // const tabItems = [
    //     {
    //         key: "1",
    //         label: <div style={{ padding: "0 24px" }}>委托协议</div>,
    //         children: (
    //             <div style={{ marginLeft: "24px" }}>
    //                 {details?.delegationAgreementUrlName && (
    //                     <>
    //                         <Document
    //                             file={details?.delegationAgreementUrlName}
    //                             onLoadSuccess={(e: any) => {
    //                                 setNumPages(e.numPages);
    //                             }}
    //                             loading="努力加载中..."
    //                             renderMode="canvas"
    //                         >
    //                             {new Array(numPages)
    //                                 .fill("")
    //                                 .map((item, index) => {
    //                                     return (
    //                                         <Page
    //                                             key={index}
    //                                             pageNumber={index + 1}
    //                                             width={800}
    //                                             loading="努力加载中..."
    //                                             renderMode="canvas"
    //                                             renderInteractiveForms={true}
    //                                             renderAnnotationLayer={false}
    //                                             renderTextLayer={false}
    //                                         />
    //                                     );
    //                                 })}
    //                         </Document>
    //                     </>
    //                 )}
    //             </div>
    //         ),
    //     },
    //     {
    //         key: "2",
    //         label: <div style={{ padding: "0 24px" }}>注销协议</div>,
    //         children: (
    //             <div style={{ display: "flex" }}>
    //                 <div>
    //                     {fileDownUrl && (
    //                         <>
    //                             <Document
    //                                 file={fileDownUrl}
    //                                 onLoadSuccess={(e: any) => {
    //                                     setNumPage(e.numPages);
    //                                 }}
    //                                 loading="努力加载中..."
    //                                 renderMode="canvas"
    //                             >
    //                                 {new Array(numPage)
    //                                     .fill("")
    //                                     .map((item, index) => {
    //                                         return (
    //                                             <Page
    //                                                 key={index}
    //                                                 pageNumber={index + 1}
    //                                                 width={600}
    //                                                 loading="努力加载中..."
    //                                                 renderMode="canvas"
    //                                                 renderInteractiveForms={
    //                                                     true
    //                                                 }
    //                                                 renderAnnotationLayer={
    //                                                     false
    //                                                 }
    //                                                 renderTextLayer={false}
    //                                             />
    //                                         );
    //                                     })}
    //                             </Document>
    //                         </>
    //                     )}
    //                 </div>
    //                 <div style={{ minWidth: "300px" }}>
    //                     {licenseCanceUrl ? (
    //                         <Image src={licenseCanceUrl} width={400}></Image>
    //                     ) : null}
    //                 </div>
    //             </div>
    //         ),
    //     },
    // ];
    return (
        <div className="licens_info_details">
            <div className="licens_info_details_container">
                <div className="h4_title border_btm">主体基本信息</div>
                <div className="basic_form_container">
                    <Row>
                        <Col span={8}>
                            <div className="form_item">
                                <span className="form_item_label">
                                    办理类型:
                                </span>
                                <div>{applyTypeObjet[details?.applyType]}</div>
                            </div>
                            <div className="form_item">
                                <span className="form_item_label">
                                    身份证号:
                                </span>
                                <div>{details?.idNo}</div>
                            </div>
                            <div className="form_item">
                                <span className="form_item_label">民族:</span>
                                <div>{details?.nation}</div>
                            </div>
                            <div className="form_item">
                                <span className="form_item_label">
                                    经营范围:
                                </span>
                                <div>{details?.businessScope}</div>
                            </div>
                            <div className="form_item">
                                <span className="form_item_label">
                                    就业人数:
                                </span>
                                <div>{details?.employedPopulation}</div>
                            </div>
                            <div className="form_item">
                                <span className="form_item_label">业务员:</span>
                                <div>{details?.clerkUserName}</div>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className="form_item">
                                <span className="form_item_label">
                                    法人姓名:
                                </span>
                                <div>{details?.legal}</div>
                            </div>
                            <div className="form_item">
                                <span className="form_item_label">
                                    个体户名称:
                                </span>
                                <div>
                                    {details?.enterpriseNameList &&
                                    details?.enterpriseNameList.length !== 0
                                        ? details?.enterpriseNameList[0]
                                        : "-"}
                                </div>
                            </div>
                            <div className="form_item">
                                <span className="form_item_label">备选1:</span>
                                <div>
                                    {(details?.enterpriseNameList &&
                                        details?.enterpriseNameList[1]) ||
                                        "-"}
                                </div>
                            </div>
                            <div className="form_item">
                                <span className="form_item_label">
                                    法人邮箱:
                                </span>
                                <div>{details?.email}</div>
                            </div>
                            <div className="form_item">
                                <span className="form_item_label">
                                    资金数额:
                                </span>
                                <div>{details?.amountOfFunds}</div>
                            </div>
                            <div className="form_item">
                                <span className="form_item_label">
                                    来源名称:
                                </span>
                                <div>{details?.sourceIdName || "-"}</div>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className="form_item">
                                <span className="form_item_label">
                                    联系电话:
                                </span>
                                <div>{details?.legalPhone}</div>
                            </div>
                            <div className="form_item">
                                <span className="form_item_label">
                                    法人住址:
                                </span>
                                <div>{details?.detailAddress}</div>
                            </div>
                            <div className="form_item">
                                <span className="form_item_label">备选2:</span>
                                <div>
                                    {(details?.enterpriseNameList &&
                                        details?.enterpriseNameList[2]) ||
                                        "-"}
                                </div>
                            </div>
                            <div className="form_item">
                                <span className="form_item_label">
                                    经营场所:
                                </span>
                                <div>{details?.businessPlace}</div>
                            </div>
                            <div className="form_item">
                                <span className="form_item_label">
                                    所属税区:
                                </span>
                                <div>{details?.taxRegionName}</div>
                            </div>
                        </Col>
                    </Row>
                </div>
                {/* 银行信息 */}
                <div className="h4_title">银行信息</div>
                <div className="basic_form_container" style={{ paddingTop: 0 }}>
                    <Row>
                        <Col span="8">
                            <div className="form_item">
                                <span className="form_item_label">
                                    开户银行:
                                </span>
                                <div>{details?.bankName}</div>
                            </div>
                            <div className="form_item">
                                <span className="form_item_label">
                                    开户支行:
                                </span>
                                <div>{details?.bankBranchName}</div>
                            </div>
                        </Col>
                        <Col span="8">
                            <div className="form_item">
                                <span className="form_item_label">账号:</span>
                                <div>{details?.bankAccountNo}</div>
                            </div>
                        </Col>
                        <Col span="8">
                            <div className="form_item">
                                <span className="form_item_label">
                                    银行预留手机号:
                                </span>
                                <div>{details?.bankPhoneNo}</div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="h4_title">证照信息</div>
                <div className="certificate_container">
                    <div className="flex_phone">
                        <Image
                            src={details?.idCardUrlName}
                            // rootClassName="certificate_img_container"
                            width={104}
                            height={104}
                        ></Image>
                        <span className="image_desc">身份证正面</span>
                    </div>
                    <div className="flex_phone">
                        <Image
                            src={details?.idCardBackUrlName}
                            // rootClassName="certificate_img_container"
                            width={104}
                            height={104}
                        ></Image>
                        <span className="image_desc">身份证反面</span>
                    </div>
                    {details?.taxRegionRequirement &&
                        details?.taxRegionRequirement.map((item: any) => {
                            return (
                                <div className="flex_phone" key={item?.photoId}>
                                    <Image
                                        src={item?.photoUrl}
                                        width={104}
                                        height={104}
                                    ></Image>
                                    <span
                                        className="image_desc"
                                        style={{ fontSize: "14px" }}
                                    >
                                        {item.name}
                                    </span>
                                </div>
                            );
                        })}

                    {licenseName ? (
                        <div className="flex_phone">
                            <Image
                                src={licenseName}
                                // rootClassName="certificate_img_container"
                                width={104}
                                height={104}
                            ></Image>
                            <span className="image_desc">营业执照</span>
                        </div>
                    ) : null}
                </div>
                {shoeVideo ? (
                    <>
                        <div className="h4_title">视频</div>{" "}
                        <div className="certificate_container">
                            <div className="flex_phone">
                                {/* {details?.faceVideoUrlName &&
                                details?.faceVideoUrlName !== "" ? ( */}
                                <video
                                    src={details?.faceVideoUrlName}
                                    style={{
                                        width: "104px",
                                        height: "104px",
                                    }}
                                    controls
                                    // autoPlay
                                    // loop
                                ></video>
                                {/* ) : null} */}
                                <span className="image_desc">人脸识别视频</span>
                            </div>
                            <div className="flex_phone">
                                <video
                                    src={details?.dualreCordingVideoName}
                                    style={{
                                        width: "104px",
                                        height: "104px",
                                    }}
                                    controls
                                    // autoPlay
                                    // loop
                                ></video>
                                <span className="image_desc">双录视频</span>
                            </div>
                        </div>
                    </>
                ) : null}
            </div>
            {/* 注销协议和委托协议 */}
            <div className="licens_info_details_container">
                <div className="h4_title border_btm">委托协议</div>
                <div style={{ marginLeft: "24px" }} className="pdf_container">
                    {details?.delegationAgreementUrlName && (
                        <>
                            <Document
                                file={details?.delegationAgreementUrlName}
                                onLoadSuccess={(e: any) => {
                                    setNumPages(e.numPages);
                                }}
                                loading="努力加载中..."
                                renderMode="canvas"
                            >
                                {new Array(numPages)
                                    .fill("")
                                    .map((item, index) => {
                                        return (
                                            <Page
                                                key={index}
                                                pageNumber={index + 1}
                                                width={800}
                                                loading="努力加载中..."
                                                renderMode="canvas"
                                                renderInteractiveForms={true}
                                                renderAnnotationLayer={false}
                                                renderTextLayer={false}
                                            />
                                        );
                                    })}
                            </Document>
                        </>
                    )}
                </div>
                {/* <Tabs defaultActiveKey="1" items={tabItems} /> */}
            </div>
        </div>
    );
};

export default BasicDetails;
