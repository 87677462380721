import React, { useEffect, useState, useRef } from "react";
import {
    Button,
    Row,
    Col,
    Form,
    Input,
    Select,
    Modal,
    DatePicker,
    Cascader,
    Upload,
    Popconfirm,
    message,
    Table,
} from "antd";
import BreadCrumbBar from "@src/components/BreadCrumbBar";
import TableCom from "@src/components/TableCom";
import { useParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import styles from "./index.module.scss";
import { enterpriseTypes, uploadSelect } from "../config";
import DraggerUpload from "../DragUpload";
import {
    getApplyInfo,
    updateApply,
    getUploadFiles,
    getCitys,
    seatchCity,
} from "../../promise";
import { Page, Document, pdfjs } from "react-pdf";
import { downloadImage, downloadFile } from "@src/utils/index";
const Option = Select.Option;
const { RangePicker } = DatePicker;
const { Dragger } = Upload;
const breads = [
    {
        label: "供应商准入",
        path: "",
    },
    {
        label: "添加供应商",
        path: "/supply",
    },
];
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 15,
    },
};
const FinancingOrdersUpdate = () => {
    const searchParams = useParams();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [modalFrom] = Form.useForm();
    const [numPages, setNumPages] = useState(0);
    const [operateForm] = Form.useForm();
    const [uploadFiles, setUploadFiles] = useState<any>({});
    // 文件上传
    const [open, setOpen] = useState(false);
    // 预览
    const [previewOpen, setPreviewOpen] = useState(false);
    const [file, setFile] = useState<any>({});
    const [info, setInfo] = useState<any>({});
    const [citys, setCitys] = useState<any>([]);
    const [list, setList] = useState<any>([]);
    const [loadList, setLoadList] = useState(true);
    // const list = useRef<any>([]);
    const getInfo = async (id: any) => {
        if (!id) return;
        const res = await getApplyInfo(id);
        if (res?.code === 200) {
            setInfo(res?.data);
            const values = res?.data;
            const basicForm = {
                name: values?.name,
                registerDate: dayjs(values?.registerDate),
                contactsName: values?.contactsName,
                supplierType: values?.supplierType,
                registerAddr: values?.registerAddr,
                contactsMobile: values?.contactsMobile,
                unifiedSocialCreditCode: values?.unifiedSocialCreditCode,
                registerAddrDetail: values?.registerAddrDetail,
                mailingAddress: values?.mailingAddress,
            };
            form.setFieldsValue(basicForm);
            const operateForms = {
                legalName: values?.legalName,
                legalIdCardStart: dayjs(values?.legalIdCardStart),
                legalIdCardEnd: dayjs(values?.legalIdCardEnd),
                legalIdCardDate: [
                    dayjs(values?.legalIdCardStart),
                    dayjs(values?.legalIdCardEnd),
                ],
                legalIdCard: values?.legalIdCard,
                // legalIdCardAddr: values?.legalIdCardAddr,
                legalMobile: values?.legalMobile,
                legalIdCardAddrDetail: values?.legalIdCardAddrDetail,
            };
            operateForm.setFieldsValue(operateForms);
            seachCode(values?.registerAddr, "form");
            // seachCode(values?.legalIdCardAddr, "opForm");
            setList(res?.data?.supplierAttachment || []);
        }
    };
    const seachCode = async (str: any, type: string) => {
        if (str) {
            const code = str;
            const pri = code.slice(0, 2) + "0000";
            const mar = code.slice(0, 4) + "00";
            if (type === "form") {
                form.setFieldValue("registerAddr", [
                    Number(pri),
                    Number(mar),
                    Number(code),
                ]);
            } else {
                // operateForm.setFieldValue("legalIdCardAddr", [
                //     Number(pri),
                //     Number(mar),
                //     Number(code),
                // ]);
            }
        }

        // const res = await seatchCity("110101");
        // const res: any = await axios.get(
        //     `https://test.api.saikul.com/pub-data/city?code=110101`
        // );
        // if (res?.code === 200) {
        //     if (res?.data?.records && res?.data?.records.length !== 0) {
        //         const vals: any = res?.data?.records[0]?.path
        //             .split("|")
        //             .map((item: any) => Number(item));
        //         if (type === "form") {
        //             console.log(vals, "vals");
        //             // form.setFieldsValue("registerAddr", vals);
        //         }
        //     }
        // }
    };
    // 获取省市区数据
    const getCityList = async () => {
        const res = await getCitys();
        if (res?.code === 200) {
            setCitys(res?.data);
        }
        console.log(res, "res-city");
    };
    const handleSubmit = async () => {
        if (!searchParams?.id) return;
        const basicForm = await form.validateFields();
        const opForm = await operateForm.validateFields();
        const params = {
            ...basicForm,
            ...opForm,
            registerDate:
                dayjs(basicForm.registerDate).format("YYYY-MM-DD") +
                " 00:00:00",
            legalIdCardStart:
                dayjs(opForm.legalIdCardDate[0]).format("YYYY-MM-DD") +
                " 00:00:00",
            legalIdCardEnd:
                dayjs(opForm.legalIdCardDate[1]).format("YYYY-MM-DD") +
                " 00:00:00",
            supplierAttachment: list,
            // legalIdCardAddr:
            //     opForm.legalIdCardAddr[opForm.legalIdCardAddr.length - 1],
            registerAddr:
                basicForm.registerAddr[basicForm.registerAddr.length - 1],
            id: searchParams?.id,
        };
        delete params.legalIdCardDate;

        console.log(params, basicForm, opForm, "sunmit");
        const res: any = await updateApply(searchParams?.id, params);
        if (res?.code === 200) {
            // navigate(-1);
            history.back();
        }
    };
    // 预览
    const handlePreview = async (record: any) => {
        console.log(record, "record");
        const str = record?.fileName.split(".");
        const type = str[str.length - 1];
        setPreviewOpen(true);
        setFile({
            ...record,
            fileUrl: record?.fileUrl,
            type,
        });
    };
    const handleDownload = async (record: any) => {
        // 下载
        const str = record?.fileName.split(".");
        const type = str[str.length - 1];
        if (type === "pdf") {
            // pdf文件
            downloadFile(record?.fileUrl, record?.fileName);
        } else {
            downloadImage(record?.fileUrl, record?.fileName);
        }
        // 如果是图片  downloadImage  否则  downloadUrlFile
    };
    const handleDelete = (record: any) => {
        const res =
            list.length !== 0
                ? list.filter((item: any) => item.fileId !== record?.fileId)
                : [];
        setInfo({
            ...info,
            supplierAttachment: res,
        });
        setList(res);
    };
    useEffect(() => {
        getInfo(searchParams?.id);
        getCityList();
    }, [searchParams]);
    const columns = [
        {
            title: "文件类型",
            dataIndex: "fileType",
            render: (record: any) => {
                const obj = uploadSelect.find(
                    (item: any) => item.value === record
                );
                return <span>{obj?.label}</span>;
            },
        },
        {
            title: "文件名称",
            dataIndex: "fileName",
        },
        {
            title: "上传时间",
            dataIndex: "createTime",
        },
        {
            title: "操作",
            render: (record: any) => {
                return (
                    <div>
                        <Button
                            type="link"
                            onClick={() => handlePreview(record)}
                        >
                            预览
                        </Button>
                        <Button
                            type="link"
                            onClick={() => handleDownload(record)}
                        >
                            下载
                        </Button>
                        <Popconfirm
                            title="删除"
                            description="确定要删除吗？"
                            onConfirm={() => handleDelete(record)}
                            okText="确定"
                            cancelText="取消"
                        >
                            <Button type="link">删除</Button>
                        </Popconfirm>
                    </div>
                );
            },
        },
    ];
    return (
        <div className={styles.supply_access_container}>
            <BreadCrumbBar breads={breads}></BreadCrumbBar>
            <div className={styles.supply_access_info}>
                <div className={styles.title}>基本信息</div>
                <div className={styles.info_values}>
                    <Form {...layout} form={form}>
                        <Row>
                            <Col span="8">
                                <Form.Item
                                    label="企业名称"
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入企业名称",
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入姓名"></Input>
                                </Form.Item>
                                <Form.Item
                                    name="registerDate"
                                    label="营业执照注册日期"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择营业执照注册日期",
                                        },
                                    ]}
                                >
                                    <DatePicker style={{ width: "100%" }} />
                                </Form.Item>
                                <Form.Item
                                    name="contactsName"
                                    label="企业联系人"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入企业联系人",
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入企业联系人"></Input>
                                </Form.Item>
                            </Col>
                            <Col span="8">
                                <Form.Item
                                    name="supplierType"
                                    label="企业类型"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择企业类型",
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="请选择企业类型"
                                        defaultValue={"INDIVIDUAL_INDUSTRIAL"}
                                    >
                                        {enterpriseTypes.map((item: any) => (
                                            <Option
                                                key={item.value}
                                                value={item.value}
                                            >
                                                {item.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="registerAddr"
                                    label="企业注册地"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择企业注册地",
                                        },
                                    ]}
                                >
                                    <Cascader
                                        options={citys}
                                        fieldNames={{
                                            label: "name",
                                            value: "code",
                                            children: "children",
                                        }}
                                    ></Cascader>
                                </Form.Item>
                                <Form.Item
                                    label="联系电话"
                                    name="contactsMobile"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入联系电话",
                                        },
                                        {
                                            pattern:
                                                /(^0\d{2,3}-\d{7,8})|(^1[3456789]\d{9}$)/,
                                            message: "请输入正确的手机号",
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="请输入联系电话"
                                        maxLength={14}
                                    ></Input>
                                </Form.Item>
                            </Col>
                            <Col span="8">
                                <Form.Item
                                    label="统一社会信用代码"
                                    name="unifiedSocialCreditCode"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入统一社会信用代码",
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="请输入统一社会信用代码"
                                        maxLength={18}
                                    ></Input>
                                </Form.Item>
                                <Form.Item
                                    label=""
                                    name="registerAddrDetail"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入企业注册地详细地址",
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入企业注册地详细地址"></Input>
                                </Form.Item>
                                <Form.Item
                                    label="通讯地址"
                                    name="mailingAddress"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入通讯地址",
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入通讯地址"></Input>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div className={`${styles.title} ${styles.mar_top_16}`}>
                    经营者信息
                </div>
                <div className={styles.info_values}>
                    <Form {...layout} form={operateForm}>
                        <Row>
                            <Col span="8">
                                <Form.Item
                                    label="姓名"
                                    name="legalName"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入姓名",
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入姓名"></Input>
                                </Form.Item>
                                <Form.Item
                                    label="身份证有效期"
                                    name="legalIdCardDate"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择身份证有效期",
                                        },
                                    ]}
                                >
                                    <RangePicker
                                        style={{ width: "100%" }}
                                        // onChange={(e: any) => {
                                        //     operateForm.setFieldValue(
                                        //         "legalIdcardDate",
                                        //         e
                                        //     );
                                        // }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span="8">
                                <Form.Item
                                    label="身份证号"
                                    name="legalIdCard"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入身份证号",
                                        },
                                        {
                                            pattern:
                                                /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
                                            message: "请输入正确的身份证号",
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入身份证号"></Input>
                                </Form.Item>
                                <Form.Item
                                    label="身份证地址"
                                    name="legalIdCardAddrDetail"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请选择身份证所属地",
                                        },
                                    ]}
                                >
                                    {/* <Cascader
                                        options={citys}
                                        fieldNames={{
                                            label: "name",
                                            value: "code",
                                            children: "children",
                                        }}
                                    ></Cascader> */}
                                    <Input placeholder="请输入身份证详细地址"></Input>
                                </Form.Item>
                            </Col>
                            <Col span="8">
                                <Form.Item
                                    label="手机号"
                                    name="legalMobile"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入手机号",
                                        },
                                        {
                                            pattern: /^1[3456789]\d{9}$/,
                                            message: "请输入正确的手机号",
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="请输入手机号"
                                        maxLength={11}
                                    ></Input>
                                </Form.Item>
                                {/* <Form.Item
                                    label=""
                                    name="legalIdCardAddrDetail"
                                    rules={[
                                        {
                                            required: true,
                                            message: "请输入身份证详细地址",
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入身份证详细地址"></Input>
                                </Form.Item> */}
                            </Col>
                        </Row>
                    </Form>
                </div>
                <div className={`${styles.title} ${styles.mar_top_16}`}>
                    附件信息
                </div>
                <div className={styles.info_values}>
                    <div className={styles.flex_end}>
                        <Button
                            type="primary"
                            onClick={() => {
                                setOpen(true);
                                modalFrom.setFieldValue(
                                    "fileType",
                                    "BUSINESS_LICENSE"
                                );
                            }}
                        >
                            上传文件
                        </Button>
                    </div>
                    <div className={styles.mar_top_16}>
                        {loadList && (
                            <Table
                                rowKey={(record: any) => record?.fileId}
                                columns={columns}
                                dataSource={list}
                                pagination={false}
                                scroll={{
                                    x: true,
                                }}
                            ></Table>
                        )}
                    </div>
                </div>
                <div className={styles.flex_center}>
                    <Button
                        onClick={() => {
                            history.back();
                        }}
                        style={{ marginRight: "16px" }}
                    >
                        取消
                    </Button>
                    <Button type="primary" onClick={handleSubmit}>
                        提交
                    </Button>
                </div>
            </div>
            {open && (
                <Modal
                    title="上传文件"
                    open={open}
                    onCancel={() => {
                        setOpen(false);
                        setUploadFiles({});
                        modalFrom.resetFields();
                        setLoadList(true);
                    }}
                    onOk={() => {
                        // 为解决本地数据更新 但是table没有刷新bug
                        const keys = Object.keys(uploadFiles);
                        if (!uploadFiles?.fileId || keys.length === 0) {
                            message.error("请上传文件");
                            return;
                        }
                        const type = modalFrom.getFieldValue("fileType");
                        const bools = list.some(
                            (item: any) => item.fileType === type
                        );
                        if (bools) {
                            message.error("该类型已上传，请重新选择");
                            return;
                        }
                        setLoadList(false);
                        modalFrom.validateFields().then((res) => {
                            console.log(keys, uploadFiles, "cliclk");
                            const params = {
                                ...res,
                                ...uploadFiles,
                            };
                            const arr = list || [];
                            arr.push(params);
                            setList(arr);
                            setLoadList(true);
                            setOpen(false);
                            setUploadFiles({});
                            modalFrom.resetFields();
                        });
                    }}
                >
                    <Form {...layout} form={modalFrom}>
                        <Form.Item
                            label="文件类型"
                            name="fileType"
                            rules={[
                                { required: true, message: "请选择文件类型" },
                            ]}
                        >
                            <Select>
                                {uploadSelect.map((item: any) => (
                                    <Option key={item.value} value={item.value}>
                                        {item.label}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Form>
                    <DraggerUpload
                        defaultList={list}
                        callback={(values: any) => {
                            console.log(values, "vlaues");
                            setUploadFiles({
                                ...values,
                                createTime: dayjs(new Date()).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                ),
                                fileUrl: values?.uri,
                            });
                        }}
                    ></DraggerUpload>
                </Modal>
            )}

            <Modal
                title={null}
                footer={null}
                open={previewOpen}
                width={850}
                onCancel={() => {
                    setPreviewOpen(false);
                    setFile({});
                }}
            >
                <div
                    style={{
                        height: "500px",
                        overflow: "auto",
                        marginTop: "20px",
                    }}
                >
                    {file?.type === "pdf" ? (
                        <Document
                            file={file?.fileUrl}
                            loading="努力加载中..."
                            renderMode="canvas"
                            onLoadSuccess={(e: any) => {
                                setNumPages(e.numPages);
                            }}
                            options={{
                                // 解决电子发票部分样式 无法识别
                                cMapUrl: `https://cdn.jsdelivr.net/npm/pdfjs-dist@2.16.105/cmaps/`,
                                cMapPacked: true,
                                disableWorker: true,
                            }}
                        >
                            {new Array(numPages).fill("").map((item, index) => {
                                return (
                                    <Page
                                        key={index}
                                        pageNumber={index + 1}
                                        width={770}
                                        loading="努力加载中..."
                                        renderMode="canvas"
                                        renderInteractiveForms={true}
                                        renderAnnotationLayer={false}
                                        renderTextLayer={false}
                                    />
                                );
                            })}
                        </Document>
                    ) : (
                        <img
                            src={file?.fileUrl}
                            // src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                            style={{ width: "730px", height: "auto" }}
                        />
                    )}
                </div>
            </Modal>
        </div>
    );
};

export default FinancingOrdersUpdate;
