import {
  Row,
  Col,
  Form,
  message,
  Modal,
  Tooltip,
  Input,
  Select,
  ImageProps,
  Image,
} from "antd";
import classname from "classnames";
import moment from "moment";
import { useState, useRef, useEffect } from "react";
import { RcFile } from "antd/lib/upload";
import { UploadProps } from "antd/es/upload";
import {
  CloseCircleTwoTone,
  InboxOutlined,
  QuestionCircleOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import { useTimeout } from "@src/hooks/useTimeout";
import axios from "axios";
import CropperModal, { imageSizeType } from "../Cropper";
import {
  DetectionItem,
  ErrorMessage,
  FaceDetectionCenter,
  FaceDetectionScheduler,
  PictureDimension,
} from "@src/utils/card/faceDetection";
import { limitFileSize } from "@src/utils/card/utils";
import Dragger from "antd/lib/upload/Dragger";
import "./index.scss";
import { politicsStatusOptions } from "../../content";
import { imageToCanvas } from "@src/utils/card/dataFormatConversion";
import { useUpdate } from "ahooks";
import { cross_env } from "@src/services/util";
//@ts-ignore
const baseURL = cross_env || "https://api.saikul.com/admin";
const FormItem = Form.Item;
const uploadFileHeader = () => {
  return {
    // ["Tenant-Id"]: window.localStorage.getItem("tenantId") || "",
    ["Authorization"]: window.localStorage.getItem("operation_authorization")
      ? JSON.parse(window.localStorage.getItem("operation_authorization") || "")
          ?.access_token
      : "",
  };
};
type PictureProof = {
  link: string;
  taxRegionId: string;
  id: string;
  name: string;
};
type IDCard = {
  front?: string;
  back?: string;
  imgBankFront?: string;
  avatar?: Blob;
  name?: string;
  idNo?: string;
};
const defaultIDCard = {
  front: undefined,
  back: undefined,
  imgBankFront: undefined,
  avatar: undefined,
  name: undefined,
  idNo: undefined,
};
type IdentityType = {
  form: any;
  idCardBackUrl?: string;
  idCardFrontUrl?: string;
};
const uuid = () => {
  let date = new Date().valueOf(); //获取时间戳
  let txt = "1234567890"; //生成的随机机器码
  let len = 13; //机器码有多少位
  let pwd = ""; //定义空变量用来接收机器码
  for (let i = 0; i < len; i++) {
    pwd += txt.charAt(Math.floor(Math.random() * txt.length)); //循环机器码位数随机填充
  }
  return date + pwd;
};

const uploadFile = async (blob: File | Blob) => {
  const formData = new FormData();
  console.log(blob, "blob---------");
  // @ts-ignore
  await formData.append("file", blob, blob.name || "image.jpeg");

  const res = await axios.put<Common.ResponseData<API.OSS.FileRes>>(
    "/sinzetech-resource/oss/endpoint?contentType=imag/jpeg&rename=" + uuid(),
    formData
  );
  const { downloadLink, filePath, ...rest } = res.data.data || {};
  return { ...rest, link: downloadLink, name: filePath };
};
const beforeUpload = (file: RcFile, e?: any) => {
  if (limitFileSize(file.size, 20)) {
    message.error("文件不可大于20MB");
    return false;
  }
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("只支持图片上传");
    return false;
  }
  return true;
};
const colProps = {
  span: 8,
};
const prefixCls = "individual-business-";

const classWithPrefix = (className: string) => prefixCls + className;

const ImageWithChanging = (props: ImageProps) => {
  return (
    <div
      className={classname("image_content", classWithPrefix("image-wrapper"))}
    >
      <Image
        onClick={(e) => {
          e.stopPropagation();
        }}
        src={props.src}
        {...props}
      />
      <div className="change_img">
        <SwapOutlined
          title=""
          className={classname(classWithPrefix("change-image-btn"))}
        />
      </div>
    </div>
  );
};
/**
 * 身份信息
 * @returns
 */
function Identity(props: IdentityType) {
  const { form, idCardFrontUrl, idCardBackUrl } = props;
  const [identityInfo, setIdentityInfo] = useState<IDCard>(defaultIDCard);
  const faceDetectionScheduler = useRef<FaceDetectionScheduler>();

  const [fileMap, _setFileMap] = useState<{
    IDCardFont?: Partial<API.OCR.IdCardFront & { link: string; id: string }>;
    IDCardBack?: Partial<API.OCR.IdCarBack & { link: string; id: string }>;
    avatar?: { link: string };
    pictureProofs: PictureProof[];
  }>({ pictureProofs: [] });
  const setFileUrlMap = (
    key: keyof typeof fileMap,
    state: Partial<typeof fileMap>[keyof typeof fileMap]
  ) => {
    _setFileMap((prev) => ({ ...prev, [key]: { ...prev[key], ...state } }));
  };
  const [
    openCancelDetectingAndOpenCorpTimer,
    closeCancelDetectingAndOpenCorpTimer,
  ] = useTimeout(() => {
    const faceDetectionCenter =
      faceDetectionScheduler.current?.faceDetectionCenter;
    if (
      faceDetectionCenter?.isDetecting &&
      !faceDetectionCenter?.canContinueDetect()
    ) {
      faceDetectionCenter.cancel();
      const blob = faceDetectionCenter.getDetectingItem()?.blob;
      whenFatalHandle(
        Object.assign(new ErrorMessage("fatal").add({ message: "识别超时" }), {
          blob,
        })
      );
    }
  }, 10000);

  const submitFace = async (blob: Blob[]) => {
    // const formData = new FormData();
    // await formData.append("file", blob[0], "image.jpeg");
    // await cancel()
    // return addPortraitHandle(formData)
  };
  const update = useUpdate();
  const successGettingAvatar = async (blobs: Blob[]) => {
    message.destroy();
    const res = await uploadFile(blobs[0]);
    setFileUrlMap("avatar", { link: res.link });
    if (idCardFrontUrl) {
      form.setFieldsValue({
        headFileId: res.name,
      });
    } else {
      const data = fileMap?.IDCardFont;
      console.log(data, "data card");
      form.setFieldsValue({
        legal: data?.name,
        registrationName: data?.name,
        detailAddress: data?.detailAddress,
        nation: data?.nation,
        idCardUrl: data?.id,
        idNo: data?.idNo,
        sex: data?.gender,
        // idCardBackUrl
        headFileId: res.name,
      });
    }
  };
  useEffect(() => {
    if (idCardBackUrl) {
      setFileUrlMap("IDCardBack", { link: idCardBackUrl });
    }
    if (idCardFrontUrl) {
      setFileUrlMap("IDCardFont", { link: idCardFrontUrl });
    }
  }, [idCardBackUrl, idCardFrontUrl]);
  useEffect(() => {
    if (fileMap.IDCardBack?.id) {
      form.setFieldsValue({
        idCardBackUrl: fileMap.IDCardBack?.id,
      });
    }
  }, [fileMap.IDCardBack?.id]);
  useEffect(() => {
    if (fileMap.IDCardFont?.id && fileMap.avatar?.link) {
      form.setFieldsValue({
        idCardUrl: fileMap.IDCardFont?.id,
      });
    }
  }, [fileMap.IDCardFont?.id, fileMap.avatar?.link]);
  useEffect(() => {
    faceDetectionScheduler.current = new FaceDetectionScheduler(
      new FaceDetectionCenter(new PictureDimension(5, 7, 2))
    );
    return () => {
      faceDetectionScheduler.current?.faceDetectionCenter.terminateWorker();
    };
  }, []);
  useEffect(() => {
    // 接收人脸信息并发生
    const destroyOnListener =
      faceDetectionScheduler.current?.faceDetectionCenter.onMessage((e) => {
        switch (e.status) {
          case "success":
            successGettingAvatar(e.data);
            return;
          case "error":
            if (e.type === "fatal") whenFatalHandle(e);
            if (e.kind === "unique")
              faceDetectionScheduler.current?.retry([
                (canvas) => {
                  return imageToCanvas(canvas, (ctx: any) => {
                    ctx?.translate(-canvas.width * 0.2, 0);
                  });
                },
                (canvas) => {
                  return imageToCanvas(canvas, (ctx: any) => {
                    const { width, height } = canvas;
                    const scale: [number, number] = [1.1, 1.1];
                    ctx?.scale(...scale);
                    ctx?.translate(
                      -(width * scale[0] - width) / 2,
                      -(height * scale[1] - height) / 2
                    );
                  });
                },
                (canvas) => {
                  return imageToCanvas(canvas, (ctx: any) => {
                    const { width, height } = canvas;
                    const scale: [number, number] = [1.2, 1.2];
                    ctx?.scale(...scale);
                    ctx?.translate(
                      -(width * scale[0] - width) / 2,
                      -(height * scale[1] - height) / 2
                    );
                  });
                },
              ]);
            else if (e.kind === "cloned")
              if (
                !faceDetectionScheduler.current?.faceDetectionCenter.detectNext()
              )
                whenFatalHandle(e);
            return;

          case "aborted":
            return;
        }
      });
    return () => {
      destroyOnListener?.();
    };
  }, [fileMap?.IDCardFont]);
  const openCorpModel = (blob?: any) => {
    CropperModal({
      required: true,
      title: (
        <>
          截取身份证人像{" "}
          <Tooltip title={"待办理个体工商户需要用户提供一寸照"}>
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      ),
      defaultCorpBoxInfo: {
        left: (550 - 130) / 2 + 60,
        top: (350 - 130 * (7 / 5)) / 2,
        width: 130,
      },
      file: blob,
      aspectRatio: imageSizeType.oneInch,
      submit: (blob) => {
        successGettingAvatar([blob]);
      },
      cancel: function () {
        form.setFieldsValue({
          imgFront: undefined,
          name: undefined,
          idNo: undefined,
        });
        setIdentityInfo(defaultIDCard);
      },
    });
  };
  const whenFatalHandle = (
    errorMessage: ErrorMessage & Pick<DetectionItem, "blob">
  ) => {
    const errorText = errorMessage.errors
      .map((error) => error.message)
      .join("、");
    message.destroy();
    message.warning("未检到身份证人脸信息，请手动截取身份证人像", 8);
    console.warn(errorText);
    openCorpModel(errorMessage.blob);
    throw Error(errorText);
  };
  /**
   * 清除身份证
   */
  const clearIdCard = (key: string, msg: string) => {
    setIdentityInfo((identityInfo) => ({ ...identityInfo, [key]: "" }));
    message.destroy();
    // NotificationError(msg);
    if (key === "front") {
      form.setFieldsValue(defaultIDCard);
    }
  };
  const idCardValidator = (
    info: any,
    key: string,
    callback?: (response: any) => void
  ) => {
    console.log(info.file.status, "<----------------");
    const { status, response } = info.file;
    if (status == "uploading") {
    }
    if (status === "done") {
      callback && callback(response);
      // if (response.ret === 200) {
      // } else {
      //   clearIdCard(key, response.msg);
      // }
    } else if (status === "error") {
      clearIdCard(key, response?.message);
      // if (response?.code == 10301) {
      //   location.href = "/login.html";
      // }
    }
  };
  const cutFaceAndPost = async (blob: RcFile) => {
    closeCancelDetectingAndOpenCorpTimer();
    openCancelDetectingAndOpenCorpTimer();
    await faceDetectionScheduler.current?.appendWithBlob(blob);
    faceDetectionScheduler.current?.faceDetectionCenter.startDetect();
  };
  const uploadAvatar = (file: RcFile) => {
    console.log("file", file);
    //@ts-ignore
    window.idCardUrl = URL.createObjectURL(file);
    //@ts-ignore
    window.idCardBackUrl = URL.createObjectURL(file);
    cutFaceAndPost(file).catch((e) => {
      whenFatalHandle(
        Object.assign(
          new ErrorMessage("fatal").add({
            message: "身份证检测API信息发送异常 " + e.message || "",
          }),
          { blob: file }
        )
      );
    });
  };
  const getBase64 = (img: Blob, callback = (val: any) => {}) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  /**
   * 身份证正面照片上传
   */
  const onUploadIDCardFront: UploadProps["onChange"] = (info: any) => {
    idCardValidator(info, "front", (response: any) => {
      const data = response.data as API.OCR.IdCardFront | undefined;
      if (!data?.dateBirth) {
        message.destroy();
        return message.warning("无法识别出生年月！");
      }
      const val = moment().diff(moment(data.dateBirth, "YYYYMMDD"), "year");
      if (val > 70 || val < 18) {
        message.destroy();
        Modal.warning({
          title:
            "按照法律法规的规定，法定代表人、董事、监事、经理、财务负责人、联络员等人员年龄必须在18至70周岁之间，请确认。 ",
        });
      } else {
        uploadFile(info.file.originFileObj).then((res) => {
          setFileUrlMap("IDCardFont", {
            ...data,
            link: res.link,
            id: res.name,
          });
          uploadAvatar(info.file.originFileObj);
        });
      }
    });
  };

  const idCardBackUploadUI = (
    <div className={classWithPrefix("dragger-inner")}>
      {fileMap.IDCardBack?.link ? (
        <ImageWithChanging
          src={fileMap.IDCardBack?.link}
          alt="身份证反面"
        ></ImageWithChanging>
      ) : (
        <div>
          <img
            src={require("@src/assets/idCard_back.png")}
            draggable={false}
            alt="idCard_front"
          />
        </div>
      )}
    </div>
  );
  const beforeUploadFrontIDCard = async (
    rcFile: RcFile,
    FileList: RcFile[]
  ) => {
    // await preventionBlackAndWhitePhoto(file);
    const isJpgOrPng = beforeUpload(rcFile);
    if (isJpgOrPng) {
      return new Promise((resolve, reject) => {
        CropperModal({
          title: "身份证正面裁剪",
          file: rcFile,
          submit: async (blob) => {
            const file = new File([blob], "image.jpeg");
            message.loading("身份图像信息识别中...", 0);
            resolve(file);
          },
          cancel: () => {
            reject();
          },
          reupload: false,
        });
      });
    }
    return false;
  };
  const onUploadIDCardBack: UploadProps["onChange"] = (info: any) => {
    idCardValidator(info, "back", (response) => {
      const data = response.data as API.OCR.IdCarBack | undefined;
      // message.success('图片上传成功, 请确认数据, 可进行多次修改');
      uploadFile(info.file.originFileObj).then((res) => {
        // form.setFieldsValue({
        //   idNoLimitedPeriod: data?.endLimitedPeriod,
        //   idNoOrganization: data?.organization,
        // });
        form.setFieldValue("idNoLimitedPeriod", data?.endLimitedPeriod);
        form.setFieldValue("idNoOrganization", data?.organization);
        console.log(
          response,
          {
            link: response?.data?.link,
            id: response?.data?.name,
          },
          "----------------<>"
        );
        setFileUrlMap("IDCardBack", {
          link: res?.link,
          id: res?.name,
        });
      });
    });
  };
  const beforeUploadBackIDCard = async (file: RcFile, FileList: RcFile[]) => {
    const isJpgOrPng = beforeUpload(file, FileList);
    if (isJpgOrPng) {
      return new Promise((resolve, reject) => {
        CropperModal({
          title: "身份证背面裁剪",
          file: file,
          submit: async (blob: Blob) => {
            const file = new File([blob], "image.jpeg");
            resolve(file);
          },
          cancel: () => {
            reject();
          },
          reupload: true,
        });
      });
    }
    return false;
  };
  const idCardFrontPlaceholderUI = (
    <div>
      <img
        src={require("../../../../../../../../assets/idCard_front.png")}
        draggable={false}
        alt="idCard_front"
      />
    </div>
  );
  return (
    <div className="identity">
      <div className="common_title">身份信息</div>
      <div className="identity_content">
        <div className="identity_inner">
          <Form.Item hidden name="sex"></Form.Item>
          <Form.Item hidden name="bankName"></Form.Item>
          <Form.Item hidden name="bankCode"></Form.Item>
          <Form.Item hidden name="headFileId"></Form.Item>
          {/* <Form.Item hidden name="nuclearName" initialValue={"0"}></Form.Item> */}
          <Form.Item hidden name="createUserName"></Form.Item>
          <Form.Item hidden name="applyUserAccount"></Form.Item>
          <Form.Item hidden name="createUserId"></Form.Item>
          <Form.Item hidden name="source"></Form.Item>
          <Form.Item hidden name="sourceId"></Form.Item>
          <Form.Item hidden name="sourceIdName"></Form.Item>
          <Form.Item hidden name="idNoLimitedPeriod"></Form.Item>
          <Form.Item hidden name="idNoOrganization"></Form.Item>
          <Form.Item
            className="front"
            label="身份证"
            name="idCardUrl"
            rules={[{ required: true, message: "请上传身份证正面" }]}
          >
            {idCardFrontUrl ? (
              <Dragger
                name="file"
                action={
                  baseURL + "/sinzetech-agency-processing/ocr/front-id-card"
                }
                headers={uploadFileHeader()}
                accept="image/*"
                // @ts-ignore
                beforeUpload={beforeUploadFrontIDCard}
                onChange={onUploadIDCardFront}
                // onChange={(info: any) => {
                //   idCardValidator(
                //     info,
                //     "front",
                //     (response: Common.ResponseData<API.OSS.FileRes>) => {
                //       const data = response.data;
                //       setFileUrlMap("IDCardFont", {
                //         link: data?.link,
                //         id: data?.name?.toString(),
                //       });
                //       uploadAvatar(info.file.originFileObj);
                //     }
                //   );
                // }}
                listType="picture-card"
                showUploadList={false}
              >
                <div className={classWithPrefix("dragger-inner")}>
                  {fileMap.IDCardFont?.link ? (
                    <ImageWithChanging
                      src={fileMap.IDCardFont?.link}
                      alt="身份证正面"
                    ></ImageWithChanging>
                  ) : (
                    idCardFrontPlaceholderUI
                  )}
                </div>
              </Dragger>
            ) : (
              <Dragger
                name="file"
                action={
                  baseURL + "/sinzetech-agency-processing/ocr/front-id-card"
                }
                headers={uploadFileHeader()}
                accept="image/*"
                onChange={onUploadIDCardFront}
                // @ts-ignore
                beforeUpload={beforeUploadFrontIDCard}
                listType="picture-card"
                showUploadList={false}
              >
                <div className={classWithPrefix("dragger-inner")}>
                  {fileMap.IDCardFont?.link && fileMap.avatar?.link ? (
                    <ImageWithChanging
                      src={fileMap.IDCardFont?.link}
                      alt="身份证正面"
                    ></ImageWithChanging>
                  ) : (
                    idCardFrontPlaceholderUI
                  )}
                </div>
              </Dragger>
            )}
          </Form.Item>
          <Form.Item
            label=""
            name="idCardBackUrl"
            rules={[{ required: true, message: "请上传身份证反面" }]}
          >
            {idCardBackUrl ? (
              <Dragger
                name="file"
                action={
                  baseURL + "/sinzetech-agency-processing/ocr/reverse-id-card"
                }
                headers={uploadFileHeader()}
                accept="image/*"
                // @ts-ignore
                beforeUpload={beforeUploadBackIDCard}
                onChange={onUploadIDCardFront}
                // onChange={(info) => {
                //   idCardValidator(
                //     info,
                //     "front",
                //     (response: Common.ResponseData<API.OSS.FileRes>) => {
                //       setFileUrlMap("IDCardBack", {
                //         link: response?.data?.link,
                //         id: response?.data?.name,
                //       });
                //     }
                //   );
                // }}
                listType="picture-card"
                showUploadList={false}
              >
                {idCardBackUploadUI}
              </Dragger>
            ) : (
              <Dragger
                action={
                  baseURL + "/sinzetech-agency-processing/ocr/reverse-id-card"
                }
                headers={uploadFileHeader()}
                listType="picture-card"
                showUploadList={false}
                accept="image/*"
                name="file"
                onChange={onUploadIDCardBack}
                // @ts-ignore
                beforeUpload={beforeUploadBackIDCard}
              >
                {idCardBackUploadUI}
              </Dragger>
            )}
          </Form.Item>
        </div>
        <p className="id_desc">支持png、jpg、jpeg文件上传，最大50MB</p>
        {form.getFieldValue("headFileId") ? (
          <>
            <div className="avatar_photo">
              <div className="avatar_photo_title">
                <span>*</span>证件照：
              </div>
              <div className="avatar_photo_content">
                <img
                  alt=""
                  src={
                    form.getFieldValue("headFileId")
                      ? "https://saikul-cloud-wms.oss-cn-qingdao.aliyuncs.com/" +
                        form.getFieldValue("headFileId")
                      : ""
                  }
                />
              </div>
            </div>
            <p className="id_desc">系统自动获取身份证上的照片</p>
          </>
        ) : null}
      </div>
      <div className="id_info_box">
        <Row>
          <Col {...colProps}>
            <Form.Item
              label="申请人姓名"
              name="legal"
              rules={[{ required: true, message: "请填写姓名", max: 100 }]}
            >
              <Input
                placeholder={"请输入个体户姓名"}
                disabled={form.getFieldValue("idCardUrl") ? false : true}
              />
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item
              label="身份证号"
              name="idNo"
              rules={[
                { required: true, message: "请输入个体户身份证" },
                {
                  pattern:
                    /^[1-9]\d{5}(19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
                  message: "请输入正确的身份证号",
                },
              ]}
            >
              <Input
                placeholder={"请输入个体户身份证"}
                disabled={form.getFieldValue("idCardUrl") ? false : true}
              />
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item
              label="申请人住址"
              name="detailAddress"
              rules={[
                {
                  required: true,
                  message: "请输入200个字符内的住址信息",
                  max: 200,
                },
              ]}
            >
              <Input placeholder={"请输入个体户详细地址"} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col {...colProps}>
            <Form.Item
              label="民族"
              name="nation"
              rules={[{ required: true, message: "请输入个体户民族" }]}
            >
              <Input placeholder={"请输入个体户民族"} />
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item
              label="联系电话"
              name="legalPhone"
              rules={[
                {
                  pattern: /^1[0-9]{10}/,
                  message: "请输入正确的手机号",
                },
                { max: 11, message: "请输入正确的手机号" },
                { required: true, message: "请输入个体户联系电话" },
              ]}
            >
              <Input placeholder={"请输入个体户联系电话"} />
            </Form.Item>
          </Col>
          <Col {...colProps}>
            <Form.Item
              label="联系邮箱"
              name="email"
              rules={[
                { required: true, message: "请输入个体户联系邮箱" },
                { max: 100, message: "请输入100个字符内" },
                {
                  pattern:
                    /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
                  message: "邮箱格式不正确",
                },
              ]}
            >
              <Input placeholder={"请输入个体户联系邮箱"} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col {...colProps}>
            <Form.Item
              label="政治面貌"
              name="politicalLandscape"
              initialValue={"群众"}
              rules={[{ required: true, message: "请选择政治面貌" }]}
            >
              <Select
                options={politicsStatusOptions.map((option) => ({
                  label: option,
                  value: option,
                }))}
              ></Select>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default Identity;
