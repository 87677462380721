import { assign } from './../../BusinessAgent/pages/promise';
import { ProDescriptionsProps } from '@ant-design/pro-descriptions';
import React from 'react';
import { getEntityDetail, getInvoiceItems, getTaxList, getTaxUsers, getUrlByFileId, updateEntityDetail } from '../promises';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import { useRequest } from 'ahooks';
import { getInvoiceConfiguration } from '@src/services/api/invoice/invoice';
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import { UploadFile } from 'antd/lib/upload';
import { Result } from '@src/services/util';
import { useLoading } from '@src/hooks/useLoading';
import { omit, pick } from 'lodash';
type OnSave = Exclude<Exclude<ProDescriptionsProps<Partial<API.Entity.Info>>['editable'],undefined>['onSave'],undefined>
export const uploadFileAndGettingRes = async (file: UploadFile)=>{
  return new Promise<Result<{downloadLink : string, downloadLinkExpirationTime: string, filePath :string}>|undefined>((resolve,reject)=>{

    if(file.status==='done'){
      resolve(file.response)
    }else if(file.status==='error'){
      message.success('更新失败')
      reject(file.error)
    }
  })
}

const getDifferentPart = <T extends object>(obj1:T,obj2:T)=>{
  return Object.keys(obj1).reduce((map,key)=>{
    if(Array.isArray(obj1[key])&&Array.isArray(obj2[key])){
      return obj1[key].length === obj2[key].length ? map : {...map,[key]: obj1[key]}
    } else if(Object.prototype.toString.call(obj1[key])==='[Object object]'){
      return {...map,[key]: obj1[key]}
    } else {
      return obj1[key] === obj2[key] ? map : {...map,[key]: obj1[key]}
    }
  },{}) as Partial<T>
}

export const useEntityDetailLogic = ()=>{
  const pathParams = useParams<{id: string}>();
    // 开票员
  const invoiceClerksService = useRequest(getTaxUsers,
    {
      defaultParams: [{roleCodes: "conductor"}],
    }
  );
  // 办税人数据
  const taxOfficerService = useRequest(getTaxUsers,
    {
      defaultParams: [{roleCodes: "TaxOfficer"}],
    }
  );
  const invoiceItemsConfig = useRequest(getInvoiceItems,
    {
      defaultParams: [{size:9999,current:1}],
    }
  );
  const taxListService = useRequest(getTaxList,
    {
      defaultParams: [{size:9999,current:1}],
    }
  );
  const entityDataService = useRequest(getEntityDetail,
    {
      defaultParams: [pathParams.id!],
      refreshDeps: [pathParams.id]
    }
  );
  
  const fileUrlService = useRequest(async (fileIdList: string[])=>{
    return getUrlByFileId({zoom:false,fileIds: fileIdList.join(',')}).then(res=>res.data.map((item,index)=>fileIdList[index]?item:undefined ))
    // getUrlByFileId({zoom:false,fileIds: fileIdList})
    // return (await Promise.allSettled(fileIdList.map((fileId)=>getUrlByFileId({zoom:false,fileIds: fileIdList.join(',')})))).map((result)=>(result.status==='fulfilled'?result.value:null))
  })

  const [onSave,saveLoading] = useLoading<OnSave>(async (_,values,oldValue)=>{

    const {...formData} = getDifferentPart(omit(values,'index'),omit(oldValue,'index'))
    // 将 id list 转换为 string
    const needToStrKeys = ['taxRegionIds','invoiceUserIds']
    const newFormData = needToStrKeys.reduce((obj,key)=>({...obj,...(obj[key]?{[key]: obj[key]?.join(',')}:{})}),formData)

    await updateEntityDetail({...newFormData,id: pathParams.id})
    await entityDataService.refreshAsync()
    message.success('更新成功')
  })

  const onSaveByNewData =  async (values: Partial<API.Entity.Info>)=>{
    const keys = Object.keys(values)
    const oldValues = keys.reduce((map,key)=>({...map,[key]: entityDataService.data?.[key]}),{})
    await onSave(keys,values,oldValues)
  }

  const [onUploadFileChange,uploadFileChangeLoading] =  useLoading(async (key:string,{file}:UploadChangeParam<UploadFile<{}>>)=>{
    const response = await uploadFileAndGettingRes(file)
    await onSaveByNewData({[key]: response?.data.filePath})
  })
  const loading = uploadFileChangeLoading || entityDataService.loading || saveLoading
  const taxOptions = taxListService.data?.map((item)=>({label: item.name,value: item.id}))
  const taxOfficerOptions = taxOfficerService.data?.data?.map((item)=>({label: item.name,value: item.id}))
  const invoiceClerkOptions = invoiceClerksService.data?.data?.map((item)=>({label: item.name,value: item.id}))
  const isNotAgency = () => entityDataService.data?.sourceType !== 'AGENCY'
  const isCRM = () => entityDataService.data?.sourceType === 'CRM'
  const isAgency = () => entityDataService.data?.sourceType === 'AGENCY'
  return {onSave,onSaveByNewData,entityDataService,taxListService,taxOptions,invoiceItemsConfig,taxOfficerService,taxOfficerOptions,invoiceClerksService,invoiceClerkOptions,onUploadFileChange,loading,id:pathParams.id,fileUrlService,isNotAgency,isCRM,isAgency}
}
export type EntityDetailContextValueType = ReturnType<typeof useEntityDetailLogic>|undefined
export const EntityDetailContext = React.createContext<EntityDetailContextValueType>(undefined)
