import BreadCrumbBar from "@src/components/BreadCrumbBar";
import CommonSearch, { FormItem } from "@src/components/common/CommonSearch";
import TableCom from "@src/components/TableCom";
import { getRangeDateOnUnitOfTime } from "@src/utils/date/utils";
import {
  Button,
  Card,
  DatePicker,
  Input,
  Space,
  Popconfirm,
  message,
  Popover,
} from "antd";
import { ColumnsType } from "antd/es/table";
import React, { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { editProductInfo, getProductList } from "../../promise";
const defaultProps = {};
type props = {};
const breads = [
  {
    label: "供应链金融",
    path: "/supply",
  },
  {
    label: "融资产品",
    path: "",
  },
];
export type FinancingProductProps = Required<typeof defaultProps> & props
export const FinancingProduct: React.FC<FinancingProductProps> = memo((props) => {
  const nav = useNavigate();

  const [pageOptions, setPageOptions] = useState({
    pageSize: 20, current: 1
  })
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState<any>({
    keyWords: '', createTimeStart: '', createTimeEnd: ''
  });
  const [list, setList] = useState([]);
  const { RangePicker } = DatePicker;
  const formList: FormItem[] = [{
    name: 'keyWords',
    label: '搜索',
    element: <Input placeholder='产品名称/融资公司' allowClear />,
  }, {
    name: 'createTime',
    label: '添加时间',
    element: <RangePicker />,
  },]
  const columns: ColumnsType<API.FinancingProduct.Item> = [
    {
      title: '产品名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '借款周期',
      dataIndex: 'loanCycleType',
      key: 'loanCycleType',
      render: (_, record) => <>{record.loanCycleType === 'SETTLEMENT_TIME' ? '按订单结算日期' : '-'}</>
    },
    {
      title: '还款模式',
      dataIndex: 'repaymentMode',
      key: 'repaymentMode',
      render: (_, record) => <>{record.repaymentMode === 'DISPOSABLE' ? '到期一次还本付息' : '-'}</>
    },

    {
      title: '利率',
      dataIndex: 'annualInterestRate',
      key: 'annualInterestRate',
      render: (_, record) => <>{record.annualInterestRate}%</>
    },
    {
      title: '提前还款',
      dataIndex: 'earlyRepaymentSupport',
      key: 'earlyRepaymentSupport',
      render: (_, record) => <>{record.earlyRepaymentSupport ? '是' : '否'}</>
    },
    {
      title: '核心企业',
      dataIndex: 'coreEnterpriseList',
      key: 'coreEnterpriseList',
      render: (_, record) => {
        const handleCoreEnterprise = record?.coreEnterpriseList
            ? record?.coreEnterpriseList.map((item) => item.name).join("、")
            : "";
        return (
          <Popover content={handleCoreEnterprise}>
            <Space><text>{handleCoreEnterprise}</text></Space>
          </Popover>)
      }
    },
    {
      title: '融资公司',
      dataIndex: 'financeCompany',
      key: 'financeCompany',
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => <>{record.status == 1 ? '启用' : '禁用'}</>
    },
    {
      title: '添加人',
      dataIndex: 'createUserName',
      key: 'createUserName',
    },
    {
      title: '添加时间',
      dataIndex: 'createTime',
      key: 'createTime',
    },
    {
      title: '操作',
      dataIndex: 'Action',
      key: 'Action',
      render: (_, record) => (
        <Space size="middle">
          <Button type='link' onClick={() => nav(`/supply/financingProducts/detail`, { state: { productId: record?.id, } })}>编辑</Button>
          {
            record!.status === 1 ? (
              <Popconfirm
                title="是否禁用？"
                onConfirm={() => {
                  editProductInfo({ ...record, status: '0' }).then(
                    (data:any) => {
                      if (data.code === 200) {
                        message.success("禁用成功")
                        getList()
                      }
                    }
                  );
                }}
              >
                <Button type="link">禁用</Button>
              </Popconfirm>
            ) : (
              <Button
                type="link"
                onClick={() => {
                  editProductInfo({ ...record, status: '1' }).then(
                    (data:any) => {
                      if (data.code === 200) {
                        message.success("启用成功")
                        getList()
                      }
                    }
                  );
                }}
              >
                {" "}
                启用
              </Button>
            )
          }
        </Space>
      ),
    },
  ]
  const getList = async (object?: any) => {
    const params = {
      size: pageOptions.pageSize,
      current: pageOptions.current,
      ...search,
      ...object,
    };
    if (object?.current && object?.size) {
      setPageOptions({
        current: object?.current,
        pageSize: object?.size,
      });
    }
    const res = await getProductList(params);
    if (res?.code === 200) {
      setList(res?.data?.records);
      setTotal(res?.data?.total);
    }
  };

  useEffect(() => {
    getList()
  }, [])
  const onSearch = (values: any) => {
    const { createTime, ...rest } = values
    const handleSearch = {
      ...rest,
      ...getRangeDateOnUnitOfTime(
        {
          createTimeStart: values.createTime?.[0],
          createTimeEnd: values.createTime?.[1],
        },
        { format: "YYYY-MM-DD HH:mm:ss" }
      ),
    }
    setSearch(handleSearch);
    getList({
      current: pageOptions.current,
      size: pageOptions.pageSize,
      ...handleSearch
    });
  };
  const handlePages = (params: { pageSize: any; current: any }) => {
    getList({
      size: params.pageSize,
      current: params.current,
    })
  }
  return <div className='public_list_page'>
    <BreadCrumbBar breads={breads}></BreadCrumbBar>

    <CommonSearch
      formList={formList}
      onSearch={(values) => {
        onSearch(values)
      }}
    />
    <Card>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button type='primary' onClick={() => nav(`/supply/financingProducts/detail`)}>
          添加融资产品
        </Button>
      </div>
      <TableCom
        rowKey={(record: any) => record?.id}
        dataSource={list}
        columns={columns}
        scroll={{ x: 1500, y: "calc(100vh - 350px)" }}
        pagination={{
          ...pageOptions,
          total,
        }}
        onChange={handlePages}
      ></TableCom>
    </Card>
  </div>
})
FinancingProduct.defaultProps = defaultProps