import ProDescriptions, {
  ProDescriptionsItemProps,
} from "@ant-design/pro-descriptions";
import { DetailPageContent } from "@src/components/DetailPageComponents/Content";
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header";
import { OperableImage } from "@src/components/OperableImage";
import { Space, Card } from "antd";
import React, { ReactNode, memo, useEffect, useMemo } from "react";
import { PaymentInfo } from "../../components/PaymentInfo";
import { Link } from "react-router-dom";
import { useEntityDetailLogic } from "../../hooks/useEntityDetailLogic";
import { commonHeaderDetail, EntityStatusEnum } from "../../constant";
import { EntityDetailPageContainer } from "../../components/EntityDetailPageContainer";
const defaultProps = {};
type props = {};
export type NaturalDetailProps = Required<typeof defaultProps> & props;

export const NaturalDetail: React.FC<NaturalDetailProps> = memo((props) => {
  const entityDetailService = useEntityDetailLogic();
  const {isAgency,isCRM,isNotAgency} = entityDetailService

  useEffect(()=>{
    if(entityDetailService.entityDataService.data){
      entityDetailService.fileUrlService.run([entityDetailService.entityDataService.data.idCardFront,entityDetailService.entityDataService.data.idCardBack])
    }
  },[entityDetailService.entityDataService.data])
  // 来源类型：CRM/EP/回收小组,创建人：章三,实体类型：企业,来源名称：台州森林,创建账号：18813012345,实体状态：正常,创建时间：2023 05-13 09:23:32
  const headerDescColumns: ProDescriptionsItemProps<API.Entity.Info>[] = [
    ...commonHeaderDetail,
    {
      dataIndex: "status",
      title: "实体状态",
      renderText: (v) => v,
      valueEnum: EntityStatusEnum,
      valueType: "select",
    },
    { dataIndex: "createTime", title: "创建时间", editable: false },
  ];

  const idCardColumns: ProDescriptionsItemProps<API.Entity.Info>[] = [
    {
      dataIndex: "idCardFront",
      title: "身份证正面",
      editable: false,
      render: (dom: any, record: any) => (
        <>
          <OperableImage
            filename="身份证正面"
            uploadFileProps={{
              onChange: entityDetailService.onUploadFileChange.bind(
                null,
                "idCardFront"
              ),
            }}
            width={150}
            height={150}
            src={entityDetailService.fileUrlService.data?.[0]}
          ></OperableImage>
        </>
      ),
    },
    {
      dataIndex: "idCardBack",
      title: "身份证反面",
      editable: false,
      render: (dom: any, record: any) => (
        <>
          <OperableImage
            filename="身份证反面"
            uploadFileProps={{
              onChange: entityDetailService.onUploadFileChange.bind(
                null,
                "idCardBack"
              ),
            }}
            width={150}
            height={150}
            src={entityDetailService.fileUrlService.data?.[1]}
          ></OperableImage>
        </>
      ),
    },
  ]

  const basicInfoColumns: ProDescriptionsItemProps<API.Entity.Info>[] = [
    { dataIndex: "name", title: "姓名", copyable: true },
    { dataIndex: "idNumber", title: "身份证号", copyable: true },
    { dataIndex: "phone", title: "联系电话" },
    { dataIndex: "agentNo", title: "代办编号",hideInDescriptions: !isAgency(),editable:false,render:(dom,record)=><Link onClick={()=>window.location.href = `/business/administration/info/${entityDetailService.entityDataService.data?.sourceId}`} to={``}>{dom}</Link> },
    {
      dataIndex: "refs",
      title: "关联个体户",
      editable:false,
      render: (_,record) => record.refs?.map((item)=><Link to={`/entity/transaction/individuality/${item.id}`}>{item.name}</Link>).reduce((list,item,index,arr)=>(arr.length-1>index?[...list,item,'、']:[...list,item]) ,[] as ReactNode[])
    },
    // { dataIndex: "0", editable: false, render: () => <></> },

  ];
console.log(entityDetailService.fileUrlService,'entityDetailService.fileUrlService');

  return (
    <EntityDetailPageContainer value={entityDetailService}>
      <DetailPageHeader
        breadCrumb={[
          { label: "交易实体" },
          { label: "自然人" },
          { label: entityDetailService.entityDataService.data?.name||'' },
        ]}
        title={entityDetailService.entityDataService.data?.name}
      >
        <ProDescriptions
          editable={{ onSave: entityDetailService.onSave }}
          columns={headerDescColumns}
          dataSource={entityDetailService.entityDataService.data}
        ></ProDescriptions>
      </DetailPageHeader>
      <DetailPageContent>
        <Card title="基本信息">
          <ProDescriptions
            editable={{ onSave: entityDetailService.onSave }}
            columns={basicInfoColumns}
            dataSource={entityDetailService.entityDataService.data}
          ></ProDescriptions>
          <ProDescriptions
            editable={{ onSave: entityDetailService.onSave }}
            columns={idCardColumns}
            dataSource={entityDetailService.entityDataService.data}
          ></ProDescriptions>
        </Card>
        <PaymentInfo></PaymentInfo>
      </DetailPageContent>
    </EntityDetailPageContainer>
  );
});
NaturalDetail.defaultProps = defaultProps;
