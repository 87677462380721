import React, { useEffect, useState, useMemo } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import TopNavbar from "../../components/TopNavbar";
import LeftNavbar from "../../components/LeftNavbar";
import { TableOutlined, DashboardOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

const BusinessAgent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const roleList = useSelector((state: any) => state?.roleCodes) || [];
  console.log(roleList, "rrrrrrrrrrr");
  useEffect(() => {
    const pathName = location.pathname.match(/\/business\/(.*)/);
    if (pathName) {
    }
    navigate("/ticket/openTask");
  }, []);
  const [menuList, setMenuList] = useState<any>([]);
  useEffect(() => {
    let arr: any = [
      {
        name: "开票",
        path: "/ticket",
        icon: <TableOutlined />,
        children: [
          {
            name: "开通任务",
            path: "/ticket/openTask",
            // icon: <TableOutlined />,
          },
          {
            name: "开票方列表",
            path: "/ticket/invoicingParty",
            // icon: <TableOutlined />,
          },
          {
            name: "开票任务",
            path: "/ticket/InvoicingTask",
          },
          {
            name: "发票台账",
            path: "/ticket/ledger",
          },
        ],
      },
    ];
    setMenuList(arr);
  }, [roleList]);
  return (
    <div className="evidence-container">
      <TopNavbar config={{ value: "ticket" }} />
      <div className="evidence-container-content">
        {menuList.length !== 0 && <LeftNavbar lists={menuList} />}
        <Outlet></Outlet>
      </div>
    </div>
  );
};

export default BusinessAgent;
