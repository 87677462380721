import React,{createContext, memo} from 'react'
import classnames from 'classnames'
import './index.scss'
import { Spin } from 'antd'
const defaultProps = {loading:false}
type props = {loading?: boolean}
export type PageContainerProps = props & React.HTMLAttributes<HTMLDivElement>

export const DetailPageContext = createContext<{loading:boolean}|undefined>(undefined)


export const DetailPageContainer:React.FC<React.PropsWithChildren<PageContainerProps>> = memo((props)=>{
  
  return <DetailPageContext.Provider value={{loading: Boolean(props.loading)}}>
     <div className={classnames(props.className,'detail-page-container')}>
    <Spin size='large' spinning={props.loading}>
      {props.children}
    </Spin>
  </div></DetailPageContext.Provider>
})
DetailPageContainer.defaultProps = defaultProps