import React, { useState } from "react";
import { Form, Input, Checkbox, Row, Col, Radio, Button } from "antd";
import UploadCom from "../../../../../../../../components/UploadCom";
import { getImageMsg, licenseProcess } from "../../../../../promise";
import styles from "./index.module.scss";
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 16,
  },
};
const RadioList = [
  {
    label: "个体工商户",
    value: "INDIVIDUAL",
  },
  {
    label: "公司",
    value: "ENTERPRISE",
  },
];

// 上传营业执照
const UploadBusinessLicense = (props: any) => {
  const { details, update, taxInfo } = props;
  const [form] = Form.useForm();
  const [change, setChange] = useState(true);
  // 营业执照信息
  const [uploadValue, setUploadValue] = useState<any>({});
  // 根据上传的营业执照信息识别关键信息  ocr  识别
  const getImgInfo = (obj?: any) => {
    const params = {
      url: obj?.downloadLink,
    };
    getImageMsg(params).then((res: any) => {
      const typeValue = res.type === "个体工商户" ? "INDIVIDUAL" : "ENTERPRISE";
      const param = {
        licenseUrl: obj?.fileId,
        enterpriseName: res.name,
        licenseNo: res.regNum,
        type: typeValue,
        legal: res.person,
        businessPlace: res.address,
      };
      const newObj = {
        ...obj,
        enterpriseName: res.name,
        licenseNo: res.regNum,
        type: typeValue,
        legal: res.person,
      };
      form.setFieldsValue(param);
      setUploadValue(newObj);
    });
  };
  // 完成营业执照的上传  并推进进度
  const handleProcess = () => {
    form.validateFields().then((res) => {
      const params = {
        applyStatus: "NO_ASSIGN_TAX",
        ...res,
      };
      licenseProcess(details?.id, params).then((response: any) => {
        if (response.code === 200) {
          update(details?.id);
        }
      });
    });
  };
  return (
    <div className={styles.upload_business_license_container}>
      <div className={styles.upload_business_license_title}>上传营业执照</div>
      <div className={styles.upload_business_license_content}>
        <Form {...layout} form={form}>
          <Row>
            <Col span="12">
              <Form.Item label="">
                <div className={styles.mar_left}>
                  <Checkbox
                    onChange={(e) => {
                      setChange(!e.target.checked);
                    }}
                  >
                    不绑定集团，自由交易
                  </Checkbox>
                </div>
              </Form.Item>
            </Col>
            <Col span="12">
              {change ? (
                <Form.Item
                  name="linkOrganization"
                  label="关联集团"
                  rules={[
                    {
                      required: true,
                      message: "请输入关联集团",
                    },
                  ]}
                >
                  <Input></Input>
                </Form.Item>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col span="12">
              <Form.Item label="营业执照" name="licenseUrl">
                <span>
                  <UploadCom
                    nullText="重新上传"
                    ButtonText="上传执照"
                    maxCount={1}
                    cb={(values: any) => {
                      // setUploadValue(values[0])
                      if (values[0] && values[0].downloadLink) {
                        getImgInfo(values[0]);
                      } else {
                        form.setFieldsValue({});
                        setUploadValue({});
                      }
                    }}
                  ></UploadCom>
                </span>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span="12">
              <Form.Item
                label="主体名称"
                name="enterpriseName"
                rules={[
                  {
                    required: taxInfo?.enableNuclearName,
                    message: "请输入主体名称",
                  },
                ]}
              >
                <Row>
                  <Col span="18">
                    <Input
                      value={uploadValue["enterpriseName"]}
                      placeholder="请输入主体名称"
                      onChange={(e: any) => {
                        setUploadValue({
                          ...uploadValue,
                          enterpriseName: e.target.value,
                        });
                        form.setFieldValue("enterpriseName", e.target.value);
                      }}
                    ></Input>
                  </Col>
                  <Col span="6">
                    <span className={styles.tooltip_warning}>!请仔细核对</span>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item
                label="公司类型"
                rules={[
                  {
                    required: true,
                    message: "请选择公司类型",
                  },
                ]}
                name="type"
              >
                <Radio.Group>
                  {RadioList.map((item: any) => {
                    return (
                      <Radio key={item.value} value={item.value}>
                        {item.label}
                      </Radio>
                    );
                  })}
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span="12">
              <Form.Item
                label="证照号"
                name="licenseNo"
                rules={[
                  {
                    required: true,
                    message: "请输入证照号",
                  },
                ]}
              >
                <Row>
                  <Col span="18">
                    <Input
                      value={uploadValue["licenseNo"]}
                      placeholder="请输入证照号"
                      onChange={(e: any) => {
                        setUploadValue({
                          ...uploadValue,
                          licenseNo: e.target.value,
                        });
                        form.setFieldValue("licenseNo", e.target.value);
                      }}
                    ></Input>
                  </Col>
                  <Col span="6">
                    <span className={styles.tooltip_warning}>!请仔细核对</span>
                  </Col>
                </Row>
              </Form.Item>
              {/*  */}
              <Form.Item
                label="经营者"
                name="legal"
                rules={[
                  {
                    required: true,
                    message: "请输入经营者",
                  },
                ]}
              >
                <Input placeholder="请输入经营者"></Input>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span="12">
              <Form.Item
                label="经营地址"
                name="businessPlace"
                rules={[
                  {
                    required: true,
                    message: "请输入经营地址",
                  },
                ]}
              >
                <Input placeholder="请输入经营地址"></Input>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div className={styles.upload_business_license_btn_center}>
          <Button type="primary" onClick={handleProcess}>
            完成
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UploadBusinessLicense;
