import { useRequest } from 'ahooks'
import { Modal, Select } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import React,{memo, useContext} from 'react'
import { EntityDetailContext } from '../../hooks/useEntityDetailLogic'
import axios from 'axios'
import { getInvoiceCategory } from '@src/services/api/invoice/invoice'
import { getInvoiceItems } from '../../promises'
import { FormInstance } from 'antd/lib/form'
const defaultProps = {}
type props = {form:FormInstance}
export type AddInvoiceItemProps = Required<typeof defaultProps> & props & React.HTMLAttributes<HTMLDivElement>
export const AddInvoiceItemFormFields:React.FC<React.PropsWithChildren<AddInvoiceItemProps>> = memo((props)=>{
  const invoiceCategories = useRequest(async ()=>axios.get(getInvoiceCategory).then((res)=>res.data.data as {invoiceCategoryShort:string}[]))
  const invoiceProjectNames = useRequest(getInvoiceItems)
  return <>
      <FormItem hidden name="invoiceItems" />
    <FormItem label='税收分类简称' name="invoiceCategoryShort" rules={[{message:'请选择税收分类简称',required:true}]}>
      <Select
        placeholder='请选择'
        fieldNames={{ label: 'invoiceCategoryShort', value: 'invoiceCategoryShort'}}
        options={invoiceCategories.data} loading={invoiceCategories.loading}
        onChange={(value => {
          props?.form.setFieldValue('invoiceCategoryCode', undefined)
          invoiceProjectNames.run({
            invoiceCategoryShort: value,
          })
        })}
      />
    </FormItem>
    <FormItem label='发票项目名称' name="invoiceCategoryCode"  rules={[{message:'请选择发票项目名称',required:true}]}>
      <Select placeholder='请选择' mode='multiple' options={invoiceProjectNames.data?.map((item)=>({label:item.invoiceProjectName,value: item.invoiceCategoryCode}))} loading={invoiceProjectNames.loading}
        onChange={(_,options)=>{
          if(Array.isArray(options))
            props?.form.setFieldsValue({
              invoiceItems: (options).map((option)=>({      code: option.value,
                name: option.label}))
            })
        }}
      />
    </FormItem>
  </>
})
AddInvoiceItemFormFields.defaultProps = defaultProps