// 审批流程  不同状态下控制不同的按钮操作
import React, { useMemo, useEffect, useState } from "react";
import ModalCom from "../../../../../../../../../../components/ModalCom";
import { Button, message, Select, Form, Input, Row, Col, Radio } from "antd";
import { uuid } from "../../../../../../../../../../utils/index";
import { useNavigate, useParams } from "react-router-dom";
import "./index.scss";
import {
  licenseProcess, // 流程推进
  getUserList, // 获取所有的user
  assign, // 分配办税人
  download, // 资料下载
} from "../../../../../../../promise";
const Option = Select.Option;
const { TextArea } = Input;
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 16,
  },
};
// 审批流程及对应的操作按钮事件
const RenderOperationButton = (props: any) => {
  const { details, codeList, update, isAssignor, taxInfo } = props;
  const searchParams = useParams();
  const navigate = useNavigate();
  const local = localStorage.getItem("operation_authorization") || "{}";
  const [declareCycleType, setDeclareCycleType] = useState(true); //
  const [taxNum, setTaxNum] = useState(""); // 税盘号
  const [sign] = Form.useForm(); // 登记 申请周期 和 税盘号
  // 控制ModalCom的显示隐藏
  const [openRef, setOpenRef] = useState({
    APPROVAL_REJECTION: false, // 审核驳回
    NO_LICENSE: false, // 审核通过
    LICENSE_FAIL: false, // 办理失败
    TAX_REJECTION: false, // 登记失败
    TAX_PASS: false, // 登记成功
  });
  // 审核要求
  const [approleOpen, setApproleOpen] = useState(false);
  const [openTax, setOpenTax] = useState(false); // 分配办税人

  // 选择用户数据
  const [selectUser, setSelectUser] = useState<any>({});
  // 获取办税人数据
  const [TaxOfficerList, setTaxOfficerList] = useState<any>([]);
  /***
   * 按钮权限
   */
  // 营业执照创建人  重发短信，编辑，个体户注销， 撤回注销
  const isCreateUser = useMemo(() => {
    return JSON.parse(local)?.userId === details?.createUser;
  }, [details, local]);
  // 审核人  审核通过，驳回
  const isToExamine = useMemo(() => {
    return JSON.parse(local)?.userId === details?.approvalUserId;
  }, [details, local]);
  // 营业执照办理人 资料打包下载，办理失败， 上传执照注销证明
  const isTransactors = useMemo(() => {
    return JSON.parse(local)?.userId === details?.licenseAgentId;
  }, [details, local]);
  //   个体户注销权限 撤回注销申请isTaxpayer
  const isIndividualCancellation = useMemo(() => {
    return (
      codeList.includes("agency_processing_deRegistration") || isCreateUser
    );
  }, [isCreateUser, codeList]);
  // 办税人 开始登记，登记失败， 登记成功，上传清税证明
  const isTaxpayer = useMemo(() => {
    return JSON.parse(local)?.userId === details?.taxAgentId;
  }, [details, local]);
  // 登记成功所选择负责人  多个  默认显示一条空的数据
  const [taxList, setTaxList] = useState<any>([
    {
      value: "",
      id: uuid(),
      account: "",
      params: {},
    },
  ]);
  // 所有用户数据
  const [user, setUser] = useState<any>([]);
  // 备份所有用户数据  users
  const [backUpUser, setBackUpUser] = useState<any>([]);
  // 审核要求
  const approleRole = useMemo(() => {
    return ["APPROVAL", "APPROVAL_REJECTION"].includes(details?.applyStatus);
  }, [details]);
  const [licenseForm] = Form.useForm(); // 办理失败
  const [taxForm] = Form.useForm(); // 登记失败
  const [approvalForm] = Form.useForm(); // 审核失败
  // 是否显示  编辑和终止注销流程  操作
  const editList = useMemo(() => {
    return [
      {
        role: true, // 权限控制是否显示
        operation: (
          <Button
            onClick={() => {
              navigate(`/business/administration/edit/${details?.id}`);
            }}
          >
            编辑
          </Button>
        ), // 操作项
      },
    ];
  }, [details]);
  // 个体户注销操作按钮
  const cancellationOperation = useMemo(() => {
    return [
      {
        role: isIndividualCancellation,
        operation: (
          <Button
            type="primary"
            onClick={() => {
              navigate("/business/logout/apply/add");
            }}
          >
            个体户注销
          </Button>
        ),
      },
    ];
  }, [isIndividualCancellation]);
  // 资料打包下载
  const downloadOperation = useMemo(() => {
    return [
      {
        role: true,
        operation: (
          <Button type="primary" onClick={() => handleDownload()}>
            资料打包下载
          </Button>
        ),
      },
    ];
  }, []);
  // 终止申请操作
  const stopApplicationOperation = useMemo(() => {
    return [
      {
        role: true,
        operation: (
          <Button type="primary" onClick={() => handleOk("TERMINATION")}>
            终止申请
          </Button>
        ),
      },
    ];
  }, []);
  // 审核通过
  const applicationSuccessOperation = useMemo(() => {
    return [
      {
        role: isToExamine,
        operation: (
          <Button type="primary" onClick={() => handleOpen("NO_LICENSE")}>
            审核通过
          </Button>
        ),
      },
    ];
  }, [isToExamine]);
  // 登记成功  操作
  const registrationSuccessOperation = useMemo(() => {
    return [
      {
        role: isTaxpayer,
        operation: (
          <Button type="primary" onClick={() => handleOpen("TAX_PASS")}>
            登记成功
          </Button>
        ),
      },
    ];
  }, [isTaxpayer]);
  // 流程推进
  const handleProcess = async (type: string, object?: any) => {
    if (!type || !searchParams?.id) return;
    const params = {
      applyStatus: type,
      ...object,
    };
    if (type === "TAX_PASS") {
      Object.assign(params, {
        declareCycleType: sign.getFieldValue("declareCycleType"),
        taxNum: sign.getFieldValue("taxNum"),
      });
    }
    const res: any = await licenseProcess(searchParams?.id, params);
    if (res?.code === 200) {
      if (openRef[type] !== undefined) {
        setOpenRef({
          ...openRef,
          [type]: false,
        });
      }

      update(searchParams?.id);
    }
  };

  // 上传注销证明
  const handleOk = (type: any, params?: any) => {
    handleProcess(type, params);
  };
  // 集成所有点击弹窗
  const handleOpen = (type: string) => {
    setOpenRef({
      ...openRef,
      [type]: true,
    });
  };

  // 根据当前状态获取
  // 资料打包下载
  const handleDownload = () => {
    if (!searchParams?.id || searchParams?.id === "") return;
    const downloadName = details?.legal + "营业执照办理申请";
    download(searchParams?.id, downloadName).then((data: any) => {
      update(searchParams?.id);
    });
  };
  // 分配办税人
  const handleAssign = () => {
    // const params ={
    //   userId: selectUser.id
    // }
    assign(searchParams?.id, JSON.stringify(selectUser.id)).then((res: any) => {
      if (res.code === 200) {
        setOpenTax(false);
        message.success("分配办税人成功");
        update(searchParams?.id);
      } else {
        message.error(res.message);
      }
    });
  };

  // 获取办税人数据  根据角色获取数据
  const getUserListAutho = () => {
    const params = {
      roleCodes: "TaxOfficer",
    };
    getUserList(params).then((res: any) => {
      if (res.code === 200) {
        setTaxOfficerList(res.data);
      }
    });
  };
  const getConductors = () => {
    const params = {
      roleCodes: "conductor",
    };
    getUserList(params).then((res: any) => {
      if (res.code === 200) {
        setUser(res.data);
        setBackUpUser(res.data);
      }
    });
  };
  useEffect(() => {
    getUserListAutho();
    getConductors();
  }, []);
  // 根据当前 详情操作 显示对用的按钮
  const handleButton = useMemo(() => {
    // 审核状态 对应的button
    let objs = {
      NO_APPROVAL: [...editList, ...stopApplicationOperation], // 待审核
      APPROVAL: [
        {
          role: isToExamine,
          operation: (
            <Button
              danger
              onClick={
                () => handleOpen("APPROVAL_REJECTION") // 审核驳回
              }
            >
              审核驳回
            </Button>
          ),
        },
        ...applicationSuccessOperation, // 审核通过
        ...stopApplicationOperation, // 终止申请
        {
          role: approleRole,
          operation: (
            <Button
              onClick={() => {
                setApproleOpen(true);
              }}
            >
              审核要求
            </Button>
          ),
        },
      ], // 审核中
      APPROVAL_REJECTION: [
        ...editList,
        {
          role: isToExamine,
          operation: (
            <Button
              disabled
              onClick={
                () => handleOpen("APPROVAL_REJECTION") // 审核驳回
              }
            >
              审核驳回
            </Button>
          ),
        },
        ...applicationSuccessOperation, // 审核通过
        ...stopApplicationOperation, // 终止申请
        {
          role: approleRole,
          operation: (
            <Button
              onClick={() => {
                setApproleOpen(true);
              }}
            >
              审核要求
            </Button>
          ),
        },
      ], // 审核驳回
      NO_LICENSE: [
        {
          role: isTransactors,
          operation: (
            <Button
              type="primary"
              onClick={() => handleProcess("SUBMIT_BUSINESS")}
            >
              开始办理营业执照
            </Button>
          ),
        },
        ...downloadOperation, //资料打包下载
        ...stopApplicationOperation, // 终止申请
      ], // 执照待办理
      SUBMIT_BUSINESS: [
        {
          role: isTransactors,
          operation: (
            <Button
              type="primary"
              onClick={() => handleOpen("LICENSE_FAIL")}
              danger
            >
              办理失败
            </Button>
          ),
        },
        ...downloadOperation, //资料打包下载
        ...stopApplicationOperation, // 终止申请
      ], // 提交工商
      LICENSE_FAIL: [
        {
          role: isTransactors,
          operation: (
            <Button
              type="primary"
              // onClick={() => handleOpen("LICENSE_FAIL")}
              disabled
              danger
            >
              办理失败
            </Button>
          ),
        },
        ...downloadOperation, //资料打包下载
        ...stopApplicationOperation, // 终止申请
        ...editList,
      ], // 办理失败
      NO_ASSIGN_TAX: [
        ...cancellationOperation, // 个体户注销
        {
          role: isTaxpayer,
          operation: (
            <Button type="primary" onClick={() => handleOk("TAX_REGISTERING")}>
              开始登记
            </Button>
          ),
        },
        {
          role: isAssignor,
          operation: (
            <Button
              type="primary"
              onClick={() => {
                setOpenTax(true);
              }}
            >
              分配办税人
            </Button>
          ),
        },
      ], //待分配办税员
      TAX_REGISTERING: [
        ...cancellationOperation, // 个体户注销
        {
          role: isTaxpayer,
          operation: (
            <Button
              type="primary"
              danger
              onClick={() => handleOpen("TAX_REJECTION")}
            >
              登记失败
            </Button>
          ),
        },
        ...registrationSuccessOperation, // 登记成功
      ], // 税务登记中
      TAX_PASS: cancellationOperation, // 税务登记成功
      TAX_REJECTION: [
        ...cancellationOperation, // 个体户注销
        {
          role: isTaxpayer,
          operation: (
            <Button
              type="primary"
              disabled
              // onClick={() => handleOpen("TAX_REJECTION")}
            >
              登记失败
            </Button>
          ),
        },
        ...registrationSuccessOperation, // 登记成功
      ], // 税务登记失败
      TERMINATION: [], // 已终止
    };
    let keys = details?.applyStatus;
    return objs[keys];
  }, [
    details,
    isToExamine,
    approleRole,
    isTransactors,
    isTaxpayer,
    cancellationOperation,
    isAssignor,
    applicationSuccessOperation,
  ]);
  return (
    <div className="btn_list_container">
      {/* 根据不同状态下   渲染 对应的操作button */}
      {handleButton &&
        handleButton.length !== 0 &&
        handleButton.map((item: any, index: number) => {
          return (
            item.role && (
              <div key={index} className="btn_list_item">
                {item.operation}
              </div>
            )
          );
        })}
      {/* 审核驳回 */}
      <ModalCom
        title="申请驳回原因"
        okText="确认驳回"
        open={openRef.APPROVAL_REJECTION}
        onCancel={() => {
          setOpenRef({
            ...openRef,
            APPROVAL_REJECTION: false,
          });
          approvalForm.resetFields();
        }}
        onOk={() => {
          approvalForm.validateFields().then((res) => {
            handleOk("APPROVAL_REJECTION", { ...res });
          });
        }}
      >
        <Form
          {...layout}
          labelCol={{
            span: 6,
          }}
          form={approvalForm}
        >
          <Form.Item
            label="请输入驳回原因"
            name="reason"
            rules={[
              {
                required: true,
                message: "请输入驳回原因",
              },
            ]}
          >
            <TextArea
              maxLength={50}
              placeholder="多行输入，不超过50字"
            ></TextArea>
          </Form.Item>
          <Form.Item
            label="操作建议"
            name="operationSuggestion"
            // rules={[
            //     {
            //         required: true,
            //         message: "请输入操作建议",
            //     },
            // ]}
          >
            <TextArea
              maxLength={100}
              placeholder="用于个体户小程序显示操作建议，不超过100字"
            ></TextArea>
          </Form.Item>
        </Form>
      </ModalCom>
      {/* 审核通过 */}
      <ModalCom
        title="审核通过"
        okText="确认通过"
        open={openRef.NO_LICENSE}
        onCancel={() => {
          // setOpenSuccess(false);
          setOpenRef({
            ...openRef,
            NO_LICENSE: false,
          });
        }}
        onOk={() => handleOk("NO_LICENSE")}
      >
        <span>
          审核通过后，将转交个体工商户营业照办理人，请仔细确认信息是否准确
        </span>
      </ModalCom>
      {/* 分配办税人 */}
      <ModalCom
        title="分配办税人"
        open={openTax}
        onCancel={() => {
          setOpenTax(false);
        }}
        onOk={() => handleAssign()}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <div>
            <span>
              姓名：
              <Select
                value={selectUser?.name}
                style={{ width: "150px" }}
                onChange={(e) => {
                  const val = JSON.parse(e) || {};
                  setSelectUser(val);
                }}
              >
                {TaxOfficerList.map((item: any) => {
                  return (
                    <Option key={item.id} value={JSON.stringify(item)}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </span>
          </div>
          <div>
            <span>手机号：{selectUser.account}</span>
          </div>
          <div>
            <Button
              type="link"
              onClick={() => {
                setSelectUser({});
              }}
            >
              删除
            </Button>
          </div>
        </div>
      </ModalCom>
      {/* 办理失败原因 */}
      <ModalCom
        title="办理失败原因"
        open={openRef.LICENSE_FAIL}
        onCancel={() => {
          setOpenRef({
            ...openRef,
            LICENSE_FAIL: false,
          });
          licenseForm.resetFields();
        }}
        onOk={() => {
          licenseForm.validateFields().then((res) => {
            handleOk("LICENSE_FAIL", {
              ...res,
            });
          });
        }}
      >
        <Form {...layout} form={licenseForm}>
          <Form.Item
            label="请输入失败原因"
            name="reason"
            rules={[
              {
                required: true,
                message: "请输入失败原因",
              },
            ]}
          >
            <TextArea
              maxLength={50}
              placeholder="多行输入，不超过50字"
            ></TextArea>
          </Form.Item>
          <Form.Item
            label="操作建议"
            name="operationSuggestion"
            // rules={[
            //     {
            //         required: true,
            //         message: "请输入操作建议",
            //     },
            // ]}
          >
            <TextArea
              maxLength={100}
              placeholder="用于个体户小程序显示操作建议，不超过100字"
            ></TextArea>
          </Form.Item>
        </Form>
      </ModalCom>
      {/* 登记失败 */}
      <ModalCom
        title="登记失败原因"
        open={openRef.TAX_REJECTION}
        onCancel={() => {
          setOpenRef({
            ...openRef,
            TAX_REJECTION: false,
          });
          // setFaillReason("");
          taxForm.resetFields();
        }}
        onOk={() => {
          taxForm.validateFields().then((res) => {
            handleOk("TAX_REJECTION", {
              ...res,
            });
          });
        }}
      >
        <Form {...layout} form={taxForm}>
          <Form.Item
            label="请输入失败原因"
            name="reason"
            rules={[
              {
                required: true,
                message: "请输入失败原因",
              },
            ]}
          >
            <TextArea
              maxLength={50}
              placeholder="多行输入，不超过50字"
            ></TextArea>
          </Form.Item>
          <Form.Item
            label="操作建议"
            name="operationSuggestion"
            // rules={[
            //     {
            //         required: true,
            //         message: "请输入操作建议",
            //     },
            // ]}
          >
            <TextArea
              maxLength={100}
              placeholder="用于个体户小程序显示操作建议，不超过100字"
            ></TextArea>
          </Form.Item>
        </Form>
      </ModalCom>
      {/* 登记成功 setStartSuc*/}
      <ModalCom
        title="登记成功"
        open={openRef.TAX_PASS}
        onCancel={() => {
          setOpenRef({
            ...openRef,
            TAX_PASS: false,
          });
        }}
        onOk={() => {
          if (taxList.length === 0 || !taxList[0]?.value) {
            message.error("请选择开票员");
            return;
          }
          let list = taxList.map((item: any) => item.value) || [];
          handleOk("TAX_PASS", {
            invoiceClerkIds: list,
          });
        }}
        okText="确认提交"
      >
        <p>
          办理成功后，买卖双方可以进行交易，请确认税务登记成功后，点击确认成功
        </p>
        {taxList.map((item: any) => {
          return (
            <div
              key={item.id}
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "5px",
              }}
            >
              <div>
                <span>
                  姓名：
                  <Select
                    value={item.params?.name}
                    style={{ width: "150px" }}
                    onChange={(e) => {
                      const val = JSON.parse(e) || {};
                      // setStartObj(val)
                      const res = taxList.map((citem: any) => {
                        if (citem.id === item.id) {
                          (citem.value = val.id), (citem.param = val);
                          citem.account = val.account;
                        }
                        return citem;
                      });
                      setTaxList(res);
                      const newUsers = JSON.parse(JSON.stringify(backUpUser));
                      const userRes = newUsers.filter((cItem: any) => {
                        return !res.some((k: any) => k.param?.id === cItem.id);
                      });
                      setUser(userRes);
                    }}
                  >
                    {user.map((item: any) => {
                      return (
                        <Option key={item.id} value={JSON.stringify(item)}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </span>
              </div>
              <div>
                <span>手机号：{item.account}</span>
              </div>
              <div>
                <Button
                  type="link"
                  onClick={() => {
                    // setStartObj({})
                    const res = taxList.filter((cItem: any) => {
                      return cItem.id !== item.id;
                    });
                    setTaxList(res);
                    // backUpUser
                    const users = backUpUser.filter((item: any) => {
                      return !res.some(
                        (cItem: any) => cItem?.param.id === item.id
                      );
                    });
                    setUser(users);
                  }}
                >
                  删除
                </Button>
              </div>
            </div>
          );
        })}

        {taxList.length < backUpUser.length ? (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              const newArr = JSON.parse(JSON.stringify(taxList));
              newArr.push({
                value: "",
                id: uuid(),
                params: {},
                account: "",
              });

              setTaxList(newArr);
              const users = backUpUser.filter((item: any) => {
                return !taxList.some(
                  (cItem: any) => cItem?.param.id === item.id
                );
              });
              setUser(users);
            }}
          >
            添加开票员
          </span>
        ) : null}
        <Form form={sign} initialValues={{ declareCycleType: "MONTH" }}>
          <Row>
            <Col>
              <Form.Item label="申报周期" name="declareCycleType">
                <Radio.Group
                //   onChange={() => setDeclareCycleType(!declareCycleType)}
                //   value={declareCycleType}
                >
                  <Radio value="MONTH">按月申报</Radio>
                  <Radio value="QUARTER">按季申报</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                label="税盘号"
                name="taxNum"
                rules={[
                  {
                    max: 12,
                    message: "请输入12位纯数字税盘号",
                  },
                  {
                    pattern: /\d{12}/,
                    message: "请输入12位纯数字税盘号",
                  },
                ]}
              >
                <Input placeholder="税盘号为12为纯数字" maxLength={12}></Input>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </ModalCom>
      {/* 审核要求 */}
      <ModalCom
        title="营业执照审核要求"
        isShowFoolter={false}
        open={approleOpen}
        onCancel={() => {
          setApproleOpen(false);
        }}
        className="modal_auditRequirement"
      >
        <div
          dangerouslySetInnerHTML={{
            __html: taxInfo?.auditRequirement,
          }}
        ></div>
      </ModalCom>
    </div>
  );
};

export default RenderOperationButton;
