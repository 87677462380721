import React, {
  CSSProperties,
  FC,
  HTMLAttributes,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { Image, ImageProps } from 'antd';
import { EyeOutlined, ToTopOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import styles from './index.module.scss';

interface Props extends ImageProps {
  actions?: React.ReactNode[];
  customActions?: (previewButton: React.ReactNode) => React.ReactNode;
}

export type EditableImageProps = Props & HTMLAttributes<any>;
export const EditableImage: FC<PropsWithChildren<EditableImageProps>> = props => {
  const { actions, customActions, src, width, height, ...rest } = props;
  const [useSrc, setUseSrc] = useState(src);
  useEffect(() => {
    setUseSrc(src);
  }, [src]);
  const [visible, setVisible] = useState(false);
  const onVisibleChange = (visible: boolean, prevVisible: boolean) => {
    // 只接受关闭功能
    if (visible === false) setVisible(visible);
  };
  const previewButton = <EyeOutlined title="预览" onClick={() => setVisible(true)} />;
  return (
    <div className={classNames(styles.editableImageContainer)}>
      <Image
        onError={() => {
          setUseSrc('/fallback-image.jpg');
        }}
        src={useSrc}
        preview={{
          onVisibleChange,
          visible,
          mask: customActions ? (
            customActions(previewButton)
          ) : (
            <div style={{ display: 'flex', gap: 12, fontSize: 16 }}>
              <div onClick={() => setVisible(true)} title="预览图片">{previewButton}</div>
              {actions}
            </div>
          ),
        }}
        {...rest}
        style={{
          // objectFit: 'cover',
          width,
          height,
        }}
      />
      <div className={styles.introduce}>
        <span className={styles.imageName}>
          {props.title}
        </span>
      </div>
    </div>
  );
};
