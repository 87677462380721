import { FaceDetection } from "face-api.js/build/commonjs/classes/FaceDetection";
// @ts-ignore
import FaceWorker from "../../worker/face.worker.js";
import { createImage } from "./utils";
import { canvasToBlob, imageToCanvas } from "./dataFormatConversion";
console.log(FaceWorker, "FaceWorker");

export type Models =
  | "faceRecognitionNet"
  | "faceLandmark68Net"
  | "faceLandmark68TinyNet"
  | "ssdMobilenetv1"
  | "tinyFaceDetector"
  | "mtcnn";

export interface FaceDetail {
  measure?: number;
  scale?: number;
  models?: Models[];
}

type PosInfo = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export class Cutter {
  x: number;
  y: number;
  w: number;
  h: number;

  constructor(x: number, y: number, w: number, h: number) {
    this.x = x;
    this.y = y;
    this.w = w;
    this.h = h;
  }
  cut(image: CanvasImageSource | OffscreenCanvas) {
    const cvs = document.createElement("canvas");
    cvs.width = this.w;
    cvs.height = this.h;
    const ctx = cvs.getContext("2d");
    ctx?.drawImage(image, this.x, this.y, this.w, this.h, 0, 0, this.w, this.h);
    return cvs;
  }
}
// 将一个不满足标准比例的size变为满足比例的
export class PictureDimension {
  ratioW: number;
  ratioH: number;
  width: number = 0;
  height: number = 0;
  scaleRatio?: number;
  newCenterPoint?: [number, number];
  oldCenterPoint?: [number, number];
  oldPos: [number, number] = [0, 0];
  x: number = 0;
  y: number = 0;

  constructor(ratioW: number, ratioH: number, scaleRatio?: number) {
    this.ratioH = ratioH;
    this.ratioW = ratioW;
    this.scaleRatio = scaleRatio;
  }

  scale() {
    if (this.width && this.height && this.scaleRatio) {
      this.width = this.width * this.scaleRatio;
      this.height = this.height * this.scaleRatio;
    }
  }

  getWRatioH() {
    return this.ratioW / this.ratioH;
  }

  getHRatioW() {
    return this.ratioH / this.ratioW;
  }

  calcNewPos() {
    if (this.newCenterPoint && this.oldCenterPoint) {
      const [newCenterPointW, newCenterPointH] = this.newCenterPoint;
      const [oldCenterPointW, oldCenterPointH] = this.oldCenterPoint;
      const [oldX, oldY] = this.oldPos;
      this.x = oldX - (newCenterPointW - oldCenterPointW);
      this.y = oldY - (newCenterPointH - oldCenterPointH);
    }
  }

  restore(x: number, y: number, width: number, height: number) {
    this.oldPos = [x, y];
    if (width > height) {
      this.width = width;
      this.height = this.getHRatioW() * width;
    } else {
      this.width = this.getWRatioH() * height;
      this.height = height;
    }
    this.scale();

    this.oldCenterPoint = [Math.floor(width / 2), Math.floor(height / 2)];
    this.newCenterPoint = [
      Math.floor(this.width / 2),
      Math.floor(this.height / 2),
    ];
    this.calcNewPos();
  }
}

class Message {
  status?: "success" | "error" | "aborted";

  constructor(status: Message["status"]) {
    this.status = status;
  }
}
type messageItem = { message: string };
export class ErrorMessage extends Message {
  status: "error" = "error";
  type: "general" | "fatal";
  errors: messageItem[] = [];
  constructor(type: ErrorMessage["type"]) {
    super("error");
    this.type = type;
  }
  add(messageItem: messageItem) {
    this.errors.push(messageItem);
    return this;
  }
  joinMessages() {
    return this.errors.join("、");
  }
}

export class SuccessMessage<T> extends Message {
  status: "success" = "success";
  data: T;
  constructor(data: T) {
    super("success");
    this.data = data;
  }
}
export class AbortedMessage<T> extends Message {
  status: "aborted" = "aborted";
  reason: string;
  originMessage?: SuccessMessage<T> | ErrorMessage;

  constructor(
    reason: string,
    originMessage?: SuccessMessage<T> | ErrorMessage
  ) {
    super("aborted");
    this.reason = reason;
    this.originMessage = originMessage;
  }
}

export type DetectionItem = {
  kind: "unique" | "cloned";
  blob?: Blob;
  originElement: HTMLCanvasElement;
};
export class FaceDetectionCenter {
  isDetecting: boolean = false;
  currentDetectingObj?: DetectionItem;
  detectionQueue: DetectionItem[] = [];
  pictureDimension: PictureDimension;
  faceWorker: Worker;

  constructor(pictureDimension: PictureDimension) {
    this.pictureDimension = pictureDimension;
    this.faceWorker = new FaceWorker();
    console.log(this.faceWorker, "-----------");
  }

  cancel() {
    this.detected();
    this.clearDetectionList();
  }
  // 在队尾插入 每一帧图像 视作第一无二的
  public append(detectionItem: Omit<DetectionItem, "kind">) {
    this.detectionQueue.push({ ...detectionItem, kind: "unique" });
    return this;
  }
  // 在队头插入 对同一帧图像的 克隆
  public insert(detectionItem: Omit<DetectionItem, "kind">) {
    this.detectionQueue.unshift({ ...detectionItem, kind: "cloned" });
    return this;
  }
  // 查看队头元素
  public peek() {
    return this.detectionQueue[0];
  }

  public getDetectingItem() {
    return this.currentDetectingObj;
  }

  public getDetectingInstance() {
    return this.currentDetectingObj?.originElement;
  }

  public getDetectingKind() {
    return this.currentDetectingObj?.kind;
  }

  /**
   * 出队并返回
   * @protected
   */
  protected seek() {
    return (this.currentDetectingObj = this.detectionQueue.shift());
  }

  public getContext() {
    return this.getDetectingInstance()?.getContext("2d");
  }
  public getImageData() {
    return this.getContext()?.getImageData(
      0,
      0,
      this.getDetectingInstance()?.width || 0,
      this.getDetectingInstance()?.height || 0
    );
  }

  protected detecting() {
    this.isDetecting = true;
  }

  protected detected() {
    this.isDetecting = false;
  }

  public clearDetectionList() {
    this.detectionQueue = [];
  }

  private cutImage(image: CanvasImageSource | OffscreenCanvas, box: PosInfo) {
    this.pictureDimension.restore(box.x, box.y, box.width, box.height);
    const { x, y, width, height } = this.pictureDimension;
    const cutter = new Cutter(x, y, width, height);
    return cutter.cut(image);
  }

  private async cutoutFacePhoto(
    canvasImageSource: CanvasImageSource | OffscreenCanvas,
    faceBoxList: FaceDetection[]
  ) {
    const boxList = faceBoxList.map((face: any) => {
      const {
        _x: x = 0,
        _y: y = 0,
        _width: width = 0,
        _height: height = 0,
      } = face?._box || {};
      return { x, y, width, height };
    });
    const cvsBoxList = boxList.map((box) =>
      this.cutImage(canvasImageSource, box)
    );
    const blobListPromise = cvsBoxList.map((cvsBox) => canvasToBlob(cvsBox));
    return Promise.all(blobListPromise);
  }

  public canContinueDetect() {
    return Boolean(this.detectionQueue.length);
  }

  public onMessage(
    callback: (
      e: (SuccessMessage<Blob[]> | ErrorMessage | AbortedMessage<Blob[]>) &
        DetectionItem
    ) => void
  ) {
    const faceWorkerOnMessage = async (e: {
      data: SuccessMessage<FaceDetection[]> | ErrorMessage;
    }) => {
      // 执行回调
      let resultMessageInstance:
        | SuccessMessage<Blob[]>
        | ErrorMessage
        | AbortedMessage<Blob[]>;
      const detectingItem = this.getDetectingItem();
      try {
        if (!detectingItem) return;
        switch (e.data.status) {
          case "success":
            const faces = await this.cutoutFacePhoto(
              detectingItem.originElement!,
              e.data.data
            );
            resultMessageInstance = new SuccessMessage(faces);
            break;
          case "error":
            resultMessageInstance = e.data;
            break;
        }
      } catch (e: any) {
        resultMessageInstance = new ErrorMessage("fatal").add({
          message: e?.message
            ? "检测信息接口异常：" + e?.message
            : "检测信息接口异常",
        });
      }
      resultMessageInstance = this.isDetecting
        ? resultMessageInstance
        : new AbortedMessage("执行在中断", resultMessageInstance);
      this.detected();
      callback(Object.assign(resultMessageInstance, detectingItem));
    };
    // 清理监听
    const destroy = () =>
      this.faceWorker?.removeEventListener("message", faceWorkerOnMessage);
    destroy();
    this.faceWorker?.addEventListener("message", faceWorkerOnMessage);
    return destroy;
  }

  terminateWorker() {
    return this.faceWorker?.terminate();
  }

  private detect() {
    const detectingInstance = this.getDetectingInstance();
    if (!detectingInstance) return;
    const imageData = this.getImageData();
    // @ts-ignore
    this.faceWorker?.postMessage(
      {
        type: "send-img-data",
        w: detectingInstance?.width,
        h: detectingInstance?.height,
        buffer: imageData?.data.buffer,
      },
      [imageData?.data.buffer]
    );
  }

  public detectNext(): boolean {
    if (!this.canContinueDetect()) return console.warn("检测队列为空"), false;
    this.detecting();
    this.seek();
    this.detect();
    return true;
  }

  public startDetect() {
    if (this.isDetecting) return false;
    return this.detectNext();
  }
}

export class FaceDetectionScheduler {
  mode: "block" | "debounce";
  faceDetectionCenter: FaceDetectionCenter;

  constructor(
    faceDetectionCenter: FaceDetectionCenter,
    mode: FaceDetectionScheduler["mode"] = "debounce"
  ) {
    this.mode = mode;
    this.faceDetectionCenter = faceDetectionCenter;
  }

  public async appendWithBlob(blob: Blob) {
    console.log("-----------------<");
    const url = URL.createObjectURL(blob);
    const image = await createImage(url);
    const canvas = imageToCanvas(image);
    URL.revokeObjectURL(url);
    this.jumpQueueHandler();
    this.faceDetectionCenter.append({ originElement: canvas, blob: blob });
    return this;
  }

  protected jumpQueueHandler() {
    switch (this.mode) {
      case "debounce":
        if (
          this.faceDetectionCenter.canContinueDetect() ||
          this.faceDetectionCenter.isDetecting
        )
          this.faceDetectionCenter.cancel();
        break;
      case "block":
        break;
    }
  }

  public retry(
    transformNewItemFnList?: ((
      current: HTMLCanvasElement
    ) => HTMLCanvasElement)[]
  ): void {
    const detectingInstance = this.faceDetectionCenter.getDetectingItem();
    if (!detectingInstance) return console.warn("无可重试对象");
    if (transformNewItemFnList)
      [...transformNewItemFnList].reverse().forEach((transformFn) =>
        this.faceDetectionCenter.insert({
          ...detectingInstance,
          originElement: transformFn(detectingInstance.originElement),
        })
      );
    //使用外部提供的转换函数生成检测对象进行重试
    else
      this.faceDetectionCenter.insert({
        ...detectingInstance,
        originElement: imageToCanvas(detectingInstance.originElement),
      }); // 直接使用队头进行重新检测

    this.faceDetectionCenter.startDetect();
  }
}
