import React, { useEffect, useState } from "react";
import { Select, Button, message } from "antd";
import {
    editCustomerPermission,
    getCustomerPermissionInfo,
    getUsersList,
    getUsersGrops,
} from "../../../../../promise";
import "./index.scss";
// 通知范围
const uuid = () => {
    let date = new Date().valueOf(); //获取时间戳
    let txt = "1234567890"; //生成的随机机器码
    let len = 13; //机器码有多少位
    let pwd = ""; //定义空变量用来接收机器码
    for (let i = 0; i < len; i++) {
        pwd += txt.charAt(Math.floor(Math.random() * txt.length)); //循环机器码位数随机填充
    }
    return date + pwd;
};
const Option = Select.Option;
const Permission = (props: any) => {
    const { details, isShowEdit } = props;
    const [isAxiosS, setIsAxios] = useState(false);
    const [showSelf, setShowSelf] = useState(false); // 控制个人  获取数据后显示
    const [isGropsEdit, setIsGropsEdit] = useState(false);
    const [isUserEdit, setIsUserEdit] = useState(false);
    // 备份个人数据
    const [backUpData, setBackUpData] = useState([]);
    // 用户组数据备份
    const [backUpDataGrops, setBackUpDataGrops] = useState([]);
    const [selectSelfs, setSelectSelfs] = useState<any>([]);
    const [backSelectSelfs, setBackSelectSelfs] = useState<any>([]);

    const [selectGrops, setSelectGrops] = useState<any>([]);
    const [backSelectGrops, setBackSelectGrops] = useState<any>([]);

    // 更新用户组和个人
    const handleUpdate = (object?: any, type?: any) => {
        if (!details.id) return;
        // const uList = selectSelfs.map((item:any) => {
        //   return item.value
        // })
        // const gList = selectGrops.map((item:any) => item.value)
        const params = {
            ...object,
        };
        editCustomerPermission(details?.id, params).then((res: any) => {
            if (res.code === 200) {
                message.success("更新成功");
                getNotify(details?.id, type);
            } else {
                message.error(res.message || "更新失败");
            }
        });
    };

    // // 获取已添加用户组数据
    // const [nofifyList, setNotifyList] = useState<any>({})
    // // 更新用户所选数据

    // 获取用户组数据
    const getGrops = () => {
        const params = {
            current: 1,
            size: 999999,
        };
        getUsersGrops(params).then((res: any) => {
            if (res.code === 200) {
                setBackUpDataGrops(res.data.records);
            }
        });
    };
    // 获取个人数据

    const getUsers = () => {
        const params = {
            current: 1,
            size: 99999,
        };
        getUsersList(params).then((res: any) => {
            if (res.code === 200) {
                setBackUpData(res.data.records);
            }
        });
    };
    // 用户组数据查询
    const getNotify = (id?: any, type?: any) => {
        if (!id) return;
        getCustomerPermissionInfo(id).then((res: any) => {
            if (res.code === 200) {
                if (type === "cancel") {
                    setIsGropsEdit(false);
                }
                if (type === "user") {
                    setIsUserEdit(false);
                }
                // setNotifyList(res.data)
                const newObj = JSON.parse(JSON.stringify(res.data));
                const groups = newObj.groups.map((item: any) => {
                    return item.groupId;
                });
                const users = newObj.users.map((item: any) => {
                    return item.userId;
                });
                setIsAxios(true);
                setShowSelf(true);
                // userGrops
                // if (!type) {
                    //保存之前 缓存第一次请求后的数据
                    setSelectGrops(groups);
                    setSelectSelfs(users);
                // }
                setBackSelectSelfs(users);
                setBackSelectGrops(groups);
            }
        });
    };
    const handleSubmitGrops = () => {
        setIsGropsEdit(false);
        const params = {
            groupIds: selectGrops,
            userIds: backSelectSelfs,
        };
        handleUpdate(params, "cancel");
    };
    const handleSubmitUsers = () => {
        setIsUserEdit(false);
        const params = {
            groupIds: backSelectGrops,
            userIds: selectSelfs,
        };
        handleUpdate(params, "user");
    };
    useEffect(() => {
        getUsers();
        getGrops();
        getNotify(details?.id);
    }, [details]);
    return (
        <div style={{ paddingLeft: "50px" }}>
            {/* <div>
                <span
                    style={{
                        display: "inline-block",
                        width: "5px",
                        height: "12px",
                        backgroundColor: "#0077ff",
                        borderRadius: "2px",
                    }}
                ></span>{" "}
                权限设置
            </div> */}
            <div>
                <span className="span_label">展示用户组:</span>
                {/* {isAxiosS ? ( */}
                <Select
                    disabled={!isGropsEdit}
                    value={selectGrops}
                    showSearch
                    style={{ minWidth: "350px" }}
                    onChange={(e: any) => {
                        setSelectGrops(e);
                    }}
                    mode="tags"
                >
                    {backUpDataGrops.map((item: any) => {
                        return (
                            <Option
                                key={item.id}
                                disabled={item.name == "高管组"}
                                value={item.id}
                            >
                                {item.name}
                            </Option>
                        );
                    })}
                </Select>
                {/* ) : null} */}

                {isShowEdit ? (
                    <span style={{ marginLeft: "10px" }}>
                        {!isGropsEdit ? (
                            <Button
                                type="primary"
                                onClick={() => {
                                    setIsGropsEdit(true);
                                }}
                            >
                                编辑
                            </Button>
                        ) : (
                            <>
                                <Button
                                    type="primary"
                                    onClick={() => handleSubmitGrops()}
                                >
                                    保存
                                </Button>
                                <Button
                                    onClick={() => {
                                        // setIsGropsEdit(false)
                                        setIsAxios(false);
                                        getNotify(details?.id, "cancel");
                                    }}
                                >
                                    取消
                                </Button>
                            </>
                        )}
                    </span>
                ) : null}
            </div>
            <div style={{ marginTop: "10px" }}>
                <span className="span_label">展示个人:</span>
                {/* {showSelf ? ( */}
                <Select
                    disabled={!isUserEdit}
                    value={selectSelfs}
                    showSearch
                    allowClear
                    style={{ minWidth: "350px" }}
                    onChange={(e: any) => {
                        setSelectSelfs(e);
                    }}
                    mode="tags"
                >
                    {backUpData.map((item: any) => {
                        return (
                            <Option key={item.id} value={item.id}>
                                {item.name}
                            </Option>
                        );
                    })}
                </Select>
                {/* ) : null} */}
                {isShowEdit ? (
                    <span style={{ marginLeft: "10px" }}>
                        {!isUserEdit ? (
                            <Button
                                type="primary"
                                onClick={() => {
                                    setIsUserEdit(true);
                                }}
                            >
                                编辑
                            </Button>
                        ) : (
                            <>
                                <Button
                                    type="primary"
                                    onClick={() => handleSubmitUsers()}
                                >
                                    保存
                                </Button>
                                <Button
                                    onClick={() => {
                                        // setIsUserEdit(false)
                                        setShowSelf(false);
                                        getNotify(details?.id, "user");
                                    }}
                                >
                                    取消
                                </Button>
                            </>
                        )}
                    </span>
                ) : null}
            </div>
        </div>
    );
};

export default Permission;
