import { cross_url_env } from "@/services/util";
/**
 *  税票系统的baseURL
 * @returns string
 */
export function invoiceURL() {
  let current_env = process.env.REACT_APP_ENV || "production";
  const baseURL: any = {
    development: "https://invoice-dev.saikul.com",
    test: "https://invoice-test.saikul.com",
    production: "https://invoice-test.saikul.com",
  }[current_env];
  return baseURL;
}
/**
 *
 * @returns
 */
export function financeURL() {
  return cross_url_env + "/finance/admin";
}


// 上传
export function uploadURL() {
  return cross_url_env + "/finance";
}